import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withApollo, graphql, Query } from 'react-apollo';
import { PEDIDO_CLIENTE } from '../../services/graphql/querys/pedido';
import CustomErrors from '../CustomErrors/CustomErrors';

import {
    TextField,
    Grid,
    MenuItem,
    Fab,
    FormControlLabel,
    Checkbox,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    DialogTitle,
    Dialog,
    List,
    ListItem,
} from '@material-ui/core';

import MaskedInput from 'react-text-mask';
import { Save, Edit, Add } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '30%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    },
    image: {
        width: '3rem',
        height: '3rem'
    }

});

class VerConferido extends Component {
    constructor(props){
        super(props);
        this.state = {
            items: null,
            fullFilments: null,
            error: [],
        }
    }
    componentWillMount(){
        console.log("Props: ", this.props)
        this.props.client.query({query: PEDIDO_CLIENTE, variables: {id: this.props.id}, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("DATA: ", data.PurchaseOrdersQuery.data[0].fulfillment)
               
                this.setState({fullFilments: data.PurchaseOrdersQuery.data[0].fulfillment.fulfillments_by_product_origin})
            }
        })
    }
          
    render() {
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <Grid className={this.props.classes.root} item xl={12}>
                    <TableContainer component={Paper}>
                        <Table className={this.props.classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Produto</TableCell>
                                    <TableCell align="center">Nome</TableCell>
                                    <TableCell align="center">Origens</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {!!this.state.fullFilments && this.state.fullFilments.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell >
                                        <img src={row.product.picture ? row.product.urlPicture : "https://crestana.com.br/img/imagens_produto/20190726_200300_1____01%20PRODUTO-SEM-IMAGEM-1000X1000.JPG"} className={this.props.classes.image} />
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">{row.product.comercial_name}</TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        <Table className={this.props.classes.table} aria-label="simple table">
                                            <TableRow>
                                                <TableCell>Vendedor</TableCell>
                                                <TableCell>Quantidade</TableCell>
                                            </TableRow>
                                            {row.origins.map(origem => (
                                                <TableRow key={origem.seller.farmer_user_id}>
                                                    <TableCell >{origem.seller.user.profile.name}</TableCell>                                                
                                                    <TableCell >{origem.quantity.toFixed(1).replace(".",",")} Kg</TableCell>                                               
                                                </TableRow>
                                            ))}
                                           
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </div>
        )
    }
}
export default withApollo(withStyles(styles)(VerConferido));