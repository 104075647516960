import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { EDIT_OWN_RATION, DELETE_OWN_RATION, EDIT_RATION_COMPOSITION, EDIT_ANIMAL_FEEDING_MEDICATION, DELETE_ANIMAL_FEED_MEDICATION,EDIT_ANIMAL_FEEDING_PLAN } from '../../../services/graphql/mutations/management';
import { OWN_RATION_INGREDIENT,RATION_COMPOSITION, ANIMAL_FEEDING_MEDICATION, FEEDING_PLAN, VARIAVEIS_QUERY } from '../../../services/graphql/querys/manejo';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';
import MaskedInput from 'react-text-mask';
import gql from 'graphql-tag';

import {
    TextField,
    Grid,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormGroup,
    MenuItem,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';
import { Add, Print, Save, Delete } from '@material-ui/icons';


const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    input: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },  
    radioClass: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },    
    divisor: {
        width: "100%",
        height: "3px",
        backgroundColor: "#000",
        marginTop: "3px",
        marginBottom: "10px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});



class CadProducaoAnimal2 extends Component { 
    constructor(props) {
        super(props);
            this.state = {
                management_id: this.props.manejo.manejo.management_id,
                ingredientes: null,
                catAnimais: null,
                catAlimento: null,
                ration_ingredient_id: null,
                management_description: null,
                own_production: null,
                buy_out: null,
                organic: null,
                non_organic: null,
                animal_category_id: null,
                ecological_feeding: null,
                conventional_feeding: null,
                ecological_medication: null,
                conventional_medication: null,
                feeding_category_id: null,
                january: null,
                february: null,
                march: null,
                april: null,
                may: null,
                june: null,
                july: null,
                august: null,
                september: null,
                october: null,
                november: null,
                december: null,
            };
    } 

    componentDidMount(){
        this.props.client.query({query: VARIAVEIS_QUERY, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("Data Ingred: ", data)
                let ingredientes = data.RationIngredientQuery;
                let catAnimais = data.AnimalCategoryQuery;
                let catAlimento = data.FeedingCategoryQuery;

                this.setState({ingredientes: ingredientes, catAnimais: catAnimais, catAlimento: catAlimento })
            }
        })
    }
    
    addORationComposition(){
        console.log("Faz Mutation", this.state)

        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate(
            {
                mutation: EDIT_RATION_COMPOSITION, 
                variables: paramsEnviar,
                errorPolicy: 'all',
                refetchQueries:[{
                    query: RATION_COMPOSITION,
                    variables: 
                    { 
                        management_id: this.state.management_id,
                    }
                }]
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            }else{
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");                
            }
        })
    }

    addOwnRationIngredient(){
        console.log("Faz Mutation", this.state)

        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate(
            {
                mutation: EDIT_OWN_RATION, 
                variables: paramsEnviar,
                errorPolicy: 'all',
                refetchQueries:[{
                    query: OWN_RATION_INGREDIENT,
                    variables: 
                    { 
                        management_id: this.state.management_id,
                    }
                }]
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            }else{
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");                
            }
        })
    }

    addAnimalFeedMedication(){
        console.log("Faz Mutation", this.state)

        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate(
            {
                mutation: EDIT_ANIMAL_FEEDING_MEDICATION, 
                variables: paramsEnviar,
                errorPolicy: 'all',
                refetchQueries:[{
                    query: ANIMAL_FEEDING_MEDICATION,
                    variables: 
                    { 
                        management_id: this.state.management_id,
                    }
                }]
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            }else{
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");                
            }
        })
    }
    addAnimalFeedPlan(){
        console.log("Faz Mutation", this.state)

        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate(
            {
                mutation: EDIT_ANIMAL_FEEDING_PLAN, 
                variables: paramsEnviar,
                errorPolicy: 'all',
                refetchQueries:[{
                    query: FEEDING_PLAN,
                    variables: 
                    { 
                        management_id: this.state.management_id,
                    }
                }]
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            }else{
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");                
            }
        })
    }
    
    removeOwnRation(id){
        console.log("Remove Esse: ",id)
        this.props.client.mutate({
            mutation: DELETE_OWN_RATION, 
            variables: {management_id: this.state.management_id, ration_ingredient_id: id}, 
            errorPolicy: 'all',
            refetchQueries:[{
                query: OWN_RATION_INGREDIENT,
                variables: 
                { 
                    management_id: this.state.management_id,
                }
            }]
        })
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({open: false, remover: null});
                alert("Erro ao remover produto!"); 
            } else {
                console.log("DATA: ", data);
                alert("Produto removido!");
            }
        })
    }
    removeAnimalFeedMedication(id){
        console.log("Remove Esse: ",id)
        this.props.client.mutate({
            mutation: DELETE_ANIMAL_FEED_MEDICATION, 
            variables: {management_id: this.state.management_id, animal_category_id: id}, 
            errorPolicy: 'all',
            refetchQueries:[{
                query: ANIMAL_FEEDING_MEDICATION,
                variables: 
                { 
                    management_id: this.state.management_id,
                }
            }]
        })
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({open: false, remover: null});
                alert("Erro ao remover produto!"); 
            } else {
                console.log("DATA: ", data);
                alert("Produto removido!");
            }
        })
    }
    renderOwnRationIngredient(ingredientes){
        return  ingredientes.map((ingrediente) =>
                    <TableRow>
                        <TableCell>{ingrediente.ration_ingredient_name}</TableCell>
                        <TableCell>{ingrediente.management_description}</TableCell>
                        <TableCell>
                            <Fab variant="extended" color="primary" onClick={() => this.removeOwnRation(ingrediente.ration_ingredient_id)}>
                                <Delete />
                                Remover
                            </Fab>
                        </TableCell>
                    </TableRow>
                )
    }

    renderRationComposition(rations){
        return  rations.map((ration) =>
                    <TableRow>
                        <TableCell>{ration.ration_ingredient_name} () %</TableCell>
                        <TableCell>{ration.own_production} %</TableCell>
                        <TableCell>{ration.buy_out} %</TableCell>
                        <TableCell>{ration.organic} %</TableCell>
                        <TableCell>{ration.non_organic} %</TableCell>
                        {/* <TableCell>
                            <Fab variant="extended" color="primary" onClick={() => this.removeRationComposition(ration.ration_ingredient_id)}>
                                <Delete />
                                Remover
                            </Fab>
                        </TableCell> */}
                    </TableRow>
                )
    }
    renderAnimalFeedingMedication(animais){
        return  animais.map((animal) =>
                    <TableRow>
                        <TableCell>{animal.animal_category}</TableCell>
                        <TableCell>{animal.ecological_feeding} %</TableCell>
                        <TableCell>{animal.conventional_feeding} %</TableCell>
                        <TableCell>{animal.ecological_medication} %</TableCell>
                        <TableCell>{animal.conventional_medication} %</TableCell>
                        <TableCell>
                            <Fab variant="extended" color="primary" onClick={() => this.removeAnimalFeedMedication(animal.animal_category_id)}>
                                <Delete />
                                Remover
                            </Fab>
                        </TableCell>
                    </TableRow>
                )
    }
    renderAnimalFeedingPlan(tipoAlimentos){
        return  tipoAlimentos.map((tipo) =>
                    <TableRow>
                        <TableCell>{tipo.feeding_category}</TableCell>
                        <TableCell>{tipo.january && "X"}</TableCell>
                        <TableCell>{tipo.february && "X"}</TableCell>
                        <TableCell>{tipo.march && "X"}</TableCell>
                        <TableCell>{tipo.april && "X"}</TableCell>
                        <TableCell>{tipo.may && "X"}</TableCell>
                        <TableCell>{tipo.june && "X"}</TableCell>
                        <TableCell>{tipo.july && "X"}</TableCell>
                        <TableCell>{tipo.august && "X"}</TableCell>
                        <TableCell>{tipo.september && "X"}</TableCell>
                        <TableCell>{tipo.october && "X"}</TableCell>
                        <TableCell>{tipo.november && "X"}</TableCell>
                        <TableCell>{tipo.december && "X"}</TableCell>
                        {/* <TableCell>
                            <Fab variant="extended" color="primary" onClick={() => this.removeAnimalFeedMedication(animal.animal_category_id)}>
                                <Delete />
                                Remover
                            </Fab>
                        </TableCell> */}
                    </TableRow>
                )
    }


    render() {
        // console.log("state", this.state)
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <div style={{paddingTop: "10px"}}>
                    <h4>CADASTRO DA PRODUÇÃO ANIMAL II {this.props.manejo.manejo.description}</h4>
                </div>
            
                <hr style={css.hr}/>

                <div style={css.contentColuns}>
                    <div style={{display: "flex", width: "100%", marginBottom: "15px"}}>
                        <sub><strong>No caso de produção própria de ingredientes para ração concentrada, como maneja a produção ?</strong></sub>
                    </div>
                    <div style={css.contentColuns}>
                        <TextField
                            id="select-produto"
                            select
                            label="Produto"
                            value={this.state.ration_ingredient_id}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => this.setState({ration_ingredient_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            style={css.input}                        
                        >
                            {
                            !!this.state.ingredientes && this.state.ingredientes.map(option => (
                                <MenuItem key={`select-produto-${option.ration_ingredient_id}`} value={option.ration_ingredient_id}>
                                    {option.ration_ingredient_name}
                                </MenuItem>                           
                                ))
                            
                            }
                        </TextField>
                        
                        <TextField 
                            label="Manejo" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({management_description: e.target.value})} 
                            value={this.state.management_description}
                            style={css.input}
                        />                            
                        <Fab variant="extended" color="primary" onClick={() => this.addOwnRationIngredient()}>
                            <Save />
                            Inserir
                        </Fab>
                    </div>
                {/* </div> */}
                    <TableContainer component={Paper}>
                        <Table className={this.props.classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Produto</TableCell>
                                    <TableCell>Manejo</TableCell>
                                    <TableCell>Remover</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <Query query={OWN_RATION_INGREDIENT} variables={{management_id: this.state.management_id }}>
                                    {({ loading, error, data }) => {
                                            if (loading) {
                                                return null;
                                            }else if(error){
                                                console.log("Error",error);
                                            }
                                            console.log("RETORNO: ",data.ManagementQuery.managementAnimalProduction);
                                            if(data.ManagementQuery.managementAnimalProduction && data.ManagementQuery.managementAnimalProduction.managementOwnRationIngredient.length > 0){
                                                return this.renderOwnRationIngredient(data.ManagementQuery.managementAnimalProduction.managementOwnRationIngredient);
                                            }else{
                                                return "";
                                            }
                                    }}
                                </Query>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <hr style={css.hr}/>

                <div style={css.contentColuns}>
                    <div style={{display: "flex", width: "100%", marginBottom: "15px"}}>
                        <sub><strong>No caso de ração concentrada, qual a composição e origem dos ingredientes ?</strong></sub>
                    </div>
                    <div style={css.contentColuns}>
                        <TextField
                            id="select-produto2"
                            select
                            label="Ingrediente"
                            value={this.state.ration_ingredient_id}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => this.setState({ration_ingredient_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            style={css.input}
                        
                        >
                            {
                            !!this.state.ingredientes && this.state.ingredientes.map(option => (
                                <MenuItem key={`select-produto2-${option.ration_ingredient_id}`} value={option.ration_ingredient_id}>
                                    {option.ration_ingredient_name}
                                </MenuItem>                           
                                ))
                            
                            }
                        </TextField>
                        
                        <TextField 
                            label="Produção Própria" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            type="number"
                            onChange={(e) => this.setState({own_production: e.target.value})} 
                            value={this.state.own_production}
                            style={css.input}
                        />         
                        
                        <TextField 
                            label="Compra Fora" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            type="number"
                            onChange={(e) => this.setState({buy_out: e.target.value})} 
                            value={this.state.buy_out}
                            style={css.input}
                        />          
                        
                        <TextField 
                            label="Orgânico" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined"
                            type="number" 
                            margin="dense" 
                            onChange={(e) => this.setState({organic: e.target.value})} 
                            value={this.state.organic}
                            style={css.input}
                        />          
                        
                        <TextField 
                            label="Não Orgânico" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            type="number"
                            onChange={(e) => this.setState({non_organic: e.target.value})} 
                            value={this.state.non_organic}
                            style={css.input}
                        />                             
                        <Fab variant="extended" color="primary" onClick={() => this.addORationComposition()}>
                            <Save />
                            Inserir
                        </Fab>
                    </div>
                {/* </div> */}
                    <TableContainer component={Paper}>
                        <Table className={this.props.classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Ingredientes (%)</TableCell>
                                    <TableCell>Produção Própria</TableCell>
                                    <TableCell>Compra Fora</TableCell>
                                    <TableCell>Orgânico</TableCell>
                                    <TableCell>Não Orgânico</TableCell>
                                    {/* <TableCell>Remover</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <Query query={RATION_COMPOSITION} variables={{management_id: this.state.management_id }}>
                                    {({ loading, error, data }) => {
                                            if (loading) {
                                                return null;
                                            }else if(error){
                                                console.log("Error",error);
                                            }
                                            console.log("RETORNO: ",data.ManagementQuery.managementAnimalProduction);
                                            if(data.ManagementQuery.managementAnimalProduction && data.ManagementQuery.managementAnimalProduction.managementRationComposition.length > 0){
                                                return this.renderRationComposition(data.ManagementQuery.managementAnimalProduction.managementRationComposition);
                                            }else{
                                                return "";
                                            }
                                    }}
                                </Query>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <hr style={css.hr}/>

<div style={css.contentColuns}>
    <div style={{display: "flex", width: "100%", marginBottom: "15px"}}>
        <sub><strong>Especifique o tipo de animal e o percentual de alimentação e medicamentos que são fornecidos:</strong></sub>
    </div>
    <div style={css.contentColuns}>
        <TextField
            id="Animal"
            select
            label="Tipo Animal"
            value={this.state.animal_category_id}
            InputLabelProps={{shrink: true}}
            onChange={(e) => this.setState({animal_category_id: e.target.value})}
            variant="outlined"
            margin="dense"
            style={css.input}
        
        >
            {
            !!this.state.catAnimais && this.state.catAnimais.map(option => (
                <MenuItem key={`animal-${option.animal_category_id}`} value={option.animal_category_id}>
                    {option.animal_category}
                </MenuItem>                           
                ))
            
            }
        </TextField>
        
        <TextField 
            label="Alimentação Ecológica(%)" 
            InputLabelProps={{shrink: true,}} 
            variant="outlined" 
            margin="dense" 
            type="number"
            onChange={(e) => this.setState({ecological_feeding: e.target.value})} 
            value={this.state.ecological_feeding}
            style={css.input}
        />         
        
        <TextField 
            label="Alimentação Convencional(%)" 
            InputLabelProps={{shrink: true,}} 
            variant="outlined" 
            margin="dense" 
            type="number"
            onChange={(e) => this.setState({conventional_feeding: e.target.value})} 
            value={this.state.conventional_feeding}
            style={css.input}
        />          
        
        <TextField 
            label="Medicação Ecológica(%)" 
            InputLabelProps={{shrink: true,}} 
            variant="outlined"
            type="number" 
            margin="dense" 
            onChange={(e) => this.setState({ecological_medication: e.target.value})} 
            value={this.state.ecological_medication}
            style={css.input}
        />          
        
        <TextField 
            label="Medicação Convencional(%)" 
            InputLabelProps={{shrink: true,}} 
            variant="outlined" 
            margin="dense" 
            type="number"
            onChange={(e) => this.setState({ conventional_medication: e.target.value})} 
            value={this.state. conventional_medication}
            style={css.input}
        />                             
        <Fab variant="extended" color="primary" onClick={() => this.addAnimalFeedMedication()}>
            <Save />
            Inserir
        </Fab>
    </div>
{/* </div> */}
    <TableContainer component={Paper}>
        <Table className={this.props.classes.table} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Tipo Animal</TableCell>
                    <TableCell>Alimentação Ecológica(%)</TableCell>
                    <TableCell>Alimentação Convencional(%)</TableCell>
                    <TableCell>Medicação Ecológica(%)</TableCell>
                    <TableCell>Medicação Convencional(%)</TableCell>
                    <TableCell>Remover</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <Query query={ANIMAL_FEEDING_MEDICATION} variables={{management_id: this.state.management_id }}>
                    {({ loading, error, data }) => {
                            if (loading) {
                                return null;
                            }else if(error){
                                console.log("Error",error);
                            }
                            console.log("RETORNO: ",data.ManagementQuery.managementAnimalProduction);
                            if(data.ManagementQuery.managementAnimalProduction && data.ManagementQuery.managementAnimalProduction.managementAnimalFeedingMedication.length > 0){
                                return this.renderAnimalFeedingMedication(data.ManagementQuery.managementAnimalProduction.managementAnimalFeedingMedication);
                            }else{
                                return "";
                            }
                    }}
                </Query>
            </TableBody>
        </Table>
    </TableContainer>
</div>

<hr style={css.hr}/>

<div style={css.contentColuns}>
    <div style={{display: "flex", width: "100%", marginBottom: "15px"}}>
        <sub><strong>Como é o plano anual de alimentação animal fornecidos:</strong></sub>
    </div>
    <div style={{display: "flex", width: "100%", marginBottom: "15px"}}>
        <TextField
            id="TipoAlim"
            select
            label="Tipo Alimento"
            value={this.state.feeding_category_id}
            InputLabelProps={{shrink: true}}
            onChange={(e) => this.setState({feeding_category_id: e.target.value})}
            variant="outlined"
            margin="dense"
            // style={css.input}
        
        >
            {
            !!this.state.catAlimento && this.state.catAlimento.map(option => (
                <MenuItem key={`alimento-${option.feeding_category_id}`} value={option.feeding_category_id}>
                    {option.feeding_category}
                </MenuItem>                           
                ))
            
            }
        </TextField>
       
        <Grid className={this.props.classes.root} item xl={1}>
            <FormControlLabel
            key="c_j"
                control={
                <Checkbox
                    checked={this.state.january}
                    onChange={(e) => this.setState({january: e.target.checked})}
                    color="primary"
                />
                }
                labelPlacement="bottom"
                label="Jan"
            />
        </Grid>
        <Grid className={this.props.classes.root} item xl={1}>
            <FormControlLabel
                control={
                <Checkbox
                    checked={this.state.february}
                    onChange={(e) => this.setState({february: e.target.checked})}
                    color="primary"
                />
                }
                labelPlacement="bottom"
                label="Fev"
            />
        </Grid>
        <Grid className={this.props.classes.root} item xl={1}>
            <FormControlLabel
                control={
                <Checkbox
                    checked={this.state.march}
                    onChange={(e) => this.setState({march: e.target.checked})}
                    color="primary"
                />
                }
                labelPlacement="bottom"
                label="Mar"
            />
        </Grid>
        <Grid className={this.props.classes.root} item xl={1}>
            <FormControlLabel
                control={
                <Checkbox
                    checked={this.state.april}
                    onChange={(e) => this.setState({april: e.target.checked})}
                    color="primary"
                />
                }
                labelPlacement="bottom"
                label="Abr"
            />
        </Grid>

        <Grid className={this.props.classes.root} item xl={1}>
            <FormControlLabel
                control={
                <Checkbox
                    checked={this.state.may}
                    onChange={(e) => this.setState({may: e.target.checked})}
                    color="primary"
                />
                }
                labelPlacement="bottom"
                label="Mai"
            />
        </Grid>
        <Grid className={this.props.classes.root} item xl={1}>
            <FormControlLabel
                control={
                <Checkbox
                    checked={this.state.june}
                    onChange={(e) => this.setState({june: e.target.checked})}
                    color="primary"
                />
                }
                labelPlacement="bottom"
                label="Jun"
            />
        </Grid>
        <Grid className={this.props.classes.root} item xl={1}>
            <FormControlLabel
                control={
                <Checkbox
                    checked={this.state.july}
                    onChange={(e) => this.setState({july: e.target.checked})}
                    color="primary"
                />
                }
                labelPlacement="bottom"
                label="Jul"
            />
        </Grid>
        <Grid className={this.props.classes.root} item xl={1}>
            <FormControlLabel
                control={
                <Checkbox
                    checked={this.state.august}
                    onChange={(e) => this.setState({august: e.target.checked})}
                    color="primary"
                />
                }
                labelPlacement="bottom"
                label="Ago"
            />
        </Grid>

        <Grid className={this.props.classes.root} item xl={1}>
            <FormControlLabel
                control={
                <Checkbox
                    checked={this.state.september}
                    onChange={(e) => this.setState({september: e.target.checked})}
                    color="primary"
                />
                }
                labelPlacement="bottom"
                label="Set"
            />
        </Grid>
        <Grid className={this.props.classes.root} item xl={1}>
            <FormControlLabel
                control={
                <Checkbox
                    checked={this.state.october}
                    onChange={(e) => this.setState({october: e.target.checked})}
                    color="primary"
                />
                }
                labelPlacement="bottom"
                label="Out"
            />
        </Grid>
        <Grid className={this.props.classes.root} item xl={1}>
            <FormControlLabel
                control={
                <Checkbox
                    checked={this.state.november}
                    onChange={(e) => this.setState({november: e.target.checked})}
                    color="primary"
                />
                }
                labelPlacement="bottom"
                label="Nov"
            />
        </Grid>
        <Grid className={this.props.classes.root} item xl={1}>
            <FormControlLabel
                control={
                <Checkbox
                    checked={this.state.december}
                    onChange={(e) => this.setState({december: e.target.checked})}
                    color="primary"
                />
                }
                labelPlacement="bottom"
                label="Dez"
            />
        </Grid>
        <Fab variant="extended" color="primary" onClick={() => this.addAnimalFeedPlan()}>
            <Save />
            Inserir
        </Fab>
    </div>
{/* </div> */}
    <TableContainer component={Paper}>
        <Table className={this.props.classes.table} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Tipo Alimento</TableCell>
                    <TableCell>Jan</TableCell>
                    <TableCell>Fev</TableCell>
                    <TableCell>Mar</TableCell>
                    <TableCell>Abr</TableCell>
                    <TableCell>Mai</TableCell>
                    <TableCell>Jun</TableCell>
                    <TableCell>Jul</TableCell>
                    <TableCell>Ago</TableCell>
                    <TableCell>Set</TableCell>
                    <TableCell>Out</TableCell>
                    <TableCell>Nov</TableCell>
                    <TableCell>Dez</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <Query query={FEEDING_PLAN} variables={{management_id: this.state.management_id }}>
                    {({ loading, error, data }) => {
                            if (loading) {
                                return null;
                            }else if(error){
                                console.log("Error",error);
                            }
                            console.log("RETORNO: ",data.ManagementQuery.managementAnimalProduction);
                            if(data.ManagementQuery.managementAnimalProduction && data.ManagementQuery.managementAnimalProduction.managementAnnualFeedingPlan.length > 0){
                                return this.renderAnimalFeedingPlan(data.ManagementQuery.managementAnimalProduction.managementAnnualFeedingPlan);
                            }else{
                                return "";
                            }
                    }}
                </Query>
            </TableBody>
        </Table>
    </TableContainer>
</div>
            </div>
        );
    }
}
export default withApollo((withStyles(styles))(CadProducaoAnimal2));

const css = {
    contentColuns: {
        display:"flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    col1: {
        maxWidth: "25%",
        minWidth: "25%",
        flex: 1
    },
    col2: {
        maxWidth: "50%",
        minWidth: "50%",
        flex: 1
    },
    col3: {
        maxWidth: "75%",
        minWidth: "75%",
        flex: 1
    },
    col4: {
        maxWidth: "100%",
        minWidth: "100%",
        flex: 1
    },
    input: {
       flex: 1,
       display: "flex",
       marginRight: 15
    },
    hr: {
        marginTop: "20px",
        marginBottom: "20px"
    }
}