import React, { Component } from 'react';
import './CustomError.css'
import HighlightOff from '@material-ui/icons/HighlightOff';
export default class CustomErrors extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: true
        }
    }
    componentWillReceiveProps(props){
        if(this.props.error && this.props.error.length > 0){
            this.setState({open: true})
        }
    }
    closeAlert = () => {
        this.setState({open: false})
    }
    render(){
            let {error} = this.props
            
            if(!!error && !!error.length){
                return(
                    <div id="error" className={this.state.open?'active':''}>
                        <h4>
                            Ocorreu um erro ao executar a ação:
                            <button type="button" onClick={this.closeAlert}>
                                <HighlightOff/>
                            </button>
                        </h4>
                        <div id="errorContent">
                            {
                                error.map((msg, index) => (
                                    msg.message &&
                                    <p key={`mensage-error-${index}`}>{msg.message}</p>
                                ))
                            }
                        </div>
                    </div>
                )
            } else {
                return false
            }
    }
}