import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { EDIT_FARMER_LOGISTIC } from '../../../services/graphql/mutations/farmer';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';

import {
    TextField,
    Grid,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Fab,
} from '@material-ui/core';
import { Add, Print, Save } from '@material-ui/icons';


const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    radioClass: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },    
    divisor: {
        width: "100%",
        height: "3px",
        backgroundColor: "#000",
        marginTop: "3px",
        marginBottom: "10px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});

class Lojistica extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            edit: null,
            user_id: null,
            use_charter_transport: null,
            use_own_transport: null,
            other_transport: null,
            bad_asphalt_road: null,
            good_asphalt_road: null,
            bad_dirt_road: null,
            good_dirt_road: null,
            road_passable_by_wagon: null,
            small_structure: null,
            average_structure: null,
            large_structure: null,
            other_structure: null,
            weekly_frequency: null,
            biweekly_frequency: null,
            other_frequency: null,
            countryside_sacks: null,
            countryside_boxes: null,
            countryside_others: null,
            transport_sacks: null,
            transport_boxes: null,
            transport_others: null,
            commercialization_sacks: null,
            commercialization_boxes: null,
            commercialization_pallets: null,
            commercialization_others: null,
            error: [],
        };
    } 
    
    componentWillReceiveProps(props){
        if(props.farmer){
            this.setState({'edit': true});
            let farmer = props.farmer;
            this.setState({
                user_id: farmer.id,

            });
            if(farmer.farmerLogistic && farmer.farmerLogistic != null){
                this.setState({
                    use_charter_transport: farmer.farmerLogistic.use_charter_transport,
                    use_own_transport: farmer.farmerLogistic.use_own_transport,
                    other_transport: farmer.farmerLogistic.other_transport,
                    bad_asphalt_road: farmer.farmerLogistic.bad_asphalt_road,
                    good_asphalt_road: farmer.farmerLogistic.good_asphalt_road,
                    bad_dirt_road: farmer.farmerLogistic.bad_dirt_road,
                    good_dirt_road: farmer.farmerLogistic.good_dirt_road,
                    road_passable_by_wagon: farmer.farmerLogistic.road_passable_by_wagon,
                    small_structure: farmer.farmerLogistic.small_structure,
                    average_structure: farmer.farmerLogistic.average_structure,
                    large_structure: farmer.farmerLogistic.large_structure,
                    other_structure: farmer.farmerLogistic.other_structure,
                    weekly_frequency: farmer.farmerLogistic.weekly_frequency,
                    biweekly_frequency: farmer.farmerLogistic.biweekly_frequency,
                    other_frequency: farmer.farmerLogistic.other_frequency,
                    countryside_sacks: farmer.farmerLogistic.countryside_sacks,
                    countryside_boxes: farmer.farmerLogistic.countryside_boxes,
                    countryside_others: farmer.farmerLogistic.countryside_others,
                    transport_sacks: farmer.farmerLogistic.transport_sacks,
                    transport_boxes: farmer.farmerLogistic.transport_boxes,
                    transport_others: farmer.farmerLogistic.transport_others,
                    commercialization_sacks: farmer.farmerLogistic.commercialization_sacks,
                    commercialization_boxes: farmer.farmerLogistic.commercialization_boxes,
                    commercialization_pallets: farmer.farmerLogistic.commercialization_pallets,
                    commercialization_others: farmer.farmerLogistic.commercialization_others,
                });
            }
        }
    }
    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});


        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate({mutation: EDIT_FARMER_LOGISTIC, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
               

            } else {
                console.log("DATA: ", data);
                this.setState({error: []})
               alert("Salvo com sucesso !");

            }
        })
    }

      
    render() {

        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <h4>Transporte:</h4>
                        <FormGroup name="transporte" style={{marginLeft: "10px", display: "flex", flexDirection: "row" ,flexWrap: "wrap"}} >
                            <Grid container spacing={3}>
                                <Grid className={this.props.classes.root} item xl={4}>
                                    <FormControlLabel control={<Checkbox checked={this.state.use_charter_transport} onChange={(e) => this.setState({use_charter_transport: e.target.checked})}  />} label="Frete" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={4}>
                                    <FormControlLabel control={<Checkbox checked={this.state.use_own_transport} onChange={(e) => this.setState({use_own_transport: e.target.checked})} />} label="Próprio" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={4}>
                                    <TextField id="transporte_outro" label="Qual?" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({other_transport: e.target.value})} value={this.state.other_transport} variant="outlined" margin="dense" /> 
                                </Grid>
                            </Grid>
                        </FormGroup>  
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <h4>Tipo de estrada:</h4>
                        <FormGroup name="tipo_estrada">
                            <Grid container spacing={3}>
                                <Grid className={this.props.classes.root} item xl={4}>
                                    <FormControlLabel control={<Checkbox checked={this.state.good_asphalt_road} onChange={(e) => this.setState({good_asphalt_road: e.target.checked})}  />} label="Asfaltica - bom estado" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={4}>
                                    <FormControlLabel control={<Checkbox checked={this.state.bad_asphalt_road} onChange={(e) => this.setState({bad_asphalt_road: e.target.checked})}  />} label="Asfaltica - mau estado" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={4}>
                                    <FormControlLabel control={<Checkbox checked={this.state.good_dirt_road} onChange={(e) => this.setState({good_dirt_road: e.target.checked})}  />} label="Terra - bom estado" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={4}>
                                    <FormControlLabel control={<Checkbox checked={this.state.bad_dirt_road} onChange={(e) => this.setState({bad_dirt_road: e.target.checked})}  />} label="Terra - mau estado" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={4}>
                                    <FormControlLabel control={<Checkbox checked={this.state.road_passable_by_wagon} onChange={(e) => this.setState({road_passable_by_wagon: e.target.checked})}  />} label="Carroçável" />
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <h4>Estrutura:  </h4>
                        <FormGroup aria-label="estrutura" name="estrutura">
                            <Grid container spacing={3}>
                                <Grid className={this.props.classes.root} item xl={4}>
                                    <FormControlLabel control={<Checkbox checked={this.state.small_structure} onChange={(e) => this.setState({small_structure: e.target.checked})}  />} label="Pequena" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={4}>
                                    <FormControlLabel control={<Checkbox checked={this.state.average_structure} onChange={(e) => this.setState({average_structure: e.target.checked})} />} label="Média" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={4}>
                                    <FormControlLabel control={<Checkbox checked={this.state.large_structure} onChange={(e) => this.setState({large_structure: e.target.checked})} />} label="Grande" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={4}>
                                    <FormControlLabel control={<Checkbox checked={this.state.other_structure}  />} label="Outra" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={4}>
                                    <TextField id="estrutura_outros" label="Qual?" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({other_structure: e.target.value})} value={this.state.other_structure} variant="outlined" margin="dense" /> 
                                </Grid>
                            </Grid>
                        </FormGroup>  
                        
                        <h4>Frequência:  </h4>
                        <FormGroup aria-label="certificacao" name="certificacao">
                            <Grid container spacing={3}>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel  control={<Checkbox checked={this.state.weekly_frequency} onChange={(e) => this.setState({weekly_frequency: e.target.checked})} />} label="Semanal" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel  control={<Checkbox checked={this.state.biweekly_frequency} onChange={(e) => this.setState({biweekly_frequency: e.target.checked})} />} label="Quinzenal" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel  control={<Checkbox checked={this.state.other_frequency}  />} label="Outra" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <TextField id="frequencia_outros" label="Qual?" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({other_frequency: e.target.value})} value={this.state.other_frequency} variant="outlined" margin="dense" /> 
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </Grid>
                    
                    <Grid className={this.props.classes.root} item xl={12}>
                        <h3>Acondicionamento do produto. Do campo à comercialização:</h3>       
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <h4>Campo:</h4>
                        <FormGroup>
                            <Grid container spacing={3}>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel control={<Checkbox  checked={this.state.countryside_sacks} onChange={(e) => this.setState({countryside_sacks: e.target.checked})}  />} label="Sacarias" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel control={<Checkbox  checked={this.state.countryside_boxes} onChange={(e) => this.setState({countryside_boxes: e.target.checked})}  />} label="Caixas" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel control={<Checkbox  checked={this.state.countryside_others} />} label="Outros" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <TextField id="campo_acondicionamento_outros" label="Qual?" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({countryside_others: e.target.value})} value={this.state.countryside_others}  variant="outlined" margin="dense" /> 
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <h4>Transporte:  </h4>
                        <FormGroup>
                            <Grid container spacing={3}>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel control={<Checkbox  checked={this.state.transport_sacks} onChange={(e) => this.setState({transport_sacks: e.target.checked})} />} label="Sacarias" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel control={<Checkbox  checked={this.state.transport_boxes} onChange={(e) => this.setState({transport_boxes: e.target.checked})}  />} label="Caixas" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel control={<Checkbox  checked={this.state.transport_others}  />} label="Outros" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <TextField id="transporte_acondicionamento_outros" label="Qual?" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({transport_others: e.target.value})} value={this.state.transport_others}  variant="outlined" margin="dense" /> 
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <h4>Comercialização:  </h4>
                        <FormGroup>
                            <Grid container spacing={3}>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel control={<Checkbox  checked={this.state.commercialization_sacks} onChange={(e) => this.setState({commercialization_sacks: e.target.checked})} />} label="Sacarias" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel control={<Checkbox  checked={this.state.commercialization_boxes} onChange={(e) => this.setState({commercialization_boxes: e.target.checked})} />} label="Caixas" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel control={<Checkbox  checked={this.state.commercialization_pallets} onChange={(e) => this.setState({commercialization_pallets: e.target.checked})} />} label="Pallets" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel control={<Checkbox  checked={this.state.commercialization_others}  />} label="Outros" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <TextField id="comercializacao_acondicionamento_outros" label="Qual?" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({commercialization_others: e.target.value})} value={this.state.commercialization_others} variant="outlined" margin="dense" /> 
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </Grid>

                    <Grid container spacing={3}>
                        {/* <Grid className={this.props.classes.root} item xl={6}>
                            <Fab variant="extended" color="primary">
                                <Print />
                                Imprimir
                            </Fab>
                        </Grid> */}
                        <Grid className={this.props.classes.root} item xl={6}>
                            <Fab variant="extended" color="primary" onClick={() => this.submitIt()}>
                                <Save />
                                Salvar
                            </Fab>
                        </Grid>
                    </Grid>
                    </Grid>
            </div>
            
        );
    }
}export default withApollo((withStyles(styles))(Lojistica));
