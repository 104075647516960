import gql from 'graphql-tag';

export const COMBOS_DEMANDA_ESTIMADA = gql`
query($station_id: Int, $product_category_id: Int, $core_id: Int, $group_id: Int, $commercialization_type_id: Int){
  StationQuery{
    id
    name
  }
  ListCustomersQuery{
    user_id
    name
  }
  ListFarmersQuery{
    user_id
    name
  }
  CommercializationsTypeQuery{
    commercialization_type_id
    description
  }
  ProductCategoryQuery{
    product_category_id
    product_category
  }
  ProductQuery(product_category_id: $product_category_id){
    product_id
    comercial_name
  }
  GroupQuery(station_id:$station_id, core_id:$core_id){
    group_id
    name
  }
  CoreQuery(station_id:$station_id){
    core_id
    name
  }
  ListReportBuyersQuery(station_id:$station_id, commercialization_type_id:$commercialization_type_id){
    buyer_id
    buyer_type
    buyer_name
  }
  ListReportSellersQuery(station_id:$station_id, core_id:$core_id, group_id:$group_id){
    seller_id
    seller_type
    seller_name
  }
}
`;
export const RELATORIO_DEMANDA_ESTIMADA = gql`
query ReportExtimatedDemandQuery($station_id: Int, $commercialization_type_id: Int, $user_id: String, $product_category_id: Int, $product_id: Int){
  ReportExtimatedDemandQuery(station_id: $station_id, commercialization_type_id: $commercialization_type_id, user_id: $user_id, product_category_id: $product_category_id, product_id: $product_id){
    csv
    dataReport {
      product_id
      comercial_name
      january
      february
      march
      april
      may
      june
      july
      august
      september
      october
      november
      december
      year
    }
  }
}
`;
export const RELATORIO_FARMER_STATION = gql`
query ReportFarmerStationQuery($core_id: Int, $station_id: Int, $group_id: Int, $name: String) {
  ReportFarmerStationQuery(core_id: $core_id, station_id: $station_id, group_id: $group_id, name: $name) {
    csv
    dataFarmerStation {
      core
      station
      group
      farmer
      full_address
      district
      city
      state
      zip_code
      legal_document
      phone
      msisdn
    }
  }
}
`;

export const RELATORIO_OPAC = gql`
query ReportOpacQuery( $station_id: Int, $core_id: Int, $group_id: Int, $get_register_from_station: Int ){
  ReportOpacQuery(station_id: $station_id, core_id: $core_id, group_id: $group_id, get_register_from_station: $get_register_from_station){
    csv
    dataReport {
      user_id
      farmer_name
      core
      group
      legal_document
      dap
      address
      address_number
      address_complement
      state
      primary_vegetable_production
      primary_animal_production
      vegetable_processing
      animal_processing
      extractivism
      productive_unit_name
      property_size
      agroindustrial_area
      organic_area
      contact
      phone
      msisdn
      email
      certificate_issue
      latitude
      longitude
      farmers_quantity_by_unit
    }
  }
}
`;

export const EXPORTAR_RELATORIO_AGRICULTOR = gql`
query ExportFarmersQuery($station_id: Int, $group_id: Int, $core_id: Int, $id: String){
  ExportFarmersQuery(station_id: $station_id, group_id: $group_id, core_id: $core_id, id: $id)
}
`;

export const EXPORTAR_RELATORIO_CLIENTES = gql`
query ExportCustomersQuery($station_id: Int, $id: String){
  ExportCustomersQuery(station_id: $station_id, id: $id)
}
`;

export const RELATORIO_DEMANDA_REAL = gql`
query(
  $station_id: Int, 
  $commercialization_type_id: Int, 
  $buyer_id: String,
  $buyer_type: String, 
  $product_category_id: Int, 
  $product_id: Int, 
  $start_date: String, 
  $end_date: String
){
  ReportDemandQuery(
    station_id: $station_id, 
    commercialization_type_id: $commercialization_type_id, 
    buyer_id: $buyer_id, 
    buyer_type: $buyer_type, 
    product_category_id: $product_category_id, 
    product_id: $product_id, 
    start_date: $start_date, 
    end_date: $end_date
  ){
    csv
    dataReport{
      product_id
      comercial_name
      total
    }
  }
}
`;
export const RELATORIO_OFERTA_REAL = gql`
query(
  $station_id: Int, 
  $core_id: Int, 
  $group_id: Int, 
  $seller_id: String,
  $seller_type: String, 
  $product_category_id: Int, 
  $product_id: Int, 
  $start_date: String, 
  $end_date: String
){
  ReportOfferQuery(
    station_id: $station_id, 
    core_id: $core_id, 
    group_id: $group_id, 
    seller_id: $seller_id, 
    seller_type: $seller_type, 
    product_category_id: $product_category_id, 
    product_id: $product_id, 
    start_date: $start_date, 
    end_date: $end_date
  ){
    csv
    dataReport{
      product_id
      comercial_name
      total_offered
    }
  }
}
`;

export const RELATORIO_DEMANDA_X_OFERTA = gql`
query(
  $station_id: Int, 
  $product_category_id: Int, 
  $product_id: Int,
  $start_date: String, 
  $end_date: String
){
  ReportOfferAndDemandQuery(
    station_id: $station_id, 
    product_category_id: $product_category_id, 
    product_id: $product_id,
    start_date: $start_date, 
    end_date: $end_date
  ){
    csv
    dataReport {
      product_id
      comercial_name
      total_offered
      total_demanded
    }
  }
}
`;