import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    TextField,
    MenuItem,
    Grid,
    FormControlLabel,
    Checkbox,
    Fab
} from '@material-ui/core';
import { withApollo, Query } from 'react-apollo';
import { EDIT_CUSTOMER_PAYMENT } from '../../../services/graphql/mutations/customer';
import CustomErrors from '../../CustomErrors/CustomErrors';


import { Print, Save } from '@material-ui/icons';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%',
    },
    title: {
        paddingTop: '3rem',
    }
}));
function Pagamento(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        user_id: !!props.customer ? props.customer.id : null,
        payment_by_bank_slip: !!props.customer && !!props.customer.customerPayment ? props.customer.customerPayment.payment_by_bank_slip : null,
        payment_by_deposit_account: !!props.customer && !!props.customer.customerPayment ? props.customer.customerPayment.payment_by_deposit_account : null,
        payment_by_money: !!props.customer && !!props.customer.customerPayment ? props.customer.customerPayment.payment_by_money : null,
        payment_by_credit_debit: !!props.customer && !!props.customer.customerPayment ? props.customer.customerPayment.payment_by_credit_debit : null,
        other_types_payment_description: !!props.customer && !!props.customer.customerPayment ? props.customer.customerPayment.other_types_payment_description : null,
        weekly_payment_term: !!props.customer && !!props.customer.customerPayment ? props.customer.customerPayment.weekly_payment_term : null,
        biweekly_payment_term: !!props.customer && !!props.customer.customerPayment ? props.customer.customerPayment.biweekly_payment_term : null,
        monthly_payment_term: !!props.customer && !!props.customer.customerPayment ? props.customer.customerPayment.monthly_payment_term : null,
        other_payment_term: !!props.customer && !!props.customer.customerPayment ? props.customer.customerPayment.other_payment_term : null,
        other_payment_term_description: !!props.customer && !!props.customer.customerPayment ? props.customer.customerPayment.other_payment_term_description : null,
        individual_invoice: !!props.customer && !!props.customer.customerPayment ? props.customer.customerPayment.individual_invoice : null,
        danfe_invoice: !!props.customer && !!props.customer.customerPayment ? props.customer.customerPayment.danfe_invoice : null,
        single_invoice: !!props.customer && !!props.customer.customerPayment ? props.customer.customerPayment.single_invoice : null,
        supplier_invoice: !!props.customer && !!props.customer.customerPayment ? props.customer.customerPayment.supplier_invoice : null,
        unnecessary_invoice: !!props.customer && !!props.customer.customerPayment ? props.customer.customerPayment.unnecessary_invoice : null,
        error: [],
    });

    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });
    };

    const handleChangeText = name => event => {
        setState({ ...state, [name]: event.target.value });
    };

    const submitIt = () => {
        let paramsEnviar = Object.entries(state).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        props.client.mutate({mutation: EDIT_CUSTOMER_PAYMENT, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    setState({...state, error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    setState({...state, error: errosMostrar})
                }
                
            } else {
                console.log("DATA: ", data);
                setState({...state, error: []});
                alert("Salvo com sucesso !");
            }
        })
    }

    return(
        <div>
            <CustomErrors error={state.error}/>
            <h4>Pagamento ao fornecedor através de</h4>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.payment_by_bank_slip}
                                onChange={handleChange('payment_by_bank_slip')}
                                color="primary"
                            />
                        }
                        label="Boleto"
                    />
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.payment_by_deposit_account}
                                onChange={handleChange('payment_by_deposit_account')}
                                color="primary"
                            />
                        }
                        label="Depósito em conta"
                    />
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.payment_by_money}
                                onChange={handleChange('payment_by_money')}
                                color="primary"
                            />
                        }
                        label="Dinheiro"
                    />
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.payment_by_credit_debit}
                                onChange={handleChange('payment_by_credit_debit')}
                                color="primary"
                            />
                        }
                        label="Crédito/Débito"
                    />
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.other_types_payment_description}
                                color="primary"
                            />
                        }
                        label="Outros"
                    />
                </Grid>
                <Grid className={classes.root} item xl={6}>
                    <TextField id="campo_pagamento_outros" className={classes.textField}  value={state.other_types_payment_description} onChange={handleChangeText('other_types_payment_description')} label="Qual?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" /> 
                </Grid>
            </Grid>
            <h4>Prazo pagamento ao fornecedor</h4>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.weekly_payment_term}
                                onChange={handleChange('weekly_payment_term')}
                                color="primary"
                            />
                        }
                        label="Semanal"
                    />
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.biweekly_payment_term}
                                onChange={handleChange('biweekly_payment_term')}
                                color="primary"
                            />
                        }
                        label="Quinzenal"
                    />
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.monthly_payment_term}
                                onChange={handleChange('monthly_payment_term')}
                                color="primary"
                            />
                        }
                        label="Mensal"
                    />
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.other_payment_term}
                                onChange={handleChange('other_payment_term')}
                                color="primary"
                            />
                        }
                        label="Outros"
                    />
                </Grid>
                <Grid className={classes.root} item xl={6}>
                    <TextField id="campo_pagamento_outros" className={classes.textField} value={state.other_payment_term_description} onChange={handleChangeText('other_payment_term_description')} label="Qual?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" /> 
                </Grid>
            </Grid>
            <h4>Tipo de nota aceita com registro de compra</h4>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.individual_invoice}
                                onChange={handleChange('individual_invoice')}
                                color="primary"
                            />
                        }
                        label="Individual"
                    />
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.danfe_invoice}
                                onChange={handleChange('danfe_invoice')}
                                color="primary"
                            />
                        }
                        label="DANFE"
                    />
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.single_invoice}
                                onChange={handleChange('single_invoice')}
                                color="primary"
                            />
                        }
                        label="Nota Avulsa"
                    />
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.supplier_invoice}
                                onChange={handleChange('supplier_invoice')}
                                color="primary"
                            />
                        }
                        label="Nota Fiscal do Fornecedor"
                    />
                </Grid>
                <Grid className={classes.root} item xl={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.unnecessary_invoice}
                                onChange={handleChange('unnecessary_invoice')}
                                color="primary"
                            />
                        }
                        label="Não Precisa"
                    />
                </Grid>
                <Grid className={classes.root} item xl={6}>
                    <Fab variant="extended" className={classes.textField} color="primary" onClick={() => submitIt()}>
                        <Save />
                        Salvar
                    </Fab>
                </Grid>
            </Grid>
        </div>
    );
}export default withApollo(Pagamento)