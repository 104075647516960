import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

// COMPONENTS
import Lista from '../../Component/Subestacao/List';
import Formulario from '../../Component/Subestacao/Formulario';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});
class Subestacao extends Component {
    render() {
        if(this.props.match.params.action == 'new' || this.props.match.params.action == 'edit'){
            return (
                <div className="page-wrapper"  style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <Formulario/>
                </div>
            );
        } else {
            return(
                <div className="page-wrapper"  style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <Lista/>
                </div>
            );
        }
    }
}
export default withStyles(styles)(Subestacao);