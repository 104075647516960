import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { withApollo, graphql, Query } from 'react-apollo';
import CustomErrors from '../CustomErrors/CustomErrors';
import { COMBOS_DEMANDA_ESTIMADA, RELATORIO_DEMANDA_REAL } from "../../services/graphql/querys/relatorio";
import {
    makeStyles,
    Table,
    TableBody,
    TableHead,
    TableFooter,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Grid,
    Input,
    InputLabel,
    TextField,
    Select,
    MenuItem,
    Button
} from '@material-ui/core/';
import Save from '@material-ui/icons/Save';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});
let UserAccount = localStorage.getItem('UserAccount') ? JSON.parse(localStorage.getItem('UserAccount')) : undefined;
console.log("UserAccount", UserAccount)
class DemandaReal extends Component {
    constructor(props){
        super(props);
        this.state ={
            estacoes: null,
            consumidores: null,
            categoriasConsumidores: null,
            produtos: null,
            categoriasProdutos: null,
            compradores: null
        }
    }
    componentWillMount(){
        this.props.client.query({query: COMBOS_DEMANDA_ESTIMADA, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else { 
                this.setState({
                    estacoes: data.StationQuery,
                    consumidores: data.ListCustomersQuery,
                    categoriasConsumidores: data.CommercializationsTypeQuery,
                    produtos: data.ProductQuery,
                    categoriasProdutos: data.ProductCategoryQuery,
                    compradores: data.ListReportBuyersQuery
                }) 
            }
        })
        .catch(erro => console.log(erro))
    }
    render(){
        return (
            <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                <h1 style={{marginBottom: "1rem"}}>Relatorio de Demanda Real</h1>
                <Grid container spacing={3}>
                     <Grid className={this.props.classes.root} item xl={12}>
                        Periodo:
                    </Grid>
                </Grid> 
                <Grid container spacing={3}>
                     <Grid className={this.props.classes.root} item xl={3}>
                        <TextField 
                        onChange={(e) => this.setState({start_date: e.target.value})} 
                        value={this.state.start_date}
                        className={this.props.classes.textField} 
                        type="date"
                        label="De:" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField 
                        onChange={(e) => this.setState({end_date: e.target.value})} 
                        value={this.state.end_date}  
                        type="date"
                        className={this.props.classes.textField} 
                        label="A:" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField
                            select
                            label="Conprador: "
                            className={this.props.classes.textField} 
                            value={this.state.buyer_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => {
                                this.setState({buyer_id: e.target.value.buyer_id, buyer_type: e.target.value.buyer_type},console.log("EVENTO: ", this.state));
                            }}
                            variant="outlined"
                            margin="dense"
                        >
                        <MenuItem key={`option-demanda-real-comprador-null`} value="">Todas Categorias</MenuItem>
                            {
                                this.state.compradores &&
                                this.state.compradores.map(
                                    (comprador, index) => {
                                        console.log(comprador);
                                        return <MenuItem key={`option-demanda-real-comprador-${index}`} value={comprador}>{comprador.buyer_name}</MenuItem>
                                    }
                                )
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{marginBottom: "1rem"}}>
                    {
                    UserAccount && UserAccount.profile.user_type === 'admin_central' &&
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField
                            select
                            label="Estação: "
                            className={this.props.classes.textField} 
                            value={this.state.station_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({station_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                        <MenuItem key={`option-demanda-real-estacoes-null`} value="">Todas Estações</MenuItem>
                            {
                                this.state.estacoes &&
                                this.state.estacoes.map(
                                    (estacao, index) => {
                                        console.log(estacao);
                                        return <MenuItem key={`option-demanda-real-estacoes-${index}`} value={estacao.id}>{estacao.name}</MenuItem>
                                    }
                                )
                            }
                        </TextField>
                    </Grid>
                    }
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField
                            select
                            label="Categoria Consumidor: "
                            className={this.props.classes.textField} 
                            value={this.state.commercialization_type_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({commercialization_type_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                        <MenuItem key={`option-demanda-real-categoria-consumidor-null`} value="">Todas Categorias</MenuItem>
                            {
                                this.state.categoriasConsumidores &&
                                this.state.categoriasConsumidores.map(
                                    (categoriaConsumidor, index) => {
                                        console.log(categoriaConsumidor);
                                        return <MenuItem key={`option-demanda-real-categoria-consumidor-${index}`} value={categoriaConsumidor.commercialization_type_id}>{categoriaConsumidor.description}</MenuItem>
                                    }
                                )
                            }
                        </TextField>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField
                            select
                            label="Categoria Produto: "
                            className={this.props.classes.textField} 
                            value={this.state.product_category_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({product_category_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                            <MenuItem key={`option-demanda-real-categoria-produtos-null`} value="">Todas Categorias</MenuItem>
                            {
                                this.state.categoriasProdutos &&
                                this.state.categoriasProdutos.map(
                                    (categoriaProduto, index) => {
                                        return <MenuItem key={`option-demanda-real-categoria-produtos-${index}`} value={categoriaProduto.product_category_id}>{categoriaProduto.product_category}</MenuItem>
                                    }
                                )
                            }
                        </TextField>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField
                            select
                            label="Produto: "
                            className={this.props.classes.textField} 
                            value={this.state.product_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({product_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                            <MenuItem key={`option-demanda-real-produtos-null`} value="">Todos Produtos</MenuItem>
                            {
                                this.state.produtos &&
                                this.state.produtos.map(
                                    (produto, index) => {
                                        console.log(produto);
                                        return <MenuItem key={`option-demanda-real-produtos-${index}`} value={produto.product_id}>{produto.comercial_name}</MenuItem>
                                    }
                                )
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <CustomErrors error={this.state.error}/>
                <Query query={RELATORIO_DEMANDA_REAL} variables={
                    UserAccount && UserAccount.profile.user_type === 'admin_central' ?
                    {
                        station_id: this.state.station_id, 
                        commercialization_type_id: this.state.commercialization_type_id, 
                        buyer_id: this.state.buyer_id, 
                        buyer_type: this.state.buyer_type, 
                        product_category_id: this.state.product_category_id, 
                        product_id: this.state.product_id,
                        start_date: this.state.start_date, 
                        end_date: this.state.end_date
                    }:
                    {
                        station_id: UserAccount.profile.station.id,
                        product_category_id: this.state.product_category_id,
                        product_id: this.state.product_id
                    }
                }>
                    {
                        ({ loading, error, data }) => {
                            if (loading) {
                                return("Buscando ..." );
                            } else if(error) {
                                console.log("Error",error);
                            } else {
                                console.log("DATA: ",data);
                                return <TableContainer component={Paper}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="th">Comprador</TableCell>
                                                <TableCell align="right">Total da Demanda</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                            data.ReportDemandQuery.dataReport.map(
                                                (report, index) => (
                                                    <TableRow>
                                                        <TableCell >{report.comercial_name}</TableCell>
                                                        <TableCell align="right">{parseFloat(report.total).toFixed(3)}</TableCell>
                                                    </TableRow>
                                                    )
                                                )
                                            }
                                        </TableBody>
                                        {
                                        data.ReportDemandQuery.dataReport && data.ReportDemandQuery.dataReport.length > 0 &&
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell align="right" colSpan={14}>
                                                        <Button variant="contained" color="primary" component="a" href={data.ReportDemandQuery.csv.replace("http:","https:")} >
                                                            <Save/> Salvar CSV
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        }
                                    </Table>
                                </TableContainer>
                            }
                        }
                    }
                </Query>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(DemandaReal));