import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { EDIT_CORE } from '../../services/graphql/mutations/core';
import { withApollo, graphql, Query } from 'react-apollo';
import CustomErrors from '../CustomErrors/CustomErrors';
import MaskedInput from 'react-text-mask';

import {
    TextField,
    Grid,
    MenuItem,
    Fab
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    }
});

const listStates = [
    {
        value: 'active',
        label: 'Ativo',
    },
    {
        value: 'inactive',
        label: 'Inativo',
    },
    {
        value: 'validate',
        label: 'Validar',
    },
];

const Celular = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={['5','5',' ','\(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        showMask
      />
    );
}

class Formulario extends Component {
    constructor(props){
        super(props);
        this.state = {
            core_id: null,
            nome: "",
            cities_involved: "",
            states_involved: "",
            coordinator: "",
            comercial_phone: "",
            cell_phone: "",
            email: "",
            country: "",
            state: "",
            city: "",
            district: "",
            address: "",
            address_number: "",
            address_complement: "",
            zip_code: "",
            legal_document: "",
            state_registration: "",
            municipal_registration: "",
            status: "",
            error: [],
        }
    }

    componentWillReceiveProps(props){
        if(!!props.core){
            let core = props.core;
            this.setState({
                core_id: core.core_id,
                nome: core.name,
                cities_involved: core.cities_involved,
                states_involved: core.states_involved,
                coordinator: core.coordinator,
                comercial_phone: core.comercial_phone,
                cell_phone: core.cell_phone,
                email: core.email,
                country: core.country,
                state: core.state,
                city: core.city,
                district: core.district,
                address: core.address,
                address_number: core.address_number,
                address_complement: core.address_complement,
                zip_code: core.zip_code,
                legal_document: core.legal_document,
                state_registration: core.state_registration,
                municipal_registration: core.municipal_registration,
                status: core.status,
            })
        }
    }

    handleChangeMask = name => event => {
        this.setState({
        [name]: event.target.value,
        });
    };

    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        paramsEnviar['name'] = paramsEnviar['nome'];

        if(!paramsEnviar.name){
            this.setState({error: [{message: "Você deve preencher o Nome do grupo."}]})
            return false;
        }else if(!paramsEnviar.status){
            this.setState({error: [{message: "Você deve setar o Status."}]})
            return false;
        }
        
        paramsEnviar.comercial_phone = paramsEnviar.comercial_phone.replace(/\D+/g, '');
        paramsEnviar.cell_phone = paramsEnviar.cell_phone.replace(/\D+/g, '');

        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate({mutation: EDIT_CORE, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            } else {
                console.log("DATA: ", data);
                this.setState({error: []});
               alert("Salvo com sucesso !");

            }
        })
    }

    render(){
        return(
            <div className={this.props.classes.content}>
                <CustomErrors error={this.state.error}/>
                <sub>
                    <span>*</span> Informações Obrigatórias
                </sub>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  name="nome" id="nome" className={this.props.classes.textField} onChange={(e) => this.setState({nome: e.target.value})} value={this.state.nome}  label="Nome*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6}>
                        <TextField
                            select
                            label="Status*"
                            value={this.state.status}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({status: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            className={this.props.classes.textField}
                        >
                        {listStates.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({cities_involved: e.target.value})} value={this.state.cities_involved}  label="Municípios envolvidos"  InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({states_involved: e.target.value})} value={this.state.states_involved}  label="Estados envolvidos" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    {/* <Grid className={this.props.classes.root} item xl={3}>
                        <TextField
                            select
                            label="Pertence a Estação"
                            value={this.state.estacao}
                            onChange={this.handleChangeEstacao}
                            variant="outlined"
                            margin="dense"
                            className={this.props.classes.textField}
                        >
                        {listEstacao.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid> */}

                    {/* <Grid className={this.props.classes.root} item xl={2}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({name: e.target.value})} value={this.state.name}  label="Nº de Grupos" variant="outlined" margin="dense" />
                    </Grid> */}
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({coordinator: e.target.value})} value={this.state.coordinator}  label="Coordenador" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({email: e.target.value})} value={this.state.email}  label="Email" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={2}>
                        <TextField  className={this.props.classes.textField} label="Telefone Comercial" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" 
                            InputProps={{
                                inputComponent: Celular,
                                onChange: this.handleChangeMask('comercial_phone'),
                                value: this.state.comercial_phone ? this.state.comercial_phone : '',
                            }}
                        />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={2}>
                        <TextField  className={this.props.classes.textField} label="Celular" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" 
                            InputProps={{
                                inputComponent: Celular,
                                onChange: this.handleChangeMask('cell_phone'),
                                value: this.state.cell_phone ? this.state.cell_phone : '',
                            }}      
                        />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={2}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({zip_code: e.target.value})} value={this.state.zip_code}  label="CEP" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={5}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({address: e.target.value})} value={this.state.address}  label="Endereço" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={1}>
                        <TextField  type="number" className={this.props.classes.textField} onChange={(e) => this.setState({address_number: e.target.value})} value={this.state.address_number}  label="Nº" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({address_complement: e.target.value})} value={this.state.address_complement}  label="Complemento" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>


                    <Grid className={this.props.classes.root} item xl={6}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({city: e.target.value})} value={this.state.city}  label="Cidade" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({state: e.target.value})} value={this.state.state}  label="Estado" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={2}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({legal_document: e.target.value})} value={this.state.legal_document}  label="CNPJ" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={5}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({state_registration: e.target.value})} value={this.state.state_registration}  label="Inscrição Estadual" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={5}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({municipal_registration: e.target.value})} value={this.state.municipal_registration}  label="Inscrição Municipal"  InputLabelProps={{shrink: true,}}variant="outlined" margin="dense" />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={6}>
                        <Fab variant="extended" className={this.props.classes.textField} color="primary" onClick={() => this.submitIt()}>
                            <Save />
                            Salvar
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Formulario));