import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { graphql } from 'react-apollo';
import { withApollo } from 'react-apollo';

// COMPONENTS
import Lista from '../../Component/Consumidores/List';
import InformacoesGeral from '../../Component/Consumidores/InfomacoesGeral';
import DadosComercializacao from '../../Component/Consumidores/DadosComercializacao';

import {  GET_CUSTOMER, LIST_CUSTOMERS  } from '../../services/graphql/querys/customer';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
  
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

function Consumidores(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [customer, setCustomer] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    console.log(props)
    if(props.match.params.action == 'new' || props.match.params.action == 'edit'){
        if(props.match.params.action == 'edit'){
            let body = {
                id: props.match.params.id
            }
            props.client.query({query: GET_CUSTOMER, variables: body, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    let customer = data.CustomerQuery[0];
                    setCustomer(customer);
                }
            })

        }

        return (
            <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                <AppBar position="static" color="default">
                    <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Informações Gerais" {...a11yProps(0)} />
                        {!!customer && <Tab label="Dados Comercialização" {...a11yProps(1)} /> }
                    </Tabs>
                </AppBar>

                 <div style={{ display: value === 0? 'block': 'none'}}>
                    <InformacoesGeral customer={customer} commercialization_types={props.data.CommercializationsTypeQuery } />
                </div>
                {!!customer && 
                    <div style={{ display: value === 1? 'block': 'none'}}>
                        <DadosComercializacao customer={customer}  products={props.data.ProductQuery } />
                    </div>
                }
            </div>
        );
    } else {
        return (
            <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                <Lista setValue={setValue} customers={props.data.ListCustomersQuery}/>
            </div>
        );
    }
}export default withApollo(graphql(LIST_CUSTOMERS)(Consumidores))