import gql from 'graphql-tag';

export const EDIT_COMMERC_TYPE_PROFIT = gql`

mutation  editCommercializationTypeProfit(
  $commercialization_type_id: Int!,
  $profit_margin: Float,
){
    
  editCommercializationTypeProfit(commercialization_type_id: $commercialization_type_id, profit_margin: $profit_margin){
    station_id
    commercialization_type_id
    profit_margin
  }
}
`;

export const CREATE_COMMERC_TYPE_PROFIT = gql`

mutation  createCommercializationTypeProfit(
  $commercialization_type_id: Int!,
  $profit_margin: Float,
){
    
  createCommercializationTypeProfit(commercialization_type_id: $commercialization_type_id, profit_margin: $profit_margin){
    station_id
    commercialization_type_id
    profit_margin
  }
}
`;

export const EDITA_FRETE = gql`

mutation  editFreight(
  $freight_id: Int!, 
  $name: String!, 
  $description: String, 
  $email: String, 
  $phone: String, 
  $legal_document: String, 
  $cost: Float 
){
    
  editFreight(freight_id: $freight_id, name: $name, description: $description, email: $email, phone: $phone, legal_document: $legal_document, cost: $cost){
    freight_id
    station_id
    name
    description
    email
    phone
    legal_document
    cost
  }
}`;

export const CRIA_FRETE = gql`

mutation  createFreight(
  $name: String!, 
  $description: String, 
  $email: String, 
  $phone: String, 
  $legal_document: String, 
  $cost: Float 
){
    
  createFreight(name: $name, description: $description, email: $email, phone: $phone, legal_document: $legal_document, cost: $cost){
    freight_id
    station_id
    name
    description
    email
    phone
    legal_document
    cost
  }
}`;