import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { CREATE_MANAGEMENT } from '../../../services/graphql/mutations/management';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';

import {
    TextField,
    Grid,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Fab,
} from '@material-ui/core';
import { Add, Print, Save } from '@material-ui/icons';


const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});

class NovoManejo extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            user_id: null,
            description: "",
            error: [],
        };
    } 
        
    componentWillReceiveProps(props){
        if(props.farmer){
            let farmer = props.farmer;
            this.setState({
                user_id: farmer.id,
            });
        }
    }
    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate({mutation: CREATE_MANAGEMENT, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        console.log("ERRO... " + key + ' ',value);
                        if(value.message.indexOf("$description") != -1){
                            errosMostrar[key] = {message: "O campo descrição é de preenchimento obrigatório."}
                        } else {
                            errosMostrar[key] = {message: value.message};
                        }
                    }
                    this.setState({error: errosMostrar})
                }
                
                

            } else {
                console.log("DATA: ", data);
               this.props.setManagement(data.createManagement);
                alert("Salvo com sucesso !");

            }
        })
    }
    
    render() {
    
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <h4>Criar Novo Manejo</h4>
                        <TextField 
                            id="descricao" 
                            onChange={(e) => this.setState({description: e.target.value})} 
                            value={this.state.description} 
                            label="Descricao" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                        /> 
                        <Fab variant="extended" color="primary" onClick={() => this.submitIt()}>
                            <Save />
                            Criar
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        );
    }
}export default withApollo((withStyles(styles))(NovoManejo));
