import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    TextField,
    Grid,
    MenuItem,
    Fab
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    }
});

const listStates = [
    {
        value: 1,
        label: 'Ativo',
    },
    {
        value: 2,
        label: 'Inativo',
    },
    {
        value: 3,
        label: 'Validar',
    },
];

const listEstacao = [
    {
        value: 1,
        label: 'Estação 1'
    },
    {
        value: 2,
        label: 'Estação 2'
    },
    {
        value: 3,
        label: 'Estação 3'
    }
]

const listNucleo = [
    {
        value: 1,
        label: 'Núcleo 1'
    },
    {
        value: 2,
        label: 'Núcleo 2'
    },
    {
        value: 3,
        label: 'Núcleo 3'
    }
]
class Formulario extends Component {
    constructor(props){
        super(props);
        this.state = {
            status: '', 
            estacao: '',
            nucleo: ''
        }
    }
    handleChange = event => {
        this.setState({status:event.target.value});
    };
    handleChangeEstacao = event => {
        this.setState({estacao:event.target.value});
    };
    handleChangeNucleo = event => {
        this.setState({nucleo:event.target.value});
    };
    render(){
        return(
            <div className={this.props.classes.content}>
                <Grid container spacing={3}>
                <Grid className={this.props.classes.root} item xl={4}>
                        <TextField
                            id="select-nucleo"
                            select
                            label="Vender para Estação"
                            value={this.state.estacao}
                            onChange={this.handleChangeEstacao}
                            variant="outlined"
                            margin="dense"
                            className={this.props.classes.textField}
                        >
                        {listEstacao.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField
                            id="select-nucleo"
                            select
                            label="Pertence ao Núcleo"
                            value={this.state.nucleo}
                            onChange={this.handleChangeNucleo}
                            variant="outlined"
                            margin="dense"
                            className={this.props.classes.textField}
                        >
                        {listNucleo.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="Nome" variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="Cidades envolvidas" variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="Estados envolvidos" variant="outlined" margin="dense" />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={2}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="Nº de Grupos" variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="Coordenador" variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="Email" variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={2}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="Telefone Comercial" variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={2}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="Celular" variant="outlined" margin="dense" />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={2}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="CEP" variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={5}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="Endereço" variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={1}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="Nº" variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="Complemento" variant="outlined" margin="dense" />
                    </Grid>


                    <Grid className={this.props.classes.root} item xl={6}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="Cidade" variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="Estado" variant="outlined" margin="dense" />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={2}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="CNPJ" variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={5}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="Inscrição Estadual" variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={5}>
                        <TextField id='innatura-produto' className={this.props.classes.textField} label="Inscrição Municipal" variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6}>
                        <TextField
                            id="select-nucleo"
                            select
                            label="Status"
                            value={this.state.status}
                            onChange={this.handleChange}
                            variant="outlined"
                            margin="dense"
                            className={this.props.classes.textField}
                        >
                        {listStates.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6}>
                        <Fab variant="extended" className={this.props.classes.textField} color="primary">
                            <Save />
                            Salvar
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default withStyles(styles)(Formulario);