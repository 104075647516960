import gql from 'graphql-tag';

export const LIST_FARMER = gql`

query listFarmers( $name: String, $state: String, $city: String, $legal_document: String ){
    ListFarmersQuery ( name: $name, state: $state, city: $city, legal_document: $legal_document ){
        user_id
        name
        state
        city
        legal_document
    }
}
`;

export const GET_FARMER = gql`
    query getFarmer($user_id: String){
        FarmerQuery(id: $user_id){
          id
          productive_unit_id
          group_id
          core_id
          station_id
          group_offerer
          contact
          mother_name
          identity_document
          dap
          rural_block_registration
          certificate
          certificate_url
          certificate_issue
          certificate_expiration
            user{
              id
              login
              email
              msisdn
              phone
              access_token
              email_verified_at
              msisdn_verified_at
              expires_in
              refresh_token
            }
            userProfile{
              name
              nickname
              gender
              legal_document
              birth
              state
              city
              district
              address
              address_number
              address_complement
              zip_code
              reference_point
              latitude
              longitude
            }
            farmerUnconformities{
              id
              user_id
              description
              resolution_date
            }
            bankInformation{
              id
              user_id
              user_type
              account_holder
              bank_name
              bank_code
              agency_number
              account_type
              account_number
            }
            familyStructure{
              id
              number_children
              marital_status
              value_agricultural_income
              external_income
              retirement_income
              social_program_income
              social_program_description
              work_off_property
              work_off_property_description
              other_external_incomes
              other_external_incomes_description
              external_income_amount
              percentage_income_from_agricultural_activity
              family_members_working_production
              family_members_dependent_property
              family_members
              property_has_family_labor
              property_has_clt_labor
              property_has_daily_labor
              property_has_partnership_labor
            }
            farmerProperty{
              id
              user_id
              name
              country
              state
              city
              address
              address_number
              address_complement
              zip_code
              reference_point
              district
              latitude
              longitude
              land_owner
              legal_right
              other_legal_right
              property_size
              productive_area_size
              production_area_size
              agroindustrial_area
              organic_area
              farmers_quantity_by_unit
              resides_in_property
              local_residence
              certification
              has_house
              has_deposit
              has_corral
              has_hennery
              has_cocoa_barge
              has_bathroom
              has_dry_toilet
              has_electricity
              has_vegetable_garden
              has_dike
              has_seedling_nursery
              has_cold_chamber
              has_paileteria
              has_caixaria
              has_weigher
              has_beneficiation_kitchen
              others_improvements
              has_tractor
              has_tobata
              has_dehydrator
              has_transport
              has_irrigation
              has_drip_irrigation
              has_sprinkler_irrigation
              others_irrigation_type
              uses_own_water_mine
              uses_cistern
              uses_weir
              uses_mine_off_property
              uses_river_stream
              uses_underground_water
              uses_artesian
              uses_circular
              others_water_sources
              cubic_meters_used
            }
            farmerProduction{
                user_id
                uses_plowing
                uses_harrowing
                uses_zero_tillage
                soil_analysis_date
                uses_manure
                amount_manure
                manure_supplier
                uses_compost
                amount_compost
                compost_supplier
                uses_bio_syrup
                amount_bio_syrup
                bio_syrup_supplier
                uses_rock_dust
                amount_rock_dust
                rock_dust_supplier
                others_inputs
                uses_own_seeds
                uses_acquired_seeds
                amount_seeds
                seed_supplier
                has_climate_difficulty
                has_input_difficulty
                has_irrigation_difficulty
                has_credit_difficulty
                has_ater_difficulty
                other_difficulties
                accessed_agricultural_credit
                reason_not_access_credit
                costing_modality
                agroecology_modality
                woman_modality
                echo_modality
                semiarid_modality
                young_modality
                forest_modality
                agrarian_reform_investiment_modality
                agrarian_reform_costing_modality
                more_aliments_modality
                cattail_modality
                other_modalities
                interest_in_credit
                costing_purpose
                amount_for_costing
                investiment_purpose
                amount_for_investiment
                has_technical_support
                description_technical_support
                uses_agroforestry_systems
                uses_biodynamic
                uses_cabruca
                uses_traditional_cultivation
                other_forms_management
                aggregates_value_primary_products
                fruit_jelly
                cassava_flour
                fruit_pulp
                cocoa_nibis
                chocolate
                has_others_aggregate_values
                description_others_aggregate_values
                structure_to_processing
            }
            farmerProducts{
              farmer_product_id
              user_id
              product_id
              measure_unit_id
              amount_units_for_kilo
              week_production_quantity
              sale_quantity_for_unit
              week_sales_value
              week_production_capacity
              produces_in_january
              produces_in_february
              produces_in_march
              produces_in_april
              produces_in_may
              produces_in_june
              produces_in_july
              produces_in_august
              produces_in_september
              produces_in_october
              produces_in_november
              produces_in_december
            }
            farmerLogistic{
              user_id
              use_charter_transport
              use_own_transport
              other_transport
              bad_asphalt_road
              good_asphalt_road
              bad_dirt_road
              good_dirt_road
              road_passable_by_wagon
              small_structure
              average_structure
              large_structure
              other_structure
              weekly_frequency
              biweekly_frequency
              other_frequency
              countryside_sacks
              countryside_boxes
              countryside_others
              transport_sacks
              transport_boxes
              transport_others
              commercialization_sacks
              commercialization_boxes
              commercialization_pallets
              commercialization_others
        
            }
            farmerCommercialization{
              user_id
              organic_commercialization_percentage
              conventional_commercialization_percentage
              baskets_in_circuit
              fair_in_circuit
              station_in_circuit
              long_circuit_in_circuit
              baskets_out_circuit
              institutional_sales_out_circuit
              public_market_out_circuit
              company_out_circuit
              conventional_fairs_out_circuit
              others_out_circuit
              description_others_out_circuit
              has_packaging
              tray_type_packing
              net_type_packing
              little_bag_type_packing
              bunch_type_packing
              others_type_packing
              description_others_type_packing
              has_packaging_label
            }
            farmerCocoa{
              user_id
              cocoa_area_size
              amount_cocoa_tree
              unknow_amount_cocoa_tree
              need_replanting
              production_volume_year
              production_volume_at
              cabruca_production_system
              full_sun_production_system
              saf_production_system
              others_production_system
              description_others_production_system
              produces_cocoa_commodity
              produces_cocoa_superior
              produces_cocoa_fine
              percentage_superior_fine
              cultivates_common_variety
              cultivates_cloned_variety
              cultivates_mixed_variety
              harvest_management_beneficiament
              fermentation_beneficiament
              drying_management_beneficiament
              warehouse_storage
              house_storage
              specific_depository_storage
              common_depository_storage
              other_storage
              description_other_storage
              storage_time_in_months
              storage_time_in_days
              nylon_sacking
              aligning_sacking
              other_sacking
              description_other_sacking
              participate_association_cooperative
              description_association_cooperative
              sells_to_industry
              sells_to_cooperative
              sells_to_intermediate
              sells_to_consumer
              transport_difficulty
              low_price_difficulty
              storage_difficulty
              quality_difficulty
              other_difficulty
              description_other_difficulty
              marketed_percentage_almonds
              marketed_percentage_nibs
              marketed_percentage_liquor
              marketed_percentage_chocolate
              marketed_percentage_cocoa_candy
              marketed_percentage_soft_cocoa
              sells_cocoa_pulp
              amount_cocoa_pulp
              sells_fruit_gourd
              amount_fruit_gourd
              sells_cocoa_honey
              amount_cocoa_honey
        
            }
            managements {
              management_id
              user_id
              description
              created_at
              edit_at
              managementFarmer {
                management_id
                name
                birth
                identity_document
                cpf_document
                rural_block_registration
                spouse_name
                spouse_birth
                children
                family_members
                address
                line_or_community
                city
                zip_code
                state
                phone
                fax
                productive_unit_description
              }
              managementActivityByArea {
                management_id
                area_type_id
                area_description
                occupied_area
                management_type_id
                installments_number
              }
              managementAnimalProduction {
                management_id
                has_dairy_bovines
                has_beef_bovines
                has_dairy_caprines
                has_beef_caprines
                has_birds_of_laying
                has_beef_birds
                has_bees_creation
                has_ovines
                has_rabbits
                has_buffalos
                has_porcines
                has_aquatic_animals
                has_other_animals
                aquatic_animals_description
                other_animals_description
                animal_waste_treatment
                in_adequate_conditions
                not_adequate_conditions_description
                intestinal_worms_control
                external_parasites_control
                common_diseases_treatments
                welfare_good_water
                welfare_good_food
                welfare_adequate_facilities
                welfare_adequate_occupancy
                welfare_shading_areas
                welfare_sun_pasture_access
                welfare_proper_bed
                welfare_other_shapes
                other_shapes_description
                plantel_herd_origin
                managementOwnRationIngredient {
                  management_id
                  ration_ingredient_id
                  ration_ingredient_name
                  management_description
                }
                managementRationComposition {
                  management_id
                  ration_ingredient_id
                  ration_ingredient_name
                  own_production
                  buy_out
                  organic
                  non_organic
                }
                managementAnimalFeedingMedication {
                  management_id
                  animal_category_id
                  animal_category
                  ecological_feeding
                  conventional_feeding
                  ecological_medication
                  conventional_medication
                }
                managementAnnualFeedingPlan {
                  management_id
                  feeding_category_id
                  feeding_category
                  january
                  february
                  march
                  april
                  may
                  june
                  july
                  august
                  september
                  october
                  november
                  december
                }
              }
              managementVegetableProduction {
                management_id
                all_crops_produced_organically
                seeds_origin
                fertilization_treatment
                soil_management_practices
                initiatives_improve_fertility
                main_spontaneous_weeds
                does_mowing
                does_manual_weeding
                does_grazing
                does_green_adubation
                does_shading
                does_other_controls
                other_controls_description
                managementPestDiseaseControl {
                  pest_disease_id
                  pest_disease_name
                  management_id
                  used_product_or_management
                  used_where
                  used_when
                  origin
                  composition
                }
              }
              managementBeekeeping {
                management_id
                bee_type
                uses_fixed_beekeeping
                uses_migratory_beekeeping
                wax_organic_apiaries
                wax_non_organic_apiaries
                wax_own_organic_production
                wax_own_non_organic_production
                bees_own_production
                bees_swarm_capture
                bees_purchase_conventional_systems
                bees_purchase_organic_swarms
                flowering_periods
                distance_less
                distance_one_km
                distance_two_km
                distance_three_km
                distance_four_km
                distance_five_km
                distance_more
                distance_more_description
                feed_honey
                feed_organic_sugar_syrup
                feed_other
                feed_other_description
                feed_for_how_long
                pest_control
              }
              managementExtractivism {
                management_id
                area_federal_conservation_unit
                area_state_conservation_unit
                area_rural_settlement_project
                area_quilombola_territory
                area_indigenous_land
                area_other
                area_other_description
                area_federal_description
                area_state_description
                situation_tenure
                situation_definitive_title
                situation_settlement
                situation_granting_use_rights
                situation_other
                situation_other_description
                managementExtractivismSpecies {
                  management_id
                  specie_id
                  specie
                  description
                  individuals_number
                  collected_part
                  collected_amount
                  collection_epoch
                  minimum_age
                  circumference
                  interval_collections
                  leaves_part_to_multiply
                }
              }
              managementProductiveUnit {
                management_id
                native_vegetation_area
                rivers_protection
                native_vegetation_recover
                uses_irrigation
                irrigation_waters_origin
                dry_garbage_treatment
                liquid_effluents_treatment
                who_works
                working_conditions
                accidents_work_responability
                school_attendance
                compromises_school_performance
                has_stored_seeds
                has_stored_manure
                has_stored_mineral_fertilizers
                has_stored_limestone
                has_stored_biofertilizers_salts
                has_stored_animal_medicines
                has_stored_mineral_salt
                has_stored_others
                description_other_stored
                commercialization_channels
                commercialization_other_questions
                managementDependency {
                  management_id
                  own_pasture
                  rent_pasture
                  own_concentrate
                  rent_concentrate
                  own_minerals_vitamins
                  rent_minerals_vitamins
                  own_hay_silage
                  rent_hay_silage
                  own_grass
                  rent_grass
                  own_grains
                  rent_grains
                  own_crop_remains
                  rent_crop_remains
                  own_others
                  rent_others
                  others_description
                }
                managementOutsideProductionInput {
                  management_id
                  product_id
                  local_name
                  measure_unit_id
                  measure_unit_description
                  annual_amount
                }
                managementProductManipulation {
                  management_id
                  product_id
                  manipulation
                  storage
                  transport
                  local_name
                  comercial_name
                }
                managementProductionEstimate {
                  management_id
                  product_category_id
                  product_category
                  product_id
                  local_name
                  comercial_name
                  amount
                  measure_unit_id
                  measure_unit_description
                  periodicity_id
                  periodicity_description
                }
              }
              managementProductionSummary {
                management_id
                glebe
                year
                semester
                specie_id
                specie
                management_type_id
                management_type
              }
            }
          }
    }
`;

