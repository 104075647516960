
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

// COMPONENTS

import Formulario from '../../Component/ValorProduto/Formulario';
import EditarValorProduto from '../../Component/ValorProduto/EditarValorProduto';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});
class ValorProduto extends Component {
    constructor(props){
        super(props);
    }
    render() {
        console.log("Props VP: ", this.props)
        if(this.props.match.params.action == 'edit'){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <EditarValorProduto id={this.props.match.params.id}/>
                </div>
            );
        } else {
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <Formulario/>
                </div>
            );
        }
    }
}
export default withStyles(styles)(ValorProduto);