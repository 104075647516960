import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import {    EDIT_PRODUCT_MANIPULATION, 
            EDIT_MANAGEMENT_DEPENDENCY, 
            EDIT_OUTSIDE_PRODUCTION,
            EDIT_PRODUCTION_ESTIMATE,
            DELETE_PRODUCT_MANIPULATION,
            DELETE_OUTSIDE_PRODUCTION,
            DELETE_PRODUCTION_ESTIMATE } from '../../../services/graphql/mutations/management';

import {    OUTSIDE_PRODUCTION,
            PRODUCT_MANIPULATION,  
            VARIAVEIS_QUERY,
            PRODUCTION_ESTIMATE,
         } from '../../../services/graphql/querys/manejo';

import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';
import MaskedInput from 'react-text-mask';
import gql from 'graphql-tag';

import {
    TextField,
    Grid,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormGroup,
    MenuItem,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';
import { Add, Print, Save, Delete } from '@material-ui/icons';


const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    input: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },  
    radioClass: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },    
    divisor: {
        width: "100%",
        height: "3px",
        backgroundColor: "#000",
        marginTop: "3px",
        marginBottom: "10px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});



class CadProdUnit2 extends Component { 
    constructor(props) {
        super(props);
        if(!!this.props.manejo.manejo.managementProductiveUnit && this.props.manejo.manejo.managementProductiveUnit != null && !!this.props.manejo.manejo.managementProductiveUnit.managementDependency){
            let manejo = this.props.manejo.manejo;
            this.state = {
                management_id: manejo.managementProductiveUnit.management_id,
                own_pasture: manejo.managementProductiveUnit.managementDependency.own_pasture,
                rent_pasture: manejo.managementProductiveUnit.managementDependency.rent_pasture,
                own_concentrate: manejo.managementProductiveUnit.managementDependency.own_concentrate,
                rent_concentrate: manejo.managementProductiveUnit.managementDependency.rent_concentrate,
                own_minerals_vitamins: manejo.managementProductiveUnit.managementDependency.own_minerals_vitamins,
                rent_minerals_vitamins: manejo.managementProductiveUnit.managementDependency.rent_minerals_vitamins,
                own_hay_silage: manejo.managementProductiveUnit.managementDependency.own_hay_silage,
                rent_hay_silage: manejo.managementProductiveUnit.managementDependency.rent_hay_silage,
                own_grass: manejo.managementProductiveUnit.managementDependency.own_grass,
                rent_grass: manejo.managementProductiveUnit.managementDependency.rent_grass,
                own_grains: manejo.managementProductiveUnit.managementDependency.own_grains,
                rent_grains: manejo.managementProductiveUnit.managementDependency.rent_grains,
                own_crop_remains: manejo.managementProductiveUnit.managementDependency.own_crop_remains,
                rent_crop_remains: manejo.managementProductiveUnit.managementDependency.rent_crop_remains,
                own_others: manejo.managementProductiveUnit.managementDependency.own_others,
                rent_others: manejo.managementProductiveUnit.managementDependency.rent_others,
                others_description: manejo.managementProductiveUnit.managementDependency.others_description,       
                production_inputs: null,        
                measure_units: null,
                products: null,
                periodicidades: null,
            }
        }else{
            this.state = {
                management_id: this.props.manejo.manejo.management_id,
                own_pasture: null,
                rent_pasture: null,
                own_concentrate: null,
                rent_concentrate: null,
                own_minerals_vitamins: null,
                rent_minerals_vitamins: null,
                own_hay_silage: null,
                rent_hay_silage: null,
                own_grass: null,
                rent_grass: null,
                own_grains: null,
                rent_grains: null,
                own_crop_remains: null,
                rent_crop_remains: null,
                own_others: null,
                rent_others: null,
                others_description: null,
                production_inputs: null,
                measure_units: null,
                products: null,
                periodicidades: null,
            };
        }
    } 

    componentDidMount(){
        this.props.client.query({query: VARIAVEIS_QUERY, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("Data Ingred: ", data)
                //let production_inputs = data.ProductionInputQuery;
                let measure_units = data.MeasureUnitsQuery;
                let insumos = data.InsumosQuery;
                let products = data.ProductQuery;
                let periodicidades = data.PeriodicityQuery;
                
                this.setState({
                    //production_inputs: production_inputs, 
                    measure_units: measure_units, 
                    insumos: insumos,
                    products: products,
                    periodicidades: periodicidades,
                })
            }
        })
    }


    editDependency(){
        console.log("Faz Mutation", this.state)

        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros DEPENDENCY: ", paramsEnviar);

        this.props.client.mutate(
            {
                mutation: EDIT_MANAGEMENT_DEPENDENCY, 
                variables: paramsEnviar,
                errorPolicy: 'all',
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            }else{
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");
            }
        })
    }

    addOutsideProduction(){
        console.log("Faz Mutation", this.state)

        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros OUTSIDE: ", paramsEnviar);

        this.props.client.mutate(
            {
                mutation: EDIT_OUTSIDE_PRODUCTION, 
                variables: paramsEnviar,
                errorPolicy: 'all',
                refetchQueries:[{
                    query: OUTSIDE_PRODUCTION,
                    variables: 
                    { 
                        management_id: this.state.management_id,
                    }
                }]
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            }else{
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");                
            }
        })
    }
    addProductManipulation(){
        console.log("Faz Mutation", this.state)
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        console.log("Enviar Parametros MANIPULATION: ", paramsEnviar);
        this.props.client.mutate(
            {
                mutation: EDIT_PRODUCT_MANIPULATION, 
                variables: paramsEnviar,
                errorPolicy: 'all',
                refetchQueries:[{
                    query: PRODUCT_MANIPULATION,
                    variables: 
                    { 
                        management_id: this.state.management_id,
                    }
                }]
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            }else{
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");
            }
        })
    }
    addProductionEstimate(){
        console.log("Faz Mutation", this.state)
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        console.log("Enviar Parametros ESTIMATE: ", paramsEnviar);
        this.props.client.mutate(
            {
                mutation: EDIT_PRODUCTION_ESTIMATE, 
                variables: paramsEnviar,
                errorPolicy: 'all',
                refetchQueries:[{
                    query: PRODUCTION_ESTIMATE,
                    variables: 
                    { 
                        management_id: this.state.management_id,
                    }
                }]
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            }else{
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");                
            }
        })
    }
    removeOutsideProduction(id){
        console.log("Remove Esse: ",id)
        this.props.client.mutate({
            mutation: DELETE_OUTSIDE_PRODUCTION, 
            variables: {management_id: this.state.management_id, product_id: id}, 
            errorPolicy: 'all',
            refetchQueries:[{
                query: OUTSIDE_PRODUCTION,
                variables: 
                { 
                    management_id: this.state.management_id,
                }
            }]
        })
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({open: false, remover: null});
                alert("Erro ao remover produto!"); 
            } else {
                console.log("DATA: ", data);
                alert("Produto removido!");
            }
        })
    }
    removeProductManipulation(id){
        console.log("Remove Esse: ",id)
        this.props.client.mutate({
            mutation: DELETE_PRODUCT_MANIPULATION, 
            variables: {management_id: this.state.management_id, product_id: id}, 
            errorPolicy: 'all',
            refetchQueries:[{
                query: PRODUCT_MANIPULATION,
                variables: 
                { 
                    management_id: this.state.management_id,
                }
            }]
        })
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({open: false, remover: null});
                alert("Erro ao remover produto!"); 
            } else {
                console.log("DATA: ", data);
                alert("Produto removido!");
            }
        })
    }

    removeProductionEstimate(id){
        console.log("Remove Esse: ",id)
        this.props.client.mutate({
            mutation: DELETE_PRODUCTION_ESTIMATE, 
            variables: {management_id: this.state.management_id, product_id: id}, 
            errorPolicy: 'all',
            refetchQueries:[{
                query: PRODUCTION_ESTIMATE,
                variables: 
                { 
                    management_id: this.state.management_id,
                }
            }]
        })
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({open: false, remover: null});
                alert("Erro ao remover produto!"); 
            } else {
                console.log("DATA: ", data);
                alert("Produto removido!");
            }
        })
    }
   
    renderOutsideProduction(outsideProduct){
        return  outsideProduct.map((out) =>
                    <TableRow>
                        <TableCell>{out.local_name}</TableCell>
                        <TableCell>{out.measure_unit_description}</TableCell>
                        <TableCell>{out.annual_amount}</TableCell>
                        <TableCell>
                            <Fab variant="extended" color="primary" onClick={() => this.removeOutsideProduction(out.product_id)}>
                                <Delete />
                                Remover
                            </Fab>
                        </TableCell>
                    </TableRow>
                )
    }
    renderProductManipulation(products){
        return  products.map((out) =>
                    <TableRow>
                        <TableCell>{out.local_name}</TableCell>
                        <TableCell>{out.manipulation}</TableCell>
                        <TableCell>{out.storage}</TableCell>
                        <TableCell>{out.transport}</TableCell>

                        <TableCell>
                            <Fab variant="extended" color="primary" onClick={() => this.removeProductManipulation(out.product_id)}>
                                <Delete />
                                Remover
                            </Fab>
                        </TableCell>
                    </TableRow>
                )
    }
    renderProductionEstimate(products){
        return  products.map((out) =>
                    <TableRow>
                        <TableCell>{out.local_name}</TableCell>
                        <TableCell>{out.measure_unit_description}</TableCell>
                        <TableCell>{out.periodicity_description}</TableCell>
                        <TableCell>{out.amount}</TableCell>

                        <TableCell>
                            <Fab variant="extended" color="primary" onClick={() => this.removeProductionEstimate(out.product_id)}>
                                <Delete />
                                Remover
                            </Fab>
                        </TableCell>
                    </TableRow>
                )
    }
   

    render() {
        // console.log("state", this.state)
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <div style={{paddingTop: "10px"}}>
                    <h4>CADASTRO UNIDADE II {this.props.manejo.manejo.description}</h4>
                </div>
            
                <hr style={css.hr}/>


                <div style={css.contentColuns}>
                    <div style={{display: "flex", width: "100%", marginBottom: "15px"}}>
                        <sub><strong>Produtos e insumos adquiridos de fora da unidade de produção</strong></sub>
                    </div>
                    <div style={css.contentColuns}>
                        <div style={{display: "flex", width: "100%"}}>
                            <sub style={{alignSelf: "center", marginRight: "15px"}}>Pastos: </sub>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.own_pasture}
                                            onChange={(e) => this.setState({own_pasture: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Produção Própria"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.rent_pasture}
                                            onChange={(e) => this.setState({rent_pasture: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Arrenda/Aluga"/>
                        </div>
                        <div style={{display: "flex", width: "100%"}}>
                            <sub style={{alignSelf: "center", marginRight: "15px"}}>Concentrado: </sub>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.own_concentrate}
                                            onChange={(e) => this.setState({own_concentrate: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Produção Própria"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.rent_concentrate}
                                            onChange={(e) => this.setState({rent_concentrate: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Arrenda/Aluga"/>
                        </div>
                        <div style={{display: "flex", width: "100%"}}>
                            <sub style={{alignSelf: "center", marginRight: "15px"}}>Minerais e vitaminas: </sub>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.own_minerals_vitamins}
                                            onChange={(e) => this.setState({own_minerals_vitamins: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Produção Própria"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.rent_minerals_vitamins}
                                            onChange={(e) => this.setState({rent_minerals_vitamins: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Arrenda/Aluga"/>
                        </div>
                        <div style={{display: "flex", width: "100%"}}>
                            <sub style={{alignSelf: "center", marginRight: "15px"}}>Fenos e silagens: </sub>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.own_hay_silage}
                                            onChange={(e) => this.setState({own_hay_silage: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Produção Própria"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.rent_hay_silage}
                                            onChange={(e) => this.setState({rent_hay_silage: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Arrenda/Aluga"/>
                        </div>
                        <div style={{display: "flex", width: "100%"}}>
                            <sub style={{alignSelf: "center", marginRight: "15px"}}>Capineiras: </sub>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.own_grass}
                                            onChange={(e) => this.setState({own_grass: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Produção Própria"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.rent_grass}
                                            onChange={(e) => this.setState({rent_grass: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Arrenda/Aluga"/>
                        </div>
                        <div style={{display: "flex", width: "100%"}}>
                            <sub style={{alignSelf: "center", marginRight: "15px"}}>Grãos: </sub>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.own_grains}
                                            onChange={(e) => this.setState({own_grains: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Produção Própria"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.rent_grains}
                                            onChange={(e) => this.setState({rent_grains: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Arrenda/Aluga"/>
                        </div>
                        <div style={{display: "flex", width: "100%"}}>
                            <sub style={{alignSelf: "center", marginRight: "15px"}}>Restos de culturas: </sub>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.own_crop_remains}
                                            onChange={(e) => this.setState({own_crop_remains: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Produção Própria"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.rent_crop_remains}
                                            onChange={(e) => this.setState({rent_crop_remains: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Arrenda/Aluga"/>
                        </div>
                        <div style={{display: "flex", width: "100%"}}>
                            <sub style={{alignSelf: "center", marginRight: "15px"}}>Outros: </sub>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.own_others}
                                            onChange={(e) => this.setState({own_others: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Produção Própria"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                            checked={this.state.rent_others}
                                            onChange={(e) => this.setState({rent_others: e.target.checked})}
                                            color="primary"
                                        />
                                }
                                label="Arrenda/Aluga"/>
                        </div>
                        <div style={{display: "flex", width: "100%"}}>
                            <TextField 
                                label="Outros. Quais?" 
                                InputLabelProps={{shrink: true,}} 
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({others_description: e.target.value})} 
                                value={this.state.others_description}
                                style={css.input}
                            />         
                        </div>
                        <Fab variant="extended" color="primary" onClick={() => this.editDependency()}>
                            <Save />
                            Inserir
                        </Fab>
                        <hr style={css.hr}/>
                    </div>

                    <div style={css.contentColuns}>
                        <div style={{display: "flex", width: "100%", marginBottom: "15px"}}>
                            <sub><strong>Produtos e insumos adquiridos de fora da unidade de produção</strong></sub>
                        </div>
                    
                        <TextField
                            id="selectProduto2"
                            select
                            label="Produto"
                            value={this.state.product_id}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => this.setState({product_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            style={css.input}
                        
                        >
                            {
                            !!this.state.insumos && this.state.insumos.map(option => (
                                <MenuItem key={`select-prodinput2-${option.product_id}`} value={option.product_id}>
                                    {option.local_name}
                                </MenuItem>                           
                                ))
                            
                            }
                        </TextField>
                        
                        <TextField
                            id="selectMeasure"
                            select
                            label="Unidade"
                            value={this.state.measure_unit_id}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => this.setState({measure_unit_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            style={css.input}
                        
                        >
                            {
                            !!this.state.measure_units && this.state.measure_units.map(option => (
                                <MenuItem key={`select-measure-${option.measure_unit_id}`} value={option.measure_unit_id}>
                                    {option.measure_unit_description}
                                </MenuItem>                           
                                ))
                            
                            }
                        </TextField>
                        

                        <TextField 
                            label="Qtde Anual" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            type="number"
                            onChange={(e) => this.setState({annual_amount: e.target.value.replace(/,/i, '.')})} 
                            value={this.state.annual_amount}
                            style={css.input}
                        />             
                        <Fab variant="extended" color="primary" onClick={() => this.addOutsideProduction()}>
                            <Save />
                            Inserir
                        </Fab>
                    </div>
                    <TableContainer component={Paper}>
                        <Table className={this.props.classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Produto</TableCell>
                                    <TableCell>Unidade</TableCell>
                                    <TableCell>Qtde Anual</TableCell>
                                    <TableCell>Remover</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <Query query={OUTSIDE_PRODUCTION} variables={{management_id: this.state.management_id }}>
                                    {({ loading, error, data }) => {
                                            if (loading) {
                                                return null;
                                            }else if(error){
                                                console.log("Error",error);
                                            }
                                            console.log("RETORNO: ",data.ManagementQuery.managementProductiveUnit);
                                            if(data.ManagementQuery.managementProductiveUnit && data.ManagementQuery.managementProductiveUnit.managementOutsideProductionInput.length > 0){
                                                return this.renderOutsideProduction(data.ManagementQuery.managementProductiveUnit.managementOutsideProductionInput);
                                            }else{
                                                return "";
                                            }
                                    }}
                                </Query>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <hr style={css.hr}/>

                
                <div >
                    <div style={{display: "flex", width: "100%", marginBottom: "15px"}}>
                        <sub><strong>Como você manipula, armazena e transporta o produto para a comercialização?</strong></sub>
                    </div>
                    
                    <TextField
                        id="selectprod1"
                        select
                        label="Produto"
                        value={this.state.product_id}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => this.setState({product_id: e.target.value})}
                        variant="outlined"
                        margin="dense"
                        style={{width: "10%"}}
                    
                    >
                        {
                        !!this.state.products && this.state.products.map(option => (
                            <MenuItem key={`select-pccccc1-${option.product_id}`} value={option.product_id}>
                                {option.local_name}
                            </MenuItem>
                            ))
                        
                        }
                    </TextField>
                    
                    <TextField 
                        label="Manipulação" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" 
                        onChange={(e) => this.setState({manipulation: e.target.value})} 
                        value={this.state.manipulation}
                        style={{width: "88.5%"}}
                    />     
                    

                    <TextField 
                        label="Armazenamento" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" 
                        onChange={(e) => this.setState({storage: e.target.value})} 
                        value={this.state.storage}
                        style={css.input}
                    />     
                    
                    <TextField 
                        label="Transporte" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" 
                        onChange={(e) => this.setState({transport: e.target.value})} 
                        value={this.state.transport}
                        style={css.input}
                    />              
                    <Fab variant="extended" color="primary" onClick={() => this.addProductManipulation()}>
                        <Save />
                        Inserir
                    </Fab>
                
                    <TableContainer component={Paper}>
                        <Table className={this.props.classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Produto</TableCell>
                                    <TableCell>Manipulação</TableCell>
                                    <TableCell>Armazenamento</TableCell>
                                    <TableCell>Transporte</TableCell>
                                    <TableCell>Remover</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <Query query={PRODUCT_MANIPULATION} variables={{management_id: this.state.management_id }}>
                                    {({ loading, error, data }) => {
                                            if (loading) {
                                                return null;
                                            }else if(error){
                                                console.log("Error",error);
                                            }
                                            console.log("RETORNO: ",data.ManagementQuery.managementProductiveUnit);
                                            if(data.ManagementQuery.managementProductiveUnit && data.ManagementQuery.managementProductiveUnit.managementProductManipulation.length > 0){
                                                return this.renderProductManipulation(data.ManagementQuery.managementProductiveUnit.managementProductManipulation);
                                            }else{
                                                return "";
                                            }
                                    }}
                                </Query>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <hr style={css.hr}/>
                <div style={css.contentColuns}>
                    <div style={{display: "flex", width: "100%", marginBottom: "15px"}}>
                        <sub><strong>Estimativa de produção agroecológica</strong></sub>
                    </div>
                    
                    <TextField
                        id="selectprod2"
                        select
                        label="Produto"
                        value={this.state.product_id2}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => this.setState({product_id2: e.target.value})}
                        variant="outlined"
                        margin="dense"
                        style={{width: "10%"}}
                    
                    >
                        {
                        !!this.state.products && this.state.products.map(option => (
                            <MenuItem key={`select-pxc2-${option.product_id}`} value={option.product_id}>
                                {option.local_name}
                            </MenuItem>                           
                            ))
                        
                        }
                    </TextField>
                    
                    <TextField
                            id="selectMeasure2"
                            select
                            label="Unidade"
                            value={this.state.measure_unit_id2}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => this.setState({measure_unit_id2: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            style={css.input}
                        
                        >
                            {
                            !!this.state.measure_units && this.state.measure_units.map(option => (
                                <MenuItem key={`select-measure2-${option.measure_unit_id}`} value={option.measure_unit_id}>
                                    {option.measure_unit_description}
                                </MenuItem>                           
                                ))
                            
                            }
                        </TextField>
                        <TextField
                            id="periodicidade"
                            select
                            label="Periodicidade"
                            value={this.state.periodicity_id}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => this.setState({periodicity_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            style={css.input}
                        
                        >
                            {
                            !!this.state.periodicidades && this.state.periodicidades.map(option => (
                                <MenuItem key={`select-periodi2-${option.periodicity_id}`} value={option.periodicity_id}>
                                    {option.periodicity_description}
                                </MenuItem>                           
                                ))
                            
                            }
                        </TextField>

                    <TextField 
                        label="Qtde Aproximada" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" 
                        type="number"
                        onChange={(e) => this.setState({amount: e.target.value.replace(/,/i, '.')})} 
                        value={this.state.amount}
                        style={css.input}
                    />     
                    

                         
                    <Fab variant="extended" color="primary" onClick={() => this.addProductionEstimate()}>
                        <Save />
                        Inserir
                    </Fab>
                
                    <TableContainer component={Paper}>
                        <Table className={this.props.classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Produto</TableCell>
                                    <TableCell>Unidade</TableCell>
                                    <TableCell>Periodicidade</TableCell>
                                    <TableCell>Qtde Aproximada</TableCell>
                                    <TableCell>Remover</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <Query query={PRODUCTION_ESTIMATE} variables={{management_id: this.state.management_id }}>
                                    {({ loading, error, data }) => {
                                            if (loading) {
                                                return null;
                                            }else if(error){
                                                console.log("Error",error);
                                            }
                                            console.log("RETORNO: ",data.ManagementQuery.managementProductiveUnit);
                                            if(data.ManagementQuery.managementProductiveUnit && data.ManagementQuery.managementProductiveUnit.managementProductionEstimate.length > 0){
                                                return this.renderProductionEstimate(data.ManagementQuery.managementProductiveUnit.managementProductionEstimate);
                                            }else{
                                                return "";
                                            }
                                    }}
                                </Query>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        );
    }
}
export default withApollo((withStyles(styles))(CadProdUnit2));

const css = {
    contentColuns: {
        display:"flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    col1: {
        maxWidth: "25%",
        minWidth: "25%",
        flex: 1
    },
    col2: {
        maxWidth: "50%",
        minWidth: "50%",
        flex: 1
    },
    col3: {
        maxWidth: "75%",
        minWidth: "75%",
        flex: 1
    },
    col4: {
        maxWidth: "100%",
        minWidth: "100%",
        flex: 1
    },
    input: {
       flex: 1,
       display: "flex",
       marginRight: 15
    },
    hr: {
        marginTop: "20px",
        marginBottom: "20px"
    }
}