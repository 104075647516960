import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import CustomErrors from '../CustomErrors/CustomErrors';
import { withApollo, Query } from 'react-apollo';
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    FormControlLabel,
    Checkbox,
    TextField,
    MenuItem
} from '@material-ui/core/';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});

class Rodada extends Component {
    constructor(props){
        super(props);
        this.state ={}
    }
    render(){
        return (
            <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                <h1 style={{marginBottom: "1rem"}}>Relatorio de Rodada</h1>
                <Grid container spacing={3}>
                     <Grid className={this.props.classes.root} item xl={12}>
                        Periodo:
                    </Grid>
                </Grid> 
                <Grid container spacing={3}>
                     <Grid className={this.props.classes.root} item xl={3}>
                        <TextField 
                        onChange={(e) => this.setState({need_replanting: e.target.value})} 
                        value={this.state.need_replanting}
                        className={this.props.classes.textField} 
                        type="date"
                        label="De:" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField 
                        onChange={(e) => this.setState({production_volume_year: e.target.value})} 
                        value={this.state.production_volume_year}  
                        type="date"
                        className={this.props.classes.textField} 
                        label="A:" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField
                            select
                            label="Estação: "
                            className={this.props.classes.textField} 
                            value={this.state.core_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({core_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField
                            select
                            label="Unidade: "
                            className={this.props.classes.textField} 
                            value={this.state.core_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({core_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{marginBottom: "1rem"}}>
                    <Grid className={this.props.classes.root} item xl={6}>
                    <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.group_offerer}
                                        onChange={(e) => this.setState({group_offerer: e.target.checked})}
                                        color="primary"
                                    />
                            }
                        label="Consumidor"/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.group_offerer}
                                        onChange={(e) => this.setState({group_offerer: e.target.checked})}
                                        color="primary"
                                    />
                            }
                        label="Agricultor"/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.group_offerer}
                                        onChange={(e) => this.setState({group_offerer: e.target.checked})}
                                        color="primary"
                                    />
                            }
                        label="Produtos"/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.group_offerer}
                                        onChange={(e) => this.setState({group_offerer: e.target.checked})}
                                        color="primary"
                                    />
                            }
                        label="Valor pedido"/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.group_offerer}
                                        onChange={(e) => this.setState({group_offerer: e.target.checked})}
                                        color="primary"
                                    />
                            }
                        label="Furo"/>
                    </Grid>
                    
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField
                            select
                            label="Núcleo: "
                            className={this.props.classes.textField} 
                            value={this.state.core_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({core_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <CustomErrors error={this.state.error}/>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell component="th">Rodada</TableCell>
                                <TableCell component="th" align="right">Consumidor</TableCell>
                                <TableCell component="th" align="right">Produtos</TableCell>
                                <TableCell component="th" align="right">Ofertante</TableCell>
                                <TableCell component="th" align="right">Produtos</TableCell>
                                <TableCell component="th" align="right">Furo</TableCell>
                                <TableCell component="th" align="right">Valor Pedido Consumidor</TableCell>
                                <TableCell component="th" align="right">Valor Pedido Agricultor</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell scope="row"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Rodada));