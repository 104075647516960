import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// COMPONENTS
import ComercializacaoGeral from './DadosComercializacao/ComercializacaoGeral';
import TipoIniciativa from './DadosComercializacao/TipoIniciativa';
import OrigemProdutosOrg from './DadosComercializacao/OrigemProdutosOrg';
import CertficacaoProd from './DadosComercializacao/CertficacaoProd';
import AcessoOfertas from './DadosComercializacao/AcessoOfertas';
import Tipos from './DadosComercializacao/Tipos';
import Logistica from './DadosComercializacao/Logistica';
import Pagamento from './DadosComercializacao/Pagamento';
import Demanda from './DadosComercializacao/Demanda';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function DadosComercializacao(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className="page-wrapper" style={{bottom: 0, top: '140px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
            <AppBar position="static" color="default">
                <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                >
                    <Tab label="Comercialização Geral" {...a11yProps(0)} />

                    <Tab label="Logística" {...a11yProps(1)} />

                    <Tab label="Pagamento" {...a11yProps(2)} />

                    <Tab label="Demanda" {...a11yProps(3)} />


                </Tabs>
            </AppBar>
            <div style={{ display: value === 0? 'block': 'none'}}>
                <ComercializacaoGeral customer={props.customer}/>
            </div>
            
            <div style={{ display: value === 1? 'block': 'none'}}>
                <Logistica customer={props.customer}/>
            </div>
            <div style={{ display: value === 2? 'block': 'none'}}>
                <Pagamento customer={props.customer}/>
            </div>
            <div style={{ display: value === 3? 'block': 'none'}}>
                <Demanda customer={props.customer} products={props.products}/>
            </div>
        </div>
    );
}