import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { CREATE_MANAGEMENT } from '../../../services/graphql/mutations/management';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';
import MaskedInput from 'react-text-mask';

import {
    TextField,
    Grid,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Fab,
} from '@material-ui/core';
import { Add, Print, Save } from '@material-ui/icons';


const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    radioClass: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },    
    divisor: {
        width: "100%",
        height: "3px",
        backgroundColor: "#000",
        marginTop: "3px",
        marginBottom: "10px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});

const Celular = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={['5','5',' ','\(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        showMask
      />
    );
}
const CelFixo = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={['5','5',' ','\(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        showMask
      />
    );
  }

class CadProdutor extends Component { 
    constructor(props) {
        super(props);
        if(!!this.props.manejo.manejo.managementFarmer && this.props.manejo.manejo.managementFarmer != null){
            let manejo = this.props.manejo.manejo;
            this.state = {
                management_id: manejo.managementFarmer.management_id,
                name: manejo.managementFarmer.name,
                birth: manejo.managementFarmer.birth,
                identity_document: manejo.managementFarmer.identity_document,
                cpf_document: manejo.managementFarmer.cpf_document,
                rural_block_registration: manejo.managementFarmer.rural_block_registration,
                spouse_name: manejo.managementFarmer.spouse_name,
                spouse_birth: manejo.managementFarmer.spouse_birth,
                children: manejo.managementFarmer.children,
                family_members: manejo.managementFarmer.family_members,
                address: manejo.managementFarmer.address,
                line_or_community: manejo.managementFarmer.line_or_community,
                city: manejo.managementFarmer.city,
                zip_code: manejo.managementFarmer.zip_code,
                state: manejo.managementFarmer.state,
                phone: manejo.managementFarmer.phone,
                fax: manejo.managementFarmer.fax,
                productive_unit_description: manejo.managementFarmer.productive_unit_description,
            }
        }else{
            let userProfile = !!this.props.farmer && !!this.props.farmer.userProfile ? this.props.farmer.userProfile : null;
            this.state = {
                management_id: this.props.manejo.manejo.management_id,
                name: !!userProfile && !!userProfile.name ? userProfile.name : "",
                birth: !!userProfile && !!userProfile.birth ? userProfile.birth : null,
                identity_document: !!this.props.farmer.identity_document ? this.props.farmer.identity_document : "",
                cpf_document: !!userProfile && !!userProfile.legal_document ? userProfile.legal_document : "",
                rural_block_registration: !!this.props.farmer.rural_block_registration ? this.props.farmer.rural_block_registration : "",
                spouse_name: null,
                spouse_birth: null,
                children: !!this.props.farmer.familyStructure && !!this.props.farmer.familyStructure.number_children ? this.props.farmer.familyStructure.number_children : null,
                family_members: null,
                address: !!userProfile && !!userProfile.address ? userProfile.address : "",
                line_or_community: null,
                city: !!userProfile && !!userProfile.city ? userProfile.city : "",
                zip_code: !!userProfile && !!userProfile.zip_code ? userProfile.zip_code : "",
                state: !!userProfile && !!userProfile.state ? userProfile.state : "",
                phone: !!this.props.farmer.user && !!this.props.farmer.user.msisdn ? this.props.farmer.user.msisdn : null,
                fax: !!this.props.farmer.user && !!this.props.farmer.user.phone ? this.props.farmer.user.phone : null,
                productive_unit_description: null,
                error: [],
            };
        }
    } 
        
    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        // this.props.client.mutate({mutation: CREATE_MANAGEMENT, variables: paramsEnviar, errorPolicy: 'all'})
        // .then(({data, errors}) => {
        //     if(errors){
        //         console.log("ERROR: ", errors)
        //         if(errors[0].message == 'validation'){
        //             let erroValid = errors[0].extensions.validation;
        //             let errosMostrar = [];
        //             let i = 0;
        //             for (var [key, value] of Object.entries(erroValid)) {
        //                 console.log("ERRO... " + key + ' ',value);
        //                 errosMostrar[i] = {message: value};
        //                 i++;
        //             }
        //             this.setState({error: errosMostrar})
        //         }else{
        //             let errosMostrar = [];
        //             for(var [key, value] of Object.entries(errors)){
        //                 errosMostrar[key] = {message: value.message};
        //             }
        //             this.setState({error: errosMostrar})
        //         }
                
                

        //     } else {
        //         console.log("DATA: ", data);
        //        this.props.setManagement(true);
        //         alert("Salvo com sucesso !");

        //     }
        // })
    }

    handleChangeMask = name => event => {
        this.setState({
        [name]: event.target.value,
        });
    };

    render() {
        // console.log("state", this.state)
        return (
            <>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3} style={{paddingTop: "10px"}}>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <h4>CADASTRO DO PRODUTOR {this.props.manejo.manejo.description}</h4>
                        <TextField id="agricultor" label="Nome do Agricultor (a)" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({name: e.target.value})} value={this.state.name}/>
                        <TextField id="data-nacimento" label="Data de nacimento" InputLabelProps={{shrink: true,}} type="date" variant="outlined" margin="dense" onChange={(e) => this.setState({birth: e.target.value})} value={this.state.birth}/>
                        <TextField id="registro-geral" label="RG" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({identity_document: e.target.value})} value={this.state.identity_document}/>
                        <TextField id="cpf-cnpj" label="CPF" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({cpf_document: e.target.value})} value={ this.state.cpf_document } />
                        <TextField id="numero-inscricao-bloco-produto" label="Nº de inscrição do bloco de produtor rural" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense"  onChange={(e) => this.setState({rural_block_registration: e.target.value})}  value={this.state.rural_block_registration}/>
                        <TextField id="nome-conju" label="Nome do Cônjuge" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({spouse_name: e.target.value})} value={this.state.spouse_name}/>
                        <TextField id="data-nacimento-conj" label="Data de nacimento do Cônjuge" InputLabelProps={{shrink: true,}} type="date" variant="outlined" margin="dense" onChange={(e) => this.setState({ spouse_birth: e.target.value})} value={this.state.spouse_birth}/>
                        <TextField id="quantidade-filhos" label="Quantidade de Filhos" type="number" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({children: e.target.value})} value={this.state.children}/>
                        <TextField id="familiares" label="Familiares" type="number" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({family_members: e.target.value})} value={this.state.family_members}/>
                        <TextField id="endereco" label="Endereço" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({address: e.target.value})} value={this.state.address}/>
                        <TextField id="linha-comu" label="Linha ou Comunidade" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({line_or_community: e.target.value})} value={this.state.line_or_community}/>
                        <TextField id="cidade" label="Municipio" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({city: e.target.value})} value={ this.state.city }/>
                        <TextField id="cep" label="CEP" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({zip_code: e.target.value})} value={ this.state.zip_code }/>
                        <TextField id="estado" label="Estado" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({state: e.target.value})} value={ this.state.state }/>
                        <TextField id="fax" label="Fax" variant="outlined" margin="dense"
                            InputLabelProps={{shrink: true,}}
                            InputProps={{
                                inputComponent: CelFixo,
                                onChange: this.handleChangeMask('phone'),
                                value: this.state.phone,
                            }}
                        />
                        <TextField id="telefone" label="Telefone" variant="outlined" margin="dense" 
                            InputLabelProps={{shrink: true,}}
                            InputProps={{
                                inputComponent: Celular,
                                onChange: this.handleChangeMask('msisdn'),
                                value: this.state.msisdn
                            }}
                        />
                    </Grid>
                    <TextField id="description" name="i_description"  value={this.state.productive_unit_description} label="Descreva sua unidade de produ" onChange={(e) => this.handleChange(e)} variant="outlined" rows="4" multiline margin="dense" />

                </Grid>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={6}>
                        <Fab variant="extended" color="primary" onClick={() => this.submitIt()}>
                            <Save />
                            Criar
                        </Fab>
                    </Grid>
                </Grid>
            </>
        );
    }
}export default withApollo((withStyles(styles))(CadProdutor));
