import gql from 'graphql-tag';

export const CONFERE_PEDIDO_FORNECEDOR = gql`
mutation receiveSalesOrder($sales_order_id: Int!, $items: [SalesOrderProductInput]){
  receiveSalesOrder(sales_order_id: $sales_order_id, items: $items){
    id
    status{
      code
      description
      posible_statuses{
        code
        description
      }
    }
  }
}
`;