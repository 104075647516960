import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    TextField,
    Grid,
    FormControlLabel,
    Button,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    DialogTitle,
    Dialog,
    List,
    ListItem
} from '@material-ui/core';
import { Search, Add, Edit, Delete } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    },
    buttonAction: {
        marginLeft: '10px',
        marginRight: '10px',
    }
});
function createData(nome, cidade, estado) {
    return { nome, cidade, estado };
}
const rows = [
    createData('Grupo do Bolinha', 'São Paulo', 'São Paulo'),
    createData('Grupo do Bolinha', 'São Paulo', 'São Paulo'),
    createData('Grupo do Bolinha', 'São Paulo', 'São Paulo'),
    createData('Grupo do Bolinha', 'São Paulo', 'São Paulo'),
    createData('Grupo do Bolinha', 'São Paulo', 'São Paulo'),
];
class Lista extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false
        }
    }
    render(){
        const { onClose, selectedValue, open } = this.props;

        const handleClose = () => {
            this.setState({open: false});
        };
        const handleClickOpen = () => {
            this.setState({open: true});
        };
        return(
            <div>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={9}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="tamanho-hectares" className={this.props.classes.textField} label="Nome" variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="tamanho-hectares" className={this.props.classes.textField} label="Município" variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="tamanho-hectares" className={this.props.classes.textField} label="Estado" variant="outlined" margin="dense" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <Grid container spacing={2}>
                            <Grid className={this.props.classes.root} align="center" item xl={5}>
                                <Button variant="contained" color="primary" disableElevation className={this.props.classes.button}>
                                    <Search /> Buscar
                                </Button>
                            </Grid>
                            <Grid className={this.props.classes.root} align="center" item xl={5}>
                                <Button variant="contained" color="primary" disableElevation className={this.props.classes.button} component="a" href="/subestacao/new">
                                    <Add /> Adicionar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <TableContainer component={Paper}>
                            <Table className={this.props.classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell align="center">Município</TableCell>
                                        <TableCell align="center">Estado</TableCell>
                                        <TableCell align="center">Editar</TableCell>
                                        {/* <TableCell align="center">Excluir</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {rows.map(row => (
                                    <TableRow key={row.nome}>
                                        <TableCell component="th" scope="row">{row.nome}</TableCell>
                                        <TableCell align="center">{row.cidade}</TableCell>
                                        <TableCell align="center">{row.estado}</TableCell>
                                        <TableCell align="center">
                                        <Fab color="primary" size="small" aria-label="editar" component="a" href="/subestacao/edit">
                                            <Edit />
                                        </Fab>
                                        </TableCell>
                                        {/* <TableCell align="center">
                                        <Fab color="primary" size="small" aria-label="search" onClick={handleClickOpen}>
                                            <Delete />
                                        </Fab>
                                        </TableCell> */}
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
                    <DialogTitle id="simple-dialog-title" align="center">
                        Você realmente deseja<br/>
                        <strong>EXCLUIR</strong> o Grupo X?
                    </DialogTitle>
                    <List>
                        <ListItem>
                            <Button variant="contained" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]} onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant="contained" color="primary" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]}>
                                Continuar
                            </Button>
                        </ListItem>
                    </List>
                </Dialog>
            </div>
        );
    }
}
export default withStyles(styles)(Lista);