import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LISTA_TIPOS_CONSUMIDOR } from '../../services/graphql/querys/customer';
import { withApollo, graphql, Query } from 'react-apollo';
import CustomErrors from '../CustomErrors/CustomErrors';
import { CREATE_COMMERCIALIZATION_TYPE } from '../../services/graphql/mutations/customer';

import {
    TextField,
    Grid,
    MenuItem,
    Fab,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
} from '@material-ui/core';
import { Add ,Edit } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    }
});

class Formulario extends Component {
    constructor(props){
        super(props);
        this.state = {
            types: null,
            error: [],
            description: null,
        }
    }
    fazBusca = () => {
        this.props.client.query({query: LISTA_TIPOS_CONSUMIDOR, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("DATA: ", data)
                this.setState({'types': data.CommercializationsTypeQuery});                    
            }
        })
    }
    submitIt(){
        this.setState({error: []});
        let paramsEnviar = Object.entries(this.state).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        console.log("Enviar Parametros: ", paramsEnviar);

        if(!paramsEnviar.description){
            this.setState({error: [{message: "Você deve preencher o tipo"}]})
            return false;
        }

        this.props.client.mutate({mutation: CREATE_COMMERCIALIZATION_TYPE, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({...this.state, error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({...this.state, error: errosMostrar})
                }
            } else {
                console.log("DATA: ", data);
                this.setState({...this.state, error: []});
                alert("Salvo com sucesso !");
                window.location.href = "/tipos-consumidor";
            }
        })    
    }
    componentDidMount(){
        this.fazBusca();
    }
  
    render(){

        return(
            <div className={this.props.classes.content}>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={2}>
                                <center>
                                    <h1>Tipos de Consumidor</h1>
                                </center>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={6}>
                        <TextField id='description'  className={this.props.classes.textField} label="Tipo Consumidor " variant="outlined" margin="dense"  value={this.state.description}  onChange={(e) => this.setState({description: e.target.value})} />
                    </Grid>
                    
                    <Grid className={this.props.classes.root} item xl={4} xs={4}>
                        <Fab variant="extended" className={this.props.classes.textField} color="primary" onClick={() => this.submitIt()}>
                            <Add />
                            Adicionar
                        </Fab>
                    </Grid>

                </Grid>
                <TableContainer component={Paper}>
                    <Table className={this.props.classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Tipo Consumidor</TableCell>
                                <TableCell align="center">Editar</TableCell>
                                {/* <TableCell align="center">Excluir</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {!!this.state.types && this.state.types.map(type => (
                            <TableRow>
                                <TableCell component="th" scope="row">{type.description}</TableCell>
                                <TableCell align="center">
                                    <Fab color="primary" size="small" aria-label="editar" component="a" href={"/tipos-consumidor/edit/" + type.commercialization_type_id}>
                                        <Edit />
                                    </Fab>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Formulario));