import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {
    TextField,
    Grid,
    FormControlLabel,
    Button,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    DialogTitle,
    Dialog,
    List,
    ListItem
} from '@material-ui/core';

import { Search, Add, Edit, Delete } from '@material-ui/icons';

// COMPONENTS
import Propriedade from './DadosProducao/Propriedade';
import EstruturaFamiliar from './DadosProducao/EstruturaFamiliar';
import ProducaoA from './DadosProducao/ProducaoA';
import ProducaoB from './DadosProducao/ProducaoB';
import Tabela from './DadosProducao/Tabela';
import Logistica from './DadosProducao/Logistica';
import Comercializacao from './DadosProducao/Comercializacao';
import Cacau from './DadosProducao/Cacau';
import NovoManejo from './Manejo/NovoManejo';
import CadProdutor from './Manejo/CadProdutor';
import CadPorArea from './Manejo/CadPorArea';
import CadProducaoAnimal from './Manejo/CadProducaoAnimal';
import CadProducaoAnimal2 from './Manejo/CadProducaoAnimal2';
import CadProducaoVegetal from './Manejo/CadProducaoVegetal';
import CadApicultura from './Manejo/CadApicultura';
import CadExtrativismo from './Manejo/CadExtrativismo';
import CadProdUnit from './Manejo/CadProdUnit';
import CadProdUnit2 from './Manejo/CadProdUnit2';
import CadGleba from './Manejo/CadGleba';







function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(
    theme => ({
        root: {
            flexGrow: 1,
            width: '100%',
        },
    })
);


export default function DadosManejo(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [manejoEditar, setManejoEditar] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let farmer = props.farmer;
    console.log("Manejo -> ", manejoEditar)
    return (
        <div className="page-wrapper" style={{bottom: 0, top: '140px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
            {!manejoEditar &&
                <NovoManejo farmer={farmer} setManagement={setManejoEditar}/>
            }

            {
            farmer.managements.length > 0 && !manejoEditar && 
                <Grid container spacing={3}>
                    <Grid className={classes.root} item xl={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Manejo</TableCell>
                                        <TableCell align="right">Editar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        farmer.managements.map(
                                        (manejo, index) => 
                                            <TableRow key={`row-manejo-list-${index}`}>
                                                <TableCell component="th" scope="row">{manejo.description}</TableCell>
                                                <TableCell align="right">
                                                    <Fab color="primary" size="small" aria-label="editar" component="a" onClick={
                                                        () => setManejoEditar({manejo})
                                                    }>
                                                        <Edit />
                                                    </Fab>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            }
            {!!manejoEditar &&
            <>
                <AppBar position="static" color="default">
                    <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Cadastro do produtor" {...a11yProps(0)} />
                        <Tab label="Gerenciamento por Área" {...a11yProps(1)} />
                        <Tab label="Produção Animal I" {...a11yProps(2)} />
                        <Tab label="Produção Animal II" {...a11yProps(3)} /> 
                        <Tab label="Produção Vegetal" {...a11yProps(4)} /> 
                        <Tab label="Apicultura" {...a11yProps(5)} /> 
                        <Tab label="Extrativismo" {...a11yProps(6)} /> 
                        <Tab label="Unidade Produtora I" {...a11yProps(7)} />     
                        <Tab label="Unidade Produtora II" {...a11yProps(8)} />     
                        <Tab label="Gleba" {...a11yProps(9)} />     


                    </Tabs>
                </AppBar>

                <div style={{ display: value === 0? 'block': 'none'}}>
                    <CadProdutor manejo={manejoEditar} farmer={farmer}/>
                </div>
                <div style={{ display: value === 1? 'block': 'none'}}>
                    <CadPorArea manejo={manejoEditar} farmer={farmer} />
                </div>

                <div style={{ display: value === 2? 'block': 'none'}}>
                     <CadProducaoAnimal manejo={manejoEditar} farmer={farmer} />
                </div>

                <div style={{ display: value === 3? 'block': 'none'}}>
                     <CadProducaoAnimal2 manejo={manejoEditar} farmer={farmer} />
                </div>

                <div style={{ display: value === 4? 'block': 'none'}}>
                     <CadProducaoVegetal manejo={manejoEditar} farmer={farmer} />
                </div>

                <div style={{ display: value === 5? 'block': 'none'}}>
                     <CadApicultura manejo={manejoEditar} farmer={farmer} />
                     
                </div>

                <div style={{ display: value === 6? 'block': 'none'}}>
                     <CadExtrativismo manejo={manejoEditar} farmer={farmer} />
                </div>

                <div style={{ display: value === 7? 'block': 'none'}}>
                    <CadProdUnit manejo={manejoEditar} farmer={farmer} />
                </div>

                <div style={{ display: value === 8? 'block': 'none'}}>
                    <CadProdUnit2 manejo={manejoEditar} farmer={farmer} />
                </div>

                <div style={{ display: value === 9? 'block': 'none'}}>
                    <CadGleba manejo={manejoEditar} farmer={farmer} />
                </div>

            </>
            }
        </div>
    );
}