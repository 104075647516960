import gql from 'graphql-tag';

export const CREATE_USER = gql`

mutation  createUser(
  $login: String!,
  $email: String,
  $password: String!,
  $user_type: String!,
  $name: String!,
  $legal_document: String!,
  $station_id: Int,
){
  createUser(
    name: $name
    login: $login
    email: $email
    password:  $password
    user_type: $user_type
    legal_document: $legal_document
    station_id: $station_id
  ) {
    id
  }
}`;

export const CHANGE_PASSWORD = gql`

mutation  editUser(
  $user_id: String!,
  $name: String,
  $email: String,
  $password: String,
){
  editUser(
    user_id: $user_id,
    name: $name,
    email: $email,
    password: $password
  ){
    id
  }
}`;

export const DELETE_USER = gql`

mutation  deleteUser(
  $user_id: String!,
){
  deleteUser(user_id: $user_id)
}`;


