import gql from 'graphql-tag';

export const PLANTS_QUERY = gql`
query PlantsQuery(
  $specie_id: Int, 
  $specie: String, 
  $description: String
){ 
  PlantSpecie(
    specie_id: $specie_id, 
    specie: $specie, 
    description: $description
  ){
    specie_id
    specie
    description
  }
}
`;