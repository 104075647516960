import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

// COMPONENTS
import Lista from '../../Component/Grupos/List';
import Formulario from '../../Component/Grupos/Formulario';
import { withApollo, graphql, Query } from 'react-apollo';
import { LIST_GROUP  } from '../../services/graphql/querys/group';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});
class Grupos extends Component {
    constructor(props){
        super(props)
        this.state = {
            group: []
        }
    }
    componentWillMount(){
        console.log("CHEGUEI NO MOUNT")
        if(this.props.match.params.action == 'edit'){
            console.log("AQUI É O EDIT", this.props.match.params.id)
            let body = {
                group_id: this.props.match.params.id
            }
            this.props.client.query({query: LIST_GROUP, variables: body, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("resp query: ",data)
                    this.setState({group: data.GroupQuery[0]});
                }
            })
        }
    }
    render() {
        if(this.props.match.params.action == 'new' || this.props.match.params.action == 'edit'){
            return (
                <div className="page-wrapper"  style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    { this.props.match.params.action == 'edit' ?
                    <Formulario group={this.state.group}/>
                    : 
                    <Formulario/>
                    }
                </div>
            );
        } else {
            return(
                <div className="page-wrapper"  style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <Lista/>
                </div>
            );
        }
    }
}
export default withApollo(withStyles(styles)(Grupos));