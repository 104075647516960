import React from 'react';
import '../assets/styles/Header.css';
import Logo from '../assets/images/logo.png';
export default function Header() {
    return (
        <header className="topbar">
            <nav className="navbar top-navbar navbar-expand-md navbar-light">
                <div className="navbar-header">
                    <a className="navbar-brand" href="index.html">
                        <span>
                            <img src={Logo} className="light-logo" alt="homepage"/>
                        </span>
                    </a>
                </div>
            </nav>
        </header>
    );
}