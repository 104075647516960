import React, { Fragment } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import "./assets/styles/Content.css";

//screens
import Usuarios from "./screens/Pages/Usuarios";
// import Dashboard from "./screens/Pages/Dashboard";
import Agricultores from "./screens/Pages/Agricultores";
import Consumidores from "./screens/Pages/Consumidores";
import Grupos from "./screens/Pages/Grupos";
import Estacao from "./screens/Pages/Estacao";
import Subestacao from "./screens/Pages/Subestacao";
import Nucleos from "./screens/Pages/Nucleos";
import UnidadesProdutiovas from "./screens/Pages/UnidadesProdutiovas";
import PlantsSpecies from "./screens/Pages/PlantsSpecies";
import Produtos from "./screens/Pages/Produtos";
import ValorProduto from "./screens/Pages/ValorProduto";
import ValoresAdicionais from "./screens/Pages/ValoresAdicionais";
import Login from "./screens/Pages/Login";
import Logout from "./screens/Pages/Logout";
import Rodada from "./screens/Pages/Rodada";
import Relatorio from "./screens/Pages/Relatorio";
import ProdutoEstoque from "./screens/Pages/ProdutoEstoque";
import TiposConsumidor from "./screens/Pages/TiposConsumidor";



const Routes = (props) => { 
    let UserAccount = localStorage.getItem('UserAccount') ? JSON.parse(localStorage.getItem('UserAccount')) : undefined;
    console.log("UserAccount: ",UserAccount);
  return(
        <BrowserRouter >
            <Fragment>
                <Switch>
                    <Route exact path="/login" render={() => <Login loginIn={props.loginIn} screenPage={props.screenPage}/>} />
                    {!UserAccount &&
                        <Redirect to="/login"/>
                    }
                </Switch>
                {UserAccount && UserAccount.profile.user_type === 'admin_central' &&
                    <Switch>
                        <Route exact path="/usuarios" component={Usuarios} />
                        <Route exact path="/usuarios/:action" component={Usuarios} />
                        <Route exact path="/usuarios/:action/:id" component={Usuarios} />
                        
                        <Route exact path="/plants-especies" component={PlantsSpecies} />
                        <Route exact path="/plants-especies/:action" component={PlantsSpecies} />
                        <Route exact path="/plants-especies/:action/:id" component={PlantsSpecies} />

                        <Route exact path="/produtos" component={Produtos} />
                        <Route exact path="/produtos/:action" component={Produtos} />
                        <Route exact path="/produtos/:action/:id" component={Produtos} />

                        <Route exact path="/valor-produto" component={ValorProduto} />
                        <Route exact path="/valor-produto/:action/:id" component={ValorProduto} />

                        <Route exact path="/estacao/" component={Estacao} />
                        <Route exact path="/estacao/:action" component={Estacao} />
                        <Route exact path="/estacao/:action/:id" component={Estacao} />

                        <Route exact path="/nucleos" component={Nucleos} />
                        <Route exact path="/nucleos/:action" component={Nucleos} />
                        <Route exact path="/nucleos/:action/:id" component={Nucleos} />

                        <Route exact path="/logout" render={() => <Logout loginIn={props.loginIn} screenPage={props.screenPage}/>} />

                        <Route exact path="/rodada" component={Rodada} />
                        <Route exact path="/rodada/:action" component={Rodada} />
                        <Route exact path="/rodada/:action/:id" component={Rodada} />
                        <Route exact path="/rodada/:action/:id" component={Rodada} />
                        <Route exact path="/rodada/:action/:id/:id_pedido" component={Rodada} />

                        <Route exact path="/tipos-consumidor" component={TiposConsumidor} />
                        <Route exact path="/tipos-consumidor/:action/:id" component={TiposConsumidor} />

                        <Route exact path="/relatorio/:action" component={Relatorio} />
                    </Switch>
                }
                {UserAccount && (UserAccount.profile.user_type === 'admin_station' || UserAccount.profile.user_type === 'station') &&
                    <Switch>
                        <Route exact path="/usuarios" component={Usuarios} />
                        <Route exact path="/usuarios/:action" component={Usuarios} />
                        <Route exact path="/usuarios/:action/:id" component={Usuarios} />

                        <Route exact path="/agricultores" component={Agricultores} />
                        <Route exact path="/agricultores/:action" component={Agricultores} />
                        <Route exact path="/agricultores/:action/:id" component={Agricultores} />

                        <Route exact path="/consumidores" component={Consumidores} />
                        <Route exact path="/consumidores/:action" component={Consumidores} />
                        <Route exact path="/consumidores/:action/:id" component={Consumidores} />

                        <Route exact path="/grupos" component={Grupos} />
                        <Route exact path="/grupos/:action" component={Grupos} />
                        <Route exact path="/grupos/:action/:id" component={Grupos} />

                        <Route exact path="/subestacao/" component={Subestacao} />
                        <Route exact path="/subestacao/:action" component={Subestacao} />
                        <Route exact path="/subestacao/:action/:id" component={Subestacao} />

                        <Route exact path="/valor-produto" component={ValorProduto} />
                        <Route exact path="/valor-produto/:action/:id" component={ValorProduto} />

                        <Route exact path="/unidades-produtivas" component={UnidadesProdutiovas} />
                        <Route exact path="/unidades-produtivas/:action" component={UnidadesProdutiovas} />
                        <Route exact path="/unidades-produtivas/:action/:id" component={UnidadesProdutiovas} />

                        <Route exact path="/valores-adicionais" component={ValoresAdicionais} />
                        <Route exact path="/valores-adicionais/:action/:id" component={ValoresAdicionais} />

                        <Route exact path="/produto-estoque" component={ProdutoEstoque} />
                        <Route exact path="/produto-estoque/:action/:id" component={ProdutoEstoque} />

                        <Route exact path="/rodada" component={Rodada} />
                        <Route exact path="/rodada/:action" component={Rodada} />
                        <Route exact path="/rodada/:action/:id" component={Rodada} />
                        <Route exact path="/rodada/:action/:id/:id_pedido" component={Rodada} />
                        
                        <Route exact path="/relatorio/:action" component={Relatorio} />
                        <Route exact path="/logout" render={() => <Logout loginIn={props.loginIn} screenPage={props.screenPage}/>}/>
                        
                    </Switch>
                }
            </Fragment>
        </BrowserRouter>
    )
}

export default Routes;
