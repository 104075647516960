import gql from 'graphql-tag';

export const LIST_CUSTOMERS = gql`

query listCustomersQuery( $name: String, $state: String, $city: String, $legal_document: String ){
  ListCustomersQuery ( name: $name, state: $state, city: $city, legal_document: $legal_document ){
        user_id
        name
        state
        city
        legal_document
    }
    CommercializationsTypeQuery{
      commercialization_type_id
      description
    }
    ProductQuery{
      product_id
      product_category_id
      local_name
      comercial_name
      unified_product
      picture
      urlPicture
      productCategory {
        product_category_id
        product_category
      }
    }
}
`;

export const LISTA_TIPOS_CONSUMIDOR = gql`

query CommercializationsTypeQuery($commercialization_type_id: Int){
  CommercializationsTypeQuery(commercialization_type_id: $commercialization_type_id){
    commercialization_type_id
    description
  }
}
`;

export const CUSTOMER_DEMAND = gql`

query CustomerQuery( $id: String! ){
  CustomerQuery(id: $id){
    id
    customerDemands {
      demand_type
      user_id
      product_id
      measure_unit_id
      january_amount
      february_amount
      march_amount
      april_amount
      may_amount
      june_amount
      july_amount
      august_amount
      september_amount
      october_amount
      november_amount
      december_amount
      weekly_amount
      purchase_months
      average_purchase_amount
      product {
        product_id
        product_category_id
        local_name
        comercial_name
        unified_product
        picture
        urlPicture
        productCategory {
          product_category_id
          product_category
        }
      }
      measureUnit {
        measure_unit_id
        measure_unit_description
      }
    }
  }
}
`;


export const GET_CUSTOMER = gql`
    query getCostumer($id: String){
      CustomerQuery(id: $id){
        id
        responsible
        comercial_phone
        commercialization_type_id
        other_commercialization_type
        state_registration
        municipal_registration
        user {
          id
          login
          email
          msisdn
          phone
          access_token
          email_verified_at
          msisdn_verified_at
          expires_in
          refresh_token
          profile {
            user_type
            name
            nickname
            gender
            legal_document
            birth
            country
            state
            city
            district
            address
            address_number
            address_complement
            zip_code
            reference_point
            latitude
            longitude
            profilePicture
          }
          adminPermissions {
            user_id
            admin_permission_id
            create
            edit
            delete
          }
        }
        userProfile {
          user_type
          name
          nickname
          gender
          legal_document
          birth
          country
          state
          city
          district
          address
          address_number
          address_complement
          zip_code
          reference_point
          latitude
          longitude
          profilePicture
        }
        customerSitesSocialNetworks {
          site_social_network_id
          user_id
          description
        }
        bankInformation {
          id
          user_id
          user_type
          account_holder
          bank_name
          bank_code
          agency_number
          account_type
          account_number
        }
        customerCommercialization {
          user_id
          fair_format
          organic_station_format
          basket_format
          supermarket_format
          solidarity_store_format
          store_format
          distributor_consumer
          small_market_network_consumer
          online_platform_consumer
          restaurant_consumer
          has_other_initiative_types
          other_initiative_types_description
          associations_cooperatives_origin
          individual_producer_origin
          distributor_origin
          solidarity_store_origin
          farmers_network_origin
          has_other_origin
          other_origin_description
          agroecological_family_farming_interest
          organic_family_farming_interest
          organic_company_interest
          reason_interest_description
          participatory_certification
          auditing_certification
          ocs_certification
          phone_watsapp_receive_offers
          site_receive_offers
          app_receive_offers
          email_receive_offers
          other_receive_offers
          other_receive_offers_description
          phone_watsapp_best_channel
          site_best_channel
          app_best_channel
          email_best_channel
          other_best_channel
          other_best_channel_description
          commercialize_in_natura_products
          commercialize_minimally_processed_products
          commercialize_processed_products
        }
        customerLogistic {
          user_id
          needs_local_logistics
          can_fetch_product
          own_vehicle
          outsourced_freight
          receives_only_on_place
          uses_solidarity_freight
          monday_morning_until
          monday_afternoon_until
          monday_night_until
          tuesday_morning_until
          tuesday_afternoon_until
          tuesday_night_until
          wednesday_morning_until
          wednesday_afternoon_until
          wednesday_night_until
          thursday_morning_until
          thursday_afternoon_until
          thursday_night_until
          friday_morning_until
          friday_afternoon_until
          friday_night_until
          saturday_morning_until
          saturday_afternoon_until
          saturday_night_until
          sunday_morning_until
          sunday_afternoon_until
          sunday_night_until
        }
        customerPayment {
          user_id
          payment_by_bank_slip
          payment_by_deposit_account
          payment_by_money
          payment_by_credit_debit
          other_types_payment_description
          weekly_payment_term
          biweekly_payment_term
          monthly_payment_term
          other_payment_term
          other_payment_term_description
          individual_invoice
          danfe_invoice
          single_invoice
          supplier_invoice
          unnecessary_invoice
        }
        customerDemands {
          demand_type
          user_id
          product_id
          measure_unit_id
          weekly_amount
          purchase_months
          average_purchase_amount
          product {
            product_id
            product_category_id
            local_name
            comercial_name
            unified_product
            picture
            urlPicture
            productCategory {
              product_category_id
              product_category
            }
          }
          measureUnit {
            measure_unit_id
            measure_unit_description
          }
        }
      }
    }
`;

