import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { 
    AppBar, Tabs, Tab, Typography, Box
} from '@material-ui/core';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withApollo, Query } from 'react-apollo';

// COMPONENTS
import Lista from '../../Component/Agricultores/List';
import InformacoesGeral from '../../Component/Agricultores/InfomacoesGeral';
import DadosProducao from '../../Component/Agricultores/DadosProducao';
import DadosManejo from '../../Component/Agricultores/DadosManejo';

import { GET_FARMER } from '../../services/graphql/querys/farmer';
import { PRODUCTS_QUERY } from '../../services/graphql/querys/product';
import { TIPOS_MANEJO } from '../../services/graphql/querys/manejo';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function allyProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
  
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

function Agricultores(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [farmer, setFarmer] = React.useState(false);
    const [tiposManejo, setTiposManejo] = React.useState(false);

    const [productUnit, setProductUnit] = React.useState(false);

    const handleChange = (event, newValue) => {
       
        setValue(newValue);
    };
    
    if(props.match.params.action == 'new' || props.match.params.action == 'edit'){
        if(props.match.params.action == 'edit'){
            let body = {
                user_id: props.match.params.id
            }

            props.client.query({query: TIPOS_MANEJO, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    let tiposManejo = data.ManagementTypeQuery;
                    setTiposManejo(tiposManejo);      
                }
            })

            props.client.query({query: GET_FARMER, variables: body, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    let farmer = data.FarmerQuery[0];
                    setFarmer(farmer);
                }
            })

        }

        return (

            <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                <AppBar position="static" color="default">
                    <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Informações Gerais" {...allyProps(0)} />
                        {farmer && <Tab label="INFORMAÇÕES ADICIONAIS" {...allyProps(1)} /> }
                        {farmer && <Tab label="Dados Manejo" {...allyProps(2)} /> }
                    </Tabs>
                </AppBar>


                <div style={{ display: value === 0? 'block': 'none'}}>
                    <InformacoesGeral farmer={farmer} productUnits={props.data.ProductiveUnitQuery} cores={props.data.CoreQuery} groups={props.data.GroupQuery}/>
                </div>
                {farmer &&  
                 <>
                    <div style={{ display: value === 1? 'block': 'none'}}>
                        <DadosProducao farmer={farmer} productUnit={props.data.ProductiveUnitQuery} products={props.data.ProductQuery } />
                    </div>
                    <div style={{ display: value === 2? 'block': 'none'}}>
                        <DadosManejo farmer={farmer} tiposManejo={tiposManejo}/>
                    </div>
                </>
                }

                {/* <TabPanel value={value} index={0}>
                    <InformacoesGeral farmer={farmer} productUnits={props.data.ProductiveUnitQuery} cores={props.data.CoreQuery} groups={props.data.GroupQuery}/>
                </TabPanel>
                {farmer && 
                <>
                    <TabPanel value={value} index={1}>
                        <DadosProducao farmer={farmer} productUnit={props.data.ProductiveUnitQuery} products={props.data.ProductQuery } />
                    </TabPanel>

                </>
                } */}
            </div>
        );
    } else {
        return (
            <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    
                    <Lista setValue={setValue} farmers={props.data.ListFarmersQuery}/>
 
            </div>
        );
    }
}export default withApollo(graphql(PRODUCTS_QUERY)(Agricultores))