import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withApollo, graphql, Query } from 'react-apollo';
import { LIST_GROUP  } from '../../services/graphql/querys/group';
import { DELETE_GROUP  } from '../../services/graphql/mutations/group';



import {
    TextField,
    Grid,
    Button,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    DialogTitle,
    Dialog,
    List,
    ListItem
} from '@material-ui/core';
import { Search, Add, Edit, Delete } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    },
    buttonAction: {
        marginLeft: '10px',
        marginRight: '10px',
    }
});
function createData(nome, cidade, estado) {
    return { nome, cidade, estado };
}
class Lista extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            name: null,
            city: null,
            state: null,
            remover: null,
            group:[]
        }
    }
    componentWillReceiveProps(props){
        this.setState({group: props.group});
    }
    fazBusca = () => {
        let body = {
            name:  this.state.name,
            city:  this.state.city,
            state:  this.state.state,
        }
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Enviar Parametros: ", paramsEnviar);
            this.props.client.query({query: LIST_GROUP, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                    if(errors[0].message == 'validation'){
                        let erroValid = errors[0].extensions.validation;
                        for (var [key, value] of Object.entries(erroValid)) {
                            console.log("ERRO... " + key + ' ',value);
                        }
                    }else{
                        alert("Por favor, preencha todos os campos do formulário")
                    }
                } else {
                    console.log("DATA: ", data)
                    this.setState({group: data.GroupQuery});                    
                }
            })
    }
    componentDidMount(){
        this.fazBusca();
    }

    removeGrupo(){
        console.log("Remover grupo: ", this.state.remover)
        this.props.client.mutate({mutation: DELETE_GROUP, variables: {group_id: this.state.remover}, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({open: false, remover: null});
                alert("Erro ao remover grupo!"); 
            } else {
                console.log("DATA: ", data);
                this.setState({open: false, remover: null});
                alert("Grupo removido!");
                window.location.href = "/grupos";
            }
        })
    }

    render(){
        const { onClose, selectedValue, open } = this.props;

        const handleClose = () => {
            this.setState({open: false, remover: null});
        };
        const handleClickOpen = (group_id) => {
            console.log("group_id: ", group_id)
            this.setState({open: true, remover: group_id});
        };

        console.log("Estado: ",this.state)
        console.log("Props: ",this.props)
        return(
            <div>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={9}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="tamanho-hectares" 
                                className={this.props.classes.textField} 
                                label="Nome" 
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({name: e.target.value})} />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="tamanho-hectares" 
                                className={this.props.classes.textField} 
                                label="Município" 
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({city: e.target.value})} />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="tamanho-hectares" 
                                className={this.props.classes.textField} 
                                label="Estado" 
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({state: e.target.value})} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <Grid container spacing={2}>
                            <Grid className={this.props.classes.root} align="center" item xl={5}>
                                <Button variant="contained" color="primary" disableElevation className={this.props.classes.button} onClick={() => this.fazBusca()}>
                                    <Search /> Buscar
                                </Button>
                            </Grid>
                            <Grid className={this.props.classes.root} align="center" item xl={5}>
                                <Button variant="contained" color="primary" disableElevation className={this.props.classes.button} component="a" href="/grupos/new">
                                    <Add /> Adicionar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <TableContainer component={Paper}>
                            <Table className={this.props.classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell align="center">Município</TableCell>
                                        <TableCell align="center">Estado</TableCell>
                                        <TableCell align="center">Editar</TableCell>
                                        <TableCell align="center">Excluir</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {this.state.group.map(row => (
                                    <TableRow key={row.group_id}>
                                        <TableCell component="th" scope="row">{row.name}</TableCell>
                                        <TableCell align="center">{row.city}</TableCell>
                                        <TableCell align="center">{row.state}</TableCell>
                                        <TableCell align="center">
                                        <Fab color="primary" size="small" aria-label="editar" component="a" href={`/grupos/edit/${row.group_id}`}>
                                            <Edit />
                                        </Fab>
                                        </TableCell>
                                        <TableCell align="center">
                                        <Fab color="primary" size="small" aria-label="search" onClick={() => handleClickOpen(row.group_id)}>
                                            <Delete />
                                        </Fab>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
                    <DialogTitle id="simple-dialog-title" align="center">
                        Você realmente deseja<br/>
                        <strong>EXCLUIR</strong> o Grupo ?
                    </DialogTitle>
                    <List>
                        <ListItem>
                            <Button variant="contained" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]} onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant="contained" color="primary" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]} onClick={() => this.removeGrupo()}>
                                Continuar
                            </Button>
                        </ListItem>
                    </List>
                </Dialog>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Lista));