import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withApollo, graphql, Query } from 'react-apollo';
import { COMMERCIALIZATION_TYPES } from '../../services/graphql/querys/valoresAdicionais';
import {
    TextField,
    Grid,
    MenuItem,
    Fab,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
} from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    }
});

class Lucro extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }
    fazBusca = () => {
        let userAcc = JSON.parse(localStorage.getItem('UserAccount'));
        let body = {
            commercialization_type_id:  this.state.byId,
            station_id: userAcc.profile.station.id,
        }
    
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
    
            this.props.client.query({query: COMMERCIALIZATION_TYPES, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("DATA: ", data)
                    this.setState({'commercializationTypes': data.CommercializationsTypeQuery});                    
                    this.setState({'stationProfits': data.StationQuery[0].stationCommercializationTypeProfit});     
                }
            })
    }
    
    componentDidMount(){
        this.fazBusca();
    }
    
    render() {
        return (
            <div>
                <Grid container spacing={3}>
                    {/* <Grid className={this.props.classes.root} item xl={4} xs={4}>
                        <TextField 
                            select
                            id='tipo-cliente' 
                            name="byId"
                            value={this.state.byId}
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="Tipo de Cliente" 
                            variant="outlined" 
                            margin="dense">
                            {this.state.commercializationTypes && this.state.commercializationTypes.map(option => (
                                <MenuItem key={`core-${option.commercialization_type_id}`} value={option.commercialization_type_id}>
                                {option.description}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid> */}

                    <Grid className={this.props.classes.root} item xl={4} xs={4}>
                        <Fab variant="extended" className={this.props.classes.textField} color="primary" component="a" href={"/valores-adicionais/edit-lucro/0"}>
                            <Add />
                            Adicionar
                        </Fab>
                    </Grid>
                </Grid>

                <TableContainer component={Paper}>
                    <Table className={this.props.classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Cliente</TableCell>
                                <TableCell>Porcentagem(%)</TableCell>
                                <TableCell align="center">Editar</TableCell>
                                {/* <TableCell align="center">Excluir</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {!!this.state.stationProfits && this.state.stationProfits.map(profit => (
                            <TableRow>
                                <TableCell component="th" scope="row">{profit.commercializationType.description}</TableCell>
                                <TableCell>{profit.profit_margin}%</TableCell>
                                <TableCell align="center">
                                    <Fab color="primary" size="small" aria-label="editar" component="a" href={"/valores-adicionais/edit-lucro/" + profit.commercializationType.commercialization_type_id}>
                                        <Edit />
                                    </Fab>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }
}
export default withApollo(withStyles(styles)(Lucro));