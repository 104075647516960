import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LISTA_RODADAS } from '../../services/graphql/querys/rodada';
import { DELETE_COMMERCIAL_ROUND } from '../../services/graphql/mutations/rodada';

import { withApollo, graphql, Query } from 'react-apollo';
import CustomErrors from '../CustomErrors/CustomErrors';

import {
    TextField,
    Grid,
    FormControlLabel,
    Button,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    DialogTitle,
    Dialog,
    List,
    ListItem
} from '@material-ui/core';
import { Search, Add, Edit, Delete, Visibility } from '@material-ui/icons';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    },
    buttonAction: {
        marginLeft: '10px',
        marginRight: '10px',
    }
});



class ListaRodada extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            rodadas: false,
            remover: null,
        }
    }
    
    fazBusca = () => {
        let body = {
            xxx:  this.state.byName,
        }
        console.log("FazBusca", this.props);
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Enviar Parametros: ", paramsEnviar);
            this.props.client.query({query: LISTA_RODADAS, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("DATA: ", data)
                    this.setState({'rodadas': data.CommercialRoundsQuery.data});                    
                }
            })
    }
    componentDidMount(){
        this.fazBusca();
    }

    removeRodada(){
        console.log("Remover rodada: ", this.state.remover)
        this.props.client.mutate({mutation: DELETE_COMMERCIAL_ROUND, variables: {id: this.state.remover}, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({open: false, remover: null});
                //alert("Erro ao remover a rodada!"); 
                alert(errors[0].message); 
            } else {
                console.log("DATA: ", data);
                this.setState({open: false, remover: null});
                alert("Rodada removida!");
                window.location.href = "/rodada";
            }
        })
    }
    render(){
        console.log("Sta> ",this.state)
        const { onClose, selectedValue, open } = this.props;

        const handleClose = () => {
            this.setState({open: false, remover: null});
        };
        const handleClickOpen = (id) => {
            console.log("id: ", id)
            this.setState({open: true, remover: id});
        };

        return(
            <div>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={9} xs={9}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={12}>
                                <TextField  className={this.props.classes.textField} id="byName" label="Rodada" variant="outlined" margin="dense" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={3}>
                        <Grid container spacing={2}>
                            <Grid className={this.props.classes.root} align="center" item xl={5} >
                                <Button variant="contained" color="primary" disableElevation className={this.props.classes.button}>
                                    <Search /> Buscar
                                </Button>
                            </Grid>
                            <Grid className={this.props.classes.root} align="center" item xl={5}>
                                <Button variant="contained" color="primary" disableElevation className={this.props.classes.button} component="a" href="/rodada/new/0">
                                    <Add /> Adicionar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <TableContainer component={Paper}>
                            <Table className={this.props.classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Rodada</TableCell>
                                        <TableCell align="center">Aberta Oferta</TableCell>
                                        <TableCell align="center">Aberta Demanda</TableCell>
                                        <TableCell align="center">Tem pedidos</TableCell>
                                        <TableCell align="center">Pedidos Cliente</TableCell>
                                        <TableCell align="center">Pedidos Fornecedor</TableCell>
                                        <TableCell align="right">Editar</TableCell>
                                        <TableCell align="center">Excluir</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {!!this.state.rodadas && this.state.rodadas.map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">{row.description}</TableCell>
                                        <TableCell align="center" component="th" scope="row">{row.open_to_offer ? 'SIM' : 'NÃO'}</TableCell>
                                        <TableCell align="center" component="th" scope="row">{row.open_to_order ? 'SIM' : 'NÃO'}</TableCell>
                                        <TableCell align="center" component="th" scope="row">{row.purchase_orders && row.purchase_orders.length > 0 ? 'SIM' : 'NÃO'}</TableCell>
                                        

                                        <TableCell align="center">
                                            <Fab color="primary" size="small" aria-label="editar" component="a" href={"/rodada/visualizar/" + row.id} >
                                                <Visibility />
                                            </Fab>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Fab color="primary" size="small" aria-label="editar" component="a" href={"/rodada/pedidoFarmer/" + row.id} >
                                                <Visibility />
                                            </Fab>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Fab color="primary" size="small" aria-label="editar" component="a" href={"/rodada/new/" + row.id} >
                                                <Edit />
                                            </Fab>
                                        </TableCell>
                                        <TableCell align="center">
                                        <Fab color="primary" size="small" aria-label="search" onClick={() => handleClickOpen(row.id)}>
                                            <Delete />
                                        </Fab>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
                    <DialogTitle id="simple-dialog-title" align="center">
                        Você realmente deseja<br/>
                        <strong>EXCLUIR</strong> a Rodada?
                    </DialogTitle>
                    <List>
                        <ListItem>
                            <Button variant="contained" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]} onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant="contained" color="primary" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]} onClick={() => this.removeRodada()}>
                                Continuar
                            </Button>
                        </ListItem>
                    </List>
                </Dialog>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(ListaRodada));