import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { EDIT_FARMER_PRODUCTION } from '../../../services/graphql/mutations/farmer';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';
import {
    TextField,
    Grid,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormGroup,
    Divider,
    Fab
} from '@material-ui/core';

import { Print, Save } from '@material-ui/icons';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    radioClass: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },    
    divisor: {
        width: "100%",
        height: "3px",
        backgroundColor: "#000",
        marginTop: "3px",
        marginBottom: "10px",
    },
    textField: {
        width: '100%'
    }
});

class ProducaoA extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            edit: null,
            user_id: null,
            uses_plowing: null,
            uses_harrowing: null,
            uses_zero_tillage: null,
            soil_analysis_date: null,
            uses_manure: null,
            amount_manure: null,
            manure_supplier: null,
            uses_compost: null,
            amount_compost: null,
            compost_supplier: null,
            uses_bio_syrup: null,
            amount_bio_syrup: null,
            bio_syrup_supplier: null,
            uses_rock_dust: null,
            amount_rock_dust: null,
            rock_dust_supplier: null,
            others_inputs: null,
            uses_own_seeds: null,
            uses_acquired_seeds: null,
            amount_seeds: null,
            seed_supplier: null,
            has_climate_difficulty: null,
            has_input_difficulty: null,
            has_irrigation_difficulty: null,
            has_credit_difficulty: null,
            has_ater_difficulty: null,
            other_difficulties: null,
            accessed_agricultural_credit: null,
            reason_not_access_credit: null,
            costing_modality: null,
            agroecology_modality: null,
            woman_modality: null,
            echo_modality: null,
            semiarid_modality: null,
            young_modality: null,
            forest_modality: null,
            agrarian_reform_investiment_modality: null,
            agrarian_reform_costing_modality: null,
            more_aliments_modality: null,
            cattail_modality: null,
            other_modalities: null,
            interest_in_credit: null,
            costing_purpose: null,
            amount_for_costing: null,
            investiment_purpose: null,
            amount_for_investiment: null,
            has_technical_support: null,
            description_technical_support: null,
            uses_agroforestry_systems: null,
            uses_biodynamic: null,
            uses_cabruca: null,
            uses_traditional_cultivation: null,
            other_forms_management: null,
            aggregates_value_primary_products: null,
            fruit_jelly: null,
            cassava_flour: null,
            fruit_pulp: null,
            cocoa_nibis: null,
            chocolate: null,
            has_others_aggregate_values: null,
            description_others_aggregate_values: null,
            structure_to_processing: null,
            error: [],
        };
    } 
    
    
    componentWillReceiveProps(props){
        if(props.farmer){
           
            this.setState({'edit': true});
            let farmer = props.farmer;
            console.log("Eh aqui: ",farmer.farmerProduction )
            this.setState({
                user_id: farmer.id,

            });
            if(farmer.farmerProduction && farmer.farmerProduction != null){
                this.setState({
                    uses_plowing: farmer.farmerProduction.uses_plowing,
                    uses_harrowing: farmer.farmerProduction.uses_harrowing,
                    uses_zero_tillage: farmer.farmerProduction.uses_zero_tillage,
                    soil_analysis_date: farmer.farmerProduction.soil_analysis_date,
                    uses_manure: farmer.farmerProduction.uses_manure,
                    amount_manure: farmer.farmerProduction.amount_manure,
                    manure_supplier: farmer.farmerProduction.manure_supplier,
                    uses_compost: farmer.farmerProduction.uses_compost,
                    amount_compost: farmer.farmerProduction.amount_compost,
                    compost_supplier: farmer.farmerProduction.compost_supplier,
                    uses_bio_syrup: farmer.farmerProduction.uses_bio_syrup,
                    amount_bio_syrup: farmer.farmerProduction.amount_bio_syrup,
                    bio_syrup_supplier: farmer.farmerProduction.bio_syrup_supplier,
                    uses_rock_dust: farmer.farmerProduction.uses_rock_dust,
                    amount_rock_dust: farmer.farmerProduction.amount_rock_dust,
                    rock_dust_supplier: farmer.farmerProduction.rock_dust_supplier,
                    others_inputs: farmer.farmerProduction.others_inputs,
                    uses_own_seeds: farmer.farmerProduction.uses_own_seeds,
                    uses_acquired_seeds: farmer.farmerProduction.uses_acquired_seeds,
                    amount_seeds: farmer.farmerProduction.amount_seeds,
                    seed_supplier: farmer.farmerProduction.seed_supplier,
                    has_climate_difficulty: farmer.farmerProduction.has_climate_difficulty,
                    has_input_difficulty: farmer.farmerProduction.has_input_difficulty,
                    has_irrigation_difficulty: farmer.farmerProduction.has_irrigation_difficulty,
                    has_credit_difficulty: farmer.farmerProduction.has_credit_difficulty,
                    has_ater_difficulty: farmer.farmerProduction.has_ater_difficulty,
                    other_difficulties: farmer.farmerProduction.other_difficulties,
                    accessed_agricultural_credit: farmer.farmerProduction.accessed_agricultural_credit ? 'S' : 'N',
                    reason_not_access_credit: farmer.farmerProduction.reason_not_access_credit,
                    costing_modality: farmer.farmerProduction.costing_modality,
                    agroecology_modality: farmer.farmerProduction.agroecology_modality,
                    woman_modality: farmer.farmerProduction.woman_modality,
                    echo_modality: farmer.farmerProduction.echo_modality,
                    semiarid_modality: farmer.farmerProduction.semiarid_modality,
                    young_modality: farmer.farmerProduction.young_modality,
                    forest_modality: farmer.farmerProduction.forest_modality,
                    agrarian_reform_investiment_modality: farmer.farmerProduction.agrarian_reform_investiment_modality,
                    agrarian_reform_costing_modality: farmer.farmerProduction.agrarian_reform_costing_modality,
                    more_aliments_modality: farmer.farmerProduction.more_aliments_modality,
                    cattail_modality: farmer.farmerProduction.cattail_modality,
                    other_modalities: farmer.farmerProduction.other_modalities,
                    interest_in_credit: farmer.farmerProduction.interest_in_credit ? 'S' : 'N',
                    costing_purpose: farmer.farmerProduction.costing_purpose,
                    amount_for_costing: farmer.farmerProduction.amount_for_costing,
                    investiment_purpose: farmer.farmerProduction.investiment_purpose,
                    amount_for_investiment: farmer.farmerProduction.amount_for_investiment,
                    has_technical_support: farmer.farmerProduction.has_technical_support ? 'S' : 'N',
                    description_technical_support: farmer.farmerProduction.description_technical_support,
                    uses_agroforestry_systems: farmer.farmerProduction.uses_agroforestry_systems,
                    uses_biodynamic: farmer.farmerProduction.uses_biodynamic,
                    uses_cabruca: farmer.farmerProduction.uses_cabruca,
                    uses_traditional_cultivation: farmer.farmerProduction.uses_traditional_cultivation,
                    other_forms_management: farmer.farmerProduction.other_forms_management,
                    aggregates_value_primary_products: farmer.farmerProduction.aggregates_value_primary_products,
                    fruit_jelly: farmer.farmerProduction.fruit_jelly,
                    cassava_flour: farmer.farmerProduction.cassava_flour,
                    fruit_pulp: farmer.farmerProduction.fruit_pulp,
                    cocoa_nibis: farmer.farmerProduction.cocoa_nibis,
                    chocolate: farmer.farmerProduction.chocolate,
                    has_others_aggregate_values: farmer.farmerProduction.has_others_aggregate_values,
                    description_others_aggregate_values: farmer.farmerProduction.description_others_aggregate_values,
                    structure_to_processing: farmer.farmerProduction.structure_to_processing
                });
            }
        }
    }
    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        if(!!paramsEnviar.has_technical_support){
            if(paramsEnviar.has_technical_support == 'S'){
                paramsEnviar.has_technical_support = true;
            }else{
                paramsEnviar.has_technical_support = false;
            }
        } 
        if(!!paramsEnviar.accessed_agricultural_credit){
            if(paramsEnviar.accessed_agricultural_credit == 'S'){
                paramsEnviar.accessed_agricultural_credit = true;
            }else{
                paramsEnviar.accessed_agricultural_credit = false;
            }
        } 
        if(!!paramsEnviar.interest_in_credit){
            if(paramsEnviar.interest_in_credit == 'S'){
                paramsEnviar.interest_in_credit = true;
            }else{
                paramsEnviar.interest_in_credit = false;
            }
        } 
        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate({mutation: EDIT_FARMER_PRODUCTION, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            } else {
                console.log("DATA: ", data);
                this.setState({error: []})
               alert("Salvo com sucesso !");

            }
        })
    }

      
    render() {
        
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={6}>
                        <h4>Preparo do solo:</h4>
                        <FormControlLabel
                            control={
                                <Checkbox color="primary"  checked={this.state.uses_plowing}  onChange={(e) => this.setState({uses_plowing: e.target.checked})}/>
                            }
                            label="Aração"
                        />
                        <FormControlLabel
                            value="gradagem"
                            control={
                                <Checkbox color="primary"  checked={this.state.uses_harrowing}  onChange={(e) => this.setState({uses_harrowing: e.target.checked})}/>
                            }
                            label="Gradagem"
                        />
                        <FormControlLabel
                            value="plantio-direto"
                            control={
                                <Checkbox color="primary"  checked={this.state.uses_zero_tillage}  onChange={(e) => this.setState({uses_zero_tillage: e.target.checked})}/>
                            }
                            label="Plantio Direto"
                        />
                        <TextField id="analise-solo" className={this.props.classes.textField} label="Análise Solo, Quando? " InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({soil_analysis_date: e.target.value})} value={this.state.soil_analysis_date} variant="outlined" margin="dense" />
                        
                        <h4>Insumos - utilizados, abtidade e local de compra</h4>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.uses_manure}
                                            onChange={(e) => this.setState({uses_manure: e.target.checked})}
                                            color="primary"
                                        />
                                    }
                                    label="Esterco"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="insumo-a-quantidade" className={this.props.classes.textField} onChange={(e) => this.setState({amount_manure: e.target.value})} value={this.state.amount_manure}  label="Quantidade" variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="insumo-a-onde-comporar" className={this.props.classes.textField} onChange={(e) => this.setState({manure_supplier: e.target.value})} value={this.state.manure_supplier}  label="Onde Comporar" variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.uses_compost}
                                            onChange={(e) => this.setState({uses_compost: e.target.checked})}
                                            color="primary"
                                        />
                                    }
                                    label="Composto"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="insumo-b-quantidade" className={this.props.classes.textField} onChange={(e) => this.setState({amount_compost: e.target.value})} value={this.state.amount_compost} label="Quantidade" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="insumo-b-onde-comporar" className={this.props.classes.textField} onChange={(e) => this.setState({compost_supplier: e.target.value})} value={this.state.compost_supplier} label="Onde Comporar" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.uses_bio_syrup}
                                            onChange={(e) => this.setState({uses_bio_syrup: e.target.checked})}
                                            color="primary"
                                        />
                                    }
                                    label="Bio-calda"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="insumo-c-quantidade" className={this.props.classes.textField} onChange={(e) => this.setState({amount_bio_syrup: e.target.value})} value={this.state.amount_bio_syrup} label="Quantidade" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="insumo-c-onde-comporar" className={this.props.classes.textField} onChange={(e) => this.setState({bio_syrup_supplier: e.target.value})} value={this.state.bio_syrup_supplier}  label="Onde Comporar" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.uses_rock_dust}
                                            onChange={(e) => this.setState({uses_rock_dust: e.target.checked})}
                                            color="primary"
                                        />
                                    }
                                    label="Pó de rocha"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="insumo-d-quantidade" className={this.props.classes.textField} onChange={(e) => this.setState({amount_rock_dust: e.target.value})} value={this.state.amount_rock_dust}  label="Quantidade" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="insumo-d-onde-comporar" className={this.props.classes.textField} onChange={(e) => this.setState({rock_dust_supplier: e.target.value})} value={this.state.rock_dust_supplier}  label="Onde Comporar" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                            </Grid>
                        </Grid>
                        <TextField id="insumo-outros" className={this.props.classes.textField} onChange={(e) => this.setState({others_inputs: e.target.value})} value={this.state.others_inputs}  label="Outros" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6}>
                        <h4>Sementes:</h4>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.uses_acquired_seeds}
                                    onChange={(e) => this.setState({uses_acquired_seeds: e.target.checked})}
                                    color="primary" 
                                />
                            }
                            label="Adquiridas"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={this.state.uses_own_seeds}
                                    onChange={(e) => this.setState({uses_own_seeds: e.target.checked})}                                    
                                    color="primary" 

                                />
                            }
                            label="Propria"
                        />
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={6}>
                                <TextField id="sementes-quantidade" className={this.props.classes.textField} onChange={(e) => this.setState({amount_seeds: e.target.value})} value={this.state.amount_seeds} label="Quantidade" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={6}>
                                <TextField id="sementes-onde-comprar" className={this.props.classes.textField} onChange={(e) => this.setState({seed_supplier: e.target.value})} value={this.state.seed_supplier} label="Onde Comprar ( se adquirida )" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                            </Grid>
                        </Grid>

                        <h4>Dificuldades da Produção:</h4>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.has_climate_difficulty}
                                            onChange={(e) => this.setState({has_climate_difficulty: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Clima"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.has_input_difficulty}
                                            onChange={(e) => this.setState({has_input_difficulty: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Insumo"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.has_irrigation_difficulty}
                                            onChange={(e) => this.setState({has_irrigation_difficulty: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Irrigação"
                                />
                            </Grid>

                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.has_ater_difficulty}
                                            onChange={(e) => this.setState({has_ater_difficulty: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="ATER"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.has_credit_difficulty}
                                            onChange={(e) => this.setState({has_credit_difficulty: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Crédito"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.other_difficulties}
                                            onChange={(e) => this.setState({other_difficulties: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Outras"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <h4>Acessou crédito agrícola ( últimos 3 anos ):</h4>
                        <RadioGroup aria-label="acessou_credito" name="acessou_credito" style={{marginLeft: "10px"}} value={this.state.accessed_agricultural_credit} onChange={(e) => this.setState({accessed_agricultural_credit: e.target.value})} row>
                                    <FormControlLabel value='S' selected control={<Radio />} label="Sim" />
                                    <FormControlLabel value='N' control={<Radio />} label="Não" />
                        </RadioGroup>  
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={6}>
                                <TextField id="credito-agricola-porque" className={this.props.classes.textField} onChange={(e) => this.setState({reason_not_access_credit: e.target.value})} value={this.state.reason_not_access_credit} label="Por Que?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                            </Grid>
                        </Grid>

                        <h4>Modalidades:</h4>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.costing_modality}
                                            onChange={(e) => this.setState({costing_modality: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Custeio"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.forest_modality}
                                            onChange={(e) => this.setState({forest_modality: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Floresta"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.agroecology_modality}
                                            onChange={(e) => this.setState({agroecology_modality: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Agroecologia"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.agrarian_reform_investiment_modality}
                                            onChange={(e) => this.setState({agrarian_reform_investiment_modality: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Investimento Reforma-Agrária"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.woman_modality}
                                            onChange={(e) => this.setState({woman_modality: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Mulher"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.agrarian_reform_costing_modality}
                                            onChange={(e) => this.setState({agrarian_reform_costing_modality: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Custeio - Reforma Agrária"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.echo_modality}
                                            onChange={(e) => this.setState({echo_modality: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Eco"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.more_aliments_modality}
                                            onChange={(e) => this.setState({more_aliments_modality: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Mais Alimentos"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.semiarid_modality}
                                            onChange={(e) => this.setState({semiarid_modality: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Seminarido"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.cattail_modality}
                                            onChange={(e) => this.setState({cattail_modality: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Taboa"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.young_modality}
                                            onChange={(e) => this.setState({young_modality: e.target.checked})}    
                                            color="primary"
                                        />
                                    }
                                    label="Jovem"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.other_modalities}
                                            color="primary"
                                        />
                                    }
                                    label="Outros"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={12}>
                                <TextField id="credito-agricola-modelidade" className={this.props.classes.textField} onChange={(e) => this.setState({other_modalities: e.target.value})} value={this.state.other_modalities} label="Qual?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={12}>
                                <h4>Tem interesse em crédito:</h4>
                                <RadioGroup aria-label="quer_credito" name="quer_credito" value={this.state.interest_in_credit} onChange={(e) => this.setState({interest_in_credit: e.target.value})} row>
                                    <FormControlLabel value='S' selected control={<Radio />} label="Sim" />
                                    <FormControlLabel value='N' control={<Radio />} label="Não" />
                                </RadioGroup>               
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={12}>
                                <h4>Finalidade:</h4>
                                <FormGroup name="finalidade" >
                                    <Grid container spacing={3}>
                                        <Grid className={this.props.classes.root} item xl={4}>
                                            <FormControlLabel control={<Checkbox checked={this.state.costing_purpose} onChange={(e) => this.setState({costing_purpose: e.target.checked})} />} label="Custeio" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={4}>
                                            <FormControlLabel control={<Checkbox checked={this.state.investiment_purpose} onChange={(e) => this.setState({investiment_purpose: e.target.checked})} />} label="Investimento" />
                                        </Grid>  
                                    </Grid>
                                </FormGroup>  
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={12}>
                                <Grid container spacing={3}>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <TextField id="qtd_custeio" className={this.props.classes.textField} onChange={(e) => this.setState({amount_for_costing: e.target.value})} value={this.state.amount_for_costing} label="Quanto para custeio ? (R$)" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />        
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <TextField id="qtd_investimento" className={this.props.classes.textField} onChange={(e) => this.setState({amount_for_investiment: e.target.value})} value={this.state.amount_for_investiment} label="Quanto para investimento ?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />        
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid className={this.props.classes.root} item xl={12}>
                            <h4>Possui acompanhamento tecnico ?</h4>
                            <Grid container spacing={3}>
                                <Grid className={this.props.classes.root} item xl={4}>
                                    <RadioGroup aria-label="acompanhamento_tecnico" name="acompanhamento_tecnico" style={{marginLeft: "10px"}} value={this.state.has_technical_support} onChange={(e) => this.setState({has_technical_support: e.target.value})} row>
                                            <FormControlLabel value='S' control={<Radio />} label="Sim" />
                                            <FormControlLabel value='N' control={<Radio />} label="Não" />
                                            <TextField id="acompanhamento_tecnico_desc" className={this.props.classes.textField} onChange={(e) => this.setState({description_technical_support: e.target.value})} value={this.state.description_technical_support} label="Qual?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" /> 
                                    </RadioGroup>     
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid className={this.props.classes.root} item xl={12}>
                            <h4>Formas de manejo da produção e práticas ágriculas utilizadas:  </h4>
                            <FormGroup>
                                <Grid container spacing={3}>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <FormControlLabel control={<Checkbox checked={this.state.uses_agroforestry_systems} onChange={(e) => this.setState({uses_agroforestry_systems: e.target.checked})} />} label="SAF's- Sistema Agroflorestais" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <FormControlLabel control={<Checkbox checked={this.state.uses_biodynamic} onChange={(e) => this.setState({uses_biodynamic: e.target.checked})} />} label="Biodinâmica" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <FormControlLabel control={<Checkbox checked={this.state.uses_cabruca} onChange={(e) => this.setState({uses_cabruca: e.target.checked})} />} label="Cabruca" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <FormControlLabel control={<Checkbox checked={this.state.uses_traditional_cultivation} onChange={(e) => this.setState({uses_traditional_cultivation: e.target.checked})} />} label="Cultivo Tradicional (hortas e palmares)" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <FormControlLabel control={<Checkbox checked={this.state.other_forms_management}/>} label="Outros" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <TextField id="formasManejo_adicional" className={this.props.classes.textField} onChange={(e) => this.setState({other_forms_management: e.target.value})} value={this.state.other_forms_management} label="Qual?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" /> 
                                    </Grid>
                                </Grid>
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid className={this.props.classes.root} item xl={12}>
                            <h4>Agrega valor aos produtos primários:</h4>
                            <FormGroup>
                                <Grid container spacing={3}>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <FormControlLabel control={<Checkbox checked={this.state.fruit_jelly} onChange={(e) => this.setState({fruit_jelly: e.target.checked})} />} label="Geléia das frutas" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <FormControlLabel control={<Checkbox checked={this.state.cassava_flour} onChange={(e) => this.setState({cassava_flour: e.target.checked})} />} label="Farinha Mandioca/Biju" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <FormControlLabel control={<Checkbox checked={this.state.fruit_pulp} onChange={(e) => this.setState({fruit_pulp: e.target.checked})} />} label="Polpa de Frutas" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <FormControlLabel control={<Checkbox checked={this.state.cocoa_nibis} onChange={(e) => this.setState({cocoa_nibis: e.target.checked})} />} label="Nibis de Cacau" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <FormControlLabel control={<Checkbox checked={this.state.chocolate} onChange={(e) => this.setState({chocolate: e.target.checked})} />} label="Chocolates" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <FormControlLabel control={<Checkbox checked={this.state.has_others_aggregate_values} onChange={(e) => this.setState({has_others_aggregate_values: e.target.checked})} />} label="Outros" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <TextField id="formasManejo_adicional" className={this.props.classes.textField} onChange={(e) => this.setState({description_others_aggregate_values: e.target.value})} value={this.state.description_others_aggregate_values} label="Outros" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" /> 
                                    </Grid>
                                </Grid>
                            </FormGroup>
                        </Grid>

                        <Grid className={this.props.classes.root} item xl={12}>
                            <TextField id="formasManejo_adicional" className={this.props.classes.textField} onChange={(e) => this.setState({structure_to_processing: e.target.value})} value={this.state.structure_to_processing} label="Qual estrutura possui para processar estes produtos ?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" /> 
                        </Grid>
                        <Grid className={this.props.classes.root} item xl={12}>
                            <Grid container spacing={3}>
                                {/* <Grid className={this.props.classes.root} item xl={6}>
                                    <Fab variant="extended" color="primary" className={this.props.classes.textField}>
                                        <Print />
                                        Imprimir
                                    </Fab>
                                </Grid> */}
                                <Grid className={this.props.classes.root} item xl={6}>
                                    <Fab variant="extended" color="primary" className={this.props.classes.textField} onClick={() => this.submitIt()}>
                                        <Save />
                                        Salvar
                                    </Fab>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}export default withApollo((withStyles(styles))(ProducaoA));


