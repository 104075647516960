import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { EDIT_PLANTS_SPECIE_QUERY } from '../../services/graphql/mutations/plantsSpecies';
import { PRODUCT_CATEGORY_QUERY } from '../../services/graphql/querys/product';
import CustomErrors from '../CustomErrors/CustomErrors';

import { withApollo, graphql, Query } from 'react-apollo';
import {
    TextField,
    Grid,
    MenuItem,
    Fab,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    }
});
class Formulario extends Component {
    constructor(props){
        super(props);
        this.state = {
            specie_id: null,
            specie: '',
            description: '',
            unified_product: '',
            error: [],
        }
    }
    componentWillReceiveProps(props){
        if(!!props.product){
            let product = props.product;
            this.setState({
                specie_id: product.specie_id,
                specie: product.specie,
                description: product.description,
            })
        }
    }
    componentDidMount(){
        this.props.client.query({query: PRODUCT_CATEGORY_QUERY, errorPolicy: 'all', fetchPolicy: 'no-cache'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                    if(errors[0].message == 'validation'){
                        let erroValid = errors[0].extensions.validation;
                        for (var [key, value] of Object.entries(erroValid)) {
                            console.log("ERRO... " + key + ' ',value);
                        }
                    }else{
                        alert("Por favor, preencha todos os campos do formulário")
                    }
                } else {
                    console.log("DATA: ", data)
                    this.setState({'productCategories': data.ProductCategoryQuery});                    
                }
            })
    }
    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate({mutation: EDIT_PLANTS_SPECIE_QUERY, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            } else {
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");
                window.location.href = "/plants-especies";

            }
        })
    }

    render(){
        console.log("State: ",this.state)
        return(
            <div className={this.props.classes.content}>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>
                </Grid>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12} xs={12}>
                        <TextField 
                        className={this.props.classes.textField} 
                        onChange={(e) => this.setState({specie: e.target.value})} 
                        value={this.state.specie} 
                        label="Especie" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12} xs={12}>
                        <TextField 
                        className={this.props.classes.textField} 
                        onChange={(e) => this.setState({description: e.target.value})} 
                        value={this.state.description} 
                        label="Descrição" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" />
                    </Grid>
                    
                    <Grid className={this.props.classes.root} item xl={6} xs={6}>
                        <Fab variant="extended" className={this.props.classes.textField} color="primary" onClick={() => this.submitIt()}>
                            <Save />
                            Salvar
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Formulario));