import React, { Component } from 'react';

import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withApollo, Query } from 'react-apollo';
import { CREATE_FARMER, EDIT_FARMER } from '../../services/graphql/mutations/farmer';
import CustomErrors from '../CustomErrors/CustomErrors';

import { withStyles } from '@material-ui/core/styles';
import {
    TextField,
    MenuItem,
    Paper,
    Grid,
    Divider,
    FormControlLabel,
    Checkbox,
    FilledInput,
    Fab,
} from '@material-ui/core';
import { Add, Print, Save, GestureTwoTone } from '@material-ui/icons';
import MaskedInput from 'react-text-mask';

/*
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
*/

const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});

const Celular = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={['5','5',' ','\(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        showMask
      />
    );
}
const CelFixo = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={['5','5',' ','\(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        showMask
      />
    );
  }

class InfomacoesGeral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: null,
            nickname: null,
            login: null,
            group_offerer: null,
            name: null,
            email: null,
            msisdn: null,
            productive_unit_id: null,
            group_id: null,
            core_id: null,
            phone: null,
            contact: null,
            identity_document: null,
            legal_document: null,
            dap: null,
            rural_block_registration: null,
            certificateFile: null,
            certificate_issue: null,
            certificate_expiration: null,
            birth: null,
            state: null,
            city: null,
            district: null,
            address: null,
            address_number: null,
            address_complement: null,
            zip_code: null,
            reference_point: null,
            latitude: null,
            longitude: null,
            bank_name: null,
            bank_code: null,
            agency_number: null,
            account_holder: null,
            account_type: null,
            account_number: null,
            country: "BRASIL",
            farmerUnconformities: null,
            inconformidades: [],
            groups: [],
            cores: [],
            productUnits: [],
            error: [],
        };
    } 

    componentWillReceiveProps(props){
        if(props.farmer){
            console.log("Farmer: ", props.farmer)
            this.setState({'edit': true});
            let farmer = props.farmer;
            this.setState({
                user_id: farmer.id,
                nickname: !!farmer.userProfile ? farmer.userProfile.nickname  : null,
                name: !!farmer.userProfile ? farmer.userProfile.name  : null ,
                email: !!farmer.user ? farmer.user.email  : null ,
                login: !!farmer.user ? farmer.user.login  : null ,
                msisdn: !!farmer.user ? farmer.user.msisdn  : null ,
                productive_unit_id: farmer.productive_unit_id,
                group_offerer: farmer.group_offerer,
                group_id: farmer.group_id,
                core_id: farmer.core_id,
                phone: !!farmer.user ? farmer.user.phone  : '' ,
                contact: farmer.contact,
                mother_name: !!farmer ? farmer.mother_name  : '' ,
                identity_document: farmer.identity_document,
                legal_document: !!farmer.userProfile ? farmer.userProfile.legal_document  : null ,
                dap: farmer.dap,
                rural_block_registration: farmer.rural_block_registration,
                certificateFile: null,
                certificate_issue: !!farmer.certificate_issue ? farmer.certificate_issue : null,
                certificate_expiration: !!farmer.certificate_expiration ? farmer.certificate_expiration : null,
                birth: !!farmer.userProfile ? farmer.userProfile.birth  : null,
                state: !!farmer.userProfile ? farmer.userProfile.state  : null ,
                city: !!farmer.userProfile ? farmer.userProfile.city  : null ,
                district: !!farmer.userProfile ? farmer.userProfile.district  : null ,
                address: !!farmer.userProfile ? farmer.userProfile.address  : null ,
                address_number: !!farmer.userProfile ? farmer.userProfile.address_number  : null ,
                address_complement: !!farmer.userProfile ? farmer.userProfile.address_complement  : null ,
                zip_code: !!farmer.userProfile ? farmer.userProfile.zip_code  : null ,
                reference_point: !!farmer.userProfile ? farmer.userProfile.reference_point  : null ,
                latitude: !!farmer.userProfile ? farmer.userProfile.latitude  : null ,
                longitude: !!farmer.userProfile ? farmer.userProfile.longitude  : null ,
                account_holder: !!farmer.bankInformation ? farmer.bankInformation.account_holder  : null ,
                bank_name: !!farmer.bankInformation ? farmer.bankInformation.bank_name  : null ,
                bank_code: !!farmer.bankInformation ? farmer.bankInformation.bank_code  : null ,
                agency_number: !!farmer.bankInformation ? farmer.bankInformation.agency_number  : null ,
                account_type:  !!farmer.bankInformation ? farmer.bankInformation.account_type  : null ,
                account_number: !!farmer.bankInformation ? farmer.bankInformation.account_number  : null ,
                farmerUnconformities: !!farmer.farmerUnconformities ? farmer.farmerUnconformities  : null,
                country: "BRASIL", 
                inconformidades: !!farmer.farmerUnconformities ? farmer.farmerUnconformities  : null,
            });
        }
        if(props.groups){
            this.setState({'groups': props.groups});
        }
        if(props.cores){
            this.setState({'cores': props.cores});
        }
        if(props.productUnits){
            this.setState({'productUnits': props.productUnits});
        }
    }
    
   
    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        console.log("Params Enviar: ", paramsEnviar)
        if(!!paramsEnviar.email && (paramsEnviar.email == null || paramsEnviar.email == '' || !paramsEnviar.email.includes("@"))){
            console.log("Email zoado");
            delete(paramsEnviar.email)
        }
        if(!this.state.edit){
            if(!paramsEnviar.name){
                this.setState({error: [{message: "Você deve preencher o Nome."}]})
                return false;
            }else if(!paramsEnviar.contact){
                this.setState({error: [{message: "Você deve preencher o telefone de Contato."}]})
                return false;   
            }else if(!paramsEnviar.legal_document){
                this.setState({error: [{message: "Você deve preencher o CPF/CNPJ."}]})
                return false;    
            }else if(!paramsEnviar.zip_code){
                this.setState({error: [{message: "Você deve preencher o CEP."}]})
                return false;    
            }else if(!paramsEnviar.address){
                this.setState({error: [{message: "Você deve preencher o Endereço."}]})
                return false;      
            }else if(!paramsEnviar.address_number){
                this.setState({error: [{message: "Você deve preencher o Número do Endereço."}]})
                return false;    
            }else if(!paramsEnviar.city){
                this.setState({error: [{message: "Você deve preencher a Cidade."}]})
                return false;
            }else if(!paramsEnviar.state){
                this.setState({error: [{message: "Você deve preencher o Estado."}]})
                return false;
            }else if(!paramsEnviar.login){
                this.setState({error: [{message: "Você deve preencher o Nome de Usuario."}]})
                return false;
            }else if(!paramsEnviar.password){
                this.setState({error: [{message: "Você deve preencher a Senha."}]})
                return false;
            }else if(paramsEnviar.password.length < 8){
                this.setState({error: [{message: "A senha deve conter pelo menos 8 caracteres"}]})
                return false;
            }else if (!/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/.test(paramsEnviar.password)){
                this.setState({error: [{message: "A senha deve conter pelo menos 1 número e 1 letra"}]})
                return false;
            }
        }
        let inconformidades = this.state.inconformidades;
        let newInconformidades = [];
            for(let i = 0; i < inconformidades.length; i++){
                newInconformidades[i] = {};
                if(inconformidades[i].id){
                    newInconformidades[i].farmer_unconformity_id = inconformidades[i].id;
                }else{
                    newInconformidades[i].farmer_unconformity_id = 0;
                }
                newInconformidades[i].description = inconformidades[i].description;
                newInconformidades[i].resolution_date = inconformidades[i].resolution_date;
            }
            // console.log("INCONF: ", newInconformidades)
       if(!!newInconformidades && !!newInconformidades.length){
            paramsEnviar = {...paramsEnviar, farmerUnconformities: newInconformidades};
       }

       if(paramsEnviar.msisdn) paramsEnviar.msisdn = paramsEnviar.msisdn.replace(/\D+/g, '');
       if(paramsEnviar.contact) paramsEnviar.contact = paramsEnviar.contact.replace(/\D+/g, '');
       if(paramsEnviar.phone) paramsEnviar.phone = paramsEnviar.phone.replace(/\D+/g, '');
       
       console.log("Enviar Parametros: ", paramsEnviar);
        this.props.client.mutate({mutation: this.state.edit ? EDIT_FARMER : CREATE_FARMER, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            } else {
                this.setState({error: []})
                console.log("DATA: ", data)
                alert("Salvo com sucesso !");

            }
        })
    }

    handleChangeMask = name => event => {
        this.setState({
        [name]: event.target.value,
        });
    };

    handleChange(e){
        // console.log("Change: ",e.target.id);
        let id = e.target.id;
        let splitado = id.split('-');
        let campo = splitado[0];
        let key = splitado[1];
        
        
        let arrObj = this.state.inconformidades;
        if(campo == 'description'){
            arrObj[key].description = e.target.value;
        }
        if(campo == 'resolution_date'){
            arrObj[key].resolution_date = e.target.value;
        }
        this.setState({inconformidades: arrObj})

    }
    getRow = (i, row) => {
        
        return (
            <>
                <TextField type="date" id={`resolution_date-${i}`} name="i_resolution_date" value={row ? row.resolution_date : ''} InputLabelProps={{shrink: true,}} onChange={(e) => this.handleChange(e)} label="Data Resolução"  variant="outlined" margin="dense" />
                <TextField id={`description-${i}`} name="i_description"  value={row ? row.description : ''} label="Descrição" onChange={(e) => this.handleChange(e)} variant="outlined" rows="4" multiline margin="dense" />
            </>
        )
    }
    getMore = () => {
            let newIncof = this.state.inconformidades;
            newIncof.push({resolution_date: "", description: ""});
            // console.log("Arr inconformidades: ",this.state.inconformidades);
            // console.log("Novo arr inconformidades: ",newIncof);
            this.setState({inconformidades: newIncof});

    }
    render() {
          console.log("Estado Atual: ",this.state)
        // console.log("props Atual: ",this.props)
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <sub>
                    <span>*</span> Informações Obrigatórias
                </sub>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={6}>
                        
                        <TextField
                            select
                            label="Núcleo"
                            value={this.state.core_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({core_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                            {this.state.cores.map(option => (
                                <MenuItem key={`core-${option.core_id}`} value={option.core_id}>
                                {option.name}
                                </MenuItem>
                            ))}
                            </TextField>

                            <TextField
                            select
                            label="Grupo"
                            value={this.state.group_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({group_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            >
                            {this.state.groups.map(option => (
                                <MenuItem key={`groups-${option.group_id}`} value={option.group_id}>
                                {option.name}
                                </MenuItem>
                            ))}
                            </TextField>
                            <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.group_offerer}
                                        onChange={(e) => this.setState({group_offerer: e.target.checked})}
                                        color="primary"
                                    />
                            }
                            label="Pode ofertar por agricultores do grupo"/>
                            <TextField
                            select
                            label="Unidade Produtiva"
                            value={this.state.productive_unit_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({productive_unit_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            >
                            {this.state.productUnits.map(option => (
                                <MenuItem key={`prodUnits-${option.productive_unit_id}`} value={option.productive_unit_id}>
                                {option.name}
                                </MenuItem>
                            ))}
                            </TextField>

                          
                            

                            <TextField id="agricultor" label="Nome do Agricultor*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({name: e.target.value})} value={this.state.name ? this.state.name : ''}/>
                            <TextField id="numero-inscricao-bloco-produto" label="Nº Inscrição do Bloco de Produto" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense"  onChange={(e) => this.setState({rural_block_registration: e.target.value})}  value={this.state.rural_block_registration ? this.state.rural_block_registration : ''}/>


                            {/* <TextField id="contato" label="Contato*" variant="outlined" margin="dense" onChange={(e) => this.setState({contact: e.target.value})} value={this.state.contact ? this.state.contact : ''}/> */}

                            <TextField id="contato" label="Contato*" variant="outlined" margin="dense" 
                                InputLabelProps={{shrink: true,}}
                                InputProps={{
                                    inputComponent: Celular,
                                    value: this.state.contact ? this.state.contact : '',
                                    onChange: this.handleChangeMask('contact'),
                                }}
                            />
                    
                            <TextField id="telefone" label="Telefone" variant="outlined" margin="dense"
                                InputLabelProps={{shrink: true,}}
                                InputProps={{
                                    inputComponent: CelFixo,
                                    onChange: this.handleChangeMask('phone'),
                                    value: this.state.phone ? this.state.phone : '',
                                }}
                             />

                            <TextField id="celular" label="Celular" variant="outlined" margin="dense" 
                                InputLabelProps={{shrink: true,}}
                                InputProps={{
                                    inputComponent: Celular,
                                    onChange: this.handleChangeMask('msisdn'),
                                    value: this.state.msisdn ? this.state.msisdn : '',
                                }}
                            />

                            <TextField id="email" label="Email" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({email: e.target.value})} value={this.state.email ? this.state.email : ''}/>
                            <TextField id="data-nacimento" label="Data/Nacimento" InputLabelProps={{shrink: true,}} type="date" variant="outlined" margin="dense" onChange={(e) => this.setState({birth: e.target.value})} value={this.state.birth ? this.state.birth : ''}/>
                            <TextField id="registro-geral" label="RG" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({identity_document: e.target.value})} value={this.state.identity_document ? this.state.identity_document : ''}/>
                            <TextField id="cpf-cnpj" label="CPF/CNPJ*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({legal_document: e.target.value})} value={ this.state.legal_document ? this.state.legal_document : ''} />
                            <TextField id="nome-mae" label="Nome da Mãe" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({mother_name: e.target.value})} value={this.state.mother_name ? this.state.mother_name : ''}/>

                            <TextField id="cep-residencial" label="CEP Residencial*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({zip_code: e.target.value})} value={ this.state.zip_code ? this.state.zip_code : ''}/>
                            <TextField id="endereco-residencial" label="Endereço Residencial*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({address: e.target.value})} value={ this.state.address ? this.state.address : ''}/>
                            <TextField id="numero" type="number" label="Número*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({address_number: e.target.value})} value={ this.state.address_number ? this.state.address_number : ''}/>
                            <TextField id="complemento" label="Complemento" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({address_complement: e.target.value})} value={ this.state.address_complement ? this.state.address_complement : ''} />

                            <TextField id="bairro" label="Bairro" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({district: e.target.value})} value={ this.state.district ? this.state.district : ''}/>
                            <TextField id="cidade" label="Cidade*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({city: e.target.value})} value={ this.state.city ? this.state.city : ''}/>
                            <TextField id="estado" label="Estado*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({state: e.target.value})} value={ this.state.state ? this.state.state : ''}/>

                            <TextField id="ponto-referencia" label="Ponto de Referencia" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({reference_point: e.target.value})} value={ this.state.reference_point ? this.state.reference_point : ''} />
                    </Grid>
                    
                    <Grid className={this.props.classes.root} item xl={6}>
                        <TextField id="nickname" label="Apelido" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({nickname: e.target.value})}   value={ this.state.nickname ? this.state.nickname : ''}/>
                        <TextField id="login" label="Nome de usuário*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({login: e.target.value})} disabled={!!this.state.edit} value={ this.state.login ? this.state.login : ''}/>
                        <TextField id="password" label="Senha - deve conter letras e numeros*" InputLabelProps={{shrink: true,}} type="password" variant="outlined" margin="dense" onChange={(e) => this.setState({password: e.target.value})} />
                        <TextField id="latitude" label="Latitude" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({latitude: e.target.value})} value={ this.state.latitude ? this.state.latitude : ''}/>
                        <TextField id="longitude" label="Longitude" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({longitude: e.target.value})} value={ this.state.longitude ? this.state.longitude : ''} />
                        <TextField id="dap" label="DAP" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({dap: e.target.value})} value={ this.state.dap ? this.state.dap : ''} />
                        
                        <Divider variant="inset" component="div" />
                        <h4>CERTIFICADO</h4>
                        <sub>
                            Arquivo do Certificado
                        </sub>
                        <TextField id="arquivo-certificado" InputLabelProps={{shrink: true,}} type="file" variant="outlined" margin="dense" onChange={(e) => this.setState({certificateFile: e.target.files[0]})}/>
                        <TextField id="data-emissao-certificado" type="date" label="Data Emissão do Certificado" InputLabelProps={{ shrink: true, }} variant="outlined" margin="dense" onChange={(e) => this.setState({certificate_issue: e.target.value})} value={ this.state.certificate_issue ? this.state.certificate_issue : ''}/>
                        <TextField id="data-vencimento-certificado" type="date" label="Data Vencimento Certificado" InputLabelProps={{ shrink: true, }} variant="outlined" margin="dense" onChange={(e) => this.setState({certificate_expiration: e.target.value})} value={ this.state.certificate_expiration ? this.state.certificate_expiration : ''}/>
                        <Divider variant="inset" component="div" />
                        <h4>INCONFORMIDADES</h4>

                        {!!this.state.inconformidades && this.state.inconformidades.map((row,i) => this.getRow(i,row))}
                      

                        <Fab variant="extended" onClick={() => this.getMore()}>
                            <Add />
                            Adicionar mais inconformidades
                        </Fab>
                        <Divider variant="inset" component="div" />
                        <h4>DADOS BANCÁRIOS</h4>
                        <TextField id="banco-nome" label="Nome" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({account_holder: e.target.value})} value={ this.state.account_holder ? this.state.account_holder : ''} />
                        <TextField id="banco-nome-banco" label="Nome Banco" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({bank_name: e.target.value})} value={ this.state.bank_name ? this.state.bank_name : ''} />
                        <TextField id="banco-numero" type="number" label="Nº Banco" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({bank_code: e.target.value})} value={ this.state.bank_code ? this.state.bank_code : ''} />
                        <TextField id="banco-agencia" label="Nº da Agencia" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({agency_number: e.target.value})} value={ this.state.agency_number ? this.state.agency_number : ''}/>

                        <TextField
                            select
                            label="Tipo Da Conta"
                            value={this.state.account_type}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({account_type: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            >
                                <MenuItem key="CC" value="1">
                                    Conta Corrente
                                </MenuItem>
                                <MenuItem key="CP" value="2">
                                    Poupança
                                </MenuItem>
                            </TextField>

                        <TextField id="banco-numero-conta" label="Nº da Conta" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({account_number: e.target.value})} value={ this.state.account_number ? this.state.account_number : ''}/>

                        <Grid container spacing={3}>
                            {/* <Grid className={this.props.classes.root} item xl={6}>
                                <Fab variant="extended" color="primary">
                                    <Print />
                                    Imprimir
                                </Fab>
                            </Grid> */}
                            <Grid className={this.props.classes.root} item xl={6} >
                                <Fab variant="extended" color="primary" onClick={() => this.submitIt() }>
                                    <Save />
                                    Salvar
                                </Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
        );
    }
}
export default withApollo((withStyles(styles))(InfomacoesGeral));