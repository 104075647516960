import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {  USER_QUERY, LIST_USER_QUERY } from '../../services/graphql/querys/user';
import {  DELETE_USER } from '../../services/graphql/mutations/user';

import { withApollo, graphql, Query } from 'react-apollo';

import {
    TextField,
    Grid,
    FormControlLabel,
    Button,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    DialogTitle,
    Dialog,
    List,
    ListItem
} from '@material-ui/core';
import { Search, Add, Edit, Delete } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    },
    buttonAction: {
        marginLeft: '10px',
        marginRight: '10px',
    }
});

class Lista extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            users: null,
            byName: null,
            byCPF: null,
            remover: null,
        }
    }

    fazBusca = () => {
        let body = {
            name:  this.state.byName,
            legal_document:  this.state.byCPF,
        }

        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Enviar Parametros: ", paramsEnviar);
            this.props.client.query({query: LIST_USER_QUERY, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){ 
                    console.log("ERROR: ", errors)
                } else {
                    console.log("DATA: ", data)
                    this.setState({'users': data.ListUsersQuery});                    
                }
            })
    }
    componentDidMount(){
        this.fazBusca();
    }

    removeUser(){
        console.log("Remover user: ", this.state.remover)
        this.props.client.mutate({mutation: DELETE_USER, variables: {user_id: this.state.remover}, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({open: false, remover: null});
                alert("Erro ao remover usuario!"); 
            } else {
                console.log("DATA: ", data);
                this.setState({open: false, remover: null});
                alert("Usuario removido!");
                window.location.href = "/usuarios";
            }
        })
    }
    
    render(){
        const { onClose, selectedValue, open } = this.props;

        const handleClose = () => {
            this.setState({open: false, remover: null});
        };
        const handleClickOpen = (user_id) => {
            console.log("UserID: ", user_id)
            this.setState({open: true, remover: user_id});
        };
        
        return(
            <div>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={9} xs={8}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xs={6}>
                                <TextField  className={this.props.classes.textField} id="byName" value={this.state.byName} onChange={(e) => this.setState({byName: e.target.value})} label="Nome" variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xs={6}>
                                <TextField  className={this.props.classes.textField} id="byCPF" value={this.state.byCPF} onChange={(e) => this.setState({byCPF: e.target.value})} label="CPF / CNPJ" variant="outlined" margin="dense" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={4}>
                        <Grid container spacing={2}>
                            <Grid className={this.props.classes.root} align="center" item xl={6}>
                                <Button variant="contained" color="primary" disableElevation className={this.props.classes.button}>
                                    <Search />
                                </Button>
                            </Grid>
                            <Grid className={this.props.classes.root} align="center" item xl={6}>
                                <Button variant="contained" color="primary" disableElevation className={this.props.classes.button} component="a" href="/usuarios/new">
                                    <Add />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <TableContainer component={Paper}>
                            <Table className={this.props.classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell>User</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Tipo</TableCell>
                                        <TableCell align="center">Editar</TableCell>
                                        <TableCell align="center">Excluir</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {!!this.state.users && this.state.users.map(row => (
                                    <TableRow key={row.user_id}>
                                        <TableCell component="th" scope="row">{row.name}</TableCell>
                                        <TableCell>{row.login}</TableCell>
                                        <TableCell>{row.user.email}</TableCell>
                                        <TableCell>{row.user.profile.user_type == "admin_station" ? "Admin de Estação" : row.user.profile.user_type == "admin_central" ? "Admin Central" : row.user.profile.user_type}</TableCell>
                                        <TableCell align="center">
                                        <Fab color="primary" size="small" aria-label="editar" component="a" href={"/usuarios/edit/" + row.user_id}>
                                            <Edit />
                                        </Fab>
                                        </TableCell>
                                        <TableCell align="center">
                                        <Fab color="primary" size="small" aria-label="search" onClick={() => handleClickOpen(row.user_id)}>
                                            <Delete />
                                        </Fab>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
                    <DialogTitle id="simple-dialog-title" align="center">
                        Você realmente deseja<br/>
                        <strong>EXCLUIR</strong> o Usuário ?
                    </DialogTitle>
                    <List>
                        <ListItem>
                            <Button variant="contained" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]} onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant="contained" color="primary" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]} onClick={() => this.removeUser()}>
                                Continuar
                            </Button>
                        </ListItem>
                    </List>
                </Dialog>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Lista));