import gql from 'graphql-tag';

export const EDIT_PRODUCT = gql`

mutation  editProduct(
  $product_id: Int, 
  $product_category_id: Int!, 
  $local_name: String!, 
  $comercial_name: String!, 
  $unified_product: String, 
  $productPicture: Upload 
){
    
  editProduct(
    product_id: $product_id
    product_category_id: $product_category_id
    local_name: $local_name
    comercial_name: $comercial_name
    unified_product: $unified_product
    productPicture: $productPicture
  ) {
    product_id
    product_category_id
    local_name
    comercial_name
    unified_product
    picture
    urlPicture
    productCategory {
      product_category_id
      product_category
    }
  }
}`;


export const ADD_PRODUCT_PRICE = gql`

mutation  createStationProductPrice(
  $product_id: Int!, 
  $measure_unit_id: String, 
  $price: Float!, 
){
    
  createStationProductPrice(
    product_id: $product_id
    measure_unit_id: $measure_unit_id
    price: $price
  ) {
    product_id
  }
}`;

export const EDIT_PRODUCT_PRICE = gql`

mutation  editStationProductPrice(
  $product_id: Int!, 
  $measure_unit_id: String, 
  $price: Float!, 
){
  editStationProductPrice(
    product_id: $product_id
    measure_unit_id: $measure_unit_id
    price: $price
  ) {
    product_id
  }
}`;


export const ADD_PRODUCT_STOCK = gql`

mutation  addProductToStationStock(
  $product_id: Int!, 
  $quantity: Float!, 
){
    
  addProductToStationStock(
    product_id: $product_id
    quantity: $quantity
  ) {
    quantity
  }
}`;

export const EDIT_PRODUCT_STOCK = gql`

mutation  editProductFromStationStock(
  $product_id: Int!, 
  $quantity: Float!, 
){
  editProductFromStationStock(
    product_id: $product_id
    quantity: $quantity
  ) {
    quantity
  }
}`;
