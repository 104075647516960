import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { EDIT_BEEKEEPING } from '../../../services/graphql/mutations/management';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';
import MaskedInput from 'react-text-mask';
import gql from 'graphql-tag';

import {
    TextField,
    Grid,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';
import { Add, Print, Save, Delete } from '@material-ui/icons';


const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    input: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },  
    radioClass: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },    
    divisor: {
        width: "100%",
        height: "3px",
        backgroundColor: "#000",
        marginTop: "3px",
        marginBottom: "10px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});



class CadApicultura extends Component { 
    constructor(props) {
        super(props);
        if(!!this.props.manejo.manejo.managementBeekeeping && this.props.manejo.manejo.managementBeekeeping != null){
            let manejo = this.props.manejo.manejo;
            this.state = {
                management_id: manejo.managementBeekeeping.management_id,
                bee_type: manejo.managementBeekeeping.bee_type,
                uses_fixed_beekeeping: manejo.managementBeekeeping.uses_fixed_beekeeping,
                uses_migratory_beekeeping: manejo.managementBeekeeping.uses_migratory_beekeeping,
                wax_organic_apiaries: manejo.managementBeekeeping.wax_organic_apiaries,
                wax_non_organic_apiaries: manejo.managementBeekeeping.wax_non_organic_apiaries,
                wax_own_organic_production: manejo.managementBeekeeping.wax_own_organic_production,
                wax_own_non_organic_production: manejo.managementBeekeeping.wax_own_non_organic_production,
                bees_own_production: manejo.managementBeekeeping.bees_own_production,
                bees_swarm_capture: manejo.managementBeekeeping.bees_swarm_capture,
                bees_purchase_conventional_systems: manejo.managementBeekeeping.bees_purchase_conventional_systems,
                bees_purchase_organic_swarms: manejo.managementBeekeeping.bees_purchase_organic_swarms,
                flowering_periods: manejo.managementBeekeeping.flowering_periods,
                distance_less: manejo.managementBeekeeping.distance_less,
                distance_one_km: manejo.managementBeekeeping.distance_one_km,
                distance_two_km: manejo.managementBeekeeping.distance_two_km,
                distance_three_km: manejo.managementBeekeeping.distance_three_km,
                distance_four_km: manejo.managementBeekeeping.distance_four_km,
                distance_five_km: manejo.managementBeekeeping.distance_five_km,
                distance_more: manejo.managementBeekeeping.distance_more,
                distance_more_description: manejo.managementBeekeeping.distance_more_description,
                feed_honey: manejo.managementBeekeeping.feed_honey,
                feed_organic_sugar_syrup: manejo.managementBeekeeping.feed_organic_sugar_syrup,
                feed_other: manejo.managementBeekeeping.feed_other,
                feed_other_description: manejo.managementBeekeeping.feed_other_description,
                feed_for_how_long: manejo.managementBeekeeping.feed_for_how_long,
                pest_control: manejo.managementBeekeeping.pest_control,
            }
        }else{
            this.state = {
                management_id: this.props.manejo.manejo.management_id,
                bee_type: null,
                uses_fixed_beekeeping: null,
                uses_migratory_beekeeping: null,
                wax_organic_apiaries: null,
                wax_non_organic_apiaries: null,
                wax_own_organic_production: null,
                wax_own_non_organic_production: null,
                bees_own_production: null,
                bees_swarm_capture: null,
                bees_purchase_conventional_systems: null,
                bees_purchase_organic_swarms: null,
                flowering_periods: null,
                distance_less: null,
                distance_one_km: null,
                distance_two_km: null,
                distance_three_km: null,
                distance_four_km: null,
                distance_five_km: null,
                distance_more: null,
                distance_more_description: null,
                feed_honey: null,
                feed_organic_sugar_syrup: null,
                feed_other: null,
                feed_other_description: null,
                feed_for_how_long: null,
                pest_control: null,
            };
        }
    } 
  
    submitIt(){
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);
        this.props.client.mutate({mutation: EDIT_BEEKEEPING, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
                
                

            } else {
                console.log("DATA: ", data);
                alert("Salvo com sucesso !");
            }
        })
    }

  

    render() {
        // console.log("state", this.state)
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <div style={{paddingTop: "10px"}}>
                    <h4>CADASTRO DA APICULTURA {this.props.manejo.manejo.description}</h4>
                    <hr style={css.hr}/>
                    <div style={css.contentColuns}>
                        <div style={css.col4}>
                            <sub>Que tipo de abelha usa ?</sub>

                            <TextField 
                                id="tipo" 
                                InputLabelProps={{shrink: true,}} 
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({bee_type: e.target.value})} 
                                value={this.state.bee_type}
                                style={css.input}
                            />
                        </div>
                    </div>
                    <hr style={css.hr}/>
                    <div style={css.contentColuns}>

                        <div style={css.col1}>
                            <sub style={{display: "flex"}}>Que tipo de apicultura utiliza ?</sub>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.uses_fixed_beekeeping}
                                        onChange={(e) => this.setState({uses_fixed_beekeeping: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Fixa"/>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.uses_migratory_beekeeping}
                                        onChange={(e) => this.setState({uses_migratory_beekeeping: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Migratória"/>
                        </div>

                        <div style={css.col3}>
                        
                            <sub style={{display: "flex"}}>Qual a origem da cera para fabricação de novas folhas de cera?</sub>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.wax_organic_apiaries}
                                        onChange={(e) => this.setState({wax_organic_apiaries: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Apiários orgânicos"/>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.wax_non_organic_apiaries}
                                        onChange={(e) => this.setState({wax_non_organic_apiaries: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Apiários não orgânicos"/>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.wax_own_organic_production}
                                        onChange={(e) => this.setState({wax_own_organic_production: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Produção própria orgânica"/>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.wax_own_non_organic_production}
                                        onChange={(e) => this.setState({wax_own_non_organic_production: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Produção própria não orgânica"/>
                        </div>
                    </div>
                    <hr style={css.hr}/>
                    <div style={css.contentColuns}>
                        <div style={css.col4}>

                            <sub style={{display: "flex"}}>Qual a origem das abelhas para povoação das colmeias?</sub>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.bees_own_production}
                                        onChange={(e) => this.setState({bees_own_production: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Produção própria"/>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.bees_swarm_capture}
                                        onChange={(e) => this.setState({bees_swarm_capture: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Captura de enxames"/>

                            {/* <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.wax_own_organic_production}
                                        onChange={(e) => this.setState({wax_own_organic_production: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Produção própria orgânica"/> */}

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.bees_purchase_conventional_systems}
                                        onChange={(e) => this.setState({bees_purchase_conventional_systems: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Compra de enxames em sistemas convencionais"/>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.bees_purchase_organic_swarms}
                                        onChange={(e) => this.setState({bees_purchase_organic_swarms: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Compra de enxames orgânicos"/>
                        </div>
                    </div>


                    <hr style={css.hr}/>
                    <div style={css.contentColuns}>
                        <div style={css.col4}>
                        <TextField 
                                InputLabelProps={{shrink: true,}} 
                                label="Quais as principais floradas exploradas pelas abelhas e seus períodos de floração?"
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({flowering_periods: e.target.value})} 
                                value={this.state.flowering_periods}
                                style={css.input}
                           />
                    
                        </div>
                    </div>

                    <hr style={css.hr}/>

                    <div style={css.contentColuns}>
                        <div style={css.col4}>

                            <sub style={{display: "flex"}}>Qual a distância do apiário do Centro Urbano?</sub>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.distance_less}
                                        onChange={(e) => this.setState({distance_less: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Menos de 1km"/>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.distance_one_km}
                                        onChange={(e) => this.setState({distance_one_km: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="1km"/>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.distance_two_km}
                                        onChange={(e) => this.setState({distance_two_km: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="2km"/>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.distance_three_km}
                                        onChange={(e) => this.setState({distance_three_km: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="3km"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.distance_four_km}
                                        onChange={(e) => this.setState({distance_four_km: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="4km"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.distance_five_km}
                                        onChange={(e) => this.setState({distance_five_km: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="5km"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.distance_more}
                                        onChange={(e) => this.setState({distance_more: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Mais de 5km"/>
                                
                            <TextField 
                                id="distancia" 
                                InputLabelProps={{shrink: true,}} 
                                label="Quanto?"
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({distance_more_description: e.target.value})} 
                                value={this.state.distance_more_description}
                                // style={css.input}
                            />
                        </div>
                    </div>

                    <hr style={css.hr}/>

                    <div style={css.contentColuns}>
                        <div style={css.col4}>

                            <sub style={{display: "flex"}}>Qual o tipo de alimentação artificial utilizada?</sub>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.feed_honey}
                                        onChange={(e) => this.setState({feed_honey: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Mel"/>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.feed_organic_sugar_syrup}
                                        onChange={(e) => this.setState({feed_organic_sugar_syrup: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Xarope de açucar orgânico"/>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.feed_other}
                                        onChange={(e) => this.setState({feed_other: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Outro"/>

                            <TextField 
                                InputLabelProps={{shrink: true,}} 
                                label="Qual?"
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({feed_other_description: e.target.value})} 
                                value={this.state.feed_other_description}
                                // style={css.input}
                           />

                            <TextField 
                                InputLabelProps={{shrink: true,}} 
                                label="Por quanto tempo?"
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({feed_for_how_long: e.target.value})} 
                                value={this.state.feed_for_how_long}
                                style={{marginLeft: "3px"}}
                           />
                        </div>
                    </div>

                    <hr style={css.hr}/>

                    <div style={css.contentColuns}>
                        <div style={css.col4}>
                        <TextField 
                                InputLabelProps={{shrink: true,}} 
                                label="No caso de pragas e doenças, como você realiza o controle?"
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({pest_control: e.target.value})} 
                                value={this.state.pest_control}
                                style={css.input}
                           />
                    
                        </div>
                    </div>
                </div>
                <hr style={css.hr}/>
                <div style={css.contentColuns}>
                    <Fab variant="extended" color="primary" onClick={() => this.submitIt()}>
                        <Save />
                        Criar
                    </Fab>
                </div>
            </div>
        );
    }
}
export default withApollo((withStyles(styles))(CadApicultura));

const css = {
    contentColuns: {
        display:"flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    col1: {
        maxWidth: "25%",
        minWidth: "25%",
        flex: 1
    },
    col2: {
        maxWidth: "50%",
        minWidth: "50%",
        flex: 1
    },
    col3: {
        maxWidth: "75%",
        minWidth: "75%",
        flex: 1
    },
    col4: {
        maxWidth: "100%",
        minWidth: "100%",
        flex: 1
    },
    input: {
       flex: 1,
       display: "flex",
       marginRight: 15
    },
    hr: {
        marginTop: "20px",
        marginBottom: "20px"
    }
}