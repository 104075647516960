
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { 
    AppBar, Tabs, Tab, Typography, Box
} from '@material-ui/core';
// COMPONENTS

import Frete from '../../Component/ValoresAdicionais/Frete';
import Lucro from '../../Component/ValoresAdicionais/Lucro';
import FreteEdit from '../../Component/ValoresAdicionais/FreteEdit';
import LucroEdit from '../../Component/ValoresAdicionais/LucroEdit';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});
class ValoresAdicionais extends Component {
    constructor(props){
        super(props);
        this.state = {
            tabSelected: 0
        }
    }
    allyProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }
    handleChange = (event, newValue) => {
        this.setState({
            tabSelected: newValue
        });
    };
    render() {
        if(this.props.match.params.action == 'edit-lucro'){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <LucroEdit id={this.props.match.params.id ? this.props.match.params.id : 0}/>
                </div>
            );
        } else if(this.props.match.params.action == 'edit-frete'){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <FreteEdit id={this.props.match.params.id ? this.props.match.params.id : 0}/>
                </div>
            );
        } else {
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <AppBar position="static" color="default">
                        <Tabs
                        value={this.state.tabSelected}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        >
                            <Tab label="Porcentagem" {...this.allyProps(0)} />
                            <Tab label="Frete" {...this.allyProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={this.state.tabSelected} index={0}>
                        <Lucro/>
                    </TabPanel>
                    <TabPanel value={this.state.tabSelected} index={1}>
                        <Frete/>
                    </TabPanel>
                    {/* <Formulario/> */}
                </div>
            );
        }
    }
}
export default withStyles(styles)(ValoresAdicionais);