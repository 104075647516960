import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withApollo, Query } from 'react-apollo';

import DemandaOferta from "../../Component/Relatorio/DemandaOferta";
import DemandaReal from "../../Component/Relatorio/DemandaReal";
import OfertaReal from "../../Component/Relatorio/OfertaReal";
import ListaAgricultores from "../../Component/Relatorio/ListaAgricultores";
import ListaClientes from "../../Component/Relatorio/ListaClientes";
import Opac from "../../Component/Relatorio/Opac";
import DemandaEstimada from "../../Component/Relatorio/DemandaEstimada";
import Rodada from "../../Component/Relatorio/Rodada";
import Agricultores from "../../Component/Relatorio/Agricultores";

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    contentBox: {
        backgroundColor:"#fff",
        borderRadius: "4px",
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    }
});

class Relatorio extends Component {
    constructor(props){
        super(props);
        this.state ={}
    }
    render(){
        if(this.props.match.params.action === 'agricultores'){
            return (
                <div className="page-wrapp" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <Agricultores />
                </div>
            );
        } else if(this.props.match.params.action === 'demanda-real' ){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <DemandaReal/>
                </div>
            );
        } else if(this.props.match.params.action === 'demanda-estimada' ){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <DemandaEstimada/>
                </div>
            );
        } else if(this.props.match.params.action === 'demanda-x-oferta' ){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <DemandaOferta/>
                </div>
            );
        } else if(this.props.match.params.action === 'lista-agricultores' ){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <ListaAgricultores/>
                </div>
            );
        } else if(this.props.match.params.action === 'lista-clientes' ){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <ListaClientes/>
                </div>
            );
        } else if(this.props.match.params.action === 'oferta-real' ){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <OfertaReal/>
                </div>
            );
        } else if(this.props.match.params.action === 'opac' ){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <Opac/>
                </div>
            );
        } else if(this.props.match.params.action === 'rodada' ){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <Rodada/>
                </div>
            );
        } else if(this.props.match.params.action === 'agricultores'){
            return (
                <div className="page-wrapp" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <Agricultores />
                </div>
            );
        }
    }
}
export default Relatorio;