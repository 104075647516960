import gql from 'graphql-tag';

export const CORE_QUERY = gql`

query CoreQuery( $core_id: Int, $name: String, $state: String, $city: String, $status: String ){
  CoreQuery ( core_id: $core_id, name: $name, state: $state, city: $city, status: $status ){
    core_id
    name
    cities_involved
    states_involved
    coordinator
    comercial_phone
    cell_phone
    email
    country
    state
    city
    district
    address
    address_number
    address_complement
    zip_code
    legal_document
    state_registration
    municipal_registration
    status
    }
}
`;