import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withApollo, graphql, Query } from 'react-apollo';
import { DADOS_STATION, LISTA_RODADAS } from '../../services/graphql/querys/rodada';
import { CRIA_RODADA, EDITA_RODADA } from '../../services/graphql/mutations/rodada';
import { LISTA_TIPOS_CONSUMIDOR } from '../../services/graphql/querys/customer';

import { Multiselect } from 'multiselect-react-dropdown';
import CustomErrors from '../CustomErrors/CustomErrors';
import * as moment from 'moment';
import 'moment-timezone';

import MomentUtils from '@date-io/moment';
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import {
    TextField,
    Grid,
    MenuItem,
    Fab,
    FormControlLabel,
    Button,
    Checkbox,
} from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { Save, Edit } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    }
});
moment.tz.setDefault("America/Sao_Paulo");
//moment.tz.setDefault('Etc/UTC');

class NovaRodada extends Component {
    constructor(props){
        super(props);
        this.state = {
            status: false,
            description: null,
            offer_starts_on: null,
            offer_ends_on: null,
            order_starts_on: null,
            order_ends_on: null,
            buyers: null,
            sellers: null,
            freights: null,
            rodadaEmAndamento: null,
            error: [],
            groups: [],
            comercType: [],
        }
        this.refSellers = React.createRef();

    }
    handleChangeMask = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };
    transformaDateLocal(data){
        return new Date(data).toISOString().substring(0, 16);
    }
    componentDidMount(){
        let rodadaEmAndamento = 0;
       if(this.props.id > 0){
            this.props.client.query({query: LISTA_RODADAS, variables: {id: this.props.id}, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("DATA: ", data)
                    let rodada = data.CommercialRoundsQuery.data[0];

                    let buyers = [];
                    if(!!rodada.buyers && rodada.buyers.length > 0){
                        rodada.buyers.forEach(element => {
                            buyers.push({key: element.user.profile.name, customer_user_id: element.customer_user_id})
                        });
                    }

                    let sellers = [];
                    if(!!rodada.sellers && rodada.sellers.length > 0){
                        rodada.sellers.forEach(element => {
                            sellers.push({key: element.user.profile.name, farmer_user_id: element.farmer_user_id})
                        });
                    }
                    let freights = [];
                    rodada.freights.forEach(element => {
                        freights.push({key: element.name, freight_id: element.freight_id})
                    });

                    let offer_starts = moment(rodada.offer_starts_on, 'YYYY-MM-DDTHH:mm');
                    let offer_ends   = moment(rodada.offer_ends_on, 'YYYY-MM-DDTHH:mm');
                    let order_starts = moment(rodada.order_starts_on, 'YYYY-MM-DDTHH:mm');
                    let order_ends   = moment(rodada.order_ends_on, 'YYYY-MM-DDTHH:mm');
                    if(moment(new Date()).isAfter(offer_starts)){
                        this.setState({rodadaEmAndamento: 1});
                    }

                    this.setState({
                        description: rodada.description,
                        offer_starts_on: offer_starts,
                        offer_ends_on: offer_ends,
                        order_starts_on: order_starts,
                        order_ends_on: order_ends,
                        buyers: buyers,
                        sellers: sellers,
                        freights: freights,
                        status: rodada.status.code == 'visible' ? false : true,
                    })
                    
                }
            })
       }

       let userAcc = JSON.parse(localStorage.getItem('UserAccount'));
       let body = {
           station_id: userAcc.profile.station.id,
       }
    
        this.props.client.query({query: DADOS_STATION, variables: body, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("DATA: ", data)
                if(!!data.StationQuery[0]){
                    let optionBuyers = [];
                    let optionSellers = [];
                    let optionFreights = [];
                    let optionGroups = [];
                    let optionComercType = [];

                    data.StationQuery[0].stationCustomers.forEach(element => {
                        optionBuyers.push({key: element.userProfile.name, customer_user_id: element.id,  commercialization_type_id: element.commercialization_type_id})
                    });
                    data.StationQuery[0].stationFarmers.forEach(element => {
                        optionSellers.push({key: element.userProfile.name, farmer_user_id: element.id})
                    });
                    data.StationQuery[0].stationFreights.forEach(element => {
                        optionFreights.push({key: element.name ,freight_id: element.freight_id})
                    });
                    data.StationQuery[0].stationGroups.forEach(element => {
                        optionGroups.push({key: element.name ,group_id: element.group_id, farmers: element.groupFarmers})
                    });
                    data.CommercializationsTypeQuery.forEach(element => {
                        optionComercType.push({key: element.description ,commercialization_type_id: element.commercialization_type_id})
                    });
                    this.setState({
                        optionComercType: optionComercType,
                        optionBuyers: optionBuyers,
                        optionSellers: optionSellers,
                        optionFreights: optionFreights,
                        optionGroups: optionGroups,
                    })
                    console.log("Buyers: ", optionBuyers)
                }                     
            }
        })
        
    }

    submitIt(){
        this.setState({error: []})
        if(!this.state.buyers){
            this.setState({error: [{message: "Você deve escolher ao menos 1 Consumidor nesta rodada"}]})
            return false;
        }else if(!this.state.sellers){
            this.setState({error: [{message: "Você deve escolher ao menos 1 Vendedor nesta rodada"}]})
            return false;
        }else if(!this.state.freights){
            this.setState({error: [{message: "Você deve cadastrar ao menos 1 Frete nesta rodada"}]})
            return false;
        }else if(!this.state.description){
            this.setState({error: [{message: "Você deve cadastrar a descrição desta rodada"}]})
            return false;
        }else if(!this.state.offer_starts_on){
            this.setState({error: [{message: "Você deve colocar a data de inicio das ofertas"}]})
            return false;
        }else if(!this.state.offer_ends_on){
            this.setState({error: [{message: "Você deve colocar a data final das ofertas"}]})
            return false;
        }else if(!this.state.order_starts_on){
            this.setState({error: [{message: "Você deve colocar a data de inicio das demandas"}]})
            return false;
        }else if(!this.state.order_ends_on ){    
            this.setState({error: [{message: "Você deve colocar a data final das demandas"}]})
            return false;       
    
        }else{
            let freights = [];
            for(let i=0; i<this.state.freights.length; i++){            
                freights[i] = this.state.freights[i].freight_id;
            }
            let newBuyers = [];
            this.state.buyers.forEach(element => {
                newBuyers.push({customer_user_id: element.customer_user_id})
            });      
            let newSellers = [];
            this.state.sellers.forEach(element => {
                newSellers.push({farmer_user_id: element.farmer_user_id})
            });      

            let body = {
                freights: freights,
                status_code: this.state.status ? 'hidden' : 'visible',
                description: this.state.description,
                offer_starts_on: this.state.offer_starts_on.format('YYYY-MM-DDTHH:mm'),
                offer_ends_on: this.state.offer_ends_on.format('YYYY-MM-DDTHH:mm'),
                order_starts_on: this.state.order_starts_on.format('YYYY-MM-DDTHH:mm'),
                order_ends_on: this.state.order_ends_on.format('YYYY-MM-DDTHH:mm'),
                buyers: newBuyers,
                sellers: newSellers,
            };

            if(this.props.id > 0){
                body.id = this.props.id;
            }

            let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Enviar Parametros: ", paramsEnviar);
            this.props.client.mutate({mutation: this.props.id > 0 ? EDITA_RODADA : CRIA_RODADA, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                    if(errors[0].message == 'validation'){
                        let erroValid = errors[0].extensions.validation;
                        let errosMostrar = [];
                        let i = 0;
                        for (var [key, value] of Object.entries(erroValid)) {
                            console.log("ERRO... " + key + ' ',value);
                            if(key == "offer_ends_on"){
                                errosMostrar[i] = {message: "A data final das ofertas deve conter uma data posterior a data inicial das ofertas."};
                            }else if(key == "order_ends_on"){
                                errosMostrar[i] = {message: "A data final das demandas deve conter uma data posterior a data inicial das demandas."};
                            }else if(key == "order_starts_on"){
                                errosMostrar[i] = {message: "A data inicial das demandas deve conter uma data posterior a data final das ofertas."};
                            }else{ 
                                errosMostrar[i] = {message: value};

                            }
                            
                            i++;
                        }
                        this.setState({...this.state, error: errosMostrar})
                    }else{
                        let errosMostrar = [];
                        for(var [key, value] of Object.entries(errors)){
                            errosMostrar[key] = {message: value.message};
                        }
                        this.setState({...this.state, error: errosMostrar})
                    }
                } else {
                    console.log("DATA: ", data);
                    this.setState({...this.state, error: []});
                    alert("Salvo com sucesso !");
                    window.location.href = "/rodada";
                }
            })    
        }
    }
  
    onSelectBuyers = (selectedList, selectedItem) => {           
        this.setState({buyers: selectedList})
    }
    onSelectSellers = (selectedList, selectedItem) => {
        this.setState({sellers: selectedList})
    }
    onSelectFreights = (selectedList, selectedItem) => {
        this.setState({freights: selectedList})
    }

    addGroup = () => {        
       if(!!this.state.group){
            let farmersSelecionados = this.state.sellers;

            if(!farmersSelecionados) farmersSelecionados = [];

            console.log("Selecionados: ", farmersSelecionados);   

            console.log("Grupo Selecionado: ", this.state.group);  
            for(let i = 0; i<this.state.group.farmers.length; i++){
                farmersSelecionados.push({key: this.state.group.farmers[i].userProfile.name, farmer_user_id: this.state.group.farmers[i].id})
            }

            let newFarmersSelecionados = farmersSelecionados.filter( (ele, ind) => ind === farmersSelecionados.findIndex( elem => elem.farmer_user_id === ele.farmer_user_id))
            console.log("Novos selecionados: ", newFarmersSelecionados);
            this.setState({sellers: newFarmersSelecionados})
       }

    }
    addConsumer = () => {        
        if(!!this.state.consumerType){
             let buyersSelecionados = this.state.buyers;
 
             if(!buyersSelecionados) buyersSelecionados = [];
 
             console.log("Selecionados: ", buyersSelecionados);   
 
             console.log("Grupo Selecionado: ", this.state.consumerType);  
            
             for(let i = 0; i<this.state.optionBuyers.length; i++){
                if(this.state.optionBuyers[i].commercialization_type_id == this.state.consumerType){
                    buyersSelecionados.push({key: this.state.optionBuyers[i].key, customer_user_id: this.state.optionBuyers[i].customer_user_id})

                }
            }

            let newbuyersSelecionados = buyersSelecionados.filter( (ele, ind) => ind === buyersSelecionados.findIndex( elem => elem.customer_user_id === ele.customer_user_id))
            console.log("Novos selecionados: ", newbuyersSelecionados);
            this.setState({buyers: newbuyersSelecionados})    
        }
     }

    handleOfferStart = date => this.setState({ offer_starts_on: date })
    handleOfferEnd = date => this.setState({ offer_ends_on: date })
    handleOrderStart = date => this.setState({ order_starts_on: date })
    handleOrderEnd = date => this.setState({ order_ends_on: date })

    render() {
        console.log("State: ",this.state)
        // console.log("optionComercType", this.state.optionComercType)
        // console.log("optionGroups", this.state.optionGroups)
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={8} xs={8}>
                        <TextField 
                            id='desc' 
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="Descrição" 
                            variant="outlined" 
                            value={this.state.description}
                            disabled={!!this.state.rodadaEmAndamento &&  this.state.rodadaEmAndamento > 0}
                            onChange={(e) => this.setState({description: e.target.value})} 
                            margin="dense"/>
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                        disabled={!!this.state.rodadaEmAndamento &&  this.state.rodadaEmAndamento > 0}

                                        checked={this.state.status_code}
                                        onChange={(e) => this.setState({status_code: e.target.checked})}
                                        color="primary"
                                    />
                            }
                            label="Esconder?"
                        />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <DateTimePicker 
                            id='offer_starts_on' 
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="Data inicio ofertas" 
                            value={this.state.offer_starts_on}
                            onChange={this.handleOfferStart}
                            ampm={false}
                            margin="dense"
                            variant="outlined" 
                            />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <DateTimePicker 
                            id='offer_ends_on' 
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="Data fim ofertas" 
                            variant="outlined" 
                            value={this.state.offer_ends_on}
                            onChange={this.handleOfferEnd}
                            ampm={false}
                            margin="dense"
                            />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <DateTimePicker 
                            id='order_starts_on' 
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="Data inicio demanda" 
                            variant="outlined" 
                            value={this.state.order_starts_on}
                            onChange={this.handleOrderStart}
                            ampm={false}
                            margin="dense"
                            />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <DateTimePicker 
                            id='order_ends_on' 
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="Data fim demanda" 
                            variant="outlined" 
                            value={this.state.order_ends_on}
                            onChange={this.handleOrderEnd}
                            ampm={false}
                            margin="dense"
                            />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <Grid sttle={{display: "flex", alignItems: "center"}}>
                        <TextField
                                select
                                label="Adicionar Grupo Consumidores"
                                value={null}
                                style={{width: "50%"}}
                                InputLabelProps={{shrink: true,}}
                                onChange={(e) => this.setState({consumerType: e.target.value})}
                                variant="outlined"
                                margin="dense"
                            >
                                <MenuItem key={`coreCons-$0`} value={null}>
                                            ---
                                </MenuItem>
                                {!!this.state.optionComercType && this.state.optionComercType.map(
                                    option => {
                                        let position = this.state.optionBuyers.findIndex(val => val.commercialization_type_id === option.commercialization_type_id);
                                        if(position > -1)
                                        return (
                                        <MenuItem key={`coreCons-${option.commercialization_type_id}`} value={option.commercialization_type_id}>
                                            {option.key}
                                        </MenuItem>
                                        )
                                    }
                                )}
                            </TextField>
                            <Button variant="contained" color="primary" style={{marginTop: "2%", marginLeft: "5%", width: "30%"}} onClick={() => this.addConsumer()}>
                                Adicionar
                            </Button>
                        </Grid>
                        <Multiselect
                            margin="dense"
                            variant="outlined" 
                            style={this.props.classes.textField} 
                            options={this.state.optionBuyers}
                            displayValue="key"
                            selectedValues={this.state.buyers}
                            placeholder="Consumidores" 
                            onSelect={this.onSelectBuyers} 
                            disablePreSelectedValues={!!this.state.rodadaEmAndamento &&  this.state.rodadaEmAndamento > 0}
                        />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <Grid sttle={{display: "flex", alignItems: "center"}}>
                            <TextField
                                select
                                label="Adicionar Grupo Vendedores"
                                value={null}
                                style={{width: "50%"}}
                                InputLabelProps={{shrink: true,}}
                                onChange={(e) => this.setState({group: e.target.value})}
                                variant="outlined"
                                margin="dense"
                            >
                                <MenuItem key={`coreSeller-$0`} value={null}>
                                            ---
                                </MenuItem>
                                {!!this.state.optionGroups && this.state.optionGroups.map(
                                    option => {
                                        if(option.farmers && option.farmers.length > 0)
                                        return(
                                            <MenuItem key={`coreSeller-${option.group_id}`} value={option}>
                                                {option.key}
                                            </MenuItem>
                                        )
                                    }
                                )}
                            </TextField>
                            <Button variant="contained" color="primary" style={{marginTop: "2%", marginLeft: "5%", width: "30%"}} onClick={() => this.addGroup()}>
                                Adicionar
                            </Button>
                        </Grid>

                    
                        <Multiselect
                            margin="dense"
                            variant="outlined" 
                            style={this.props.classes.textField} 
                            options={this.state.optionSellers}
                            displayValue="key"
                            selectedValues={this.state.sellers}
                            placeholder="Vendedores" 
                            ref={this.refSellers}
                            onSelect={this.onSelectSellers} 
                            disablePreSelectedValues={!!this.state.rodadaEmAndamento &&  this.state.rodadaEmAndamento > 0}
                        />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                       
                       <Multiselect
                           margin="dense"
                           variant="outlined" 
                           style={this.props.classes.textField} 
                           options={this.state.optionFreights}
                           displayValue="key"
                           selectedValues={this.state.freights}
                           placeholder="Fretes" 
                           onSelect={this.onSelectFreights} 
                           disablePreSelectedValues={!!this.state.rodadaEmAndamento &&  this.state.rodadaEmAndamento > 0}

                       />
                   </Grid>

                    <Grid className={this.props.classes.root} item xl={3} xs={4}>
                        <Fab variant="extended" className={this.props.classes.textField} color="primary" onClick={() => this.submitIt()}>
                            <Save />
                            Salvar
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withApollo(withStyles(styles)(NovaRodada));