import gql from 'graphql-tag';

export const CREATE_FARMER = gql`

mutation createFarmer(
    $name: String!,
    $email: String,
    $msisdn: String,
    $phone: String,
    $productive_unit_id: Int,
    $group_id: Int,
    $core_id: Int,
    $contact: String!,
    $group_offerer: Boolean,
    $identity_document: String,
    $legal_document: String!,
    $dap: String,
    $mother_name: String,
    $rural_block_registration: String,
    $certificateFile: Upload,
    $certificate_issue: String,
    $certificate_expiration: String,
    $login: String!,
    $nickname: String,
    $password: String!,
    $birth: String,
    $profilePicture: Upload,
    $country: String,
    $state: String!,
    $city: String!,
    $district: String,
    $address: String!,
    $address_number: Int!, 
    $address_complement: String,
    $zip_code: String!,
    $reference_point: String, 
    $latitude: String,
    $longitude: String,
    $bank_name: String,
    $bank_code: Int,
    $agency_number: String,
    $account_holder: String,
    $account_number: String,
    $account_type: Int,
    $farmerUnconformities: [FarmerUnconformitiesInput],
){
    
    createFarmer(
      name: $name,
      email: $email,
      msisdn: $msisdn,
      phone: $phone,
      group_offerer: $group_offerer,
      productive_unit_id: $productive_unit_id,
      group_id: $group_id,
      core_id: $core_id,
      contact: $contact,
      identity_document: $identity_document,
      legal_document: $legal_document,
      dap: $dap,
      mother_name: $mother_name,
      rural_block_registration: $rural_block_registration,
      certificateFile: $certificateFile,
      certificate_issue: $certificate_issue,
      certificate_expiration: $certificate_expiration,
      nickname: $nickname,
      login: $login,
      password: $password,
      birth: $birth,
      profilePicture: $profilePicture,
      country: $country,
      state: $state,
      city: $city,
      district: $district,
      address: $address,
      address_number: $address_number,
      address_complement: $address_complement,
      zip_code: $zip_code,
      reference_point: $reference_point,
      latitude: $latitude,
      longitude: $longitude,
      bank_name: $bank_name,
      bank_code: $bank_code,
      agency_number: $agency_number,
      account_holder: $account_holder,
      account_type: $account_type,
      account_number: $account_number,
      farmerUnconformities: $farmerUnconformities,
    ) {
      id
      user{
        id
        email
      }
    }
  }
`;

export const EDIT_FARMER = gql`

mutation editFarmerGeneral(
    $user_id: String!,
    $name: String,
    $email: String,
    $msisdn: String,
    $group_offerer: Boolean,
    $phone: String,
    $productive_unit_id: Int,
    $mother_name: String,
    $group_id: Int,
    $core_id: Int,
    $contact: String,
    $identity_document: String,
    $legal_document: String,
    $dap: String,
    $rural_block_registration: String,
    $certificateFile: Upload,
    $certificate_issue: String,
    $certificate_expiration: String,
    $nickname: String,
    $password: String,
    $birth: String,
    $profilePicture: Upload,
    $country: String,
    $state: String,
    $city: String,
    $district: String,
    $address: String,
    $address_number: Int, 
    $address_complement: String,
    $zip_code: String,
    $reference_point: String, 
    $latitude: String,
    $longitude: String,
    $bank_name: String,
    $bank_code: Int,
    $agency_number: String,
    $account_holder: String,
    $account_number: String,
    $account_type: Int,
    $farmerUnconformities: [FarmerUnconformitiesInput],
){
    
  editFarmerGeneral(
      user_id: $user_id,
      name: $name,
      email: $email,
      msisdn: $msisdn,
      phone: $phone,
      group_offerer: $group_offerer,
      productive_unit_id: $productive_unit_id,
      group_id: $group_id,
      core_id: $core_id,
      contact: $contact,
      identity_document: $identity_document,
      legal_document: $legal_document,
      dap: $dap,
      mother_name: $mother_name,
      rural_block_registration: $rural_block_registration,
      certificateFile: $certificateFile,
      certificate_issue: $certificate_issue,
      certificate_expiration: $certificate_expiration,
      nickname: $nickname,
      password: $password,
      birth: $birth,
      profilePicture: $profilePicture,
      country: $country,
      state: $state,
      city: $city,
      district: $district,
      address: $address,
      address_number: $address_number,
      address_complement: $address_complement,
      zip_code: $zip_code,
      reference_point: $reference_point,
      latitude: $latitude,
      longitude: $longitude,
      bank_name: $bank_name,
      bank_code: $bank_code,
      agency_number: $agency_number,
      account_holder: $account_holder,
      account_type: $account_type,
      account_number: $account_number,
      farmerUnconformities: $farmerUnconformities,
    ) {
      id
      user{
        id
        email
      }
    }
  }
`;

export const EDIT_FAMILY = gql`

mutation editFarmerStructureFamily(
    $user_id: String!,
    $number_children: Int,
    $marital_status: String!,
    $value_agricultural_income: Float,
    $external_income: Boolean,
    $retirement_income: Boolean,
    $social_program_income: Boolean,
    $social_program_description: String,
    $work_off_property: Boolean,
    $work_off_property_description: String,
    $other_external_incomes: Boolean,
    $other_external_incomes_description: String,
    $external_income_amount: Float,
    $percentage_income_from_agricultural_activity: Float,
    $family_members_working_production: Int,
    $family_members_dependent_property: Int,
    $family_members: String,
    $property_has_family_labor: Boolean,
    $property_has_clt_labor: Boolean,
    $property_has_daily_labor: Boolean,
    $property_has_partnership_labor: Boolean
){
    
  editFarmerStructureFamily(
    id: $user_id,
    number_children: $number_children,
    marital_status: $marital_status,
    value_agricultural_income: $value_agricultural_income,
    external_income: $external_income,
    retirement_income: $retirement_income,
    social_program_income: $social_program_income,
    social_program_description: $social_program_description,
    work_off_property: $work_off_property,
    work_off_property_description: $work_off_property_description,
    other_external_incomes: $other_external_incomes,
    other_external_incomes_description: $other_external_incomes_description,
    external_income_amount: $external_income_amount,
    percentage_income_from_agricultural_activity: $percentage_income_from_agricultural_activity,
    family_members_working_production: $family_members_working_production,
    family_members_dependent_property: $family_members_dependent_property,
    family_members: $family_members,
    property_has_family_labor: $property_has_family_labor,
    property_has_clt_labor: $property_has_clt_labor,
    property_has_daily_labor: $property_has_daily_labor,
    property_has_partnership_labor: $property_has_partnership_labor,

    ) {
      id
    }
  }
`;




export const EDIT_FARMER_PRODUCTION = gql`

mutation editFarmerProduction(
    $user_id: String!,
    $uses_plowing: Boolean,
    $uses_harrowing: Boolean,
    $uses_zero_tillage: Boolean,
    $soil_analysis_date: String,
    $uses_manure: Boolean,
    $amount_manure: String,
    $manure_supplier: String,
    $uses_compost: Boolean,
    $amount_compost: String,
    $compost_supplier: String,
    $uses_bio_syrup: Boolean,
    $amount_bio_syrup: String,
    $bio_syrup_supplier: String,
    $uses_rock_dust: Boolean,
    $amount_rock_dust: String,
    $rock_dust_supplier: String,
    $others_inputs: String,
    $uses_own_seeds: Boolean,
    $uses_acquired_seeds: Boolean,
    $amount_seeds: String,
    $seed_supplier: String,
    $has_climate_difficulty: Boolean,
    $has_input_difficulty: Boolean,
    $has_irrigation_difficulty: Boolean,
    $has_credit_difficulty: Boolean,
    $has_ater_difficulty: Boolean,
    $other_difficulties: String,
    $accessed_agricultural_credit: Boolean,
    $reason_not_access_credit: String,
    $costing_modality: Boolean,
    $agroecology_modality: Boolean,
    $woman_modality: Boolean,
    $echo_modality: Boolean,
    $semiarid_modality: Boolean,
    $young_modality: Boolean,
    $forest_modality: Boolean,
    $agrarian_reform_investiment_modality: Boolean,
    $agrarian_reform_costing_modality: Boolean,
    $more_aliments_modality: Boolean,
    $cattail_modality: Boolean,
    $other_modalities: String,
    $interest_in_credit: Boolean,
    $costing_purpose: Boolean,
    $amount_for_costing: Float,
    $investiment_purpose: Boolean,
    $amount_for_investiment: Float,
    $has_technical_support: Boolean,
    $description_technical_support: String,
    $uses_agroforestry_systems: Boolean,
    $uses_biodynamic: Boolean,
    $uses_cabruca: Boolean,
    $uses_traditional_cultivation: Boolean,
    $other_forms_management: String,
    $aggregates_value_primary_products: Boolean,
    $fruit_jelly: Boolean,
    $cassava_flour: Boolean,
    $fruit_pulp: Boolean,
    $cocoa_nibis: Boolean,
    $chocolate: Boolean,
    $has_others_aggregate_values: Boolean,
    $description_others_aggregate_values: String,
    $structure_to_processing: String,
  ){

    editFarmerProduction(
      user_id: $user_id,
      uses_plowing: $uses_plowing,
      uses_harrowing: $uses_harrowing,
      uses_zero_tillage: $uses_zero_tillage,
      soil_analysis_date: $soil_analysis_date,
      uses_manure: $uses_manure,
      amount_manure: $amount_manure,
      manure_supplier: $manure_supplier,
      uses_compost: $uses_compost,
      amount_compost: $amount_compost,
      compost_supplier: $compost_supplier,
      uses_bio_syrup: $uses_bio_syrup,
      amount_bio_syrup: $amount_bio_syrup,
      bio_syrup_supplier: $bio_syrup_supplier,
      uses_rock_dust: $uses_rock_dust,
      amount_rock_dust: $amount_rock_dust,
      rock_dust_supplier: $rock_dust_supplier,
      others_inputs: $others_inputs,
      uses_own_seeds: $uses_own_seeds,
      uses_acquired_seeds: $uses_acquired_seeds,
      amount_seeds: $amount_seeds,
      seed_supplier: $seed_supplier,
      has_climate_difficulty: $has_climate_difficulty,
      has_input_difficulty: $has_input_difficulty,
      has_irrigation_difficulty: $has_irrigation_difficulty,
      has_credit_difficulty: $has_credit_difficulty,
      has_ater_difficulty: $has_ater_difficulty,
      other_difficulties: $other_difficulties,
      accessed_agricultural_credit: $accessed_agricultural_credit,
      reason_not_access_credit: $reason_not_access_credit,
      costing_modality: $costing_modality,
      agroecology_modality: $agroecology_modality,
      woman_modality: $woman_modality,
      echo_modality: $echo_modality,
      semiarid_modality: $semiarid_modality,
      young_modality: $young_modality,
      forest_modality: $forest_modality,
      agrarian_reform_investiment_modality: $agrarian_reform_investiment_modality,
      agrarian_reform_costing_modality: $agrarian_reform_costing_modality,
      more_aliments_modality: $more_aliments_modality,
      cattail_modality: $cattail_modality,
      other_modalities: $other_modalities,
      interest_in_credit: $interest_in_credit,
      costing_purpose: $costing_purpose,
      amount_for_costing: $amount_for_costing,
      investiment_purpose: $investiment_purpose,
      amount_for_investiment: $amount_for_investiment,
      has_technical_support: $has_technical_support,
      description_technical_support: $description_technical_support,
      uses_agroforestry_systems: $uses_agroforestry_systems,
      uses_biodynamic: $uses_biodynamic,
      uses_cabruca: $uses_cabruca,
      uses_traditional_cultivation: $uses_traditional_cultivation,
      other_forms_management: $other_forms_management,
      aggregates_value_primary_products: $aggregates_value_primary_products,
      fruit_jelly: $fruit_jelly,
      cassava_flour: $cassava_flour,
      fruit_pulp: $fruit_pulp,
      cocoa_nibis: $cocoa_nibis,
      chocolate: $chocolate,
      has_others_aggregate_values: $has_others_aggregate_values,
      description_others_aggregate_values: $description_others_aggregate_values,
      structure_to_processing: $structure_to_processing
      ) {
        user_id
      }
    }
  `;


  export const EDIT_FARMER_PROPERTY = gql`

  mutation  editFarmerProperty(
    $id: Int,
    $user_id: String!,
    $name: String,
    $country: String,
    $state: String,
    $city: String,
    $address: String,
    $address_number: Int,
    $address_complement: String,
    $zip_code: String,
    $reference_point: String,
    $district: String,
    $latitude: String,
    $longitude: String,
    $land_owner: Boolean,
    $legal_right: String,
    $other_legal_right: String,
    $property_size: Float,
    $productive_area_size: Float,
    $production_area_size: Float,
    $agroindustrial_area: Float,
    $organic_area: Float,
    $farmers_quantity_by_unit: Int,
    $resides_in_property: Boolean,
    $local_residence: String,
    $certification: String,
    $has_house: Boolean,
    $has_deposit: Boolean,
    $has_corral: Boolean,
    $has_hennery: Boolean,
    $has_cocoa_barge: Boolean,
    $has_bathroom: Boolean,
    $has_dry_toilet: Boolean,
    $has_electricity: Boolean,
    $has_vegetable_garden: Boolean,
    $has_dike: Boolean,
    $has_seedling_nursery: Boolean,
    $has_cold_chamber: Boolean,
    $has_paileteria: Boolean,
    $has_caixaria: Boolean,
    $has_weigher: Boolean,
    $has_beneficiation_kitchen: Boolean,
    $others_improvements: String,
    $has_tractor: Boolean,
    $has_tobata: Boolean,
    $has_dehydrator: Boolean,
    $has_transport: Boolean,
    $has_irrigation: Boolean,
    $others_equipments: String,
    $has_drip_irrigation: Boolean,
    $has_sprinkler_irrigation: Boolean,
    $others_irrigation_type: String,
    $uses_own_water_mine: Boolean,
    $uses_cistern: Boolean,
    $uses_weir: Boolean,
    $uses_mine_off_property: Boolean,
    $uses_river_stream: Boolean,
    $uses_underground_water: Boolean,
    $uses_artesian: Boolean,
    $uses_circular: Boolean,
    $others_water_sources: String,
    $cubic_meters_used: Int
     
  ){
      
    editFarmerProperty(
      id: $id,
      user_id: $user_id,
      name: $name,
      country: $country,
      state: $state,
      city: $city,
      address: $address,
      address_number: $address_number,
      address_complement: $address_complement,
      zip_code: $zip_code,
      reference_point: $reference_point,
      district: $district,
      latitude: $latitude,
      longitude: $longitude,
      land_owner: $land_owner,
      legal_right: $legal_right,
      other_legal_right: $other_legal_right,
      property_size: $property_size,
      productive_area_size: $productive_area_size,
      production_area_size: $production_area_size,
      agroindustrial_area: $agroindustrial_area,
      organic_area: $organic_area,
      farmers_quantity_by_unit: $farmers_quantity_by_unit,
      resides_in_property: $resides_in_property,
      local_residence: $local_residence,
      certification: $certification,
      has_house: $has_house,
      has_deposit: $has_deposit,
      has_corral: $has_corral,
      has_hennery: $has_hennery,
      has_cocoa_barge: $has_cocoa_barge,
      has_bathroom: $has_bathroom,
      has_dry_toilet: $has_dry_toilet,
      has_electricity: $has_electricity,
      has_vegetable_garden: $has_vegetable_garden,
      has_dike: $has_dike,
      has_seedling_nursery: $has_seedling_nursery,
      has_cold_chamber: $has_cold_chamber,
      has_paileteria: $has_paileteria,
      has_caixaria: $has_caixaria,
      has_weigher: $has_weigher,
      has_beneficiation_kitchen: $has_beneficiation_kitchen,
      others_improvements: $others_improvements,
      has_tractor: $has_tractor,
      has_tobata: $has_tobata,
      has_dehydrator: $has_dehydrator,
      has_transport: $has_transport,
      has_irrigation: $has_irrigation,
      others_equipments: $others_equipments,
      has_drip_irrigation: $has_drip_irrigation,
      has_sprinkler_irrigation: $has_sprinkler_irrigation,
      others_irrigation_type: $others_irrigation_type,
      uses_own_water_mine: $uses_own_water_mine,
      uses_cistern: $uses_cistern,
      uses_weir: $uses_weir,
      uses_mine_off_property: $uses_mine_off_property,
      uses_river_stream: $uses_river_stream,
      uses_underground_water: $uses_underground_water,
      uses_artesian: $uses_artesian,
      uses_circular: $uses_circular,
      others_water_sources: $others_water_sources,
      cubic_meters_used: $cubic_meters_used,
  
      ) {
        id
        user_id
      }
    }
  `;

export const EDIT_FARMER_LOGISTIC = gql`

mutation editFarmerLogistic(
    $user_id: String!,
    $use_charter_transport: Boolean,
    $use_own_transport: Boolean,
    $other_transport: String,
    $bad_asphalt_road: Boolean,
    $good_asphalt_road: Boolean,
    $bad_dirt_road: Boolean,
    $good_dirt_road: Boolean,
    $road_passable_by_wagon: Boolean,
    $small_structure: Boolean,
    $average_structure: Boolean,
    $large_structure: Boolean,
    $other_structure: String,
    $weekly_frequency: Boolean,
    $biweekly_frequency: Boolean,
    $other_frequency: String,
    $countryside_sacks: Boolean,
    $countryside_boxes: Boolean,
    $countryside_others: String,
    $transport_sacks: Boolean,
    $transport_boxes: Boolean,
    $transport_others: String,
    $commercialization_sacks: Boolean,
    $commercialization_boxes: Boolean,
    $commercialization_pallets: Boolean,
    $commercialization_others: String,
){
    
  editFarmerLogistic(
    user_id: $user_id,
    use_charter_transport: $use_charter_transport,
    use_own_transport: $use_own_transport,
    other_transport: $other_transport,
    bad_asphalt_road: $bad_asphalt_road,
    good_asphalt_road: $good_asphalt_road,
    bad_dirt_road: $bad_dirt_road,
    good_dirt_road: $good_dirt_road,
    road_passable_by_wagon: $road_passable_by_wagon,
    small_structure: $small_structure,
    average_structure: $average_structure,
    large_structure: $large_structure,
    other_structure: $other_structure,
    weekly_frequency: $weekly_frequency,
    biweekly_frequency: $biweekly_frequency,
    other_frequency: $other_frequency,
    countryside_sacks: $countryside_sacks,
    countryside_boxes: $countryside_boxes,
    countryside_others: $countryside_others,
    transport_sacks: $transport_sacks,
    transport_boxes: $transport_boxes,
    transport_others: $transport_others,
    commercialization_sacks: $commercialization_sacks,
    commercialization_boxes: $commercialization_boxes,
    commercialization_pallets: $commercialization_pallets,
    commercialization_others: $commercialization_others,

    ) {
      user_id
    }
  }
`;

export const EDIT_FARMER_COMMERCIALIZATION = gql`

mutation  editFarmerCommercialization(
    $user_id: String!,
    $organic_commercialization_percentage: Float,
    $conventional_commercialization_percentage: Float,
    $baskets_in_circuit: Boolean,
    $fair_in_circuit: Boolean,
    $station_in_circuit: Boolean,
    $long_circuit_in_circuit: Boolean,
    $baskets_out_circuit: Boolean,
    $institutional_sales_out_circuit: Boolean,
    $public_market_out_circuit: Boolean,
    $company_out_circuit: Boolean,
    $conventional_fairs_out_circuit: Boolean,
    $others_out_circuit: Boolean,
    $description_others_out_circuit: String,
    $has_packaging: String,
    $tray_type_packing: String,
    $net_type_packing: String,
    $little_bag_type_packing: String,
    $bunch_type_packing: String,
    $others_type_packing: String,
    $description_others_type_packing: String,
    $has_packaging_label: String
){
    
  editFarmerCommercialization(
    user_id: $user_id,
    organic_commercialization_percentage: $organic_commercialization_percentage,
    conventional_commercialization_percentage: $conventional_commercialization_percentage,
    baskets_in_circuit: $baskets_in_circuit,
    fair_in_circuit: $fair_in_circuit,
    station_in_circuit: $station_in_circuit,
    long_circuit_in_circuit: $long_circuit_in_circuit,
    baskets_out_circuit: $baskets_out_circuit,
    institutional_sales_out_circuit: $institutional_sales_out_circuit,
    public_market_out_circuit: $public_market_out_circuit,
    company_out_circuit: $company_out_circuit,
    conventional_fairs_out_circuit: $conventional_fairs_out_circuit,
    others_out_circuit: $others_out_circuit,
    description_others_out_circuit: $description_others_out_circuit,
    has_packaging: $has_packaging,
    tray_type_packing: $tray_type_packing,
    net_type_packing: $net_type_packing,
    little_bag_type_packing: $little_bag_type_packing,
    bunch_type_packing: $bunch_type_packing,
    others_type_packing: $others_type_packing,
    description_others_type_packing: $description_others_type_packing,
    has_packaging_label: $has_packaging_label,

    ) {
      user_id
    }
  }
`;

export const EDIT_FARMER_CACAU = gql`

mutation  editFarmerCocoa(
    $user_id: String!,
    $cocoa_area_size: Float,
    $amount_cocoa_tree: Int,
    $unknow_amount_cocoa_tree: Boolean,
    $need_replanting: String,
    $production_volume_year: Int,
    $production_volume_at: Int,
    $cabruca_production_system: Boolean,
    $full_sun_production_system: Boolean,
    $saf_production_system: Boolean,
    $others_production_system: Boolean,
    $description_others_production_system: String,
    $produces_cocoa_commodity: Boolean,
    $produces_cocoa_superior: Boolean,
    $produces_cocoa_fine: Boolean,
    $percentage_superior_fine: Float,
    $cultivates_common_variety: Boolean,
    $cultivates_cloned_variety: Boolean,
    $cultivates_mixed_variety: Boolean,
    $harvest_management_beneficiament: String,
    $fermentation_beneficiament: String,
    $drying_management_beneficiament: String,
    $warehouse_storage: Boolean,
    $house_storage: Boolean,
    $specific_depository_storage: Boolean,
    $common_depository_storage: Boolean,
    $other_storage: Boolean,
    $description_other_storage: String,
    $storage_time_in_months: Int,
    $storage_time_in_days: Int,
    $nylon_sacking: Boolean,
    $aligning_sacking: Boolean,
    $other_sacking: Boolean,
    $description_other_sacking: String,
    $participate_association_cooperative: Boolean,
    $description_association_cooperative: String,
    $sells_to_industry: Boolean,
    $sells_to_cooperative: Boolean,
    $sells_to_intermediate: Boolean,
    $sells_to_consumer: Boolean,
    $transport_difficulty: Boolean,
    $low_price_difficulty: Boolean,
    $storage_difficulty: Boolean,
    $quality_difficulty: Boolean,
    $other_difficulty: Boolean,
    $description_other_difficulty: String,
    $marketed_percentage_almonds: Float,
    $marketed_percentage_nibs: Float,
    $marketed_percentage_liquor: Float,
    $marketed_percentage_chocolate: Float,
    $marketed_percentage_cocoa_candy: Float,
    $marketed_percentage_soft_cocoa: Float,
    $sells_cocoa_pulp: Boolean,
    $amount_cocoa_pulp: Int,
    $sells_fruit_gourd: Boolean,
    $amount_fruit_gourd: Int,
    $sells_cocoa_honey: Boolean,
    $amount_cocoa_honey: Int,
){
    
  editFarmerCocoa(
    user_id: $user_id,
    cocoa_area_size: $cocoa_area_size,
    amount_cocoa_tree: $amount_cocoa_tree,
    unknow_amount_cocoa_tree: $unknow_amount_cocoa_tree,
    need_replanting: $need_replanting,
    production_volume_year: $production_volume_year,
    production_volume_at: $production_volume_at,
    cabruca_production_system: $cabruca_production_system,
    full_sun_production_system: $full_sun_production_system,
    saf_production_system: $saf_production_system,
    others_production_system: $others_production_system,
    description_others_production_system: $description_others_production_system,
    produces_cocoa_commodity: $produces_cocoa_commodity,
    produces_cocoa_superior: $produces_cocoa_superior,
    produces_cocoa_fine: $produces_cocoa_fine,
    percentage_superior_fine: $percentage_superior_fine,
    cultivates_common_variety: $cultivates_common_variety,
    cultivates_cloned_variety: $cultivates_cloned_variety,
    cultivates_mixed_variety: $cultivates_mixed_variety,
    harvest_management_beneficiament: $harvest_management_beneficiament,
    fermentation_beneficiament: $fermentation_beneficiament,
    drying_management_beneficiament: $drying_management_beneficiament,
    warehouse_storage: $warehouse_storage,
    house_storage: $house_storage,
    specific_depository_storage: $specific_depository_storage,
    common_depository_storage: $common_depository_storage,
    other_storage: $other_storage,
    description_other_storage: $description_other_storage,
    storage_time_in_months: $storage_time_in_months,
    storage_time_in_days: $storage_time_in_days,
    nylon_sacking: $nylon_sacking,
    aligning_sacking: $aligning_sacking,
    other_sacking: $other_sacking,
    description_other_sacking: $description_other_sacking,
    participate_association_cooperative: $participate_association_cooperative,
    description_association_cooperative: $description_association_cooperative,
    sells_to_industry: $sells_to_industry,
    sells_to_cooperative: $sells_to_cooperative,
    sells_to_intermediate: $sells_to_intermediate,
    sells_to_consumer: $sells_to_consumer,
    transport_difficulty: $transport_difficulty,
    low_price_difficulty: $low_price_difficulty,
    storage_difficulty: $storage_difficulty,
    quality_difficulty: $quality_difficulty,
    other_difficulty: $other_difficulty,
    description_other_difficulty: $description_other_difficulty,
    marketed_percentage_almonds: $marketed_percentage_almonds,
    marketed_percentage_nibs: $marketed_percentage_nibs,
    marketed_percentage_liquor: $marketed_percentage_liquor,
    marketed_percentage_chocolate: $marketed_percentage_chocolate,
    marketed_percentage_cocoa_candy: $marketed_percentage_cocoa_candy,
    marketed_percentage_soft_cocoa: $marketed_percentage_soft_cocoa,
    sells_cocoa_pulp: $sells_cocoa_pulp,
    amount_cocoa_pulp: $amount_cocoa_pulp,
    sells_fruit_gourd: $sells_fruit_gourd,
    amount_fruit_gourd: $amount_fruit_gourd,
    sells_cocoa_honey: $sells_cocoa_honey,
    amount_cocoa_honey: $amount_cocoa_honey,

    ) {
      user_id
    }
  }
`;


export const EDIT_FARMER_PRODUCTS = gql`

mutation  editFarmerProduct(
    $user_id: String!,
    $farmer_product_id: Int,
    $product_id: Int!,
    $measure_unit_id: String,
    $amount_units_for_kilo: Float,
    $week_production_quantity: Int,
    $sale_quantity_for_unit: Int,
    $week_sales_value: Float,
    $week_production_capacity: Int,
    $produces_in_january: Boolean,
    $produces_in_february: Boolean,
    $produces_in_march: Boolean,
    $produces_in_april: Boolean,
    $produces_in_may: Boolean,
    $produces_in_june: Boolean,
    $produces_in_july: Boolean,
    $produces_in_august: Boolean,
    $produces_in_september: Boolean,
    $produces_in_october: Boolean,
    $produces_in_november: Boolean,
    $produces_in_december: Boolean,
){
    
  editFarmerProduct(
    user_id: $user_id,
    farmer_product_id: $farmer_product_id,
    product_id: $product_id,
    measure_unit_id: $measure_unit_id,
    amount_units_for_kilo: $amount_units_for_kilo,
    week_production_quantity: $week_production_quantity,
    sale_quantity_for_unit: $sale_quantity_for_unit,
    week_sales_value: $week_sales_value,
    week_production_capacity: $week_production_capacity,
    produces_in_january: $produces_in_january,
    produces_in_february: $produces_in_february,
    produces_in_march: $produces_in_march,
    produces_in_april: $produces_in_april,
    produces_in_may: $produces_in_may,
    produces_in_june: $produces_in_june,
    produces_in_july: $produces_in_july,
    produces_in_august: $produces_in_august,
    produces_in_september: $produces_in_september,
    produces_in_october: $produces_in_october,
    produces_in_november: $produces_in_november,
    produces_in_december: $produces_in_december,

    ) {
      user_id
    }
  }
`;

export const DELETE_USER = gql`

mutation  deleteFarmer(
  $user_id: String!,
){
  deleteFarmer(user_id: $user_id)
}`;

export const DELETE_FARMER_PRODUCT = gql`

mutation  deleteFarmerProduct(
  $farmer_product_id: Int!,
){
  deleteFarmerProduct(farmer_product_id: $farmer_product_id)
}`;


