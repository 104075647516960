import gql from 'graphql-tag';

export const TIPOS_MANEJO = gql`
    query ManagementTypeQuery{
        ManagementTypeQuery{
          management_type_id
          management_type
        }
    }
`;
export const OWN_RATION_INGREDIENT = gql`
    query ManagementQuery($management_id: Int!){ 
      ManagementQuery(management_id: $management_id){
        managementAnimalProduction {    
          managementOwnRationIngredient {
            management_id
            ration_ingredient_id
            ration_ingredient_name
            management_description
          }
        }
      }
    }
`;

export const RATION_COMPOSITION = gql`
    query ManagementQuery($management_id: Int!){ 
      ManagementQuery(management_id: $management_id){
        managementAnimalProduction {    
          managementRationComposition {
            management_id
            ration_ingredient_id
            ration_ingredient_name
            own_production
            buy_out
            organic
            non_organic
          }
        }
      }
    }
`;
export const ANIMAL_FEEDING_MEDICATION = gql`
    query ManagementQuery($management_id: Int!){ 
      ManagementQuery(management_id: $management_id){
        managementAnimalProduction {    
          managementAnimalFeedingMedication {
            management_id
            animal_category_id
            animal_category
            ecological_feeding
            conventional_feeding
            ecological_medication
            conventional_medication
          }
        }
      }
    }
`;
export const FEEDING_PLAN = gql`
    query ManagementQuery($management_id: Int!){ 
      ManagementQuery(management_id: $management_id){
        managementAnimalProduction {    
          managementAnnualFeedingPlan {
            management_id
            feeding_category_id
            feeding_category
            january
            february
            march
            april
            may
            june
            july
            august
            september
            october
            november
            december
          }
        }
      }
    }
`;

export const PEST_CONTROL = gql`
    query ManagementQuery($management_id: Int!){ 
      ManagementQuery(management_id: $management_id){
        managementVegetableProduction {
          managementPestDiseaseControl {
            pest_disease_id
            pest_disease_name
            management_id
            used_product_or_management
            used_where
            used_when
            origin
            composition
          }
        }
      }
    }
`;

export const EXTRACTIVISM_SPECIES = gql`
    query ManagementQuery($management_id: Int!){ 
      ManagementQuery(management_id: $management_id){
        managementExtractivism {
          managementExtractivismSpecies {
            management_id
            specie_id
            specie
            description
            individuals_number
            collected_part
            collected_amount
            collection_epoch
            minimum_age
            circumference
            interval_collections
            leaves_part_to_multiply
          }
        }
      }
    }
`;

export const MANEJO_DEPENDENCIAS = gql`
    query ManagementQuery($management_id: Int!){ 
      ManagementQuery(management_id: $management_id){
        managementProductiveUnit {
          managementDependency {
            management_id
            own_pasture
            rent_pasture
            own_concentrate
            rent_concentrate
            own_minerals_vitamins
            rent_minerals_vitamins
            own_hay_silage
            rent_hay_silage
            own_grass
            rent_grass
            own_grains
            rent_grains
            own_crop_remains
            rent_crop_remains
            own_others
            rent_others
            others_description
          }
        }
      }
    }
`;

export const OUTSIDE_PRODUCTION = gql`
    query ManagementQuery($management_id: Int!){ 
      ManagementQuery(management_id: $management_id){
        managementProductiveUnit {
          managementOutsideProductionInput{
            management_id
            product_id
            local_name
            measure_unit_id
            measure_unit_description
            annual_amount
          }
        }
      }
    }
`;

export const PRODUCT_MANIPULATION = gql`
    query ManagementQuery($management_id: Int!){ 
      ManagementQuery(management_id: $management_id){
        managementProductiveUnit {
          managementProductManipulation {
            management_id
            product_id
            manipulation
            storage
            transport
            local_name
            comercial_name
          }          
        }
      }
    }
`;

export const PRODUCTION_ESTIMATE = gql`
    query ManagementQuery($management_id: Int!){ 
      ManagementQuery(management_id: $management_id){
        managementProductiveUnit {
          managementProductionEstimate {
            management_id
            product_category_id
            product_category
            product_id
            local_name
            comercial_name
            amount
            measure_unit_id
            measure_unit_description
            periodicity_id
            periodicity_description
          }
        }
      }
    }
`;

export const PRODUCTION_SUMMARY = gql`
    query ManagementQuery($management_id: Int!){ 
      ManagementQuery(management_id: $management_id){
        managementProductionSummary {
          management_id
          glebe
          year
          semester
          specie_id
          specie
          management_type_id
          management_type
        }
      }
    }
`;



export const VARIAVEIS_QUERY = gql`
    query VariaveisQuery{ 
      RationIngredientQuery{
        ration_ingredient_id
        ration_ingredient_name
        show_composition_editing
        picture
        urlPicture
      }
      AnimalCategoryQuery{
        animal_category_id
        animal_category
      }
      FeedingCategoryQuery{
        feeding_category_id
        feeding_category
      }
      PlantSpecie{
        specie_id
        specie
        description
      }
      # ProductionInputQuery{
      #   production_input_id
      #   production_input_name
      #   picture
      #   urlPicture
      # }
      MeasureUnitsQuery{
        measure_unit_id
        measure_unit_description
      }
      InsumosQuery: ProductQuery(product_category_id: 7){
        product_id
        local_name
      }
      ProductQuery{
        product_id
        local_name
      }
      PeriodicityQuery{
        periodicity_id
        periodicity_description
      }
    }
    `;