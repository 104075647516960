import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withApollo, graphql, Query } from 'react-apollo';
import { PEDIDO_FORNECEDOR } from '../../services/graphql/querys/pedido';
import { CONFERE_PEDIDO_FORNECEDOR } from '../../services/graphql/mutations/pedido';
import CustomErrors from '../CustomErrors/CustomErrors';

import {
    TextField,
    Grid,
    MenuItem,
    Fab,
    FormControlLabel,
    Checkbox,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    DialogTitle,
    Dialog,
    List,
    ListItem,
} from '@material-ui/core';

import MaskedInput from 'react-text-mask';
import { Save, Edit, Add } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '30%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    },
    image: {
        width: '3rem',
        height: '3rem'
    }

});

class ConferirPedido extends Component {
    constructor(props){
        super(props);
        this.state = {
            round_id: this.props.id_rodada,
            sales_order_id: this.props.id_pedido,
            saleOrder: null,
            items: null,
            error: [],
        }
    }
    componentWillMount(){
        this.props.client.query({query: PEDIDO_FORNECEDOR, variables: {id_pedido: this.state.sales_order_id, id: this.state.round_id}, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("DATA: ", data.CommercialRoundsQuery.data[0].sales_orders[0])
               
                let items = [];
                data.CommercialRoundsQuery.data[0].sales_orders[0].items.forEach(item => {
                    items[item.id] = {sales_order_product_id: item.id, delivered: false, loss: 0};
                });
                this.setState({saleOrder: data.CommercialRoundsQuery.data[0].sales_orders[0], items: items})
            }
        })
    }
        

    updateItem(id, field, value) {
        let itemsUpdated = this.state.items;
        if(field == 'loss'){
            itemsUpdated[id].loss = value;
        }else{
            itemsUpdated[id].delivered = value;
        }
        this.setState({items: itemsUpdated});
    }
    peso = (id, field, value) => {
        let itemsUpdated = this.state.items;
		let v = value,
			integer = v.split(',')[0];


		v = v.replace(/\D/, "");
		v = v.replace(/^[0]+/, "");

		if(v.length <= 1 || !integer)
		{
            console.log(v)
			if(v.length === 1) v = '0.' + v;
			//if(v.length === 2) v = '0.0' + v;
			//if(v.length === 3) v = '0.' + v;
			//if(v.length === 4) v = '0.' + v;
		} else {
            v = v.replace(/^(\d{1,})(\d{1})$/, "$1.$2");
            console.log(v)
        }
        if(field == 'loss'){
            itemsUpdated[id].loss = v;
        }else{
            itemsUpdated[id].delivered = v;
        }
        this.setState({items: itemsUpdated});
        // if(parseFloat(v) < this.state.produto.available_quantity){
        //     this.setState({value:parseFloat(v).toFixed(1)});
        // } else {
        //     this.setState({value:this.state.produto.available_quantity.toFixed(1)});
        // }
	}

    submitIt(){
       
        let x = 0;
        let newItems = [];
        this.state.items.forEach(item => {
           newItems[x] = item;
           x++
        });
        let paramsEnviar = {sales_order_id: this.state.sales_order_id, items: newItems};

        console.log("Ehh", paramsEnviar)

        this.props.client.mutate({mutation: CONFERE_PEDIDO_FORNECEDOR, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            } else {
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");
                window.location.href = "/rodada/pedidoFarmer/" + this.props.id_rodada;
            }
        })



    }

    render() {
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <Grid className={this.props.classes.root} item xl={12}>
                    <TableContainer component={Paper}>
                        <Table className={this.props.classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Produto</TableCell>
                                    <TableCell align="center">Nome</TableCell>
                                    <TableCell align="center">KG's Pedido</TableCell>
                                    <TableCell align="center">Perda KG's</TableCell>
                                    <TableCell align="center">Entregue</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {!!this.state.items && !!this.state.saleOrder && this.state.saleOrder.items.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell >
                                        <img src={row.product.picture ? row.product.urlPicture : "https://crestana.com.br/img/imagens_produto/20190726_200300_1____01%20PRODUTO-SEM-IMAGEM-1000X1000.JPG"} className={this.props.classes.image} />
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">{row.product.comercial_name}</TableCell>
                                    <TableCell align="center" component="th" scope="row">{row.quantity.toFixed(1).replace(".",",")}Kg</TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        <TextField  className={this.props.classes.textField} 
                                                    label="Perda" 
                                                    type="number"
                                                    InputLabelProps={{shrink: true,}} 
                                                    onChange={(e) => this.peso(row.id, 'loss', e.target.value)} 
                                                    value={this.state.items[row.id].loss} 
                                                    variant="outlined" 
                                                    margin="dense" />
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.items[row.id].delivered}
                                                    onChange={(e) =>  this.updateItem(row.id, 'delivered', e.target.checked)} 
                                                    color="primary"
                                                />
                                            }
                                            className={this.props.classes.ContentCheckBox}
                                            labelPlacement="bottom"
                                            label="Entregue"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        <Grid className={this.props.classes.root} align="center" item xl={5} >
                                <Button variant="contained" color="primary" disableElevation className={this.props.classes.button} onClick={() => this.submitIt()}>
                                    <Add /> Verificar Pedido
                                </Button>
                            </Grid>
                    </TableContainer>
                </Grid>
            </div>
        )
    }
}
export default withApollo(withStyles(styles)(ConferirPedido));