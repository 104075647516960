import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { EDIT_PRODUCTIVE_UNIT } from '../../../services/graphql/mutations/management';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';
import MaskedInput from 'react-text-mask';
import gql from 'graphql-tag';

import {
    TextField,
    Grid,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    MenuItem,
} from '@material-ui/core';
import { Add, Print, Save, Delete } from '@material-ui/icons';


const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    input: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },  
    radioClass: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },    
    divisor: {
        width: "100%",
        height: "3px",
        backgroundColor: "#000",
        marginTop: "3px",
        marginBottom: "10px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});



class CadProdUnit extends Component { 
    constructor(props) {
        super(props);
        if(!!this.props.manejo.manejo.managementProductiveUnit && this.props.manejo.manejo.managementProductiveUnit != null){
            let manejo = this.props.manejo.manejo;
            this.state = {
                management_id: manejo.managementProductiveUnit.management_id,
                native_vegetation_area: manejo.managementProductiveUnit.native_vegetation_area,
                rivers_protection: manejo.managementProductiveUnit.rivers_protection,
                native_vegetation_recover: manejo.managementProductiveUnit.native_vegetation_recover,
                uses_irrigation: manejo.managementProductiveUnit.uses_irrigation,
                irrigation_waters_origin: manejo.managementProductiveUnit.irrigation_waters_origin,
                dry_garbage_treatment: manejo.managementProductiveUnit.dry_garbage_treatment,
                liquid_effluents_treatment: manejo.managementProductiveUnit.liquid_effluents_treatment,
                who_works: manejo.managementProductiveUnit.who_works,
                working_conditions: manejo.managementProductiveUnit.working_conditions,
                accidents_work_responability: manejo.managementProductiveUnit.accidents_work_responability,
                school_attendance: manejo.managementProductiveUnit.school_attendance,
                compromises_school_performance: manejo.managementProductiveUnit.compromises_school_performance,
                has_stored_seeds: manejo.managementProductiveUnit.has_stored_seeds,
                has_stored_manure: manejo.managementProductiveUnit.has_stored_manure,
                has_stored_mineral_fertilizers: manejo.managementProductiveUnit.has_stored_mineral_fertilizers,
                has_stored_limestone: manejo.managementProductiveUnit.has_stored_limestone,
                has_stored_biofertilizers_salts: manejo.managementProductiveUnit.has_stored_biofertilizers_salts,
                has_stored_animal_medicines: manejo.managementProductiveUnit.has_stored_animal_medicines,
                has_stored_mineral_salt: manejo.managementProductiveUnit.has_stored_mineral_salt,
                has_stored_others: manejo.managementProductiveUnit.has_stored_others,
                description_other_stored: manejo.managementProductiveUnit.description_other_stored,
                commercialization_channels: manejo.managementProductiveUnit.commercialization_channels,
                commercialization_other_questions: manejo.managementProductiveUnit.commercialization_other_questions,
            }
        }else{
            this.state = {
                management_id: this.props.manejo.manejo.management_id,
                native_vegetation_area: null,
                rivers_protection: null,
                native_vegetation_recover: null,
                uses_irrigation: null,
                irrigation_waters_origin: null,
                dry_garbage_treatment: null,
                liquid_effluents_treatment: null,
                who_works: null,
                working_conditions: null,
                accidents_work_responability: null,
                school_attendance: null,
                compromises_school_performance: null,
                has_stored_seeds: null,
                has_stored_manure: null,
                has_stored_mineral_fertilizers: null,
                has_stored_limestone: null,
                has_stored_biofertilizers_salts: null,
                has_stored_animal_medicines: null,
                has_stored_mineral_salt: null,
                has_stored_others: null,
                description_other_stored: null,
                commercialization_channels: null,
                commercialization_other_questions: null,
            };
        }
    } 

    submitIt(){
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);
        this.props.client.mutate({mutation: EDIT_PRODUCTIVE_UNIT, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
                
                

            } else {
                console.log("DATA: ", data);
                alert("Salvo com sucesso !");
            }
        })
    }

   
    render() {
        // console.log("state", this.state)
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <div style={{paddingTop: "10px"}}>
                    <h4>CADASTRO UNIDADE PRODUTIVA {this.props.manejo.manejo.description}</h4>
                    <hr style={css.hr}/>
                    <div style={css.contentColuns}>
                        <div style={css.col4}>
                            <sub>Quanto da área de unidade de produção é coberta por vegetação native ? (em %)</sub>
                                <TextField 
                                    id="area" 
                                    type="number"
                                    label="" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({native_vegetation_area: e.target.value})} 
                                    value={this.state.native_vegetation_area}
                                    style={css.input}
                                />
                            <sub>Como os rios, córregos e nascentes estão protegidas ?</sub>
                                <TextField 
                                    id="rios" 
                                    label="" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({rivers_protection: e.target.value})} 
                                    value={this.state.rivers_protection}
                                    style={css.input}
                                />
                            <sub>Qual o plano de recuperação da vegetação nativa do entorno das fontes e nascentes, rios e córregos ?</sub>
                                <TextField 
                                    id="recuperacao" 
                                    label="" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({native_vegetation_recover: e.target.value})} 
                                    value={this.state.native_vegetation_recover}
                                    style={css.input}
                                />     
                            <sub>A unidade de produção utiliza irrigação ?</sub>
                                <TextField 
                                    id="irrigacao" 
                                    label="" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({uses_irrigation: e.target.value})} 
                                    value={this.state.uses_irrigation}
                                    style={css.input}
                                />     
                            <sub>Qual a origem das águas utilizadas para irrigação ?</sub>
                                <TextField 
                                    id="origem_irrigacao" 
                                    label="" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({irrigation_waters_origin: e.target.value})} 
                                    value={this.state.irrigation_waters_origin}
                                    style={css.input}
                                />           
                            <sub>Qual o tratamento/destino do lixo seco produzido na unidade de produção ?</sub>
                                <TextField 
                                    id="tratamento" 
                                    label="" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({dry_garbage_treatment: e.target.value})} 
                                    value={this.state.dry_garbage_treatment}
                                    style={css.input}
                                />     
                            <sub>Qual o tratamento/destino dos efluentes liquidos (esgoto de cozinha e do banheiro) na unidade de produção?</sub>
                                <TextField 
                                    id="tratamento_efluentes" 
                                    label="" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({liquid_effluents_treatment: e.target.value})} 
                                    value={this.state.liquid_effluents_treatment}
                                    style={css.input}
                                />     
                            <sub>Quem trabalha nas atividades da unidade de produção?</sub>
                                <TextField 
                                    id="quem_trabalha" 
                                    label="" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({who_works: e.target.value})} 
                                    value={this.state.who_works}
                                    style={css.input}
                                />          
                            <sub>No caso de contratação de mão-de-obra, os trabalhadores possuem boas condições de trabalho? Estes são remunerados de forma adequada ?</sub>
                                <TextField 
                                    id="maodeobra" 
                                    label="" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({working_conditions: e.target.value})} 
                                    value={this.state.working_conditions}
                                    style={css.input}
                                />   
                            <sub>No caso de acidentes de trabalho, de que forma a familia se responsabiliza oelo trabalhador ?</sub>
                                <TextField 
                                    id="respons" 
                                    label="" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({accidents_work_responability: e.target.value})} 
                                    value={this.state.accidents_work_responability}
                                    style={css.input}
                                />   
                            <sub>As crianças e adolecentes frequentam regularmente a escola ?</sub>
                                <TextField 
                                    id="escola" 
                                    label="" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({school_attendance: e.target.value})} 
                                    value={this.state.school_attendance}
                                    style={css.input}
                                />     
                            <sub>O trabalho na unidade de produção compromete de alguma forma o desempenho e a permanência destes na escola ?</sub>
                                <TextField 
                                    id="escola_perform" 
                                    label="" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({compromises_school_performance: e.target.value})} 
                                    value={this.state.compromises_school_performance}
                                    style={css.input}
                                /> 
                            <sub>Liste os principais canais de comercialização</sub>
                                <TextField 
                                    id="comerc" 
                                    label="" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({commercialization_channels: e.target.value})} 
                                    value={this.state.commercialization_channels}
                                    style={css.input}
                                /> 
                            <sub>Outras questões em relação a comercialização que julga importante.</sub>
                                <TextField 
                                    id="escola_perform" 
                                    label="" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({commercialization_other_questions: e.target.value})} 
                                    value={this.state.commercialization_other_questions}
                                    style={css.input}
                                /> 
                                 <hr style={css.hr} />
                                <div style={css.col4}>
                                    <sub>Insumos, máquinas e ferramentas estocados/utilizados na Unidade de produção</sub>
                                </div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.has_stored_seeds}
                                        onChange={(e) => this.setState({has_stored_seeds: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Sementes"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.has_stored_manure}
                                        onChange={(e) => this.setState({has_stored_manure: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Estercos"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state. has_stored_mineral_fertilizers}
                                        onChange={(e) => this.setState({ has_stored_mineral_fertilizers: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Adubos Minerais"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.has_stored_limestone}
                                        onChange={(e) => this.setState({has_stored_limestone: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Calcário"/>       
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.has_stored_biofertilizers_salts}
                                        onChange={(e) => this.setState({has_stored_biofertilizers_salts: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Sais para Biofertilizantes"/>      
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.has_stored_animal_medicines}
                                        onChange={(e) => this.setState({has_stored_animal_medicines: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Medicamentos para animais"/>     
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.has_stored_mineral_salt}
                                        onChange={(e) => this.setState({has_stored_mineral_salt: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Sal Mineral"/>        
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.has_stored_others}
                                        onChange={(e) => this.setState({has_stored_others: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Outros"/>   

                                <TextField 
                                    id="outros_quais" 
                                    label="Qual (is)?" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({description_other_stored: e.target.value})} 
                                    value={this.state.description_other_stored}
                                    // style={css.input}
                                /> 
                            
                        </div>
                    </div>       
                    <div style={css.contentColuns}>
                        <Fab variant="extended" color="primary" onClick={() => this.submitIt()}>
                            <Save />
                            Criar
                        </Fab>
                    </div>
                </div>             
                <hr style={css.hr} />
            </div>
        );
    }
}
export default withApollo((withStyles(styles))(CadProdUnit));

const css = {
    contentColuns: {
        display:"flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    col1: {
        maxWidth: "25%",
        minWidth: "25%",
        flex: 1
    },
    col2: {
        maxWidth: "50%",
        minWidth: "50%",
        flex: 1
    },
    col3: {
        maxWidth: "75%",
        minWidth: "75%",
        flex: 1
    },
    col4: {
        maxWidth: "100%",
        minWidth: "100%",
        flex: 1
    },
    input: {
       flex: 1,
       display: "flex",
       marginRight: 15
    },
    hr: {
        marginTop: "20px",
        marginBottom: "20px"
    }
}