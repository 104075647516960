import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { EDIT_EXTRACTIVISM, EDIT_EXTRACTIVISM_SPECIE, DELETE_EXTRACTIVISM_SPECIE } from '../../../services/graphql/mutations/management';
import { EXTRACTIVISM_SPECIES, VARIAVEIS_QUERY } from '../../../services/graphql/querys/manejo';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';
import MaskedInput from 'react-text-mask';
import gql from 'graphql-tag';

import {
    TextField,
    Grid,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    MenuItem,
} from '@material-ui/core';
import { Add, Print, Save, Delete } from '@material-ui/icons';


const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    input: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },  
    radioClass: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },    
    divisor: {
        width: "100%",
        height: "3px",
        backgroundColor: "#000",
        marginTop: "3px",
        marginBottom: "10px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});



class CadExtrativismo extends Component { 
    constructor(props) {
        super(props);
        if(!!this.props.manejo.manejo.managementExtractivism && this.props.manejo.manejo.managementExtractivism != null){
            let manejo = this.props.manejo.manejo;
            this.state = {
                management_id: manejo.managementExtractivism.management_id,
                area_federal_conservation_unit: manejo.managementExtractivism.area_federal_conservation_unit,
                area_state_conservation_unit: manejo.managementExtractivism.area_state_conservation_unit,
                area_rural_settlement_project: manejo.managementExtractivism.area_rural_settlement_project,
                area_quilombola_territory: manejo.managementExtractivism.area_quilombola_territory,
                area_indigenous_land: manejo.managementExtractivism.area_indigenous_land,
                area_other: manejo.managementExtractivism.area_other,
                area_other_description: manejo.managementExtractivism.area_other_description,
                area_federal_description: manejo.managementExtractivism.area_federal_description,
                area_state_description: manejo.managementExtractivism.area_state_description,
                situation_tenure: manejo.managementExtractivism.situation_tenure,
                situation_definitive_title: manejo.managementExtractivism.situation_definitive_title,
                situation_settlement: manejo.managementExtractivism.situation_settlement,
                situation_granting_use_rights: manejo.managementExtractivism.situation_granting_use_rights,
                situation_other: manejo.managementExtractivism.situation_other,
                situation_other_description: manejo.managementExtractivism.situation_other_description,
            }
        }else{
            this.state = {
                management_id: this.props.manejo.manejo.management_id,
                area_federal_conservation_unit: null,
                area_state_conservation_unit: null,
                area_rural_settlement_project: null,
                area_quilombola_territory: null,
                area_indigenous_land: null,
                area_other: null,
                area_other_description: null,
                area_federal_description: null,
                area_state_description: null,
                situation_tenure: null,
                situation_definitive_title: null,
                situation_settlement: null,
                situation_granting_use_rights: null,
                situation_other: null,
                situation_other_description: null,
            };
        }
    } 
    componentDidMount(){
        this.props.client.query({query: VARIAVEIS_QUERY, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("Data Ingred: ", data)
                let tiposPlanta = data.PlantSpecie;
                this.setState({tiposPlanta: tiposPlanta })
            }
        })
    }
    submitIt(){
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);
        this.props.client.mutate({mutation: EDIT_EXTRACTIVISM, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
                
                

            } else {
                console.log("DATA: ", data);
                alert("Salvo com sucesso !");
            }
        })
    }

    addPestControl(){
        console.log("Faz Mutation", this.state)

        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate(
            {
                mutation: EDIT_EXTRACTIVISM_SPECIE, 
                variables: paramsEnviar,
                errorPolicy: 'all',
                refetchQueries:[{
                    query: EXTRACTIVISM_SPECIES,
                    variables: 
                    { 
                        management_id: this.state.management_id,
                    }
                }]
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            }else{
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");                
            }
        })
    }

    renderSpecies(species){
            return  species.map((specie) =>
                        <TableRow>
                            <TableCell>{specie.specie}</TableCell>
                            <TableCell>{specie.individuals_number}</TableCell>
                            <TableCell>{specie.collected_part}</TableCell>
                            <TableCell>{specie.collected_amount}</TableCell>
                            <TableCell>{specie.collection_epoch}</TableCell>
                            <TableCell>{specie.minimum_age}</TableCell>
                            <TableCell>{specie.circumference}</TableCell>
                            <TableCell>{specie.interval_collections}</TableCell>
                            <TableCell>{specie.leaves_part_to_multiply}</TableCell>
                            <TableCell>
                                <Fab variant="extended" color="primary" onClick={() => this.removeSpecie(specie.specie_id)}>
                                    <Delete />
                                    Remover
                                </Fab>
                            </TableCell>
                        </TableRow>
                    )
    }
    removeSpecie(id){
        console.log("Remove Esse: ",id)
        this.props.client.mutate({
            mutation: DELETE_EXTRACTIVISM_SPECIE, 
            variables: {management_id: this.state.management_id,specie_id: id}, 
            errorPolicy: 'all',
            refetchQueries:[{
                query: EXTRACTIVISM_SPECIES,
                variables: 
                { 
                    management_id: this.state.management_id,
                }
            }]
        })
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({open: false, remover: null});
                alert("Erro ao remover!"); 
            } else {
                console.log("DATA: ", data);
                alert("Removido!");
            }
        })
    }

    render() {
        // console.log("state", this.state)
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <div style={{paddingTop: "10px"}}>
                    <h4>CADASTRO EXTRATIVISMO {this.props.manejo.manejo.description}</h4>
                    <hr style={css.hr}/>
                    <div style={css.contentColuns}>
                        <div style={css.col4}>
                        <sub>Qual o tipo de área da sua atividade extrativista?</sub>
                        </div>
                        <div style={css.col4}>
                           
                            
                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.area_federal_conservation_unit}
                                        onChange={(e) => this.setState({area_federal_conservation_unit: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Unidade de Conservação Federal"/>
                                <TextField 
                                    id="qualFederal" 
                                    label="Qual?" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({area_federal_description: e.target.value})} 
                                    value={this.state.area_federal_description}
                                    // style={css.input}
                                />
                                                            
                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.area_state_conservation_unit}
                                        onChange={(e) => this.setState({area_state_conservation_unit: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Unidade de Conservação Estatal"/>

                                <TextField 
                                    id="qualEstatal" 
                                    label="Qual?" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({area_state_description: e.target.value})} 
                                    value={this.state.area_state_description}
                                    // style={css.input}
                                />

                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state. area_rural_settlement_project}
                                        onChange={(e) => this.setState({ area_rural_settlement_project: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Área de Projeto de Assentamento Rural"/>

                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.area_quilombola_territory}
                                        onChange={(e) => this.setState({area_quilombola_territory: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Território Quilombola"/>

                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.area_indigenous_land}
                                        onChange={(e) => this.setState({area_indigenous_land: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Terra Indigena"/>

                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.area_other}
                                        onChange={(e) => this.setState({area_other: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Outros"/>

                                <TextField 
                                    id="outros_desc" 
                                    label="Qual (is)?" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({area_other_description: e.target.value})} 
                                    value={this.state.area_other_description}
                                    // style={css.input}
                                />
                        
                        </div>
                    </div>       
                    <hr style={css.hr}/>
                    <div style={css.contentColuns}>
                        <div style={css.col4}>
                        <sub>Qual situação fundiária da área de coleta?</sub>
                        </div>
                        <div style={css.col4}>
                           
                            
                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.situation_tenure}
                                        onChange={(e) => this.setState({situation_tenure: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Posse"/>
                           
                                                            
                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state. situation_definitive_title}
                                        onChange={(e) => this.setState({ situation_definitive_title: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Titulo definitivo"/>

                              

                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state. situation_settlement}
                                        onChange={(e) => this.setState({ situation_settlement: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Assentamento"/>

                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.situation_granting_use_rights}
                                        onChange={(e) => this.setState({situation_granting_use_rights: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Concessão de Direitos Real de Uso"/>


                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.situation_other}
                                        onChange={(e) => this.setState({situation_other: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Outros"/>

                                <TextField 
                                    id="outros_quais" 
                                    label="Qual (is)?" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({situation_other_description: e.target.value})} 
                                    value={this.state.situation_other_description}
                                    // style={css.input}
                                />
                        
                        </div>
                    </div>
                </div>             
                <div style={css.contentColuns}>
                    <Fab variant="extended" color="primary" onClick={() => this.submitIt()}>
                        <Save />
                        Criar
                    </Fab>
                </div>

                <hr style={css.hr} />


                <div style={css.contentColuns}>
                    <div style={{display: "flex", width: "100%", marginBottom: "15px"}}>
                        <sub><strong>Quais são as espécies e parâmetros que utiliza para coletar os produtos ?</strong></sub>
                    </div>
                    <div style={css.contentColuns}>
                        
                    <TextField
                            id="select-produto"
                            select
                            label="Espécie"
                            value={this.state.specie_id}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => this.setState({specie_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            style={css.input}
                        >
                            {
                            !!this.state.tiposPlanta && this.state.tiposPlanta.map(option => (
                                <MenuItem key={`select-esp-${option.specie_id}`} value={option.specie_id}>
                                    {option.specie}
                                </MenuItem>
                                ))
                            
                            }
                        </TextField>   
                        
                        <TextField 
                            label="Nº de Indivíduos" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({individuals_number: e.target.value})} 
                            value={this.state.individuals_number}
                            style={css.input}
                        />         
                        <TextField 
                            label="Parte Coletada" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({collected_part: e.target.value})} 
                            value={this.state.collected_part}
                            style={css.input}
                        />         
                        <TextField 
                            label="Qtde Coletada" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({collected_amount: e.target.value})} 
                            value={this.state.collected_amount}
                            style={css.input}
                        />   
                        </div>
                        <div style={css.contentColuns}>
                        <TextField 
                            label="Época de Coleta" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({collection_epoch: e.target.value})} 
                            value={this.state.collection_epoch}
                            style={css.input}
                        />       
                        <TextField 
                            label="Idade Min. da Planta" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({minimum_age: e.target.value})} 
                            value={this.state.minimum_age}
                            style={css.input}
                        />     
                        <TextField 
                            label="Circunf.(rôdo)" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({circumference: e.target.value})} 
                            value={this.state.circumference}
                            style={css.input}
                        />   
                        <TextField 
                            label="Intervalo Coletas" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({interval_collections: e.target.value})} 
                            value={this.state.interval_collections}
                            style={css.input}
                        />        
                        </div>
                        <div style={css.contentColuns}>
                        <div style={css.col4}>
                       <TextField 
                            label="Deixa frutos ou plantas sem coletar para que se multipliquem?" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({leaves_part_to_multiply: e.target.value})} 
                            value={this.state.leaves_part_to_multiply}
                            style={css.input}
                        />    
                        <Fab variant="extended" color="primary" onClick={() => this.addPestControl()}>
                            <Save />
                            Inserir
                        </Fab>
                        </div>
               
                    </div>
                {/* </div> */}
                    <TableContainer component={Paper}>
                        <Table className={this.props.classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Espécie</TableCell>
                                    <TableCell>Nº de Indivíduos</TableCell>
                                    <TableCell>Parte Coletada</TableCell>
                                    <TableCell>Época de Coleta</TableCell>
                                    <TableCell>Idade Min. da Planta</TableCell>
                                    <TableCell>Circunf.(rôdo)</TableCell>
                                    <TableCell>Intervalo Coletas</TableCell>
                                    <TableCell>Deixa frutos ou plantas sem coletar para que multipliquem?</TableCell>
                                    <TableCell>Remover</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <Query query={EXTRACTIVISM_SPECIES} variables={{management_id: this.state.management_id }}>
                                    {({ loading, error, data }) => {
                                            if (loading) {
                                                return null;
                                            }else if(error){
                                                console.log("Error",error);
                                            }
                                            console.log("RETORNO: ",data.ManagementQuery.managementExtractivism);
                                            if(!!data.ManagementQuery.managementExtractivism && data.ManagementQuery.managementExtractivism.managementExtractivismSpecies.length > 0){
                                                return this.renderSpecies(data.ManagementQuery.managementExtractivism.managementExtractivismSpecies);
                                            }else{
                                                return "";
                                            }
                                    }}
                                </Query>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        );
    }
}
export default withApollo((withStyles(styles))(CadExtrativismo));

const css = {
    contentColuns: {
        display:"flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    col1: {
        maxWidth: "25%",
        minWidth: "25%",
        flex: 1
    },
    col2: {
        maxWidth: "50%",
        minWidth: "50%",
        flex: 1
    },
    col3: {
        maxWidth: "75%",
        minWidth: "75%",
        flex: 1
    },
    col4: {
        maxWidth: "100%",
        minWidth: "100%",
        flex: 1
    },
    input: {
       flex: 1,
       display: "flex",
       marginRight: 15
    },
    hr: {
        marginTop: "20px",
        marginBottom: "20px"
    }
}