import React, { Component } from 'react';
import { 
    Button,
    Divider,
    List,
    ListItem,
    ListItemText,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Typography,
    TextField,
    Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LISTA_RODADAS } from '../../services/graphql/querys/rodada';
import { FILL_PURCHASE_ORDER } from '../../services/graphql/mutations/rodada';
import CustomErrors from '../CustomErrors/CustomErrors';

import { withApollo, graphql, Query } from 'react-apollo';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    contentBox: {
        backgroundColor:"#fff",
        borderRadius: "4px",
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    }
});
var total = 0;
class PedidoRodada extends Component {
    constructor(props){
        super(props);
        this.state ={
            bottom: false,
            expanded: false,
            rodada: false,
            pedido: false,
            prodBuySeller: false,
            error: [],
        }
    }
    toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        this.setState({ [side]: open });
    };
    handleChange = panel => (event, isExpanded) => {
        this.setState({ 
            expanded: panel 
        });
    };
    
    handleChangePedido(e) {
        // If you are using babel, you can use ES 6 dictionary syntax
        // let change = { [e.target.name] = e.target.value }
        console.log(e.target)
        let change = {}
        if(e.target.value){
            change[e.target.name] = parseFloat(e.target.value);
        }else{
            change[e.target.name] = 0;
        }
        this.setState(change)
      }
      handleChangeName(nome, valor) {
        // If you are using babel, you can use ES 6 dictionary syntax
        // let change = { [e.target.name] = e.target.value }
        let change = {}
        change[nome] = valor
        this.setState(change)
      }

    componentDidMount(){
        let body = {
            id:  this.props.id_rodada,
        }
        let prodBuySeller = [];
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        console.log("Enviar Parametros: ", paramsEnviar);
        this.props.client.query({query: LISTA_RODADAS, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("DATA: ", data)
                this.setState({'rodada': data.CommercialRoundsQuery.data[0]});     
                let rodada = data.CommercialRoundsQuery.data[0];
                rodada.purchase_orders.map(pedido => {
                    if(pedido.id == this.props.id_pedido){ 
                        this.setState({pedido: pedido})
                        
                        pedido.items.map(item => {
                            this.handleChangeName(`total-${item.product.product_id}`,0)
                            rodada.offer_by_product_origin.map(sellers => {
                                if(sellers.product.product_id == item.product.product_id){
                                    prodBuySeller.push({
                                        id: item.id,
                                        product_id: item.product.product_id,
                                        quantity: item.quantity,
                                        local_name: item.product.local_name,
                                        sellers: sellers.origins,
                                        estoque: sellers.stock_quantity,
                                    })
                                }
                            })
                        })
                        this.setState({prodBuySeller: prodBuySeller})
                        console.log("prodBuySeller: ",prodBuySeller);
                    }
             
                })
            }
        })
        
    }
    peso = (e) => {
		let v = e.target.value,
			integer = v.split(',')[0];


		v = v.replace(/\D/, "");
		v = v.replace(/^[0]+/, "");

		if(v.length <= 1 || !integer)
		{
            console.log(v)
			if(v.length === 1) v = '0.' + v;
			//if(v.length === 2) v = '0.0' + v;
			//if(v.length === 3) v = '0.' + v;
			//if(v.length === 4) v = '0.' + v;
		} else {
            v = v.replace(/^(\d{1,})(\d{1})$/, "$1.$2");
            console.log(v)
        }
        e.target.value = v;
        let change = {}
        if(e.target.value){
            change[e.target.name] = parseFloat(v);
        }else{
            change[e.target.name] = 0;
        }
        this.setState(change)
        // if(parseFloat(v) < this.state.produto.available_quantity){
        //     this.setState({value:parseFloat(v).toFixed(1)});
        // } else {
        //     this.setState({value:this.state.produto.available_quantity.toFixed(1)});
        // }
	}

    sumUp(item) {
        // console.log("FCC: ", sellers);
        let total = 0;
        item.sellers.forEach(seller => {
            let sellerId = seller.seller.farmer_user_id ? seller.seller.farmer_user_id : seller.seller.station_id;
            if (!!this.state[`prod-${item.product_id}-sell-${sellerId}`]) {
                total = total + this.state[`prod-${item.product_id}-sell-${sellerId}`];
            }
        });
        if (!!this.state[`prod-${item.product_id}-sell-estoque`]) {
            total = total + this.state[`prod-${item.product_id}-sell-estoque`];
        }
        return total;
    }

    getName(seller, product_id){

        var sellerId = seller.seller.farmer_user_id ? seller.seller.farmer_user_id : seller.seller.station_id;

        return `prod-${product_id}-sell-${sellerId}`;
    }

    getNameEstoque(product_id){
        return `prod-${product_id}-sell-estoque`;
    }

    getKg(seller, product_id){
        var sellerId = seller.seller.farmer_user_id ? seller.seller.farmer_user_id : seller.seller.station_id;
        return this.state[`prod-${product_id}-sell-${sellerId}`] ? this.state[`prod-${product_id}-sell-${sellerId}`] : 0;
    }
    getKgEstoque(product_id){
        return this.state[`prod-${product_id}-sell-estoque`] ? this.state[`prod-${product_id}-sell-estoque`] : 0;
    }
    submitIt(){
        // PRECISO:
        // purchase_order_id 
        // quantity: 10, from_stock: false, farmer_user_id: "asdasd", station_id: 1

        let paramsEnviar = { };

        paramsEnviar = {
            purchase_order_id: this.state.pedido.id,
            fulfillments: [],
        }
        let j = 0;
        this.state.prodBuySeller.forEach(prod => {
            prod.sellers.forEach(seller => {
                let sellerId = seller.seller.farmer_user_id ? seller.seller.farmer_user_id : seller.seller.station_id;
                if(this.state[`prod-${prod.product_id}-sell-${sellerId}`] > 0 ){               
                    paramsEnviar.fulfillments[j] = {
                        quantity: this.state[`prod-${prod.product_id}-sell-${sellerId}`], 
                        from_stock: false, 
                        farmer_user_id: seller.seller.farmer_user_id ? seller.seller.farmer_user_id : false, 
                        station_id: seller.seller.station_id ? seller.seller.station_id : false, 
                        product_id: prod.product_id, 
                    }
                j++;
            }})
            if(!!this.state[`prod-${prod.product_id}-sell-estoque`] && this.state[`prod-${prod.product_id}-sell-estoque`] > 0 ){
                paramsEnviar.fulfillments[j] = {
                    quantity: this.state[`prod-${prod.product_id}-sell-estoque`], 
                    from_stock: true, 
                    product_id: prod.product_id, 
                }
                j++;
            }
        })

        console.log("ParamsEnviar: ", paramsEnviar)
        this.props.client.mutate({mutation: FILL_PURCHASE_ORDER, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR2: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        if(key == "fulfillments"){
                            errosMostrar[i] = {message: "Voce deve escolher de onde os produtos vao sair"};

                        }else{
                            errosMostrar[i] = {message: value};
                        }
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        if(value.message == "Product or product quantity not available at the chosen source"){
                            errosMostrar[key] = {message: "Voce pediu uma quantidade superior a ofertada"};
                        }else if(value.message == "The quantity of product (308) served exceeds the order quantity"){
                            errosMostrar[key] = {message: "Voce colocou uma quantidade superior a demanda"};

                        }else{
                            errosMostrar[key] = {message: value.message};
                        }
                    }
                    this.setState({error: errosMostrar})
                }
            } else {
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");
                window.location.href = "/rodada/visualizar/" + this.props.id_rodada;

            }
        })


    }

    render(){
        console.log(this.state)
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={4} xs={4}>
                        <div className={this.props.classes.contentBox}>
                            <List>
                                <ListItem>
                                    <ListItemText>
                                        <h2>Demanda/Produtos pedidos</h2>
                                    </ListItemText>
                                </ListItem>
                                {this.state.pedido && this.state.pedido.items.map(item => (
                                    <>
                                    <ListItem key={item.id}>
                                        <ListItemText>
                                            <h3>{item.product.local_name} - {item.quantity.toFixed(1).replace(".",",")}kg</h3>
                                        </ListItemText>
                                    </ListItem>
                                    <Divider />
                                    </>
                                ))}
                            </List>
                        </div>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={4}>
                    {!!this.state.prodBuySeller && this.state.prodBuySeller.map(item => (
                        <ExpansionPanel expanded={this.state.expanded === `panel${item.product_id}`} onChange={this.handleChange(`panel${item.product_id}`)}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${item.product_id}bh-content`}
                            id={`panel${item.product_id}bh-header`}
                            >
                                <Typography className={this.props.classes.heading}>{item.local_name}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={3}>
                                    {item.sellers.map(seller => (
                                        <>
                                            <Grid className={this.props.classes.root} item xl={6} xs={6}>{seller.seller.user ? seller.seller.user.profile.name : seller.seller.name}</Grid>
                                            <Grid className={this.props.classes.root} item xl={2} xs={2}>{seller.quantity.toFixed(1).replace(".",",")}kg</Grid>
                                            <Grid className={this.props.classes.root} item xl={4} xs={4}>
                                                <TextField  className={this.props.classes.textField} 
                                                    onChange={this.peso.bind(this) }
                                                    // value={this.state[`prod-${item.product_id}-sell-${seller.seller.farmer_user_id}`]}
                                                    value={this.state[this.getName(seller, item.product_id)]}
                                                    label="Kg" 
                                                    type="number"
                                                    step={0.1}
                                                    onKeyDown={ e => (e.keyCode === 188 ||  e.keyCode === 69 || e.keyCode === 190 ) && e.preventDefault() }
                                                    name={this.getName(seller, item.product_id)}
                                                    InputLabelProps={{shrink: true,}} 
                                                    variant="outlined" 
                                                    margin="dense" 
                                                />
                                            </Grid>
                                        </>
                                    ))}

                                     <Grid className={this.props.classes.root} item xl={6} xs={6}>Estoque</Grid>
                                            <Grid className={this.props.classes.root} item xl={2} xs={2}>{item.estoque.toFixed(1).replace(".",",")}kg</Grid>
                                            <Grid className={this.props.classes.root} item xl={4} xs={4}>
                                                <TextField  className={this.props.classes.textField} 
                                                    onChange={this.peso.bind(this) }
                                                    // value={this.state[`prod-${item.product_id}-sell-${seller.seller.farmer_user_id}`]}
                                                    value={this.state[this.getNameEstoque(item.product_id)]}
                                                    label="Kg" 
                                                    type="number"
                                                    onKeyDown={ e => ( e.keyCode === 188 || e.keyCode === 69 || e.keyCode === 190 ) && e.preventDefault() }
                                                    name={this.getNameEstoque(item.product_id)}
                                                    InputLabelProps={{shrink: true,}} 
                                                    variant="outlined" 
                                                    margin="dense" 
                                                />
                                            </Grid>

                                    <Grid className={this.props.classes.root} item xl={8} xs={8}>Total:</Grid>
                                    <Grid className={this.props.classes.root} item xl={4} xs={4}>
                                    
                                   
                                    {this.sumUp(item).toFixed(1).replace(".",",")}kg

                                    </Grid>

                                    <Grid className={this.props.classes.root} item xl={8} xs={8}>Total Desejado:</Grid>
                                    <Grid className={this.props.classes.root} item xl={4} xs={4}>{item.quantity.toFixed(1).replace(".",",")}kg</Grid>

                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))}
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={4}>
                        <div className={this.props.classes.contentBox}>
                                <List>
                                    <ListItem>
                                        <ListItemText>
                                            <h2>Pedido {this.state.pedido.id}</h2>
                                        </ListItemText>
                                    </ListItem>
                                    {!!this.state.prodBuySeller && this.state.prodBuySeller.map(item => (

                                        <ListItem>
                                            <ListItemText>
                                                <h3>{item.local_name} - {item.quantity.toFixed(1).replace(".",",")}kg</h3>
                                                <Grid container spacing={3}>
                                                    {item.sellers.map(seller => (
                                                        <>
                                                        <Grid className={this.props.classes.root} item xl={8} xs={8}>
                                                            {seller.seller.user ? seller.seller.user.profile.name : seller.seller.name}  
                                                        </Grid>
                                                        <Grid className={this.props.classes.root} item xl={4} xs={4}>
                                                            {this.getKg(seller,item.product_id).toFixed(1).replace(".",",")}kg
                                                            {/* {!!this.state[`prod-${item.product_id}-sell-${seller.seller.farmer_user_id}`] ? this.state[`prod-${item.product_id}-sell-${seller.seller.farmer_user_id}`] : 0}kg */}
                                                        </Grid>
                                                        </>
                                                    ))}
                                                    <Grid className={this.props.classes.root} item xl={8} xs={8}>
                                                            Estoque  
                                                        </Grid>
                                                        <Grid className={this.props.classes.root} item xl={4} xs={4}>
                                                            {this.getKgEstoque(item.product_id).toFixed(1).replace(".",",")}kg
                                                        </Grid>
                                                </Grid>
                                            </ListItemText>
                                            <Divider />
                                        </ListItem>
                                        
                                    ))}

                                    <ListItem>
                                        <ListItemText>
                                            <Grid container spacing={3}>
                                                <Grid className={this.props.classes.root} item xl={4} xs={4}></Grid>
                                                <Grid className={this.props.classes.root} item xl={4} xs={4}></Grid>
                                                <Grid className={this.props.classes.root} item xl={4} xs={4}>
                                                    <Button variant="contained" color="primary" onClick={() => this.submitIt()}>
                                                        Enviar pedido
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid container spacing={3}>
                                                <Grid className={this.props.classes.root} item xl={8} xs={8}>Pedido 005</Grid>
                                                <Grid className={this.props.classes.root} itemm xl={4} xs={4}>
                                                    <strong style={{color:"#80AE26"}}>Pedido Enviado</strong>
                                                </Grid>
                                            </Grid> */}
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(PedidoRodada));