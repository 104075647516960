import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { EDIT_PRODUCT } from '../../services/graphql/mutations/product';
import { PRODUCT_CATEGORY_QUERY } from '../../services/graphql/querys/product';
import CustomErrors from '../CustomErrors/CustomErrors';

import { withApollo, graphql, Query } from 'react-apollo';
import {
    TextField,
    Grid,
    MenuItem,
    Fab,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    }
});
class Formulario extends Component {
    constructor(props){
        super(props);
        this.state = {
            product_id: 0,
            product_category_id: '',
            local_name: '',
            comercial_name: '',
            unified_product: '',
            productPicture: null,
            productCategories: [],
            error: [],
        }
    }
    componentWillReceiveProps(props){
        if(!!props.product){
            let product = props.product;
            this.setState({
                product_id: product.product_id,
                product_category_id: product.product_category_id,
                local_name: product.local_name,
                comercial_name: product.comercial_name,
                unified_product: product.unified_product,
            })
        }
    }
    componentDidMount(){
        this.props.client.query({query: PRODUCT_CATEGORY_QUERY, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                    if(errors[0].message == 'validation'){
                        let erroValid = errors[0].extensions.validation;
                        for (var [key, value] of Object.entries(erroValid)) {
                            console.log("ERRO... " + key + ' ',value);
                        }
                    }else{
                        alert("Por favor, preencha todos os campos do formulário")
                    }
                } else {
                    console.log("DATA: ", data)
                    this.setState({'productCategories': data.ProductCategoryQuery});                    
                }
            })
    }
    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate({mutation: EDIT_PRODUCT, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            } else {
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");
                window.location.href = "/produtos";

            }
        })
    }

    render(){
        console.log("State: ",this.state)
        return(
            <div className={this.props.classes.content}>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>
                </Grid>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={6} xs={6}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({local_name: e.target.value})} value={this.state.local_name} label="Nome Produto Local" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={6}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({comercial_name: e.target.value})} value={this.state.comercial_name} label="Nome Produto Comercial" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={6}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({unified_product: e.target.value})} value={this.state.unified_product} label="Produto Unificado" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={6}>

                        <TextField
                            select
                            label="Categoria do Produto"
                            InputLabelProps={{shrink: true,}}
                            value={this.state.product_category_id}
                            onChange={(e) => this.setState({product_category_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            className={this.props.classes.textField}
                        >
                        {!!this.state.productCategories && this.state.productCategories.map(option => (
                            <MenuItem key={option.product_category_id} value={option.product_category_id}>
                                {option.product_category}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={12}>                   
                        Foto: <input type="file" className={this.props.classes.root} onChange={(e) => this.setState({productPicture: e.target.files[0]})}   />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={6}>
                        <Fab variant="extended" className={this.props.classes.textField} color="primary" onClick={() => this.submitIt()}>
                            <Save />
                            Salvar
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Formulario));