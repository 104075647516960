import gql from 'graphql-tag';

export const USER_QUERY = gql`

query UserQuery(
    $id: String
){
  UserQuery(id: $id){
    id
    login
    email
    msisdn
    phone
    access_token
    email_verified_at
    msisdn_verified_at
    expires_in
    refresh_token
    profile {
      user_type
      name
      nickname
      gender
      legal_document
      birth
      country
      state
      city
      district
      address
      address_number
      address_complement
      zip_code
      reference_point
      latitude
      longitude
      profilePicture
    }
    adminPermissions {
      user_id
      admin_permission_id
      create
      edit
      delete
    }
  }
}
`;

export const LIST_USER_QUERY = gql`

query ListUsersQuery(
    $name: String,
    $state: String,
    $city: String,
    $legal_document: String
){
  ListUsersQuery(name: $name, state: $state, city: $city, legal_document: $legal_document){
    user_id
    name
    login
    state
    city
    legal_document
    user{
      access_token
      email
      profile{
        user_type
      }
    }
  }
}
`;