import gql from 'graphql-tag';

export const EDIT_MANAGEMENT_ACTIVIT = gql`
mutation editManagementActivityByArea(
    $management_id: Int!, 
    $area_type_id: Int!, 
    $management_type_id: String!, 
    $occupied_area: Float, 
    $installments_number: Int
){
  editManagementActivityByArea(
    management_id: $management_id, 
    area_type_id: $area_type_id, 
    management_type_id: $management_type_id, 
    occupied_area: $occupied_area, 
    installments_number: $installments_number
  ){
    management_id
    area_type_id
    area_description
    occupied_area
    management_type_id
    installments_number
  }
}
`;

export const CREATE_MANAGEMENT = gql`

mutation  createManagement( $user_id: String!, $description: String!){
  createManagement(
    user_id: $user_id,
    description: $description,
  ) {
    management_id
    user_id
    description
    created_at
    edit_at
    managementFarmer {
      management_id
      name
      birth
      identity_document
      cpf_document
      rural_block_registration
      spouse_name
      spouse_birth
      children
      family_members
      address
      line_or_community
      city
      zip_code
      state
      phone
      fax
      productive_unit_description
    }
    managementActivityByArea {
      management_id
      area_type_id
      area_description
      occupied_area
      management_type_id
      installments_number
    }
    managementAnimalProduction {
      management_id
      has_dairy_bovines
      has_beef_bovines
      has_dairy_caprines
      has_beef_caprines
      has_birds_of_laying
      has_beef_birds
      has_bees_creation
      has_ovines
      has_rabbits
      has_buffalos
      has_porcines
      has_aquatic_animals
      has_other_animals
      aquatic_animals_description
      other_animals_description
      animal_waste_treatment
      in_adequate_conditions
      not_adequate_conditions_description
      intestinal_worms_control
      external_parasites_control
      common_diseases_treatments
      welfare_good_water
      welfare_good_food
      welfare_adequate_facilities
      welfare_adequate_occupancy
      welfare_shading_areas
      welfare_sun_pasture_access
      welfare_proper_bed
      welfare_other_shapes
      other_shapes_description
      plantel_herd_origin
      managementOwnRationIngredient {
        management_id
        ration_ingredient_id
        ration_ingredient_name
        management_description
      }
      managementRationComposition {
        management_id
        ration_ingredient_id
        ration_ingredient_name
        own_production
        buy_out
        organic
        non_organic
      }
      managementAnimalFeedingMedication {
        management_id
        animal_category_id
        animal_category
        ecological_feeding
        conventional_feeding
        ecological_medication
        conventional_medication
      }
      managementAnnualFeedingPlan {
        management_id
        feeding_category_id
        feeding_category
        january
        february
        march
        april
        may
        june
        july
        august
        september
        october
        november
        december
      }
    }
    managementVegetableProduction {
      management_id
      all_crops_produced_organically
      seeds_origin
      fertilization_treatment
      soil_management_practices
      initiatives_improve_fertility
      main_spontaneous_weeds
      does_mowing
      does_manual_weeding
      does_grazing
      does_green_adubation
      does_shading
      does_other_controls
      other_controls_description
      managementPestDiseaseControl {
        pest_disease_id
        pest_disease_name
        management_id
        used_product_or_management
        used_where
        used_when
        origin
        composition
      }
    }
    managementBeekeeping {
      management_id
      bee_type
      uses_fixed_beekeeping
      uses_migratory_beekeeping
      wax_organic_apiaries
      wax_non_organic_apiaries
      wax_own_organic_production
      wax_own_non_organic_production
      bees_own_production
      bees_swarm_capture
      bees_purchase_conventional_systems
      bees_purchase_organic_swarms
      flowering_periods
      distance_less
      distance_one_km
      distance_two_km
      distance_three_km
      distance_four_km
      distance_five_km
      distance_more
      distance_more_description
      feed_honey
      feed_organic_sugar_syrup
      feed_other
      feed_other_description
      feed_for_how_long
      pest_control
    }
    managementExtractivism {
      management_id
      area_federal_conservation_unit
      area_state_conservation_unit
      area_rural_settlement_project
      area_quilombola_territory
      area_indigenous_land
      area_other
      area_other_description
      area_federal_description
      area_state_description
      situation_tenure
      situation_definitive_title
      situation_settlement
      situation_granting_use_rights
      situation_other
      situation_other_description
      managementExtractivismSpecies {
        management_id
        specie_id
        specie
        description
        individuals_number
        collected_part
        collected_amount
        collection_epoch
        minimum_age
        circumference
        interval_collections
        leaves_part_to_multiply
      }
    }
    managementProductiveUnit {
      management_id
      native_vegetation_area
      rivers_protection
      native_vegetation_recover
      uses_irrigation
      irrigation_waters_origin
      dry_garbage_treatment
      liquid_effluents_treatment
      who_works
      working_conditions
      accidents_work_responability
      school_attendance
      compromises_school_performance
      has_stored_seeds
      has_stored_manure
      has_stored_mineral_fertilizers
      has_stored_limestone
      has_stored_biofertilizers_salts
      has_stored_animal_medicines
      has_stored_mineral_salt
      has_stored_others
      description_other_stored
      commercialization_channels
      commercialization_other_questions
      managementDependency {
        management_id
        own_pasture
        rent_pasture
        own_concentrate
        rent_concentrate
        own_minerals_vitamins
        rent_minerals_vitamins
        own_hay_silage
        rent_hay_silage
        own_grass
        rent_grass
        own_grains
        rent_grains
        own_crop_remains
        rent_crop_remains
        own_others
        rent_others
        others_description
      }
      managementOutsideProductionInput {
        management_id
        product_id
        local_name
        measure_unit_id
        measure_unit_description
        annual_amount
      }
      managementProductManipulation {
        management_id
        product_id
        manipulation
        storage
        transport
        local_name
        comercial_name
      }
      managementProductionEstimate {
        management_id
        product_category_id
        product_category
        product_id
        local_name
        comercial_name
        amount
        measure_unit_id
        measure_unit_description
        periodicity_id
        periodicity_description
      }
    }
    managementProductionSummary {
      management_id
      glebe
      year
      semester
      specie_id
      specie
      management_type_id
      management_type
    }
  }
}
`;
export const EDIT_ANIMAL_PRODUCTION = gql`

mutation  editManagementAnimalProduction(
  $management_id: Int!,
  $has_dairy_bovines: Boolean,
  $has_beef_bovines: Boolean,
  $has_dairy_caprines: Boolean,
  $has_beef_caprines: Boolean,
  $has_birds_of_laying: Boolean,
  $has_beef_birds: Boolean,
  $has_bees_creation: Boolean,
  $has_ovines: Boolean,
  $has_rabbits: Boolean,
  $has_buffalos: Boolean,
  $has_porcines: Boolean,
  $has_aquatic_animals: Boolean,
  $has_other_animals: Boolean,
  $aquatic_animals_description: String,
  $other_animals_description: String,
  $animal_waste_treatment: String,
  $in_adequate_conditions: Boolean,
  $not_adequate_conditions_description: String,
  $intestinal_worms_control: String,
  $external_parasites_control: String,
  $common_diseases_treatments: String,
  $welfare_good_water: Boolean,
  $welfare_good_food: Boolean,
  $welfare_adequate_facilities: Boolean,
  $welfare_adequate_occupancy: Boolean,
  $welfare_shading_areas: Boolean,
  $welfare_sun_pasture_access: Boolean,
  $welfare_proper_bed: Boolean,
  $welfare_other_shapes: Boolean,
  $other_shapes_description: String,
  $plantel_herd_origin: String, 
){
  editManagementAnimalProduction(
    management_id: $management_id,
    has_dairy_bovines: $has_dairy_bovines,
    has_beef_bovines: $has_beef_bovines,
    has_dairy_caprines: $has_dairy_caprines,
    has_beef_caprines: $has_beef_caprines,
    has_birds_of_laying: $has_birds_of_laying,
    has_beef_birds: $has_beef_birds,
    has_bees_creation: $has_bees_creation,
    has_ovines: $has_ovines,
    has_rabbits: $has_rabbits,
    has_buffalos: $has_buffalos,
    has_porcines: $has_porcines,
    has_aquatic_animals: $has_aquatic_animals,
    aquatic_animals_description: $aquatic_animals_description,
    has_other_animals: $has_other_animals,
    other_animals_description: $other_animals_description,
    animal_waste_treatment: $animal_waste_treatment,
    in_adequate_conditions: $in_adequate_conditions,
    not_adequate_conditions_description: $not_adequate_conditions_description,
    intestinal_worms_control: $intestinal_worms_control,
    external_parasites_control: $external_parasites_control,
    common_diseases_treatments: $common_diseases_treatments,
    welfare_good_water: $welfare_good_water,
    welfare_good_food: $welfare_good_food,
    welfare_adequate_facilities: $welfare_adequate_facilities,
    welfare_adequate_occupancy: $welfare_adequate_occupancy,
    welfare_shading_areas: $welfare_shading_areas,
    welfare_sun_pasture_access: $welfare_sun_pasture_access,
    welfare_proper_bed: $welfare_proper_bed,
    welfare_other_shapes: $welfare_other_shapes,
    other_shapes_description: $other_shapes_description,
    plantel_herd_origin: $plantel_herd_origin,
  ) {
    management_id
   
  }
}
`;

export const EDIT_OWN_RATION = gql`
mutation editManagementOwnRationIngredient ( $management_id: Int!, $ration_ingredient_id: Int!, $management_description: String ){
  editManagementOwnRationIngredient(
    management_id: $management_id,
    ration_ingredient_id: $ration_ingredient_id,
    management_description: $management_description,

  ){
    management_id
  }
}`;

export const DELETE_OWN_RATION = gql`
mutation deleteManagementOwnRationIngredient ( $management_id: Int!, $ration_ingredient_id: Int!){
  deleteManagementOwnRationIngredient(
    management_id: $management_id,
    ration_ingredient_id: $ration_ingredient_id,
  )
}`;

export const DELETE_ANIMAL_FEED_MEDICATION = gql`
mutation deleteManagementAnimalFeedingMedication ( $management_id: Int!, $animal_category_id: Int!){
  deleteManagementAnimalFeedingMedication(
    management_id: $management_id,
    animal_category_id: $animal_category_id,
  )
}`;



export const EDIT_RATION_COMPOSITION = gql`
mutation editManagementRationComposition ( $management_id: Int!, 
    $ration_ingredient_id: Int!, 
    $own_production: Float,  
    $buy_out: Float, 
    $organic: Float, 
    $non_organic: Float){
      
  editManagementRationComposition(
    management_id: $management_id,
    ration_ingredient_id: $ration_ingredient_id,
    own_production: $own_production,
    buy_out: $buy_out,
    organic: $organic,
    non_organic: $non_organic,
  ){
    management_id
  }
}`;

export const EDIT_ANIMAL_FEEDING_MEDICATION = gql`
mutation editManagementAnimalFeedingMedication ( 
    $management_id: Int!, 
    $animal_category_id: Int!, 
    $ecological_feeding: Float, 
    $conventional_feeding: Float, 
    $ecological_medication: Float, 
    $conventional_medication: Float ){   

    editManagementAnimalFeedingMedication(
      management_id: $management_id,
      animal_category_id: $animal_category_id,
      ecological_feeding: $ecological_feeding,
      conventional_feeding: $conventional_feeding,
      ecological_medication: $ecological_medication,
      conventional_medication: $conventional_medication,
    ){
      management_id
    }
  }`;

export const EDIT_ANIMAL_FEEDING_PLAN = gql`
mutation editManagementAnnualFeedingPlan ( 
      $management_id: Int!, 
      $feeding_category_id: Int!, 
      $january: Boolean, 
      $february: Boolean, 
      $march: Boolean, 
      $april: Boolean, 
      $may: Boolean, 
      $june: Boolean, 
      $july: Boolean, 
      $august: Boolean, 
      $september: Boolean, 
      $october: Boolean, 
      $november: Boolean, 
      $december: Boolean ){   

    editManagementAnnualFeedingPlan(
      management_id: $management_id,
      feeding_category_id: $feeding_category_id,
      january: $january,
      february: $february,
      march: $march,
      april: $april,
      may: $may,
      june: $june,
      july: $july,
      august: $august,
      september: $september,
      october: $october,
      november: $november,
      december: $december,
    ){
      management_id
    }
  }`;

  
  export const EDIT_VEGETABLE_PRODUCTION = gql`
    mutation editManagementVegetableProduction ( 
      $management_id: Int!, 
      $all_crops_produced_organically: String, 
      $seeds_origin: String,
      $fertilization_treatment: String, 
      $soil_management_practices: String, 
      $initiatives_improve_fertility: String, 
      $main_spontaneous_weeds: String, 
      $does_mowing: Boolean, 
      $does_manual_weeding: Boolean, 
      $does_grazing: Boolean, 
      $does_green_adubation: Boolean, 
      $does_shading: Boolean, 
      $does_other_controls: Boolean, 
      $other_controls_description: String ){
  
      editManagementVegetableProduction(
        management_id: $management_id,
        all_crops_produced_organically: $all_crops_produced_organically,
        seeds_origin: $seeds_origin,
        fertilization_treatment: $fertilization_treatment,
        soil_management_practices: $soil_management_practices,
        initiatives_improve_fertility: $initiatives_improve_fertility,
        main_spontaneous_weeds: $main_spontaneous_weeds,
        does_mowing: $does_mowing,
        does_manual_weeding: $does_manual_weeding,
        does_grazing: $does_grazing,
        does_green_adubation: $does_green_adubation,
        does_shading: $does_shading,
        does_other_controls: $does_other_controls,
        other_controls_description: $other_controls_description,
      ){
        management_id
      }
    }`;
  
    export const CREATE_PEST_DISEASE_CONTROL = gql`
    mutation createManagementPestDiseaseControl ( 
        $management_id: Int!, 
        $pest_disease_name: String!, 
        $used_product_or_management: String, 
        $used_where: String, 
        $used_when: String, 
        $origin: String, 
        $composition: String
    ){
  
      createManagementPestDiseaseControl(
        management_id: $management_id,
        pest_disease_name: $pest_disease_name,
        used_product_or_management: $used_product_or_management,
        used_where: $used_where,
        used_when: $used_when,
        origin: $origin,
        composition: $composition,
      ){
        management_id
      }
    }`; 

export const DELETE_PEST_CONTROL = gql`
mutation deleteManagementPestDiseaseControl ( $pest_disease_id: Int!){
  deleteManagementPestDiseaseControl(
    pest_disease_id: $pest_disease_id,
  )
}`;

export const DELETE_EXTRACTIVISM_SPECIE = gql`
mutation deleteManagementExtractivismSpecie ( $management_id: Int!, $specie_id: Int!){
  deleteManagementExtractivismSpecie(
    management_id: $management_id,
    specie_id: $specie_id,
  )
}`;

export const EDIT_BEEKEEPING = gql`
mutation editManagementBeekeeping ( 
    $management_id: Int!,
    $bee_type: String,
    $uses_fixed_beekeeping: Boolean,
    $uses_migratory_beekeeping: Boolean,
    $wax_organic_apiaries: Boolean,
    $wax_non_organic_apiaries: Boolean,
    $wax_own_organic_production: Boolean,
    $wax_own_non_organic_production: Boolean,
    $bees_own_production: Boolean,
    $bees_swarm_capture: Boolean,
    $bees_purchase_conventional_systems: Boolean,
    $bees_purchase_organic_swarms: Boolean,
    $flowering_periods: String,
    $distance_less: Boolean,
    $distance_one_km: Boolean,
    $distance_two_km: Boolean,
    $distance_three_km: Boolean,
    $distance_four_km: Boolean,
    $distance_five_km: Boolean,
    $distance_more: Boolean,
    $distance_more_description: String,
    $feed_honey: Boolean,
    $feed_organic_sugar_syrup: Boolean,
    $feed_other: Boolean,
    $feed_other_description: String,
    $feed_for_how_long: String,
    $pest_control: String
){

  editManagementBeekeeping(
    management_id: $management_id,
    bee_type: $bee_type,
    uses_fixed_beekeeping: $uses_fixed_beekeeping,
    uses_migratory_beekeeping: $uses_migratory_beekeeping,
    wax_organic_apiaries: $wax_organic_apiaries,
    wax_non_organic_apiaries: $wax_non_organic_apiaries,
    wax_own_organic_production: $wax_own_organic_production,
    wax_own_non_organic_production: $wax_own_non_organic_production,
    bees_own_production: $bees_own_production,
    bees_swarm_capture: $bees_swarm_capture,
    bees_purchase_conventional_systems: $bees_purchase_conventional_systems,
    bees_purchase_organic_swarms: $bees_purchase_organic_swarms,
    flowering_periods: $flowering_periods,
    distance_less: $distance_less,
    distance_one_km: $distance_one_km,
    distance_two_km: $distance_two_km,
    distance_three_km: $distance_three_km,
    distance_four_km: $distance_four_km,
    distance_five_km: $distance_five_km,
    distance_more: $distance_more,
    distance_more_description: $distance_more_description,
    feed_honey: $feed_honey,
    feed_organic_sugar_syrup: $feed_organic_sugar_syrup,
    feed_other: $feed_other,
    feed_other_description: $feed_other_description,
    feed_for_how_long: $feed_for_how_long,
    pest_control: $pest_control,
  ){
    management_id
  }
}`; 

export const EDIT_EXTRACTIVISM = gql`
mutation editManagementExtractivism ( 
    $management_id: Int!,
    $area_federal_conservation_unit: Boolean,
    $area_state_conservation_unit: Boolean,
    $area_rural_settlement_project: Boolean,
    $area_quilombola_territory: Boolean,
    $area_indigenous_land: Boolean,
    $area_other: Boolean,
    $area_other_description: String,
    $area_federal_description: String,
    $area_state_description: String,
    $situation_tenure: Boolean,
    $situation_definitive_title: Boolean,
    $situation_settlement: Boolean,
    $situation_granting_use_rights: Boolean,
    $situation_other: Boolean,
    $situation_other_description: String,
){

  editManagementExtractivism(
    management_id: $management_id,
    area_federal_conservation_unit: $area_federal_conservation_unit,
    area_state_conservation_unit: $area_state_conservation_unit,
    area_rural_settlement_project: $area_rural_settlement_project,
    area_quilombola_territory: $area_quilombola_territory,
    area_indigenous_land: $area_indigenous_land,
    area_other: $area_other,
    area_other_description: $area_other_description,
    area_federal_description: $area_federal_description,
    area_state_description: $area_state_description,
    situation_tenure: $situation_tenure,
    situation_definitive_title: $situation_definitive_title,
    situation_settlement: $situation_settlement,
    situation_granting_use_rights: $situation_granting_use_rights,
    situation_other: $situation_other,
    situation_other_description: $situation_other_description,

  ){
    management_id
  }
}`; 

export const EDIT_EXTRACTIVISM_SPECIE = gql`
mutation editManagementExtractivismSpecie ( 
    $management_id: Int!,
    $specie_id: Int!,
    $individuals_number: Int,
    $collected_part: String,
    $collected_amount: String,
    $collection_epoch: String,
    $minimum_age: String,
    $circumference: String,
    $interval_collections: String,
    $leaves_part_to_multiply: String,
){

  editManagementExtractivismSpecie(
    management_id: $management_id,
    specie_id: $specie_id,
    individuals_number: $individuals_number,
    collected_part: $collected_part,
    collected_amount: $collected_amount,
    collection_epoch: $collection_epoch,
    minimum_age: $minimum_age,
    circumference: $circumference,
    interval_collections: $interval_collections,
    leaves_part_to_multiply: $leaves_part_to_multiply,

  ){
    management_id
  }
}`; 

export const EDIT_PRODUCTIVE_UNIT = gql`
mutation editManagementProductiveUnit ( 
  $management_id: Int!,
  $native_vegetation_area: Float,
  $rivers_protection: String,
  $native_vegetation_recover: String,
  $uses_irrigation: String,
  $irrigation_waters_origin: String,
  $dry_garbage_treatment: String,
  $liquid_effluents_treatment: String,
  $who_works: String,
  $working_conditions: String,
  $accidents_work_responability: String,
  $school_attendance: String,
  $compromises_school_performance: String,
  $has_stored_seeds: Boolean,
  $has_stored_manure: Boolean,
  $has_stored_mineral_fertilizers: Boolean,
  $has_stored_limestone: Boolean,
  $has_stored_biofertilizers_salts: Boolean,
  $has_stored_animal_medicines: Boolean,
  $has_stored_mineral_salt: Boolean,
  $has_stored_others: Boolean,
  $description_other_stored: String,
  $commercialization_channels: String,
  $commercialization_other_questions: String,  
){

  editManagementProductiveUnit(
    management_id: $management_id,
    native_vegetation_area: $native_vegetation_area,
    rivers_protection: $rivers_protection,
    native_vegetation_recover: $native_vegetation_recover,
    uses_irrigation: $uses_irrigation,
    irrigation_waters_origin: $irrigation_waters_origin,
    dry_garbage_treatment: $dry_garbage_treatment,
    liquid_effluents_treatment: $liquid_effluents_treatment,
    who_works: $who_works,
    working_conditions: $working_conditions,
    accidents_work_responability: $accidents_work_responability,
    school_attendance: $school_attendance,
    compromises_school_performance: $compromises_school_performance,
    has_stored_seeds: $has_stored_seeds,
    has_stored_manure: $has_stored_manure,
    has_stored_mineral_fertilizers: $has_stored_mineral_fertilizers,
    has_stored_limestone: $has_stored_limestone,
    has_stored_biofertilizers_salts: $has_stored_biofertilizers_salts,
    has_stored_animal_medicines: $has_stored_animal_medicines,
    has_stored_mineral_salt: $has_stored_mineral_salt,
    has_stored_others: $has_stored_others,
    description_other_stored: $description_other_stored,
    commercialization_channels: $commercialization_channels,
    commercialization_other_questions: $commercialization_other_questions,
  ){
    management_id
  }
}`; 

export const EDIT_MANAGEMENT_DEPENDENCY = gql`
mutation editManagementDependency ( 
    $management_id: Int!,
    $own_pasture: Boolean,
    $rent_pasture: Boolean,
    $own_concentrate: Boolean,
    $rent_concentrate: Boolean,
    $own_minerals_vitamins: Boolean,
    $rent_minerals_vitamins: Boolean,
    $own_hay_silage: Boolean,
    $rent_hay_silage: Boolean,
    $own_grass: Boolean,
    $rent_grass: Boolean,
    $own_grains: Boolean,
    $rent_grains: Boolean,
    $own_crop_remains: Boolean,
    $rent_crop_remains: Boolean,
    $own_others: Boolean,
    $rent_others: Boolean,
    $others_description: String,
){

  editManagementDependency(
    management_id: $management_id,
    own_pasture: $own_pasture,
    rent_pasture: $rent_pasture,
    own_concentrate: $own_concentrate,
    rent_concentrate: $rent_concentrate,
    own_minerals_vitamins: $own_minerals_vitamins,
    rent_minerals_vitamins: $rent_minerals_vitamins,
    own_hay_silage: $own_hay_silage,
    rent_hay_silage: $rent_hay_silage,
    own_grass: $own_grass,
    rent_grass: $rent_grass,
    own_grains: $own_grains,
    rent_grains: $rent_grains,
    own_crop_remains: $own_crop_remains,
    rent_crop_remains: $rent_crop_remains,
    own_others: $own_others,
    rent_others: $rent_others,
    others_description: $others_description,

  ){
    management_id
  }
}`; 

export const EDIT_OUTSIDE_PRODUCTION = gql`
mutation editManagementOutsideProductionInput ( 
    $management_id: Int!,
    $product_id: Int!,
    $measure_unit_id: String!,
    $annual_amount: Float,
){
  editManagementOutsideProductionInput(
    management_id: $management_id,
    product_id: $product_id,
    measure_unit_id: $measure_unit_id,
    annual_amount: $annual_amount,
  ){
    management_id
  }
}`; 

export const EDIT_PRODUCT_MANIPULATION = gql`
mutation editManagementProductManipulation ( 
    $management_id: Int!,
    $product_id: Int!,
    $manipulation: String,
    $storage: String,
    $transport: String,
){
  editManagementProductManipulation(
    management_id: $management_id,
    product_id: $product_id,
    manipulation: $manipulation,
    storage: $storage,
    transport: $transport,
  ){
    management_id
  }}`; 

  export const EDIT_PRODUCTION_ESTIMATE = gql`
mutation editManagementProductionEstimate ( 
    $management_id: Int!,
    $product_id2: Int!,
    $amount: Float,
    $measure_unit_id2: String,
    $periodicity_id: String,
){
  editManagementProductionEstimate(
    management_id: $management_id,
    product_id: $product_id2,
    amount: $amount,
    measure_unit_id: $measure_unit_id2,
    periodicity_id: $periodicity_id,
  ){
    management_id
  }}`; 


  export const CREATE_PRODUCTION_SUMMARY = gql`
  mutation createManagementProductionSummary ( 
    $management_id: Int!,
    $glebe: Int!,
    $glebeProductionSummary: Float,
   
){
  createManagementProductionSummary(
    management_id: $management_id,
    glebe: $glebe,
    glebeProductionSummary: [ManagementProductionSummaryInput],
  ){
    management_id
    glebe
    year
    semester
    specie_id
    specie
    management_type_id
    management_type
  }}`; 

export const DELETE_PRODUCTION_SUMMARY = gql`
mutation deleteManagementProductionSummary ( $management_id: Int!, $glebe: Int!){
  deleteManagementProductionSummary(
    management_id: $management_id,
    glebe: $glebe,
  )
}`;

export const DELETE_OUTSIDE_PRODUCTION = gql`
mutation deleteManagementOutsideProductionInput ( $management_id: Int!, $product_id: Int!){
  deleteManagementOutsideProductionInput(
    management_id: $management_id,
    product_id: $product_id,

  )
}`;

export const DELETE_PRODUCTION_ESTIMATE = gql`
mutation deleteManagementProductionEstimate ( $management_id: Int!, $product_id: Int!){
  deleteManagementProductionEstimate(
    management_id: $management_id,
    product_id: $product_id,

  )
}`;
export const DELETE_PRODUCT_MANIPULATION = gql`
mutation deleteManagementProductManipulation ( $management_id: Int!, $product_id: Int!){
  deleteManagementProductManipulation(
    management_id: $management_id,
    product_id: $product_id,

  )
}`;







