import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    TextField,
    Grid,
    FormControlLabel,
    Divider,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    DialogTitle,
    Dialog,
    List,
    ListItem,
    Button
} from '@material-ui/core';
import { Search, Add, Edit, Delete } from '@material-ui/icons';
import { LIST_CUSTOMERS  } from '../../services/graphql/querys/customer';
import { DELETE_USER  } from '../../services/graphql/mutations/customer';

import { withApollo, graphql, Query } from 'react-apollo';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});

class Lista extends Component {
    constructor(props) {
        super(props);
        this.state = {
           customers: this.props.customers,
           open: false,
           remover: null,
        };
    }

    componentWillReceiveProps(props){
        this.setState({customers: props.customers});
    }

    fazBusca = () => {
        let body = {
            name:  document.getElementById('byName').value,
            legal_document:  document.getElementById('byDocument').value,
            city:  document.getElementById('byCity').value,
            state:  document.getElementById('byState').value,
        }
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Enviar Parametros: ", paramsEnviar);
            this.props.client.query({query: LIST_CUSTOMERS, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                    if(errors[0].message == 'validation'){
                        let erroValid = errors[0].extensions.validation;
                        for (var [key, value] of Object.entries(erroValid)) {
                            console.log("ERRO... " + key + ' ',value);
                        }
                    }else{
                        alert("Por favor, preencha todos os campos do formulário")
                    }
                } else {
                    console.log("DATA: ", data)
                    this.setState({'customers': data.ListCustomersQuery});                    
                }
            })
    }
    removeUser(){
        console.log("Remover user: ", this.state.remover)
        this.props.client.mutate({mutation: DELETE_USER, variables: {user_id: this.state.remover}, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({open: false, remover: null});
                alert("Erro ao remover usuario!"); 
            } else {
                console.log("DATA: ", data);
                this.setState({open: false, remover: null});
                alert("Usuario removido!");
                window.location.href = "/consumidores";
            }
        })
    }

    render() {
        const handleClose = () => {
            this.setState({open: false, remover: null});
        };
        const handleClickOpen = (user_id) => {
            console.log("UserID: ", user_id)
            this.setState({open: true, remover: user_id});
        };
        return(
            <div>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xs={10} xs={9}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xs={3} xs={6}>
                                <TextField id="byName" className={this.props.classes.textField} label="Nome" variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xs={3} xs={6}>
                                <TextField id="byDocument" className={this.props.classes.textField} label="CPF/CNPJ" variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xs={3} xs={6}>
                                <TextField id="byCity" className={this.props.classes.textField} label="Município" variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xs={3} xs={6}>
                                <TextField id="byState" className={this.props.classes.textField} label="Estado" variant="outlined" margin="dense" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xs={2} xs={3}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} align="center" item xs={6}>
                                <Fab color="primary" size="small" aria-label="search"  onClick={() => this.fazBusca()}>
                                    <Search />
                                </Fab>
                            </Grid>
                            <Grid className={this.props.classes.root} align="center" item xs={6}>
                                <Fab color="primary" size="small" aria-label="add" component="a" href="/consumidores/new">
                                    <Add />
                                </Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!!this.state.customers && 
                        <Grid className={this.props.classes.root} item xs={12}>
                            <TableContainer component={Paper}>
                                <Table className={this.props.classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nome</TableCell>
                                            <TableCell align="center">CPF/CNPJ</TableCell>
                                            <TableCell align="center">Município</TableCell>
                                            <TableCell align="center">Estado</TableCell>
                                            <TableCell align="center">Editar</TableCell>
                                            <TableCell align="center">Excluir</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {this.state.customers.map(row => (
                                        <TableRow key={row.nome}>
                                            <TableCell component="th" scope="row">{row.name}</TableCell>
                                            <TableCell align="center">{row.legal_document}</TableCell>
                                            <TableCell align="center">{row.city}</TableCell>
                                            <TableCell align="center">{row.state}</TableCell>
                                            <TableCell align="center">
                                            <Fab color="primary" size="small" aria-label="editar" component="a" href={"/consumidores/edit/" + row.user_id} >
                                                <Edit />
                                            </Fab>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Fab color="primary" size="small" aria-label="search" onClick={() => handleClickOpen(row.user_id)}>
                                                    <Delete />
                                                </Fab>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    }
                </Grid>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
                    <DialogTitle id="simple-dialog-title" align="center">
                        Você realmente deseja<br/>
                        <strong>EXCLUIR</strong> o Usuário ?
                    </DialogTitle>
                    <List>
                        <ListItem>
                            <Button variant="contained" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]} onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant="contained" color="primary" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]} onClick={() => this.removeUser()}>
                                Continuar
                            </Button>
                        </ListItem>
                    </List>
                </Dialog>
            </div>
        );
    }
}export default withApollo(withStyles(styles)(Lista));
