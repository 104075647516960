import gql from 'graphql-tag';

export const PEDIDO_FORNECEDOR = gql`

query SalesOrdersQuery( $id: Int, $id_pedido: Int ){
  CommercialRoundsQuery(id: $id){
    data{
      sales_orders(id: $id_pedido){
        id
        items{
          id
          quantity
          product{
            product_id
            local_name
            comercial_name
            picture
            urlPicture
          }
          status{
            code
            description
            posible_statuses{
              code
              description
            }
          }
        }
      }
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
}
`;

export const PEDIDO_CLIENTE = gql`

query PurchaseOrdersQuery( $id: Int){
  PurchaseOrdersQuery(id: $id){
    data{
      id
      fulfillment{
        
        fulfillments_by_product_origin{
          product{
            product_id
            local_name
            comercial_name
            picture
            urlPicture
          }
          stock_quantity
          origins{
            quantity
            seller{
              ... on Farmer {
                farmer_user_id: id
                contact
                identity_document
                dap
                user {
                  id
                  email
                  profile{
                    name
                  }
                }
              }
              ... on Station {
                station_id: id
                name
                is_substation
                state
                city
              }
            }
          }
        }
      }
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
}
`;