import gql from 'graphql-tag';

export const CREATE_CUSTOMER = gql`

mutation  createCustomer(
  $login: String!,
  $email: String,
  $msisdn: String,
  $phone: String!,
  $name: String!,
  $nickname: String,
  $password: String!,
  $birth: String,
  $country: String,
  $state: String!,
  $city: String!,
  $district: String,
  $address: String!,
  $address_number: Int!,
  $address_complement: String,
  $zip_code: String!,
  $reference_point: String,
  $latitude: String,
  $longitude: String,
  $responsible: String,
  $comercial_phone: String,
  $commercialization_type_id: Int,
  $other_commercialization_type: String,
  $legal_document: String!,
  $state_registration: String,
  $municipal_registration: String,
  $customerSitesSocialNetworks: [CustomerSiteSocialNetworkInput],
  $bank_name: String,
  $bank_code: Int,
  $agency_number: String,
  $account_holder: String,
  $account_type: Int,
  $account_number: String,
){
    
  createCustomer(
    login: $login,
    email: $email,
    msisdn: $msisdn,
    phone: $phone,
    name: $name,
    nickname: $nickname,
    password: $password,
    birth: $birth,
    country: $country,
    state: $state,
    city: $city,
    district: $district,
    address: $address,
    address_number: $address_number,
    address_complement: $address_complement,
    zip_code: $zip_code,
    reference_point: $reference_point,
    latitude: $latitude,
    longitude: $longitude,
    responsible: $responsible,
    comercial_phone: $comercial_phone,
    commercialization_type_id: $commercialization_type_id,
    other_commercialization_type: $other_commercialization_type,
    legal_document: $legal_document,
    state_registration: $state_registration,
    municipal_registration: $municipal_registration,
    customerSitesSocialNetworks: $customerSitesSocialNetworks,
    bank_name: $bank_name,
    bank_code: $bank_code,
    agency_number: $agency_number,
    account_holder: $account_holder,
    account_type: $account_type,
    account_number: $account_number,
  ) {
      id
      responsible
  }
}
`;

export const EDIT_CUSTOMER = gql`

mutation editCustomerGeneral(
  $user_id: String!,
  $email: String,
  $msisdn: String,
  $phone: String,
  $name: String,
  $nickname: String,
  $password: String,
  $birth: String,
  $country: String,
  $state: String,
  $city: String,
  $district: String,
  $address: String,
  $address_number: Int,
  $address_complement: String,
  $zip_code: String,
  $reference_point: String,
  $latitude: String,
  $longitude: String,
  $responsible: String,
  $comercial_phone: String,
  $commercialization_type_id: Int,
  $other_commercialization_type: String,
  $legal_document: String,
  $state_registration: String,
  $municipal_registration: String,
  $customerSitesSocialNetworks: [CustomerSiteSocialNetworkInput],
  $bank_name: String,
  $bank_code: Int,
  $agency_number: String,
  $account_holder: String,
  $account_type: Int,
  $account_number: String,
  
){
    
  editCustomerGeneral(
    user_id: $user_id,
    email: $email,
    msisdn: $msisdn,
    phone: $phone,
    name: $name,
    nickname: $nickname,
    password: $password,
    birth: $birth,
    country: $country,
    state: $state,
    city: $city,
    district: $district,
    address: $address,
    address_number: $address_number,
    address_complement: $address_complement,
    zip_code: $zip_code,
    reference_point: $reference_point,
    latitude: $latitude,
    longitude: $longitude,
    responsible: $responsible,
    comercial_phone: $comercial_phone,
    commercialization_type_id: $commercialization_type_id,
    other_commercialization_type: $other_commercialization_type,
    legal_document: $legal_document,
    state_registration: $state_registration,
    municipal_registration: $municipal_registration,
    customerSitesSocialNetworks: $customerSitesSocialNetworks,
    bank_name: $bank_name,
    bank_code: $bank_code,
    agency_number: $agency_number,
    account_holder: $account_holder,
    account_type: $account_type,
    account_number: $account_number,
  ) {
      id
      responsible
  }
}
`;

export const EDIT_COSTUMER_COMMERCIALIZATION = gql`

mutation  editCustomerCommercialization(
  $user_id: String!,
  $fair_format: Boolean,
  $organic_station_format: Boolean,
  $basket_format: Boolean,
  $supermarket_format: Boolean,
  $solidarity_store_format: Boolean,
  $store_format: Boolean,
  $distributor_consumer: Boolean,
  $small_market_network_consumer: Boolean,
  $online_platform_consumer: Boolean,
  $restaurant_consumer: Boolean,
  $has_other_initiative_types: Boolean,
  $other_initiative_types_description: String,
  $associations_cooperatives_origin: Boolean,
  $individual_producer_origin: Boolean,
  $distributor_origin: Boolean,
  $solidarity_store_origin: Boolean,
  $farmers_network_origin: Boolean,
  $has_other_origin: Boolean,
  $other_origin_description: String,
  $agroecological_family_farming_interest: Boolean,
  $organic_family_farming_interest: Boolean,
  $organic_company_interest: Boolean,
  $reason_interest_description: String,
  $participatory_certification: Boolean,
  $auditing_certification: Boolean,
  $ocs_certification: Boolean,
  $phone_watsapp_receive_offers: Boolean,
  $site_receive_offers: Boolean,
  $app_receive_offers: Boolean,
  $email_receive_offers: Boolean,
  $other_receive_offers: Boolean,
  $other_receive_offers_description: String,
  $phone_watsapp_best_channel: Boolean,
  $site_best_channel: Boolean,
  $app_best_channel: Boolean,
  $email_best_channel: Boolean,
  $other_best_channel: Boolean,
  $other_best_channel_description: String,
  $commercialize_in_natura_products: Boolean,
  $commercialize_minimally_processed_products: Boolean,
  $commercialize_processed_products: Boolean,
  
){
    
  editCustomerCommercialization(
    user_id: $user_id,
    fair_format: $fair_format,
    organic_station_format: $organic_station_format,
    basket_format: $basket_format,
    supermarket_format: $supermarket_format,
    solidarity_store_format: $solidarity_store_format,
    store_format: $store_format,
    distributor_consumer: $distributor_consumer,
    small_market_network_consumer: $small_market_network_consumer,
    online_platform_consumer: $online_platform_consumer,
    restaurant_consumer: $restaurant_consumer,
    has_other_initiative_types: $has_other_initiative_types,
    other_initiative_types_description: $other_initiative_types_description,
    associations_cooperatives_origin: $associations_cooperatives_origin,
    individual_producer_origin: $individual_producer_origin,
    distributor_origin: $distributor_origin,
    solidarity_store_origin: $solidarity_store_origin,
    farmers_network_origin: $farmers_network_origin,
    has_other_origin: $has_other_origin,
    other_origin_description: $other_origin_description,
    agroecological_family_farming_interest: $agroecological_family_farming_interest,
    organic_family_farming_interest: $organic_family_farming_interest,
    organic_company_interest: $organic_company_interest,
    reason_interest_description: $reason_interest_description,
    participatory_certification: $participatory_certification,
    auditing_certification: $auditing_certification,
    ocs_certification: $ocs_certification,
    phone_watsapp_receive_offers: $phone_watsapp_receive_offers,
    site_receive_offers: $site_receive_offers,
    app_receive_offers: $app_receive_offers,
    email_receive_offers: $email_receive_offers,
    other_receive_offers: $other_receive_offers,
    other_receive_offers_description: $other_receive_offers_description,
    phone_watsapp_best_channel: $phone_watsapp_best_channel,
    site_best_channel: $site_best_channel,
    app_best_channel: $app_best_channel,
    email_best_channel: $email_best_channel,
    other_best_channel: $other_best_channel,
    other_best_channel_description: $other_best_channel_description,
    commercialize_in_natura_products: $commercialize_in_natura_products,
    commercialize_minimally_processed_products: $commercialize_minimally_processed_products,
    commercialize_processed_products: $commercialize_processed_products,    
  ) {
      user_id
  }
}
`;


export const EDIT_CUSTOMER_LOGISTIC = gql`

mutation editCustomerLogistic(
  $user_id: String!,
  $needs_local_logistics: Boolean,
  $can_fetch_product: Boolean,
  $own_vehicle: Boolean,
  $outsourced_freight: Boolean,
  $receives_only_on_place: Boolean,
  $uses_solidarity_freight: Boolean,
  $monday_morning_until: String,
  $monday_afternoon_until: String,
  $monday_night_until: String,
  $tuesday_morning_until: String,
  $tuesday_afternoon_until: String,
  $tuesday_night_until: String,
  $wednesday_morning_until: String,
  $wednesday_afternoon_until: String,
  $wednesday_night_until: String,
  $thursday_morning_until: String,
  $thursday_afternoon_until: String,
  $thursday_night_until: String,
  $friday_morning_until: String,
  $friday_afternoon_until: String,
  $friday_night_until: String,
  $saturday_morning_until: String,
  $saturday_afternoon_until: String,
  $saturday_night_until: String,
  $sunday_morning_until: String,
  $sunday_afternoon_until: String,
  $sunday_night_until: String,
){
    
  editCustomerLogistic(
    user_id: $user_id,
    needs_local_logistics: $needs_local_logistics,
    can_fetch_product: $can_fetch_product,
    own_vehicle: $own_vehicle,
    outsourced_freight: $outsourced_freight,
    receives_only_on_place: $receives_only_on_place,
    uses_solidarity_freight: $uses_solidarity_freight,
    monday_morning_until: $monday_morning_until,
    monday_afternoon_until: $monday_afternoon_until,
    monday_night_until: $monday_night_until,
    tuesday_morning_until: $tuesday_morning_until,
    tuesday_afternoon_until: $tuesday_afternoon_until,
    tuesday_night_until: $tuesday_night_until,
    wednesday_morning_until: $wednesday_morning_until,
    wednesday_afternoon_until: $wednesday_afternoon_until,
    wednesday_night_until: $wednesday_night_until,
    thursday_morning_until: $thursday_morning_until,
    thursday_afternoon_until: $thursday_afternoon_until,
    thursday_night_until: $thursday_night_until,
    friday_morning_until: $friday_morning_until,
    friday_afternoon_until: $friday_afternoon_until,
    friday_night_until: $friday_night_until,
    saturday_morning_until: $saturday_morning_until,
    saturday_afternoon_until: $saturday_afternoon_until,
    saturday_night_until: $saturday_night_until,
    sunday_morning_until: $sunday_morning_until,
    sunday_afternoon_until: $sunday_afternoon_until,
    sunday_night_until: $sunday_night_until,
   
  ) {
      user_id
      
  }
}
`;

export const EDIT_CUSTOMER_PAYMENT = gql`

mutation  editCustomerPayment(
  $user_id: String!,
  $payment_by_bank_slip: Boolean,
  $payment_by_deposit_account: Boolean,
  $payment_by_money: Boolean,
  $payment_by_credit_debit: Boolean,
  $other_types_payment_description: String,
  $weekly_payment_term: Boolean,
  $biweekly_payment_term: Boolean,
  $monthly_payment_term: Boolean,
  $other_payment_term: Boolean,
  $other_payment_term_description: String,
  $individual_invoice: Boolean,
  $danfe_invoice: Boolean,
  $single_invoice: Boolean,
  $supplier_invoice: Boolean,
  $unnecessary_invoice: Boolean
  
){
  editCustomerPayment(
    user_id: $user_id,
    payment_by_bank_slip: $payment_by_bank_slip,
    payment_by_deposit_account: $payment_by_deposit_account,
    payment_by_money: $payment_by_money,
    payment_by_credit_debit: $payment_by_credit_debit,
    other_types_payment_description: $other_types_payment_description,
    weekly_payment_term: $weekly_payment_term,
    biweekly_payment_term: $biweekly_payment_term,
    monthly_payment_term: $monthly_payment_term,
    other_payment_term: $other_payment_term,
    other_payment_term_description: $other_payment_term_description,
    individual_invoice: $individual_invoice,
    danfe_invoice: $danfe_invoice,
    single_invoice: $single_invoice,
    supplier_invoice: $supplier_invoice,
    unnecessary_invoice: $unnecessary_invoice,
    
  ) {
      user_id
  }
}`;

export const CREATE_CUSTOMER_DEMAND = gql`

mutation  createCustomerDemand(
  $user_id: String!,
  $customerDemand: CustomerDemandInput ,
  
){
  createCustomerDemand(
    user_id: $user_id, 
    customerDemand: $customerDemand,
  ) {
    demand_type
    user_id
    product_id
    measure_unit_id
    january_amount
    february_amount
    march_amount
    april_amount
    may_amount
    june_amount
    july_amount
    august_amount
    september_amount
    october_amount
    november_amount
    december_amount
    weekly_amount
    purchase_months
    average_purchase_amount
    product {
      product_id
      product_category_id
      local_name
      comercial_name
      unified_product
      picture
      urlPicture
      productCategory {
        product_category_id
        product_category
      }
    }
    measureUnit {
      measure_unit_id
      measure_unit_description
    }
  }
}`;

export const DELETE_USER = gql`

mutation  deleteCustomer(
  $user_id: String!,
){
  deleteCustomer(user_id: $user_id)
}`;


export const CREATE_COMMERCIALIZATION_TYPE = gql`
mutation  createCommercializationType($description: String!){
    createCommercializationType(description: $description){
      commercialization_type_id
      description
    }
}`;


export const EDIT_COMMERCIALIZATION_TYPE = gql`
mutation  editCommercializationType($commercialization_type_id: Int!, $description: String!){
  editCommercializationType(commercialization_type_id: $commercialization_type_id, description: $description){
      commercialization_type_id
      description
    }
}`;