import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { EDIT_FARMER_CACAU } from '../../../services/graphql/mutations/farmer';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';

import {
    TextField,
    Grid,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    Divider,
    Fab
} from '@material-ui/core';
import { Print, Save } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});

class Cacau extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            edit: null,
            user_id: null,
            user_id: null,
            cocoa_area_size: null,
            amount_cocoa_tree: null,
            unknow_amount_cocoa_tree: null,
            need_replanting: null,
            production_volume_year: null,
            production_volume_at: null,
            cabruca_production_system: null,
            full_sun_production_system: null,
            saf_production_system: null,
            others_production_system: null,
            description_others_production_system: null,
            produces_cocoa_commodity: null,
            produces_cocoa_superior: null,
            produces_cocoa_fine: null,
            percentage_superior_fine: null,
            cultivates_common_variety: null,
            cultivates_cloned_variety: null,
            cultivates_mixed_variety: null,
            harvest_management_beneficiament: null,
            fermentation_beneficiament: null,
            drying_management_beneficiament: null,
            warehouse_storage: null,
            house_storage: null,
            specific_depository_storage: null,
            common_depository_storage: null,
            other_storage: null,
            description_other_storage: null,
            storage_time_in_months: null,
            storage_time_in_days: null,
            nylon_sacking: null,
            aligning_sacking: null,
            other_sacking: null,
            description_other_sacking: null,
            participate_association_cooperative: null,
            description_association_cooperative: null,
            sells_to_industry: null,
            sells_to_cooperative: null,
            sells_to_intermediate: null,
            sells_to_consumer: null,
            transport_difficulty: null,
            low_price_difficulty: null,
            storage_difficulty: null,
            quality_difficulty: null,
            other_difficulty: null,
            description_other_difficulty: null,
            marketed_percentage_almonds: null,
            marketed_percentage_nibs: null,
            marketed_percentage_liquor: null,
            marketed_percentage_chocolate: null,
            marketed_percentage_cocoa_candy: null,
            marketed_percentage_soft_cocoa: null,
            sells_cocoa_pulp: null,
            amount_cocoa_pulp: null,
            sells_fruit_gourd: null,
            amount_fruit_gourd: null,
            sells_cocoa_honey: null,
            amount_cocoa_honey: null,
            error: [],
        };
    } 
        
    componentWillReceiveProps(props){
        if(props.farmer){
            this.setState({'edit': true});
            let farmer = props.farmer;
            this.setState({
                user_id: farmer.id,

            });
            if(farmer.farmerCocoa && farmer.farmerCocoa != null){
                this.setState({
                    cocoa_area_size: farmer.farmerCocoa.cocoa_area_size,
                    amount_cocoa_tree: farmer.farmerCocoa.amount_cocoa_tree,
                    unknow_amount_cocoa_tree: farmer.farmerCocoa.unknow_amount_cocoa_tree,
                    need_replanting: farmer.farmerCocoa.need_replanting,
                    production_volume_year: farmer.farmerCocoa.production_volume_year,
                    production_volume_at: farmer.farmerCocoa.production_volume_at,
                    cabruca_production_system: farmer.farmerCocoa.cabruca_production_system,
                    full_sun_production_system: farmer.farmerCocoa.full_sun_production_system,
                    saf_production_system: farmer.farmerCocoa.saf_production_system,
                    others_production_system: farmer.farmerCocoa.others_production_system,
                    description_others_production_system: farmer.farmerCocoa.description_others_production_system,
                    produces_cocoa_commodity: farmer.farmerCocoa.produces_cocoa_commodity,
                    produces_cocoa_superior: farmer.farmerCocoa.produces_cocoa_superior,
                    produces_cocoa_fine: farmer.farmerCocoa.produces_cocoa_fine,
                    percentage_superior_fine: farmer.farmerCocoa.percentage_superior_fine,
                    cultivates_common_variety: farmer.farmerCocoa.cultivates_common_variety,
                    cultivates_cloned_variety: farmer.farmerCocoa.cultivates_cloned_variety,
                    cultivates_mixed_variety: farmer.farmerCocoa.cultivates_mixed_variety,
                    harvest_management_beneficiament: farmer.farmerCocoa.harvest_management_beneficiament,
                    fermentation_beneficiament: farmer.farmerCocoa.fermentation_beneficiament,
                    drying_management_beneficiament: farmer.farmerCocoa.drying_management_beneficiament,
                    warehouse_storage: farmer.farmerCocoa.warehouse_storage,
                    house_storage: farmer.farmerCocoa.house_storage,
                    specific_depository_storage: farmer.farmerCocoa.specific_depository_storage,
                    common_depository_storage: farmer.farmerCocoa.common_depository_storage,
                    other_storage: farmer.farmerCocoa.other_storage,
                    description_other_storage: farmer.farmerCocoa.description_other_storage,
                    storage_time_in_months: farmer.farmerCocoa.storage_time_in_months,
                    storage_time_in_days: farmer.farmerCocoa.storage_time_in_days,
                    nylon_sacking: farmer.farmerCocoa.nylon_sacking,
                    aligning_sacking: farmer.farmerCocoa.aligning_sacking,
                    other_sacking: farmer.farmerCocoa.other_sacking,
                    description_other_sacking: farmer.farmerCocoa.description_other_sacking,
                    participate_association_cooperative: farmer.farmerCocoa.participate_association_cooperative ? 'S' : 'N',
                    description_association_cooperative: farmer.farmerCocoa.description_association_cooperative,
                    sells_to_industry: farmer.farmerCocoa.sells_to_industry,
                    sells_to_cooperative: farmer.farmerCocoa.sells_to_cooperative,
                    sells_to_intermediate: farmer.farmerCocoa.sells_to_intermediate,
                    sells_to_consumer: farmer.farmerCocoa.sells_to_consumer,
                    transport_difficulty: farmer.farmerCocoa.transport_difficulty,
                    low_price_difficulty: farmer.farmerCocoa.low_price_difficulty,
                    storage_difficulty: farmer.farmerCocoa.storage_difficulty,
                    quality_difficulty: farmer.farmerCocoa.quality_difficulty,
                    other_difficulty: farmer.farmerCocoa.other_difficulty,
                    description_other_difficulty: farmer.farmerCocoa.description_other_difficulty,
                    marketed_percentage_almonds: farmer.farmerCocoa.marketed_percentage_almonds,
                    marketed_percentage_nibs: farmer.farmerCocoa.marketed_percentage_nibs,
                    marketed_percentage_liquor: farmer.farmerCocoa.marketed_percentage_liquor,
                    marketed_percentage_chocolate: farmer.farmerCocoa.marketed_percentage_chocolate,
                    marketed_percentage_cocoa_candy: farmer.farmerCocoa.marketed_percentage_cocoa_candy,
                    marketed_percentage_soft_cocoa: farmer.farmerCocoa.marketed_percentage_soft_cocoa,
                    sells_cocoa_pulp: farmer.farmerCocoa.amount_cocoa_pulp ? true : false,
                    amount_cocoa_pulp: farmer.farmerCocoa.amount_cocoa_pulp,
                    sells_fruit_gourd: farmer.farmerCocoa.amount_fruit_gourd ? true : false,
                    amount_fruit_gourd: farmer.farmerCocoa.amount_fruit_gourd,
                    sells_cocoa_honey: farmer.farmerCocoa.amount_cocoa_honey ? true : false,
                    amount_cocoa_honey: farmer.farmerCocoa.amount_cocoa_honey,
                 
                });
            }
        }
    }
    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        if(!!paramsEnviar.participate_association_cooperative){
            if(paramsEnviar.participate_association_cooperative == 'S'){
                paramsEnviar.participate_association_cooperative = true;
            }else{
                paramsEnviar.participate_association_cooperative = false;
            }
        } 
        
        if(paramsEnviar.amount_cocoa_pulp){
            paramsEnviar.sells_cocoa_pulp = true;
        }
        if(paramsEnviar.amount_fruit_gourd){
            paramsEnviar.sells_fruit_gourd = true;
        }
        if(paramsEnviar.amount_cocoa_honey){
            paramsEnviar.sells_cocoa_honey = true;
        }
        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate({mutation: EDIT_FARMER_CACAU, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
                

            } else {
                console.log("DATA: ", data);
                this.setState({error: []})
                alert("Salvo com sucesso !");

            }
        })
    }
    
          
    render() {

        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={5}>
                        <TextField  onChange={(e) => this.setState({cocoa_area_size: e.target.value})} value={this.state.cocoa_area_size} className={this.props.classes.textField} label="Tamanho em hectares da área de cacau" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={5}>
                        <TextField  onChange={(e) => this.setState({amount_cocoa_tree: e.target.value})} value={this.state.amount_cocoa_tree} className={this.props.classes.textField} label="Quantidade de pés de cacau produtivos no total" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.unknow_amount_cocoa_tree}
                                        onChange={(e) => this.setState({unknow_amount_cocoa_tree: e.target.checked})}
                                        color="primary"
                                    />
                            }
                            label="Não sabe"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12}></Grid>
                </Grid>
                <h4>Precisa replantio ou adensamento ?  </h4>    
                <Grid container spacing={3}>
                     <Grid className={this.props.classes.root} item xl={5}>
                        Quantas Plantas ?
                        <TextField onChange={(e) => this.setState({need_replanting: e.target.value})} value={this.state.need_replanting} className={this.props.classes.textField} label="Quantas plantas?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={5}>
                        Volume produção por ano (@): 
                        <TextField onChange={(e) => this.setState({production_volume_year: e.target.value})} value={this.state.production_volume_year} className={this.props.classes.textField} label="Ano" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={2}>
                        @ 
                        <TextField onChange={(e) => this.setState({production_volume_at: e.target.value})} value={this.state.production_volume_at} className={this.props.classes.textField} label="@" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                </Grid>   
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12}></Grid>
                </Grid>
                <h4>Sistema de produção</h4>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.cabruca_production_system}
                                    onChange={(e) => this.setState({cabruca_production_system: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Cabrura"
                        />
                    </Grid>
                    <Grid container item xl={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.full_sun_production_system}
                                    onChange={(e) => this.setState({full_sun_production_system: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Pleno Sol"
                        />
                    </Grid>
                    <Grid container item xl={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.saf_production_system}
                                    onChange={(e) => this.setState({saf_production_system: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="SAF"
                        />
                    </Grid>
                    <Grid container item xl={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.others_production_system}
                                    onChange={(e) => this.setState({others_production_system: e.target.checked})}
                                    color="primary"
                            />
                            }
                            label="Outros"
                        />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField id="quantidade-de-pes" onChange={(e) => this.setState({description_others_production_system: e.target.value})} value={this.state.description_others_production_system} className={this.props.classes.textField} label="Qual" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                </Grid>
                <h4>Tipo de cacau produzido:</h4>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <FormControlLabel
                            value="naoSabe"
                            control={
                                <Checkbox
                                    checked={this.state.produces_cocoa_commodity}
                                    onChange={(e) => this.setState({produces_cocoa_commodity: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Commodity / Bulk"
                        />
                    </Grid>
                    <Grid container item xl={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.produces_cocoa_superior}
                                    onChange={(e) => this.setState({produces_cocoa_superior: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Superior Bahia"
                        />
                    </Grid>
                    <Grid container item xl={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.produces_cocoa_fine}
                                    onChange={(e) => this.setState({produces_cocoa_fine: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Fino (qualidade e sensorial)"
                        />
                    </Grid>
                </Grid>
                <TextField id="quantidade-de-pes" onChange={(e) => this.setState({percentage_superior_fine: e.target.value})} value={this.state.percentage_superior_fine} className={this.props.classes.textField} label="Qual porcentagem do cacau é Superior ou Fino (65% amêndoas marrois)? %" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                <h4>Variedades cacau cultivadas</h4>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.cultivates_common_variety}
                                    onChange={(e) => this.setState({cultivates_common_variety: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Comum"
                        />
                    </Grid>
                    <Grid container item xl={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.cultivates_cloned_variety}
                                    onChange={(e) => this.setState({cultivates_cloned_variety: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Clonado"
                        />
                    </Grid>
                    <Grid container item xl={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.cultivates_mixed_variety}
                                    onChange={(e) => this.setState({cultivates_mixed_variety: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Misto"
                        />
                    </Grid>
                </Grid>
                <h4>Como é o beneficiamento do cacau?</h4>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField  onChange={(e) => this.setState({harvest_management_beneficiament: e.target.value})} value={this.state.harvest_management_beneficiament} className={this.props.classes.textField} label="Manejo da Colheita" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid container item xl={4}>
                        <TextField  onChange={(e) => this.setState({fermentation_beneficiament: e.target.value})} value={this.state.fermentation_beneficiament} className={this.props.classes.textField} label="Fermentação" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid container item xl={4}>
                        <TextField  onChange={(e) => this.setState({drying_management_beneficiament: e.target.value})} value={this.state.drying_management_beneficiament} className={this.props.classes.textField} label="Sacagem" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                </Grid>
                <h4>Local Armazenamento:</h4>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.warehouse_storage}
                                    onChange={(e) => this.setState({warehouse_storage: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Armazém"
                        />
                    </Grid>
                    <Grid container item xl={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.house_storage}
                                    onChange={(e) => this.setState({house_storage: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Casa"
                        />
                    </Grid>
                    <Grid container item xl={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.specific_depository_storage}
                                    onChange={(e) => this.setState({specific_depository_storage: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Despósito Específico"
                        />
                    </Grid>
                    <Grid container item xl={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.common_depository_storage}
                                    onChange={(e) => this.setState({common_depository_storage: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Despósito Comum"
                        />
                    </Grid>
                    <Grid container item xl={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.other_storage}
                                    onChange={(e) => this.setState({other_storage: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="outros"
                        />
                    </Grid>
                    <Grid container item xl={2}>
                        <TextField id="quantidade-de-pes" onChange={(e) => this.setState({description_other_storage: e.target.value})} value={this.state.description_other_storage} className={this.props.classes.textField} label="Qual?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                </Grid>
                <h4>Tempo Estocamento</h4>
                <Grid container spacing={3}>
                    <Grid container item xl={6}>
                        <TextField id="quantidade-de-pes" onChange={(e) => this.setState({storage_time_in_days: e.target.value})} value={this.state.storage_time_in_days} className={this.props.classes.textField} label="Dias" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid container item xl={6}>
                        <TextField id="quantidade-de-pes" onChange={(e) => this.setState({storage_time_in_months: e.target.value})} value={this.state.storage_time_in_months} className={this.props.classes.textField} label="Meses" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                </Grid>
                <h4>Tipo Sacaria de Armazenamento</h4>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.nylon_sacking}
                                    onChange={(e) => this.setState({nylon_sacking: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Nylon"
                        />
                    </Grid>
                    <Grid container item xl={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.aligning_sacking}
                                    onChange={(e) => this.setState({aligning_sacking: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Alinhagem"
                        />
                    </Grid>
                    <Grid container item xl={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.other_sacking}
                                    onChange={(e) => this.setState({other_sacking: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Outro"
                        />
                    </Grid>
                    <Grid container item xl={3}>
                        <TextField id="quantidade-de-pes" onChange={(e) => this.setState({description_other_sacking: e.target.value})} value={this.state.description_other_sacking}  className={this.props.classes.textField} label="Qual?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                </Grid>
                <h4>Tem participação e associação ou cooperativa</h4>
                <RadioGroup aria-label="gender" name="renda-extra" value={this.state.participate_association_cooperative} onChange={(e) => this.setState({participate_association_cooperative: e.target.value})}>
                    <Grid container spacing={3}>
                        <Grid className={this.props.classes.root} item xl={4}>
                        <FormControlLabel
                            value="N"
                            control={
                                <Radio color="primary" />
                            }
                            label="Não"
                        />
                        </Grid>
                        <Grid className={this.props.classes.root} item xl={4}>
                        <FormControlLabel
                            value="S"
                            control={
                                <Radio color="primary" />
                            }
                            label="Sim"
                        />
                        </Grid>
                        <Grid className={this.props.classes.root} item xl={4}>
                            <TextField id="programa-social" onChange={(e) => this.setState({description_association_cooperative: e.target.value})} value={this.state.description_association_cooperative} className={this.props.classes.textField} label="Quais?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                        </Grid>
                    </Grid>
                </RadioGroup>
                <h4>Como vende o cacau?</h4>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.sells_to_industry}
                                    onChange={(e) => this.setState({sells_to_industry: e.target.checked})}
                                    color="primary"
                            />
                            }
                            label="Direto para indústria"
                        />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.sells_to_cooperative}
                                    onChange={(e) => this.setState({sells_to_cooperative: e.target.checked})}
                                    color="primary"
                            />
                            }
                            label="Para Cooperativa"
                        />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.sells_to_intermediate}
                                    onChange={(e) => this.setState({sells_to_intermediate: e.target.checked})}
                                    color="primary"
                            />
                            }
                            label="Para Intermediário"
                        />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.sells_to_consumer}
                                    onChange={(e) => this.setState({sells_to_consumer: e.target.checked})}
                                    color="primary"
                            />
                            }
                            label="Direto para consumidor"
                        />
                    </Grid>
                </Grid>
                <h4>Dificuldade mais recorrente para comercialização?</h4>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.transport_difficulty}
                                    onChange={(e) => this.setState({transport_difficulty: e.target.checked})}
                                    color="primary"
                            />
                            }
                            label="Transporte"
                        />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.low_price_difficulty}
                                    onChange={(e) => this.setState({low_price_difficulty: e.target.checked})}
                                    color="primary"
                            />
                            }
                            label="Preço baixo"
                        />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.storage_difficulty}
                                    onChange={(e) => this.setState({storage_difficulty: e.target.checked})}
                                    color="primary"
                            />
                            }
                            label="Armazenamento e embalagem"
                        />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.quality_difficulty}
                                    onChange={(e) => this.setState({quality_difficulty: e.target.checked})}
                                    color="primary"
                            />
                            }
                            label="Qualidade"
                        />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.other_difficulty}
                                    onChange={(e) => this.setState({other_difficulty: e.target.checked})}
                                    color="primary"
                            />
                            }
                            label="Outros"
                        />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField id="programa-social" onChange={(e) => this.setState({description_other_difficulty: e.target.value})} value={this.state.description_other_difficulty}  className={this.props.classes.textField} label="Quais?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                </Grid>
                <h4>Da amêndoa de cacau, produtos comercializados, e suas devidas porcentagens?</h4>
                <Grid container spacing={3}>
                    <Grid item xl={4}>
                        <TextField id="programa-social" className={this.props.classes.textField} label="Amêndoas %" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({marketed_percentage_almonds: e.target.value})} value={this.state.marketed_percentage_almonds} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid item xl={4}>
                        <TextField id="programa-social" className={this.props.classes.textField} label="Nibis %" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({marketed_percentage_nibs: e.target.value})} value={this.state.marketed_percentage_nibs} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid item xl={4}>
                        <TextField id="programa-social" className={this.props.classes.textField} label="Chocolate %" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({marketed_percentage_chocolate: e.target.value})} value={this.state.marketed_percentage_chocolate} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid item xl={4}>
                        <TextField id="programa-social" className={this.props.classes.textField} label="Licor %" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({marketed_percentage_liquor: e.target.value})} value={this.state.marketed_percentage_liquor} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid item xl={4}>
                        <TextField id="programa-social" className={this.props.classes.textField} label="Cacauada %" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({marketed_percentage_cocoa_candy: e.target.value})} value={this.state.marketed_percentage_cocoa_candy} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid item xl={4}>
                        <TextField id="programa-social" className={this.props.classes.textField} label="Cacau Mole %" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({marketed_percentage_soft_cocoa: e.target.value})} value={this.state.marketed_percentage_soft_cocoa} variant="outlined" margin="dense" />
                    </Grid>
                </Grid>
                <h4>Derivados de cacay comercializados</h4>
                <Grid container spacing={3}>
                    <Grid item xl={6}>
                        <TextField id="programa-social" className={this.props.classes.textField} label="Polpa - Quanto? ( KG )"  InputLabelProps={{shrink: true,}}onChange={(e) => this.setState({amount_cocoa_pulp: e.target.value})} value={this.state.amount_cocoa_pulp} variant="outlined" margin="dense" />
                        <TextField id="programa-social" className={this.props.classes.textField} label="Fruto/Cabaça - Quanto? ( UNIDADE )" InputLabelProps={{shrink: true,}}  onChange={(e) => this.setState({amount_fruit_gourd: e.target.value})} value={this.state.amount_fruit_gourd} variant="outlined" margin="dense" /> 
                        <TextField id="programa-social" className={this.props.classes.textField} label="Mel de cacau - Quanto? ( LITRO )" InputLabelProps={{shrink: true,}}  onChange={(e) => this.setState({amount_cocoa_honey: e.target.value})} value={this.state.amount_cocoa_honey} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid item xl={6}>
                        <Grid container spacing={3}>
                            {/* <Grid className={this.props.classes.root} item xl={6}>
                                <Fab variant="extended" color="primary" className={this.props.classes.textField}>
                                    <Print />
                                    Imprimir
                                </Fab>
                            </Grid> */}
                            <Grid className={this.props.classes.root} item xl={6}>
                                <Fab variant="extended" color="primary" className={this.props.classes.textField} onClick={() => this.submitIt()}>
                                    <Save />
                                    Salvar
                                </Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}export default withApollo((withStyles(styles))(Cacau));
