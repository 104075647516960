import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { EDIT_ANIMAL_PRODUCTION } from '../../../services/graphql/mutations/management';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';
import MaskedInput from 'react-text-mask';
import gql from 'graphql-tag';

import {
    TextField,
    Grid,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';
import { Add, Print, Save } from '@material-ui/icons';


const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    input: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },  
    radioClass: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },    
    divisor: {
        width: "100%",
        height: "3px",
        backgroundColor: "#000",
        marginTop: "3px",
        marginBottom: "10px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});



class CadProducaoAnimal extends Component { 
    constructor(props) {
        super(props);
        if(!!this.props.manejo.manejo.managementAnimalProduction && this.props.manejo.manejo.managementAnimalProduction != null){
            let manejo = this.props.manejo.manejo;
            this.state = {
                management_id: manejo.managementAnimalProduction.management_id,
                has_dairy_bovines: manejo.managementAnimalProduction.has_dairy_bovines,
                has_beef_bovines: manejo.managementAnimalProduction.has_beef_bovines,
                has_dairy_caprines: manejo.managementAnimalProduction.has_dairy_caprines,
                has_beef_caprines: manejo.managementAnimalProduction.has_beef_caprines,
                has_birds_of_laying: manejo.managementAnimalProduction.has_birds_of_laying,
                has_beef_birds: manejo.managementAnimalProduction.has_beef_birds,
                has_bees_creation: manejo.managementAnimalProduction.has_bees_creation,
                has_ovines: manejo.managementAnimalProduction.has_ovines,
                has_rabbits: manejo.managementAnimalProduction.has_rabbits,
                has_buffalos: manejo.managementAnimalProduction.has_buffalos,
                has_porcines: manejo.managementAnimalProduction.has_porcines,
                has_aquatic_animals: manejo.managementAnimalProduction.has_aquatic_animals,
                aquatic_animals_description: manejo.managementAnimalProduction.aquatic_animals_description,
                has_other_animals: manejo.managementAnimalProduction.has_other_animals,
                other_animals_description: manejo.managementAnimalProduction.other_animals_description,
                animal_waste_treatment: manejo.managementAnimalProduction.animal_waste_treatment,
                in_adequate_conditions: manejo.managementAnimalProduction.in_adequate_conditions,
                not_adequate_conditions_description: manejo.managementAnimalProduction.not_adequate_conditions_description,
                intestinal_worms_control: manejo.managementAnimalProduction.intestinal_worms_control,
                external_parasites_control: manejo.managementAnimalProduction.external_parasites_control,
                common_diseases_treatments: manejo.managementAnimalProduction.common_diseases_treatments,
                welfare_good_water: manejo.managementAnimalProduction.welfare_good_water,
                welfare_good_food: manejo.managementAnimalProduction.welfare_good_food,
                welfare_adequate_facilities: manejo.managementAnimalProduction.welfare_adequate_facilities,
                welfare_adequate_occupancy: manejo.managementAnimalProduction.welfare_adequate_occupancy,
                welfare_shading_areas: manejo.managementAnimalProduction.welfare_shading_areas,
                welfare_sun_pasture_access: manejo.managementAnimalProduction.welfare_sun_pasture_access,
                welfare_proper_bed: manejo.managementAnimalProduction.welfare_proper_bed,
                welfare_other_shapes: manejo.managementAnimalProduction.welfare_other_shapes,
                other_shapes_description: manejo.managementAnimalProduction.other_shapes_description,
                plantel_herd_origin: manejo.managementAnimalProduction.plantel_herd_origin,
            }
        }else{
            this.state = {
                management_id: this.props.manejo.manejo.management_id,
                has_dairy_bovines: null,
                has_beef_bovines: null,
                has_dairy_caprines: null,
                has_beef_caprines: null,
                has_birds_of_laying: null,
                has_beef_birds: null,
                has_bees_creation: null,
                has_ovines: null,
                has_rabbits: null,
                has_buffalos: null,
                has_porcines: null,
                has_aquatic_animals: null,
                aquatic_animals_description: null,
                has_other_animals: null,
                other_animals_description: null,
                animal_waste_treatment: null,
                in_adequate_conditions: null,
                not_adequate_conditions_description: null,
                intestinal_worms_control: null,
                external_parasites_control: null,
                common_diseases_treatments: null,
                welfare_good_water: null,
                welfare_good_food: null,
                welfare_adequate_facilities: null,
                welfare_adequate_occupancy: null,
                welfare_shading_areas: null,
                welfare_sun_pasture_access: null,
                welfare_proper_bed: null,
                welfare_other_shapes: null,
                other_shapes_description: null,
                plantel_herd_origin: null,
            };
        }
    }
    componentDidMount(){
        console.log("MANEJO: ",this.props.manejo.manejo)
    }
  
    submitIt(){
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);
        this.props.client.mutate({mutation: EDIT_ANIMAL_PRODUCTION, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
                
                

            } else {
                console.log("DATA: ", data);
                alert("Salvo com sucesso !");
            }
        })
    }


    render() {
        // console.log("state", this.state)
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <div style={{paddingTop: "10px"}}>
                    <h4>CADASTRO DA PRODUÇÃO ANIMAL {this.props.manejo.manejo.description}</h4>
                    <sub>Quais as atividades de produção animal?</sub>
                    <div style={css.contentColuns}>
                        <div style={css.col1}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.has_dairy_bovines}
                                        onChange={(e) => this.setState({has_dairy_bovines: e.target.checked})}
                                        color="primary"
                                    />
                            }
                            label="Bovinos de leite"/>
                        </div>
                        <div style={css.col1}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.has_beef_bovines}
                                        onChange={(e) => this.setState({has_beef_bovines: e.target.checked})}
                                        color="primary"
                                    />
                            }
                            label="Bovinos de corte"/>
                        </div>
                        <div style={css.col1}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.has_dairy_caprines}
                                        onChange={(e) => this.setState({has_dairy_caprines: e.target.checked})}
                                        color="primary"
                                    />
                            }
                            label="Caprinos de leite"/>
                        </div>
                        <div style={css.col1}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.has_beef_caprines}
                                        onChange={(e) => this.setState({has_beef_caprines: e.target.checked})}
                                        color="primary"
                                    />
                            }
                            label="Caprinos de corte"/>
                        </div>
                        <div style={css.col1}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.has_birds_of_laying}
                                        onChange={(e) => this.setState({has_birds_of_laying: e.target.checked})}
                                        color="primary"
                                    />
                            }
                            label="Aves de postura"/>
                        </div>
                        <div style={css.col1}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.has_beef_birds}
                                        onChange={(e) => this.setState({has_beef_birds: e.target.checked})}
                                        color="primary"
                                    />
                            }
                            label="Aves de corte"/>
                        </div>

                        <div style={css.col1}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.has_bees_creation}
                                        onChange={(e) => this.setState({has_bees_creation: e.target.checked})}
                                        color="primary"
                                    />
                            }
                            label="Criação de abelhas"/>
                        </div>

                        <div style={css.col}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.has_ovines}
                                        onChange={(e) => this.setState({has_ovines: e.target.checked})}
                                        color="primary"
                                    />
                            }
                            label="Ovinos"/>
                        </div>

                        <div style={css.col1}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.has_rabbits}
                                        onChange={(e) => this.setState({has_rabbits: e.target.checked})}
                                        color="primary"
                                    />
                            }
                            label="Coelhos"/>
                        </div>

                        <div style={css.col1}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.has_buffalos}
                                        onChange={(e) => this.setState({has_buffalos: e.target.checked})}
                                        color="primary"
                                    />
                            }
                            label="Búfalos"/>
                        </div>

                        <div style={css.col1}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.has_porcines}
                                        onChange={(e) => this.setState({has_porcines: e.target.checked})}
                                        color="primary"
                                    />
                            }
                            label="Suínos"/>
                        </div>
                    </div>
                    <div style={css.contentColuns}>
                        <div style={css.col1}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                        checked={this.state.has_aquatic_animals}
                                        onChange={(e) => this.setState({has_aquatic_animals: e.target.checked})}
                                        color="primary"
                                    />
                            }
                            label="Animais aquáticos"/>
                        </div>
                        <div style={css.col1}>
                            <TextField 
                                id="agricultor" 
                                label="Descrição de animais aquáticos" 
                                InputLabelProps={{shrink: true,}} 
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({aquatic_animals_description: e.target.value})} 
                                value={this.state.aquatic_animals_description}
                                style={css.input}
                            />
                        </div>
                        <div style={css.col1}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.has_other_animals}
                                    onChange={(e) => this.setState({has_other_animals: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Outros animais"/>
                        </div>
                        <div style={css.col1}>
                            <TextField 
                                id="agricultor" 
                                label="Descrição de outros animais" 
                                InputLabelProps={{shrink: true,}} 
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({other_animals_description: e.target.value})} 
                                value={this.state.other_animals_description}
                                style={css.input}
                            />
                        </div>
                    </div>
                </div>
                <hr style={css.hr} />
                <div style={css.contentColuns}>
                    <TextField 
                        id="agricultor" 
                        label="Tratamento e destino de resíduos gerados pela criação de animais" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" 
                        onChange={(e) => this.setState({animal_waste_treatment: e.target.value})} 
                        value={this.state.animal_waste_treatment}
                        style={css.input}
                    />
                </div>
                <sub>Os animais vivem em condições adequadas?</sub>
                <div style={css.contentColuns}>
                    <div style={css.col1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.in_adequate_conditions == true}
                                    onChange={(e) => this.setState({in_adequate_conditions: true})}
                                    color="primary"
                                />
                            }
                            label="Sim"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.in_adequate_conditions == false}
                                    onChange={(e) => this.setState({in_adequate_conditions: false})}
                                    color="primary"
                                />
                            }
                            label="Não"
                        />
                    </div>

                    <div style={css.col3}>
                        <TextField 
                            id="agricultor" 
                            label="Razões para os animais que não vivem em condições adequadas" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({not_adequate_conditions_description: e.target.value})} 
                            value={this.state.not_adequate_conditions_description}
                            style={css.input}
                        />
                    </div>

                </div>
                <div style={css.contentColuns}>
                    <TextField 
                        id="agricultor" 
                        label="Prevenção e controle de vermes intestinais" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" 
                        onChange={(e) => this.setState({intestinal_worms_control: e.target.value})} 
                        value={this.state.intestinal_worms_control}
                        style={css.input}
                    />
                </div>
                <div style={css.contentColuns}>
                    <TextField 
                        id="agricultor" 
                        label="Prevenção e controle de parasitas externos" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" 
                        onChange={(e) => this.setState({external_parasites_control: e.target.value})} 
                        value={this.state.external_parasites_control}
                        style={css.input}
                    />
                </div>
                <div style={css.contentColuns}>
                    <TextField 
                        id="agricultor" 
                        label="Doenças mais comuns e usadas" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" 
                        onChange={(e) => this.setState({common_diseases_treatments: e.target.value})} 
                        value={this.state.common_diseases_treatments}
                        style={css.input}
                    />
                </div>
                <hr style={css.hr}/>
                <sub>Como promove o bem-estar animal</sub>
                <div style={css.contentColuns}>
                    <div style={css.col1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.welfare_good_water}
                                    onChange={(e) => this.setState({welfare_good_water: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Água de boa qualidade"
                        />
                    </div>
                    <div style={css.col1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.welfare_good_food}
                                    onChange={(e) => this.setState({welfare_good_food: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Alimentos de boa qualidade"
                        />
                    </div>
                    <div style={css.col1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.welfare_adequate_facilities}
                                    onChange={(e) => this.setState({welfare_adequate_facilities: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Instalações adequadas e confortáveis"
                        />
                    </div>
                    <div style={css.col1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.welfare_adequate_occupancy}
                                    onChange={(e) => this.setState({welfare_adequate_occupancy: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Ocupação adequada"
                        />
                    </div>
                    <div style={css.col1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.welfare_shading_areas}
                                    onChange={(e) => this.setState({welfare_shading_areas: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Áreas sombreadas no pasto"
                        />
                    </div>
                    <div style={css.col1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.welfare_sun_pasture_access}
                                    onChange={(e) => this.setState({welfare_sun_pasture_access: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Acesso diário a áreas com sol e pastagem"
                        />
                    </div>
                    <div style={css.col1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.welfare_proper_bed}
                                    onChange={(e) => this.setState({welfare_proper_bed: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Manejo adequado do leito"
                        />
                    </div>
                    <div style={css.col1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.welfare_other_shapes}
                                    onChange={(e) => this.setState({welfare_other_shapes: e.target.checked})}
                                    color="primary"
                                />
                            }
                            label="Outras formas"
                        />
                    </div>
                </div>
                <div style={css.contentColuns}>
                    <TextField 
                        id="agricultor" 
                        label="Descrição de outras formas" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" 
                        onChange={(e) => this.setState({other_shapes_description: e.target.value})} 
                        value={this.state.other_shapes_description}
                        style={css.input}
                    />
                </div>
                <div style={css.contentColuns}>
                    <TextField 
                        id="agricultor" 
                        label="O tipo de plantel/rebanho é adicionado à compra de animais. São de origem orgânica?" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" 
                        onChange={(e) => this.setState({plantel_herd_origin: e.target.value})} 
                        value={this.state.plantel_herd_origin}
                        style={css.input}
                    />
                </div>
                <div style={css.contentColuns}>
                    <Fab variant="extended" color="primary" onClick={() => this.submitIt()}>
                        <Save />
                        Criar
                    </Fab>
                </div>
            </div>
        );
    }
}
export default withApollo((withStyles(styles))(CadProducaoAnimal));

const css = {
    contentColuns: {
        display:"flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    col1: {
        maxWidth: "25%",
        minWidth: "25%",
        flex: 1
    },
    col2: {
        maxWidth: "50%",
        minWidth: "50%",
        flex: 1
    },
    col3: {
        maxWidth: "75%",
        minWidth: "75%",
        flex: 1
    },
    col4: {
        maxWidth: "100%",
        minWidth: "100%",
        flex: 1
    },
    input: {
       flex: 1,
       display: "flex",
       marginRight: 15
    },
    hr: {
        marginTop: "20px",
        marginBottom: "20px"
    }
}