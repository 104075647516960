import gql from 'graphql-tag';

export const PROD_UNIT_QUERY = gql`

query ProductiveUnitQuery( $productive_unit_id: Int, $name: String, $state: String, $city: String, $status: String ){
  ProductiveUnitQuery ( productive_unit_id: $productive_unit_id, name: $name, state: $state, city: $city, status: $status ){
      productive_unit_id
      group_id
      core_id
      station_id
      name
      cities_involved
      states_involved
      coordinator
      comercial_phone
      cell_phone
      email
      country
      state
      city
      district
      address
      address_number
      address_complement
      zip_code
      legal_document
      state_registration
      municipal_registration
      status
    }
}
`;