import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../CustomErrors/CustomErrors';

import {
    TextField,
    Grid,
    MenuItem,
    Fab,

    FormControl,
    InputLabel,
    Select
} from '@material-ui/core';
import MaskedInput from 'react-text-mask';

import { Save } from '@material-ui/icons';
import { withApollo, graphql, Query } from 'react-apollo';
import { CREATE_GROUP, EDIT_GROUP  } from '../../services/graphql/mutations/group';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    }
});
const listStates = [
    {
        value: 'active',
        label: 'Ativo',
    },
    {
        value: 'inactive',
        label: 'Inativo',
    },
    {
        value: 'validate',
        label: 'Validar',
    },
];

const Celular = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={['5','5',' ','\(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        showMask
      />
    );
}

class Formulario extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: '', 
            cities_involved: '', 
            states_involved: '', 
            coordinator: '', 
            comercial_phone: '', 
            cell_phone: '', 
            email: '', 
            country: 'BR', 
            state: '', 
            city: '', 
            district: '', 
            address: '', 
            address_number: '', 
            address_complement: '', 
            zip_code: '', 
            legal_document: '', 
            state_registration: '', 
            municipal_registration: '', 
            status: '',
            error: [],
        }
    }
    componentWillReceiveProps(props,state){
        console.log("MEUS State: ",state)
        console.log("MEUS PROPS: ",props)
        let group = props.group
        this.setState({
            group_id: group.group_id,
            name: group.name, 
            cities_involved: group.cities_involved, 
            states_involved: group.states_involved, 
            coordinator: group.coordinator, 
            comercial_phone: group.comercial_phone, 
            cell_phone: group.cell_phone, 
            email: group.email, 
            country: group.country, 
            state: group.state, 
            city: group.city, 
            district: group.district, 
            address: group.address, 
            address_number: group.address_number, 
            address_complement: group.address_complement, 
            zip_code: group.zip_code, 
            legal_document: group.legal_document, 
            state_registration: group.state_registration, 
            municipal_registration: group.municipal_registration, 
            status: group.status
        })
    }
    
    handleChange = event => {
        this.setState({status:event.target.value});
    };
    handleChangeMask = name => event => {
        this.setState({
        [name]: event.target.value,
        });
    };

    submit = () => {
        console.log("SUBMIT STATE: ",this.state);
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        if(!paramsEnviar.name){
            this.setState({error: [{message: "Você deve preencher o Nome do grupo."}]})
            return false;
        }else if(!paramsEnviar.status){
            this.setState({error: [{message: "Você deve setar o Status."}]})
            return false;
        }
        if(paramsEnviar.comercial_phone) paramsEnviar.comercial_phone = paramsEnviar.comercial_phone.replace(/\D+/g, '');
        if(paramsEnviar.cell_phone) paramsEnviar.cell_phone = paramsEnviar.cell_phone.replace(/\D+/g, '');


        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate(
            {
                mutation: this.state.group_id ? EDIT_GROUP : CREATE_GROUP, 
                variables: paramsEnviar,
                errorPolicy: 'all',
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
                
    
            } else {
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");

            }
        })
        .catch(erro => {
            console.log(erro)
        })
    }
    render(){
        return(
            <div className={this.props.classes.content}>
                <CustomErrors error={this.state.error}/>
                <sub>
                    <span>*</span> Informações Obrigatórias
                </sub>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField id='group-nome' 
                        className={this.props.classes.textField} 
                        InputLabelProps={{shrink: true,}}
                        label="Nome*" variant="outlined" margin="dense" 
                        onChange={(e) => this.setState({name: e.target.value})}
                        value={this.state.name} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6}>
                        <FormControl variant="outlined" className={this.props.classes.textField}>
                            <InputLabel id="group-status">Status*</InputLabel>
                            <Select
                                native
                                value={this.state.status}
                                InputLabelProps={{shrink: true,}}
                                onChange={this.handleChange}
                                labelId="group-status"
                            >
                            <option value=""></option>
                            <option value="active">Ativo</option>
                            <option value="inactive">Inativo</option>
                            <option value="validate">Validar</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField id='group-cities-involved' 
                        className={this.props.classes.textField} 
                        InputLabelProps={{shrink: true,}}
                        label="Municípios envolvidos" variant="outlined" margin="dense" 
                        onChange={(e) => this.setState({cities_involved: e.target.value})}
                        value={this.state.cities_involved} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField id='group-states-involved' 
                        className={this.props.classes.textField} 
                        InputLabelProps={{shrink: true,}}
                        label="Estados envolvidos" variant="outlined" margin="dense" 
                        onChange={(e) => this.setState({states_involved: e.target.value})}
                        value={this.state.states_involved} />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField id='group-coordinator' 
                        className={this.props.classes.textField} 
                        InputLabelProps={{shrink: true,}}
                        label="Coordenador" variant="outlined" margin="dense" 
                        onChange={(e) => this.setState({coordinator: e.target.value})}
                        value={this.state.coordinator} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField id='group-email' 
                        className={this.props.classes.textField} 
                        InputLabelProps={{shrink: true,}}
                        label="Email" variant="outlined" margin="dense" 
                        onChange={(e) => this.setState({email: e.target.value})}
                        value={this.state.email} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={2}>
                        <TextField id='group-comercial-phone' 
                            className={this.props.classes.textField} 
                            InputLabelProps={{shrink: true,}}
                            label="Telefone Comercial" variant="outlined" margin="dense" 
                            InputProps={{
                                inputComponent: Celular,
                                onChange: this.handleChangeMask('comercial_phone'),
                                value: this.state.comercial_phone ? this.state.comercial_phone : '',
                            }}
                        />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={2}>
                        <TextField id='group-cell-phone' 
                            className={this.props.classes.textField} 
                            InputLabelProps={{shrink: true,}}
                            label="Celular" variant="outlined" margin="dense" 
                            InputProps={{
                                inputComponent: Celular,
                                onChange: this.handleChangeMask('cell_phone'),
                                value: this.state.cell_phone ? this.state.cell_phone : '',
                            }}                        
                        />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={2}>
                        <TextField id='group-zip-code' 
                        className={this.props.classes.textField} 
                        InputLabelProps={{shrink: true,}}
                        label="CEP" variant="outlined" margin="dense" 
                        onChange={(e) => this.setState({zip_code: e.target.value})}
                        value={this.state.zip_code} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={5}>
                        <TextField id='group-address' 
                        className={this.props.classes.textField} 
                        InputLabelProps={{shrink: true,}}
                        label="Endereço" variant="outlined" margin="dense" 
                        onChange={(e) => this.setState({address: e.target.value})}
                        value={this.state.address} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={1}>
                        <TextField id='group-address-number' 
                        className={this.props.classes.textField} 
                        InputLabelProps={{shrink: true,}}
                        label="Nº" variant="outlined" margin="dense" type="number" 
                        onChange={(e) => this.setState({address_number: e.target.value})}
                        value={this.state.address_number} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField id='group-address-complement' 
                        className={this.props.classes.textField} 
                        InputLabelProps={{shrink: true,}}
                        label="Complemento" variant="outlined" margin="dense" 
                        onChange={(e) => this.setState({address_complement: e.target.value})}
                        value={this.state.address_complement} />
                    </Grid>


                    <Grid className={this.props.classes.root} item xl={6}>
                        <TextField id='group-city' 
                        className={this.props.classes.textField} 
                        InputLabelProps={{shrink: true,}}
                        label="Cidade" variant="outlined" margin="dense" 
                        onChange={(e) => this.setState({city: e.target.value})}
                        value={this.state.city} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6}>
                        <TextField id='group-state' 
                        className={this.props.classes.textField} 
                        InputLabelProps={{shrink: true,}}
                        label="Estado" variant="outlined" margin="dense" 
                        onChange={(e) => this.setState({state: e.target.value})}
                        value={this.state.state} />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={2}>
                        <TextField id='group-legal-document' 
                        className={this.props.classes.textField} 
                        InputLabelProps={{shrink: true,}}
                        label="CNPJ" variant="outlined" margin="dense" 
                        onChange={(e) => this.setState({legal_document: e.target.value})}
                        value={this.state.legal_document} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={5}>
                        <TextField id='group-state-registration' 
                        className={this.props.classes.textField} 
                        InputLabelProps={{shrink: true,}}
                        label="Inscrição Estadual" variant="outlined" margin="dense" 
                        onChange={(e) => this.setState({state_registration: e.target.value})}
                        value={this.state.state_registration} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={5}>
                        <TextField id='group-municipal-registration' 
                        className={this.props.classes.textField} 
                        InputLabelProps={{shrink: true,}}
                        label="Inscrição Municipal" variant="outlined" margin="dense" 
                        onChange={(e) => this.setState({municipal_registration: e.target.value})}
                        value={this.state.municipal_registration} />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={6}>
                        <Fab variant="extended" className={this.props.classes.textField} color="primary" onClick={() => this.submit()}>
                            <Save />
                            Salvar
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Formulario));