import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { EDIT_CUSTOMER, CREATE_CUSTOMER } from '../../services/graphql/mutations/customer';
import CustomErrors from '../CustomErrors/CustomErrors';

import { withStyles } from '@material-ui/core/styles';
import {
    TextField,
    MenuItem,
    Paper,
    Grid,
    Divider,
    FilledInput,
    Fab,
} from '@material-ui/core';
import { Add, Print, Save, StreetviewOutlined } from '@material-ui/icons';
import MaskedInput from 'react-text-mask';

const Celular = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={['5','5',' ','\(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        showMask
      />
    );
}
/*
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
*/

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});

const estacoesSubestacoes = [
    {
        value: 1,
        label: 'Raizes do Sertão',
    },
];
const tiposDeComercializacoes = [
    {
        value: 1,
        label: 'Restaurante',
    },
];

class InfomacoesGeral extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            edit: null,
            login: null,
            email: null,
            msisdn: null,
            phone: null,
            name: null,
            nickname: null,
            password: null,
            birth: null,
            country: null,
            state: null,
            city: null,
            district: null,
            address: null,
            address_number: null,
            address_complement: null,
            zip_code: null,
            reference_point: null,
            latitude: null,
            longitude: null,
            responsible: null,
            comercial_phone: null,
            commercialization_type_id: null,
            other_commercialization_type: null,
            legal_document: null,
            state_registration: null,
            municipal_registration: null,
            customerSitesSocialNetworks: null,
            bank_name: null,
            bank_code: null,
            agency_number: null,
            account_holder: null,
            account_type: null,
            account_number: null,
            error: [],
        };
    } 

    componentWillReceiveProps(props){
        if(props.customer){
            this.setState({'edit': true});
            let customer = props.customer;
            this.setState({
                user_id: customer.id,
                login: !!customer.user ? customer.user.login : null,
                email: !!customer.user ? customer.user.email : null,
                msisdn: !!customer.user ? customer.user.msisdn : null,
                phone: !!customer.user ? customer.user.phone : null,
                name: !!customer.userProfile ? customer.userProfile.name : null,
                nickname: !!customer.userProfile ? customer.userProfile.nickname : null,
                birth: !!customer.userProfile ? customer.userProfile.birth : null,
                country: !!customer.userProfile ? customer.userProfile.country : null,
                state: !!customer.userProfile ? customer.userProfile.state : null,
                city: !!customer.userProfile ? customer.userProfile.city : null,
                district: !!customer.userProfile ? customer.userProfile.district : null,
                address: !!customer.userProfile ? customer.userProfile.address : null,
                address_number: !!customer.userProfile ? customer.userProfile.address_number : null,
                address_complement: !!customer.userProfile ? customer.userProfile.address_complement : null,
                zip_code: !!customer.userProfile ? customer.userProfile.zip_code : null,
                reference_point: !!customer.userProfile ? customer.userProfile.reference_point : null,
                latitude: !!customer.userProfile ? customer.userProfile.latitude : null,
                longitude: !!customer.userProfile ? customer.userProfile.longitude : null,
                responsible: customer.responsible,
                comercial_phone: customer.comercial_phone,
                commercialization_type_id: customer.commercialization_type_id,
                other_commercialization_type: customer.other_commercialization_type,
                legal_document: !!customer.userProfile ? customer.userProfile.legal_document : null,
                state_registration: customer.state_registration,
                municipal_registration: customer.municipal_registration,
                customerSitesSocialNetworks: customer.customerSitesSocialNetworks,
                account_holder: !!customer.bankInformation ? customer.bankInformation.account_holder : null,
                bank_name: !!customer.bankInformation? customer.bankInformation.bank_name : null,
                bank_code: !!customer.bankInformation ? customer.bankInformation.bank_code : null,
                agency_number: !!customer.bankInformation ? customer.bankInformation.agency_number : null,
                account_type:  !!customer.bankInformation ? customer.bankInformation.account_type  : null ,
                account_number: !!customer.bankInformation ? customer.bankInformation.account_number  : null ,
            });
        }
    }
    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        console.log("Params Enviar: ", paramsEnviar)
        if(!!paramsEnviar.email && (paramsEnviar.email == null || paramsEnviar.email == '' || !paramsEnviar.email.includes("@"))){
            console.log("Email zoado");
            delete(paramsEnviar.email)
        }
        if(!this.state.edit){
            if(!paramsEnviar.name){
                this.setState({error: [{message: "Você deve preencher o Nome."}]})
                return false;
            }else if(!paramsEnviar.login){
                this.setState({error: [{message: "Você deve preencher o Nome de Usuario."}]})
                return false;
            }else if(!paramsEnviar.password ){
                this.setState({error: [{message: "Você deve preencher a Senha."}]})
                return false;
            }else if(paramsEnviar.password.length < 8 ){
                this.setState({error: [{message: "A senha deve conter pelo menos 8 caracteres"}]})
                return false;
            }else if (!/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/.test(paramsEnviar.password)){
                this.setState({error: [{message: "A senha deve conter pelo menos 1 número e 1 letra"}]})
                return false;
            }else if(!paramsEnviar.state){
                this.setState({error: [{message: "Você deve preencher o Estado."}]})
                return false;
            }else if(!paramsEnviar.city){
                this.setState({error: [{message: "Você deve preencher a Cidade."}]})
                return false;
            }else if(!paramsEnviar.address){
                this.setState({error: [{message: "Você deve preencher o Endereço."}]})
                return false;      
            }else if(!paramsEnviar.address_number){
                this.setState({error: [{message: "Você deve preencher o Número do Endereço."}]})
                return false;    
            }else if(!paramsEnviar.zip_code){
                this.setState({error: [{message: "Você deve preencher o CEP."}]})
                return false;    
            }else if(!paramsEnviar.phone){
                this.setState({error: [{message: "Você deve preencher o Celular."}]})
                return false;   
            }else if(!paramsEnviar.legal_document){
                this.setState({error: [{message: "Você deve preencher o CPF/CNPJ."}]})
                return false;                   
            }
        }
        // let inconformidades = this.state.inconformidades;
        // let newInconformidades = [];
        // for(let i = 0; i < inconformidades.length; i++){
        //     newInconformidades[i] = {};
        //     if(inconformidades[i].id){
        //         newInconformidades[i].farmer_unconformity_id = inconformidades[i].id;
        //     }else{
        //         newInconformidades[i].farmer_unconformity_id = 0;
        //     }
        //     newInconformidades[i].description = inconformidades[i].description;
        //     newInconformidades[i].resolution_date = inconformidades[i].resolution_date;
        // }
        // console.log("INCONF: ", newInconformidades)
        // if(!!newInconformidades && !!newInconformidades.length){
        //         paramsEnviar = {...paramsEnviar, farmerUnconformities: newInconformidades};
        // }

        if(paramsEnviar.phone) paramsEnviar.phone = paramsEnviar.phone.replace(/\D+/g, '');
        if(paramsEnviar.comercial_phone) paramsEnviar.comercial_phone = paramsEnviar.comercial_phone.replace(/\D+/g, '');
        
        console.log("Enviar Parametros: ", paramsEnviar);
        
        this.props.client.mutate({mutation: this.state.edit ? EDIT_CUSTOMER : CREATE_CUSTOMER , variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }

            } else {
                console.log("DATA: ", data)
                this.setState({error: []});
                alert("Salvo com sucesso !");

            }
        })
    }

    handleChangeMask = name => event => {
        this.setState({
        [name]: event.target.value,
        });
    };
    
    render() {
        return (
            <div>
             <CustomErrors error={this.state.error}/>
                <sub>
                    <span>*</span> Informações Obrigatórias
                </sub>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={4} xs={12}>
                    
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({name: e.target.value})} value={this.state.name ? this.state.name : ''} label="Nome*" variant="outlined" margin="dense" />
                    </Grid>
                    {/* <Grid className={this.props.classes.root} item xl={6}>
                        <TextField
                            id="select-nucleo"
                            select
                            label="Estação/Sub-Estaçnao que Compra*"
                            value={this.state.station_id}
                            onChange={(e) => this.setState({station_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            className={this.props.classes.textField}
                            >
                            {this.state.stations.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid> */}
                    
                    <Grid className={this.props.classes.root} item xl={4} xs={12}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({responsible: e.target.value})} value={this.state.responsible ? this.state.responsible : ''} label="Responsável" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={12}>
                        <TextField  className={this.props.classes.textField} label="Telefone Comercial" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" 
                             InputProps={{
                                    inputComponent: Celular,
                                    value: this.state.comercial_phone ? this.state.comercial_phone : '',
                                    onChange: this.handleChangeMask('comercial_phone'),
                            }}
                        />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={12}>
                        <TextField  className={this.props.classes.textField} label="Celular*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense"
                            InputProps={{
                                    inputComponent: Celular,
                                    value: this.state.phone ? this.state.phone : '',
                                    onChange: this.handleChangeMask('phone'),
                            }}
                          />
                    </Grid>


                    <Grid className={this.props.classes.root} item xl={4} xs={12}>
                        <TextField className={this.props.classes.textField}  onChange={(e) => this.setState({nickname: e.target.value})} value={this.state.nickname ? this.state.nickname : ''}  label="Apelido" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={12}>
                        <TextField className={this.props.classes.textField}  onChange={(e) => this.setState({login: e.target.value})} value={this.state.login ? this.state.login : ''} disabled={!!this.state.edit} label="Nome de usuário*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={12}>
                        <TextField className={this.props.classes.textField}  onChange={(e) => this.setState({password: e.target.value})} label={`Senha - deve conter letras e numeros*`} InputLabelProps={{shrink: true,}} type="password" variant="outlined" margin="dense"  />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={4} xs={12}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({email: e.target.value})} value={this.state.email ? this.state.email : ''} label="Email" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={10}>
                        <TextField  className={this.props.classes.textField} label="Site & Sites Redes Sociais" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={1} xs={2}>
                        <Fab color="primary" size="small" aria-label="add">
                            <Add />
                        </Fab>
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={2} xs={12}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({zip_code: e.target.value})} value={this.state.zip_code ? this.state.zip_code : ''} label="CEP*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={5} xs={12}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({address: e.target.value})} value={this.state.address ? this.state.address : ''} label="Endereço*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={2} xs={12}>
                        <TextField  type="number" className={this.props.classes.textField} onChange={(e) => this.setState({address_number: e.target.value})} value={this.state.address_number ? this.state.address_number : ''} label="Nº*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={12}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({address_complement: e.target.value})} value={this.state.address_complement ? this.state.address_complement : ''} label="Complemento" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={12}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({city: e.target.value})} value={this.state.city ? this.state.city : ''} label="Cidade*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={12}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({state: e.target.value})} value={this.state.state ? this.state.state : ''} label="Estado*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={6} xs={12}>
                        <TextField
                            id="select-nucleo"
                            select
                            label="Tipo de Comercialização"
                            value={this.state.commercialization_type_id ? this.state.commercialization_type_id : ''}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({commercialization_type_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            className={this.props.classes.textField}
                            >
                            {!!this.props.commercialization_types && this.props.commercialization_types.map(option => (
                                <MenuItem key={option.commercialization_type_id} value={option.commercialization_type_id}>
                                {option.description}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={12}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({other_commercialization_type: e.target.value})} value={this.state.other_commercialization_type ? this.state.other_commercialization_type : ''} label="Outros" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={4} xs={12}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({legal_document: e.target.value})} value={this.state.legal_document ? this.state.legal_document : ''} label="CPF/CNPJ*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={12}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({state_registration: e.target.value})} value={this.state.state_registration ? this.state.state_registration : ''} label="Inscrição Estadual" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={12}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({municipal_registration: e.target.value})} value={this.state.municipal_registration ? this.state.municipal_registration : ''} label="Inscrição Municipal" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                </Grid>
                <h4>DADOS BANCÁRIOS</h4>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={6} xs={12}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({account_holder: e.target.value})} value={this.state.account_holder ? this.state.account_holder : ''} label="Nome" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={12}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({bank_name: e.target.value})} value={this.state.bank_name ? this.state.bank_name : ''} label="Nome Banco" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={12}>
                        <TextField  type="number" className={this.props.classes.textField} onChange={(e) => this.setState({bank_code: e.target.value})} value={this.state.bank_code ? this.state.bank_code : ''}  label="Nº Banco"  InputLabelProps={{shrink: true,}}variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={12}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({agency_number: e.target.value})} value={this.state.agency_number ? this.state.agency_number : ''}  label="Nº da Agencia" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={12}>
                        <TextField
                            select
                            label="Tipo Da Conta"
                            value={this.state.account_type}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({account_type: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            >
                                <MenuItem key="CC" value="1">
                                    Conta Corrente
                                </MenuItem>
                                <MenuItem key="CP" value="2">
                                    Poupança
                                </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={12}>
                        <TextField  className={this.props.classes.textField} onChange={(e) => this.setState({account_number: e.target.value})} value={this.state.account_number ? this.state.account_number : ''}  label="Nº da Conta" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>


                    <Grid className={this.props.classes.root} item xl={6} xs={12}>
                        <Fab variant="extended" className={this.props.classes.textField} color="primary" onClick={() => this.submitIt()}>
                            <Save />
                            Salvar
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        );
    }
}export default withApollo((withStyles(styles))(InfomacoesGeral));