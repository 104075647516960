import gql from 'graphql-tag';

export const LIST_GROUP = gql`

query GroupQuery(
    $group_id: Int,
    $name: String,
    $state: String,
    $city: String,
    $status: String
){
  GroupQuery(
      group_id: $group_id, 
      name: $name, 
      state: $state, 
      city: $city, 
      status: $status
    ){
    group_id
    name
    cities_involved
    states_involved
    coordinator
    comercial_phone
    cell_phone
    email
    country
    state
    city
    district
    address
    address_number
    address_complement
    zip_code
    legal_document
    state_registration
    municipal_registration
    status
  }
}
`;