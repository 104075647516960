import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {  EDIT_PRODUCTIVE_UNIT  } from '../../services/graphql/mutations/productUnit';
import {  LIST_GROUP  } from '../../services/graphql/querys/group';
import CustomErrors from '../CustomErrors/CustomErrors';
import MaskedInput from 'react-text-mask';

import { withApollo, graphql, Query } from 'react-apollo';
import {
    TextField,
    Grid,
    MenuItem,
    Fab
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    }
});
const listStates = [
    {
        value: 'active',
        label: 'Ativo',
    },
    {
        value: 'inactive',
        label: 'Inativo',
    },
    {
        value: 'validate',
        label: 'Validar',
    },
];


const Celular = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={['5','5',' ','\(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        showMask
      />
    );
}

let erroCampos = {};
let arrObrigatorio = ['name'];
class Formulario extends Component {
    constructor(props){
        super(props);
        this.state = {
            productive_unit_id: null,
            group_id: "",
            core_id: "",
            name: "",
            cities_involved: "",
            states_involved: "",
            coordinator: "",
            comercial_phone: "",
            cell_phone: "",
            email: "",
            country: "",
            state: "",
            city: "",
            district: "",
            address: "",
            address_number: "",
            address_complement: "",
            zip_code: "",
            legal_document: "",
            state_registration: "",
            municipal_registration: "",
            status: "",
            groups: [],
            error: [],
            inputError: null
        }
    }

    componentWillReceiveProps(props){
        if(!!props.productUnit){
            let productUnit = props.productUnit;
            this.setState({
                productive_unit_id: productUnit.productive_unit_id,
                group_id: productUnit.group_id,
                core_id: productUnit.core_id,
                name: productUnit.name,
                cities_involved: productUnit.cities_involved,
                states_involved: productUnit.states_involved,
                coordinator: productUnit.coordinator,
                comercial_phone: productUnit.comercial_phone,
                cell_phone: productUnit.cell_phone,
                email: productUnit.email,
                country: productUnit.country,
                state: productUnit.state,
                city: productUnit.city,
                district: productUnit.district,
                address: productUnit.address,
                address_number: productUnit.address_number,
                address_complement: productUnit.address_complement,
                zip_code: productUnit.zip_code,
                legal_document: productUnit.legal_document,
                state_registration: productUnit.state_registration,
                municipal_registration: productUnit.municipal_registration,
                status: productUnit.status,
            })
        }
    }

    componentWillMount(){
        this.props.client.query({query: LIST_GROUP, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            } else {
                let groups = data.GroupQuery;
                this.setState({error: []});
                this.setState({groups: groups});
            }
        })
    }

    handleChangeMask = name => event => {
        this.setState({
        [name]: event.target.value,
        });
    };

    submitIt = () => {
        let body = this.state;
        this.setState({error: []})
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        if(!paramsEnviar.name){
            this.setState({error: [{message: "Você deve preencher o Nome da unidade produtiva."}]})
            return false;
        }else if(!paramsEnviar.status){
            this.setState({error: [{message: "Você deve setar o Status."}]})
            return false;
        }

        if(paramsEnviar.comercial_phone) paramsEnviar.comercial_phone = paramsEnviar.comercial_phone.replace(/\D+/g, '');
        if(paramsEnviar.cell_phone) paramsEnviar.cell_phone = paramsEnviar.cell_phone.replace(/\D+/g, '');
        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate({mutation: EDIT_PRODUCTIVE_UNIT, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
 
            } else {
                console.log("DATA: ", data);
               alert("Salvo com sucesso !");

            }
        })
    }

    handleChangeStatus = event => {
        this.setState({status:event.target.value});
    };
    handleChangeGrupo = event => {
        this.setState({group:event.target.value});
    };
    render(){
        return(
            <div className={this.props.classes.content}>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={4} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.name}
                        onChange={(e) => this.setState({name: e.target.value})} value={this.state.name}  label="Nome*" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={6}>
                        <TextField
                            select
                            label="Status*"
                            InputLabelProps={{shrink: true,}}
                            value={this.state.status}
                            error={erroCampos.status}
                            onChange={(e) => this.setState({status: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            className={this.props.classes.textField}
                        >
                        {listStates.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.cities_involved}
                        onChange={(e) => this.setState({cities_involved: e.target.value})} value={this.state.cities_involved}  label="Cidades envolvidas"  InputLabelProps={{shrink: true,}}variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.states_involved}
                        onChange={(e) => this.setState({states_involved: e.target.value})} value={this.state.states_involved}  label="Estados envolvidos" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={2} xs={6}>
                    <TextField
                            select
                            label="Grupo"
                            InputLabelProps={{shrink: true,}}
                            value={this.state.group_id}
                            error={erroCampos.group_id}
                            onChange={(e) => this.setState({group_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            className={this.props.classes.textField}
                        >
                        {this.state.groups.map(option => (
                            <MenuItem key={option.group_id} value={option.group_id}>
                                {option.name}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.coordinator}
                        onChange={(e) => this.setState({coordinator: e.target.value})} value={this.state.coordinator}  label="Coordenador" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.email}
                        onChange={(e) => this.setState({email: e.target.value})} value={this.state.email}  label="Email" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={2} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.comercial_phone}
                        label="Telefone Comercial" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" 
                            InputProps={{
                                inputComponent: Celular,
                                onChange: this.handleChangeMask('comercial_phone'),
                                value: this.state.comercial_phone ? this.state.comercial_phone : '',
                            }}
                        />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={2} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.cell_phone}
                        label="Celular" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" 
                            InputProps={{
                                inputComponent: Celular,
                                onChange: this.handleChangeMask('cell_phone'),
                                value: this.state.cell_phone ? this.state.cell_phone : '',
                            }}      
                        />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={2} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.zip_code}
                        onChange={(e) => this.setState({zip_code: e.target.value})} value={this.state.zip_code}  label="CEP" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={5} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.address}
                        onChange={(e) => this.setState({address: e.target.value})} value={this.state.address}  label="Endereço" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={1} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.address_number}
                        onChange={(e) => this.setState({address_number: e.target.value})} value={this.state.address_number} type="number" label="Nº" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.address_complement}
                        onChange={(e) => this.setState({address_complement: e.target.value})} value={this.state.address_complement}  label="Complemento" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>


                    <Grid className={this.props.classes.root} item xl={6} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.city}
                        onChange={(e) => this.setState({city: e.target.value})} value={this.state.city}  label="Cidade" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={6} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.state}
                        onChange={(e) => this.setState({state: e.target.value})} value={this.state.state}  label="Estado" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={2} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.legal_document}
                        onChange={(e) => this.setState({legal_document: e.target.value})} value={this.state.legal_document}  label="CNPJ" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={5} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.state_registration}
                        onChange={(e) => this.setState({state_registration: e.target.value})} value={this.state.state_registration}  label="Inscrição Estadual" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={5} xs={6}>
                        <TextField  className={this.props.classes.textField} 
                        error={erroCampos.municipal_registration}
                        onChange={(e) => this.setState({municipal_registration: e.target.value})} value={this.state.municipal_registration}  label="Inscrição Municipal" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                    </Grid>
                   
                    <Grid className={this.props.classes.root} item xl={6}>
                        <Fab variant="extended" className={this.props.classes.textField} onClick={() => this.submitIt()} color="primary">
                            <Save />
                            Salvar
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Formulario));