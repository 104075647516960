import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {  STATION_QUERY  } from '../../services/graphql/querys/station';
import { withApollo, graphql, Query } from 'react-apollo';
import CustomErrors from '../CustomErrors/CustomErrors';
import { LISTA_RODADAS } from '../../services/graphql/querys/rodada';
import { CONFIRMA_PEDIDOS, ALTERA_ESTADO_PEDIDO_CLIENTE } from '../../services/graphql/mutations/rodada';

import {
    TextField,
    Grid,
    FormControl,
    FormControlLabel,
    Button,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    DialogTitle,
    Dialog,
    List,
    ListItem,
    Select,
    MenuItem
} from '@material-ui/core';
import { Search, Add, Edit, Delete, Visibility } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    },
    buttonAction: {
        marginLeft: '10px',
        marginRight: '10px',
    },
});


class ListaPedidos extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            id: false,
            rodada: false,
            faltaPedido: false,
            totalPedido: [],
        }
    }
    fazBusca = () => {
        let body = {
            id:  this.props.id,
        }
        console.log("FazBusca", this.props);
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Enviar Parametros: ", paramsEnviar);
            this.props.client.query({query: LISTA_RODADAS, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("DATA: ", data)
                    let rodada = data.CommercialRoundsQuery.data[0];
                    this.setState({'rodada': rodada}); 
                    rodada.purchase_orders.forEach(element => {
                        if(element.status.code != 'approved_waiting_seller_order'){
                            this.setState({faltaPedido: true})
                        }
                    });                   
                }
            })
    }
    componentDidMount(){
        this.fazBusca();
    }

    confirmaPedidos(){
        let body = {
            commercial_round_id:  this.props.id,
        }
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Enviar Parametros: ", paramsEnviar);
            this.props.client.mutate({mutation: CONFIRMA_PEDIDOS, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                    if(errors[0].message == 'validation'){
                        let erroValid = errors[0].extensions.validation;
                        let errosMostrar = [];
                        let i = 0;
                        for (var [key, value] of Object.entries(erroValid)) {
                            console.log("ERRO... " + key + ' ',value);
                            errosMostrar[i] = {message: value};
                            i++;
                        }
                        this.setState({...this.state, error: errosMostrar})
                    }else{
                        let errosMostrar = [];
                        for(var [key, value] of Object.entries(errors)){
                            errosMostrar[key] = {message: value.message};
                        }
                        this.setState({...this.state, error: errosMostrar})
                    }
                } else {
                    console.log("DATA: ", data);
                    this.setState({...this.state, error: []});
                    alert("Salvo com sucesso !");
                    window.location.href = "/rodada/visualizar/" + this.props.id;
                }
            })    
    }

    changeStatus(status, id){
        console.log("Mudar id: " + id + " Para status: " + status)
        this.props.client.mutate({
            mutation: ALTERA_ESTADO_PEDIDO_CLIENTE, 
            variables: {
                id: id,
                status_code: status
            }, 
            errorPolicy: 'all',
        })
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("DATA: ", data);
                alert("Alterado com sucesso !");
                window.location.href = "/rodada/visualizar/" + this.props.id;
            
            }
        }) 
    }
    valorPedido = (index_purchase) => {
        console.log("Este pedido: ", this.state.rodada.purchase_orders[index_purchase])
        let orders = this.state.rodada.purchase_orders[index_purchase];
        let valorTotal = 0;
        for(let i=0; i<orders.items.length; i++){
            valorTotal += orders.items[i].quantity*orders.items[i].unit_price;
        }

        return valorTotal.toFixed(2);
    }
    valorTotal = () => {
        let orders = this.state.rodada.purchase_orders;
        let total = 0;
        for(let h=0; h<orders.length; h++){
            for(let i=0; i<orders[h].items.length; i++){
                total += orders[h].items[i].quantity*orders[h].items[i].unit_price;
            }
        }
        return total.toFixed(2);
    }
    render(){
        const { onClose, selectedValue, open } = this.props;

        const handleClose = () => {
            this.setState({open: false});
        };
        const handleClickOpen = () => {
            this.setState({open: true});
        };
        console.log("Muda EStado", this.state)
        return(
            <div>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={9} xs={9}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={12}>
                                <TextField  className={this.props.classes.textField} id="byName" label="Pedido" variant="outlined" margin="dense" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={3}>
                        <Grid container spacing={2}>
                            <Grid className={this.props.classes.root} align="center" item xl={5} >
                                <Button variant="contained" color="primary" disableElevation className={this.props.classes.button}>
                                    <Search /> Buscar
                                </Button>
                            </Grid>
                            {/* <Grid className={this.props.classes.root} align="center" item xl={5}>
                                <Button variant="contained" color="primary" disableElevation className={this.props.classes.button} component="a" href="/rodada/new">
                                    <Add /> Adicionar
                                </Button>
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <TableContainer component={Paper}>
                            <Table className={this.props.classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Pedido</TableCell>
                                        <TableCell>Consumidor</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Ver</TableCell>
                                        <TableCell>Editar</TableCell>
                                        <TableCell>Refazer</TableCell>
                                        <TableCell align="right">Valor</TableCell>
                                        {/* <TableCell align="center">Excluir</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!this.state.rodada &&  !!this.state.rodada.purchase_orders && this.state.rodada.purchase_orders.map((row,index) => (
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row"> {row.id}</TableCell>
                                            <TableCell component="th" scope="row"> {row.purchaser.__typename == 'Customer' ? row.purchaser.user.profile.name : row.purchaser.name}</TableCell>
                                            <TableCell component="th" scope="row"> {row.status.description}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {(row.status.code == "approved" || row.status.code == "sended" || row.status.code == "delivered" || row.status.code == "approved_waiting_seller_order") &&
                                                    <Fab color="primary" size="small" aria-label="ver" component="a" href={"/rodada/verConferido/"+this.props.id+"/" + row.id} >
                                                        <Visibility />
                                                    </Fab>
                                                }
                                            </TableCell>
                                            
                                                {(row.status.code != "approved" && row.status.code != "sended" && row.status.code != "delivered" && row.status.code != "approved_waiting_seller_order") ? 
                                                <>
                                                    <TableCell component="th" scope="row">
                                                        <Fab color="primary" size="small" aria-label="editar" component="a" href={"/rodada/editar/"+this.props.id+"/" + row.id} >
                                                            <Edit />
                                                        </Fab>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"></TableCell>
                                                </>
                                                
                                                : row.status.code == "approved_waiting_seller_order" ?
                                                    <>
                                                        <TableCell component="th" scope="row"></TableCell>
                                                        <TableCell component="th" scope="row">
                                                        <Fab color="primary" size="small" aria-label="editar" component="a" href={"/rodada/editar/"+this.props.id+"/" + row.id} >
                                                            <Edit />
                                                        </Fab>
                                                        </TableCell>
                                                    </>
                                                :
                                                <>
                                                    <TableCell component="th" scope="row">
                                                        <FormControl >
                                                            <Select
                                                                labelId="demo-customized-select-label"
                                                                id="demo-customized-select"
                                                                displayEmpty
                                                                value={""}
                                                                onChange={(e) => this.changeStatus(e.target.value, row.id)}
                                                                >
                                                                <MenuItem value="" disabled>
                                                                    {row.status.description}
                                                                </MenuItem> 
                                                                {row.status.posible_statuses.map(status => (
                                                                    <MenuItem value={status.code}>{status.description}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row"></TableCell>
                                                    </>
                                                }

                                            <TableCell align="right">
                                            
                                                R$ {this.valorPedido(index)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {!!this.state.rodada &&  !!this.state.rodada.purchase_orders &&
                                    <>
                                        <TableCell component="th" scope="row">Total:</TableCell>
                                        <TableCell component="th" scope="row"></TableCell>
                                        <TableCell component="th" scope="row"></TableCell>
                                        <TableCell component="th" scope="row"></TableCell>
                                        <TableCell component="th" scope="row"></TableCell>
                                        <TableCell  align="right" component="th" scope="row">R$ {this.valorTotal()}</TableCell>
                                    </>
                                    }
                                </TableBody>
                            </Table>
                            {!this.state.faltaPedido && 
                            <Grid className={this.props.classes.root} align="center" item xl={5} >
                                <Button variant="contained" color="primary" disableElevation className={this.props.classes.button} onClick={() => this.confirmaPedidos()}>
                                    <Add /> Confirmar Pedidos
                                </Button>
                            </Grid>
                            }
                        </TableContainer>
                    </Grid>
                </Grid>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
                    <DialogTitle id="simple-dialog-title" align="center">
                        Você realmente deseja<br/>
                        <strong>EXCLUIR</strong> o Grupo X?
                    </DialogTitle>
                    <List>
                        <ListItem>
                            <Button variant="contained" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]} onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant="contained" color="primary" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]}>
                                Continuar
                            </Button>
                        </ListItem>
                    </List>
                </Dialog>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(ListaPedidos));