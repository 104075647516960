import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withApollo, graphql, Query } from 'react-apollo';
import CustomErrors from '../CustomErrors/CustomErrors';

import { COMBOS_DEMANDA_ESTIMADA, RELATORIO_DEMANDA_X_OFERTA } from "../../services/graphql/querys/relatorio";
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableFooter,
    TableRow,
    Paper,
    Grid,
    Input,
    InputLabel,
    TextField,
    Select,
    MenuItem,
    Button
} from '@material-ui/core/';
import Save from '@material-ui/icons/Save';
//import { makeStyles, useTheme } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});

let UserAccount = localStorage.getItem('UserAccount') ? JSON.parse(localStorage.getItem('UserAccount')) : undefined;
class DemandaOferta extends Component {
    constructor(props){
        super(props);
        this.state ={
            estacoes: null,
            grupos: null,
            nucleos: null,
            consumidores: null,
            categoriasConsumidores: null,
            produtos: null,
            categoriasProdutos: null,
            vendedores: null
        }
    }
    componentWillMount(){
        this.getOption()
    }
    getOption = () => {
        let paramsEnviar = {
            station_id: this.state.station_id, 
            product_category_id:  this.state.product_category_id, 
            core_id: this.state.core_id, 
            group_id: this.state.group_id, 
            commercialization_type_id: this.state.commercialization_type_id
        }
        this.props.client.query({query: COMBOS_DEMANDA_ESTIMADA, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors, refetch}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else { 
                this.setState({
                    estacoes: data.StationQuery,
                    grupos: data.GroupQuery,
                    nucleos: data.CoreQuery,
                    consumidores: data.ListCustomersQuery,
                    categoriasConsumidores: data.CommercializationsTypeQuery,
                    produtos: data.ProductQuery,
                    categoriasProdutos: data.ProductCategoryQuery,
                    vendedores: data.ListReportSellersQuery
                }) 
            }
        })
        .catch(erro => console.log(erro))
    }
    render(){
        return (
            <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                <h1 style={{marginBottom: "1rem"}}>Relatorio de Demanda vs. Oferta</h1>
                <Grid container spacing={3}>
                     <Grid className={this.props.classes.root} item xl={12}>
                        Periodo:
                    </Grid>
                </Grid> 
                <Grid container spacing={3}>
                <Grid className={this.props.classes.root} item xl={3}>
                    <TextField 
                        onChange={(e) => this.setState({start_date: e.target.value})} 
                        value={this.state.start_date}
                        className={this.props.classes.textField} 
                        type="date"
                        label="De:" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField 
                        onChange={(e) => this.setState({end_date: e.target.value})} 
                        value={this.state.end_date}  
                        type="date"
                        className={this.props.classes.textField} 
                        label="A:" 
                        InputLabelProps={{shrink: true,}} 
                        variant="outlined" 
                        margin="dense" />
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{marginBottom: "1rem"}}>
                    {
                    UserAccount && UserAccount.profile.user_type === 'admin_central' &&
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField
                            select
                            label="Estação: "
                            className={this.props.classes.textField} 
                            value={this.state.station_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({station_id: e.target.value}, () => this.getOption())}
                            variant="outlined"
                            margin="dense"
                        >
                        <MenuItem key={`option-demanda-real-estacoes-null`} value="">Todas Estações</MenuItem>
                            {
                                this.state.estacoes &&
                                this.state.estacoes.map(
                                    (estacao, index) => {
                                        console.log(estacao);
                                        return <MenuItem key={`option-demanda-real-estacoes-${index}`} value={estacao.id}>{estacao.name}</MenuItem>
                                    }
                                )
                            }
                        </TextField>
                    </Grid>
                    }
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField
                            select
                            label="Produto: "
                            className={this.props.classes.textField} 
                            value={this.state.product_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({product_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                            <MenuItem key={`option-demanda-real-produtos-null`} value="">Todos Produtos</MenuItem>
                            {
                                this.state.produtos &&
                                this.state.produtos.map(
                                    (produto, index) => {
                                        console.log(produto);
                                        return <MenuItem key={`option-demanda-real-produtos-${index}`} value={produto.product_id}>{produto.comercial_name}</MenuItem>
                                    }
                                )
                            }
                        </TextField>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField
                            select
                            label="Categoria Produto: "
                            className={this.props.classes.textField} 
                            value={this.state.product_category_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({product_category_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                            <MenuItem key={`option-demanda-real-categoria-produtos-null`} value="">Todas Categorias</MenuItem>
                            {
                                this.state.categoriasProdutos &&
                                this.state.categoriasProdutos.map(
                                    (categoriaProduto, index) => {
                                        return <MenuItem key={`option-demanda-real-categoria-produtos-${index}`} value={categoriaProduto.product_category_id}>{categoriaProduto.product_category}</MenuItem>
                                    }
                                )
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <CustomErrors error={this.state.error}/>
                <Query query={RELATORIO_DEMANDA_X_OFERTA} variables={
                    UserAccount && UserAccount.profile.user_type === 'admin_central' ?
                    {
                        station_id: this.state.station_id, 
                        core_id: this.state.core_id, 
                        group_id: this.state.group_id, 
                        seller_id: this.state.seller_id, 
                        seller_type: this.state.seller_type, 
                        product_category_id: this.state.product_category_id, 
                        product_id: this.state.product_id,
                        start_date: this.state.start_date, 
                        end_date: this.state.end_date
                    }:
                    {
                        station_id: UserAccount.profile.station.id,
                        product_category_id: this.state.product_category_id,
                        product_id: this.state.product_id
                    }
                }>
                    {
                        ({ loading, error, data }) => {
                            if (loading) {
                                return("Buscando ..." );
                            } else if(error) {
                                console.log("Error",error);
                            } else {
                                console.log("DATA: ",data);
                                return <TableContainer component={Paper}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="th"></TableCell>
                                                <TableCell align="right">Total Ofertado</TableCell>
                                                <TableCell align="right">Total Demandado</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                data.ReportOfferAndDemandQuery.dataReport.map(
                                                    (report, index) => (
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">{report.comercial_name}</TableCell>
                                                            <TableCell align="right">{parseFloat(report.total_offered).toFixed(3)}</TableCell>
                                                            <TableCell align="right">{parseFloat(report.total_demanded).toFixed(3)}</TableCell>
                                                        </TableRow>
                                                    )
                                                )
                                            }
                                        </TableBody>
                                        {
                                            data.ReportOfferAndDemandQuery.dataReport && data.ReportOfferAndDemandQuery.dataReport.length > 0 &&
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell align="right" colSpan={14}>
                                                        <Button variant="contained" color="primary" component="a" href={data.ReportOfferAndDemandQuery.csv.replace("http:","https:")} >
                                                            <Save/> Salvar CSV
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        }
                                    </Table>
                                </TableContainer>
                            }
                        }
                    }
                </Query>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(DemandaOferta));