import React, { Component } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ApolloProvider } from "react-apollo";
import apolloClient from "./services/apollo";
import "moment/locale/pt-br";
import './assets/styles/App.css';
import './assets/styles/Print.css';

import Header from './screens/Header';
import Sidebar from './screens/SideBar';
import Routes from './routes';

class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      display: false
    }
  }
  loginIn = () => { this.setState({display: true}); }
  screenPage = () => { this.setState({display: false}); }
  render(){
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ApolloProvider client={apolloClient}>
          {
          this.state.display ?
          <div className="App">
            <Routes loginIn={() => false} screenPage={() => false} />
          </div>
          :
          <div className="App">
            <Header />
            <Sidebar />
            <Routes loginIn={this.loginIn} screenPage={this.screenPage} />
          </div>
          }
        </ApolloProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

export default App;
