import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Avatar from '@material-ui/core/Avatar';
// import Link from '@material-ui/core/Link';
import Collapse from '@material-ui/core/Collapse';
import '../assets/styles/Sidebar.css';

import {
    // Speed,
    SupervisedUserCircle,
    AccountCircle,
    Group,
    RadioButtonChecked,
    Loyalty,
    MonetizationOn,
    Payment,
    PersonalVideo,
    Person,
    MeetingRoom,
    // AttachMoney,
    Cached,
    AssignmentInd,
    Assessment,
    ExpandLess,
    ExpandMore,
    Spa
} from '@material-ui/icons';

import LocalConvenienceStoreIcon from '@material-ui/icons/LocalConvenienceStore';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 240,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    menuActive: {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
}));

export default function Sidebar(props) {
        const classes = useStyles();
        let param = window.location.pathname;
        let urlArray = param.split('/');
        let [open, setOpen] = React.useState(urlArray[1]);
        let [openItem, setOpenItem] = React.useState(false);

        let handleClick = (item) => {
            setOpen(item);
        };
        let handleClickItem = () => {
            setOpenItem(!openItem);
        };
        let UserAccount = localStorage.getItem('UserAccount') ? JSON.parse(localStorage.getItem('UserAccount')) : undefined;
        console.log("UserAccount", UserAccount)
        return (
            <aside className="left-sidebar" style={{overflow: 'visible'}}>
                <div className="slimScrollDiv" style={{position: 'relative', overflow: 'visible', width: 'auto', height: '100%'}}>
                    <div className="scroll-sidebar" style={{overflow: 'auto', width: 'auto', height: '100%'}}>
                    {UserAccount && UserAccount.profile.user_type === 'admin_central' &&
                        <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={classes.root}
                        >
                            <ListItem>
                                <div style={{textAlign: "center",width: '100%'}}>
                                    <h4 className="MuiTypography-root jss448 MuiTypography-h5">{UserAccount.profile.name}</h4>
                                </div>
                            </ListItem>
                            <ListItem button component="a" href="/usuarios" className={open ==='usuarios' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <Person />
                                </ListItemIcon>
                                <ListItemText primary="Usuários" />
                            </ListItem>

                            <ListItem button component="a" href="/plants-especies" className={open ==='plants-especies' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <Spa />
                                </ListItemIcon>
                                <ListItemText primary="Plantas e Especies" />
                            </ListItem>

                            <ListItem button component="a" href="/produtos" className={open ==='produtos' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <MonetizationOn />
                                </ListItemIcon>
                                <ListItemText primary="Produtos" />
                            </ListItem>
{/* 
                            <ListItem button component="a" href="/valor-produto" className={open=='valor-produto' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <AttachMoney />
                                </ListItemIcon>
                                <ListItemText primary="Valor de Produto" />
                            </ListItem> */}

                            <ListItem button component="a" href="/estacao" className={open ==='estacao' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <PersonalVideo />
                                </ListItemIcon>
                                <ListItemText primary="Estação" />
                            </ListItem>

                            <ListItem button component="a" href="/nucleos" className={open ==='nucleos' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <RadioButtonChecked />
                                </ListItemIcon>
                                <ListItemText primary="Núcleos" />
                            </ListItem>

                            {/* <ListItem button component="a" href="/pedido-rodada" className={open=='pedido-rodada' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <PersonalVideo />
                                </ListItemIcon>
                                <ListItemText primary="Pedido Rodada" />
                            </ListItem> */}

                            <ListItem button component="a" href="/tipos-consumidor" className={open ==='tipos-consumidor' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <AssignmentInd />
                                </ListItemIcon>
                                <ListItemText primary="Tipos Consumidor" />
                            </ListItem>

                            <ListItem button className={open ==='tipos-consumidor' ? classes.menuActive: ''} onClick={handleClickItem}>
                                <ListItemIcon>
                                    <Assessment />
                                </ListItemIcon>
                                <ListItemText primary="Relatorios" />
                                {openItem ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openItem} timeout="auto" unmountOnExit>
                            <List component="a" href="/relatorio/agricultores" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Agricultores" />
                                    </ListItem>
                                </List>
                                <List component="a" href="/relatorio/demanda-estimada" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Demanda Estimada" />
                                    </ListItem>
                                </List>
                                <List component="a" href="/relatorio/demanda-real" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Demanda Real" />
                                    </ListItem>
                                </List>
                                <List component="a" href="/relatorio/demanda-x-oferta" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Demanda x Oferta" />
                                    </ListItem>
                                </List>
                                <List component="a" href="/relatorio/lista-agricultores" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Lista de Agricultores" />
                                    </ListItem>
                                </List>
                                <List component="a" href="/relatorio/lista-clientes" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Exportar cadastro Consumidor" />
                                    </ListItem>
                                </List>
                                <List component="a" href="/relatorio/oferta-real" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Oferta Real" />
                                    </ListItem>
                                </List>
                                <List component="a" href="/relatorio/opac" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Opac" />
                                    </ListItem>
                                </List>
                                {/* <List component="a" href="/relatorio/rodada" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Rodada" />
                                    </ListItem>
                                </List> */}
                            </Collapse>
                            
                            <ListItem button component="a" href="/logout" className={`logout ${open ==='valores-adicionais' ? classes.menuActive: ''}`}>
                                <ListItemIcon>
                                    <MeetingRoom />
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItem>
                        </List>
                    }
                    {UserAccount && (UserAccount.profile.user_type === 'admin_station' || UserAccount.profile.user_type === 'station') &&
                        <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={classes.root}
                        >
                            <ListItem>
                                <div style={{textAlign: "center",width: '100%'}}>
                                    <h4 className="MuiTypography-root jss448 MuiTypography-h5">{UserAccount.profile.name}</h4>
                                    <p className="MuiTypography-root MuiTypography-body2">{UserAccount.profile.station.name}</p>
                                </div>
                            </ListItem>
                            <ListItem button component="a" href="/usuarios" className={open ==='usuarios' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <Person />
                                </ListItemIcon>
                                <ListItemText primary="Usuários" />
                            </ListItem>

                            <ListItem button component="a" href="/agricultores" className={open ==='agricultores' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                <SupervisedUserCircle />
                                </ListItemIcon>
                                <ListItemText primary="Agricultores" />
                            </ListItem>

                            <ListItem button component="a" href="/consumidores" className={open ==='consumidores' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <AccountCircle />
                                </ListItemIcon>
                                <ListItemText primary="Consumidores" />
                            </ListItem>

                            <ListItem button component="a" href="/grupos" className={open ==='grupos' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <Group />
                                </ListItemIcon>
                                <ListItemText primary="Grupos" />
                            </ListItem>

                            <ListItem button component="a" href="/rodada" className={open ==='rodada' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <Cached />
                                </ListItemIcon>
                                <ListItemText primary="Rodada" />
                            </ListItem>

                            <ListItem button component="a" href="/valor-produto" className={open ==='valor-produto' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <MonetizationOn />
                                </ListItemIcon>
                                <ListItemText primary="Valor de Produto" />
                            </ListItem>

                            <ListItem button component="a" href="/produto-estoque" className={open ==='produto-estoque' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <LocalConvenienceStoreIcon />
                                </ListItemIcon>
                                <ListItemText primary="Estoque" />
                            </ListItem>

                            <ListItem button component="a" href="/unidades-produtivas" className={open ==='unidades-produtivas' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <Loyalty />
                                </ListItemIcon>
                                <ListItemText primary="Unidades Produtivas" />
                            </ListItem>

                            <ListItem button component="a" href="/valores-adicionais" className={open ==='valores-adicionais' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <Payment />
                                </ListItemIcon>
                                <ListItemText primary="Valores Adicionais" />
                            </ListItem>

                            <ListItem button className={open ==='tipos-consumidor' ? classes.menuActive: ''} onClick={handleClickItem}>
                                <ListItemIcon>
                                    <Assessment />
                                </ListItemIcon>
                                <ListItemText primary="Relatorios" />
                                {openItem ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openItem} timeout="auto" unmountOnExit>
                                <List component="a" href="/relatorio/agricultores" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Agricultores" />
                                    </ListItem>
                                </List>
                                <List component="a" href="/relatorio/demanda-estimada" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Demanda Estimada" />
                                    </ListItem>
                                </List>
                                <List component="a" href="/relatorio/demanda-real" disablePadding onClick={handleClickItem}>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Demanda Real" />
                                    </ListItem>
                                </List>
                                <List component="a" href="/relatorio/demanda-x-oferta" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Demanda x Oferta" />
                                    </ListItem>
                                </List>
                                <List component="a" href="/relatorio/lista-agricultores" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Exportar Cadastro Agricultores" />
                                    </ListItem>
                                </List>
                                <List component="a" href="/relatorio/lista-clientes" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Exportar Cadastro Consumidor" />
                                    </ListItem>
                                </List>
                                <List component="a" href="/relatorio/oferta-real" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Oferta Real" />
                                    </ListItem>
                                </List>
                                <List component="a" href="/relatorio/opac" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Opac" />
                                    </ListItem>
                                </List>
                                {/* <List component="a" href="/relatorio/rodada" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Rodada" />
                                    </ListItem>
                                </List> */}
                            </Collapse>

                            <ListItem button component="a" href="/logout" className={`logout`}>
                                <ListItemIcon>
                                    <MeetingRoom />
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItem>
                        </List>
                    }
                    {UserAccount && (UserAccount.profile.user_type === 'customer' || UserAccount.profile.user_type === 'farmer') &&
                        <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={classes.root}
                        >
                            <ListItem>
                                <div style={{textAlign: "center",width: '100%'}}>
                                    <h4 className="MuiTypography-root jss448 MuiTypography-h5">{UserAccount.profile.name}</h4>
                                </div>
                            </ListItem>
                            <ListItem button component="a" href="/rodada" className={open ==='rodada' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <Person />
                                </ListItemIcon>
                                <ListItemText primary="Pedidos" />
                            </ListItem>
                        </List>
                    }
                        {/* <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={classes.root}
                        >
                            <ListItem button component="a" href="/">
                                <ListItemIcon>
                                    <Speed />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItem>
                            <ListItem button component="a" href="/usuarios" className={open=='usuarios' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <Person />
                                </ListItemIcon>
                                <ListItemText primary="Usuários" />
                            </ListItem>
                            <ListItem button component="a" href="/agricultores" className={open=='agricultores' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                <SupervisedUserCircle />
                                </ListItemIcon>
                                <ListItemText primary="Agricultores" />
                            </ListItem>

                            <ListItem button component="a" href="/consumidores" className={open=='consumidores' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <AccountCircle />
                                </ListItemIcon>
                                <ListItemText primary="Consumidores" />
                            </ListItem>

                            <ListItem button component="a" href="/grupos" className={open=='grupos' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <Group />
                                </ListItemIcon>
                                <ListItemText primary="Grupos" />
                            </ListItem>

                            <ListItem button component="a" href="/estacao" className={open=='estacao' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <PersonalVideo />
                                </ListItemIcon>
                                <ListItemText primary="Estação" />
                            </ListItem>

                            <ListItem button component="a" href="/nucleos" className={open=='nucleos' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <RadioButtonChecked />
                                </ListItemIcon>
                                <ListItemText primary="Núcleos" />
                            </ListItem>

                            <ListItem button component="a" href="/unidades-produtivas" className={open=='unidades-produtivas' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <Loyalty />
                                </ListItemIcon>
                                <ListItemText primary="Unidades Produtivas" />
                            </ListItem>

                            <ListItem button component="a" href="/produtos" className={open=='produtos' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <MonetizationOn />
                                </ListItemIcon>
                                <ListItemText primary="Produtos" />
                            </ListItem>

                            <ListItem button component="a" href="/valores-adicionais" className={open=='valores-adicionais' ? classes.menuActive: ''}>
                                <ListItemIcon>
                                    <Payment />
                                </ListItemIcon>
                                <ListItemText primary="Valores Adicionais" />
                            </ListItem>
                        </List> */}
                    </div>
                </div>
            </aside>
        );
}