import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    TextField,
    Grid,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Fab,
} from '@material-ui/core';
import { Add, Print, Save } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    radioClass: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },    
    divisor: {
        width: "100%",
        height: "3px",
        backgroundColor: "#000",
        marginTop: "3px",
        marginBottom: "10px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
}));

export default function ProducaoB() {
    const classes = useStyles();
    const [value, setValue] = React.useState();

    const handleChange = event => {
        setValue(event.target.value);
    };

    return (
        <div>
    
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={4}>
                    <div className={classes.radioClass} xl={4}>
                        <FormLabel component="quer_credito" row><b>Tem interesse em crédito </b></FormLabel>
                        <RadioGroup aria-label="quer_credito" name="quer_credito" style={{marginLeft: "10px"}} value={value} onChange={handleChange} row>
                                <FormControlLabel value="S" control={<Radio />} label="Sim" />
                                <FormControlLabel value="N" control={<Radio />} label="Não" />
                        </RadioGroup>                    
                    </div>               
                </Grid>
                <Grid className={classes.root} item xl={4}>
                    <div  className={classes.radioClass} xl={4}>
                        <FormLabel component="finalidade" row><b>Finalidade:  </b></FormLabel>
                        <FormGroup name="finalidade" >
                            <FormControlLabel control={<Checkbox onChange={handleChange} value="custeio" />} label="Custeio" />
                            <FormControlLabel control={<Checkbox onChange={handleChange} value="investimento" />} label="Investimento" />
                        </FormGroup>  
                                   
                    </div>
                </Grid>
                <Grid className={classes.root} item xl={4}>
                    <div  className={classes.radioClass} xl={8} style={{marginLeft: "10px", display: "flex", flexDirection: "row" ,flexWrap: "wrap"}}>

                        <TextField style={{width: "60%"}} id="qtd_custeio" label="Quanto para custeio ? (R$)" variant="outlined" margin="dense" />        
                    </div>
                    <div  className={classes.radioClass} xl={8} style={{marginLeft: "10px", display: "flex", flexDirection: "row" ,flexWrap: "wrap"}}>

                        <TextField style={{width: "60%"}} id="qtd_investimento" label="Quanto para investimento ?" variant="outlined" margin="dense" />        
                    </div>
                </Grid>
            </Grid>

            <div className={classes.divisor}></div>

            <Grid container spacing={3}>
             <Grid className={classes.root} item xl={4}>
                    <div  className={classes.radioClass} xl={4} >
                        <FormLabel component="acompanhamento_tecnico" row><b>Possui acompanhamento tecnico ?  </b></FormLabel>
                        <RadioGroup aria-label="acompanhamento_tecnico" name="acompanhamento_tecnico" style={{marginLeft: "10px"}} value={value} onChange={handleChange} row>
                                <FormControlLabel value="S" control={<Radio />} label="Sim" />
                                <FormControlLabel value="N" control={<Radio />} label="Não" />
                                <TextField id="bemFeitorias_adicional" label="Qual?" variant="outlined" margin="dense" /> 
                        </RadioGroup>     
                                   
                    </div>
                </Grid>
            </Grid>

            <div className={classes.divisor}></div>

            <Grid container spacing={3}>
                <div  className={classes.radioClass} style={{padding: "12px"}} xl={12}>
                    <FormLabel component="certificacao" row><b>Certificação:  </b></FormLabel>
                    <RadioGroup aria-label="certificacao" name="certificacao" style={{marginLeft: "10px"}} value={value} onChange={handleChange} row>
                            <FormControlLabel value="auditada" control={<Radio />} label="Auditada" />
                            <FormControlLabel value="participativa" control={<Radio />} label="Participativa" />
                            <FormControlLabel value="ocs" control={<Radio />} label="OCS" />
                    </RadioGroup>                   
                </div>
            </Grid>

            <div className={classes.divisor}></div>
            <Grid  className={classes.radioClass} style={{padding: "12px"}} container spacing={3}>
                <div className={classes.radioClass} xl={12}>
                    <FormLabel component="formasManejo" xl={4} row><b>Formas de manejo da produção e práticas ágriculas utilizadas:  </b></FormLabel>
                    <FormGroup style={{marginLeft: "10px", display: "flex", flexDirection: "row" ,flexWrap: "wrap", justifyContent: "spaceBetween" }} >
                        <FormControlLabel control={<Checkbox onChange={handleChange} value="saf" />} label="SAF's- Sistema Agroflorestais" />
                        <FormControlLabel control={<Checkbox onChange={handleChange} value="biodinamica" />} label="Biodinâmica" />
                        <FormControlLabel control={<Checkbox onChange={handleChange} value="cabruca" />} label="Cabruca" />
                        <FormControlLabel control={<Checkbox onChange={handleChange} value="tradicional" />} label="Cultivo Tradicional (hortas e palmares)" />
                        <FormControlLabel control={<Checkbox onChange={handleChange} value="outros" />} label="Outros" />
                        <TextField id="formasManejo_adicional" label="Qual?" variant="outlined" margin="dense" /> 
                    </FormGroup>
                    
                </div>
                      
            </Grid>
            
            <div className={classes.divisor}></div>

            <Grid  className={classes.radioClass} style={{padding: "12px"}} container spacing={3}>
                <div className={classes.radioClass} xl={6}>
                    <FormLabel component="formasManejo" xl={6} row><b>Quais? </b></FormLabel>
                    <FormGroup style={{marginLeft: "10px", display: "flex", flexDirection: "row" ,flexWrap: "wrap", justifyContent: "spaceBetween" }} >
                        <FormControlLabel control={<Checkbox onChange={handleChange} value="geleia" />} label="Geléia das frutas" />
                        <FormControlLabel control={<Checkbox onChange={handleChange} value="farinha" />} label="Farinha Mandioca/Biju" />
                        <FormControlLabel control={<Checkbox onChange={handleChange} value="polpas" />} label="Polpa de Frutas" />
                        <FormControlLabel control={<Checkbox onChange={handleChange} value="nibis" />} label="Nibis de Cacau" />
                        <FormControlLabel control={<Checkbox onChange={handleChange} value="chocolates" />} label="Chocolates" />
                        <FormControlLabel control={<Checkbox onChange={handleChange} value="outros" />} label="Outros" />
                        <TextField id="formasManejo_adicional" label="Outros" variant="outlined" margin="dense" /> 
                    </FormGroup>
                </div>

                      
            </Grid>
            <div className={classes.radioClass} xl={6}>
                <TextField style={{width: "100%"}} id="formasManejo_adicional" label="Qual estrutura tem para processar estes produtos ?" variant="outlined" margin="dense" /> 
            </div>

            <Grid container spacing={3}>
                        {/* <Grid className={classes.root} item xl={6}>
                            <Fab variant="extended" color="primary">
                                <Print />
                                Imprimir
                            </Fab>
                        </Grid> */}
                        <Grid className={classes.root} item xl={6}>
                            <Fab variant="extended" color="primary">
                                <Save />
                                Salvar
                            </Fab>
                        </Grid>
                    </Grid>
        </div>
        
    );
}