import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { EDIT_FAMILY } from '../../../services/graphql/mutations/farmer';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';

import {
    TextField,
    MenuItem,
    Grid,
    Fab,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup
} from '@material-ui/core';
import { Add, Print, Save } from '@material-ui/icons';

const nucleos = [
    {
        value: 'married',
        label: 'Casado',
    },
    {
        value: 'single',
        label: 'Solteiro',
    },
    {
        value: 'divorced',
        label: 'Divorciado',
    },
    {
        value: 'widowed',
        label: 'Viuvo',
    },
    {
        value: 'judicially_divorced',
        label: 'Separado Judicialmente',
    },
];

const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});

function Idade(dt) {  
    let hoje = new Date;  
    let nascimento = new Date(dt);  
    var diferencaAnos = hoje.getFullYear() - nascimento.getFullYear();  
    if (new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) <  
        new Date(hoje.getFullYear(), nascimento.getMonth(), nascimento.getDate()))  
      diferencaAnos--;  
    return diferencaAnos;  
}  

class EstruturaFamiliar extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            edit: null,
            idate: null,
            number_children: null,
            marital_status: null,
            value_agricultural_income: null,
            external_income: null,
            retirement_income: null,
            social_program_income: null,
            social_program_description: null,
            work_off_property: null,
            work_off_property_description: null,
            other_external_incomes: null,
            other_external_incomes_description: null,
            external_income_amount: null,
            percentage_income_from_agricultural_activity: null,
            family_members_working_production: null,
            family_members_dependent_property: null,
            family_members: null,
            property_has_family_labor: null,
            property_has_clt_labor: null,
            property_has_daily_labor: null,
            property_has_partnership_labor: null,
            error: [],
        };
    } 
    
    
    componentWillReceiveProps(props){
        if(props.farmer){
            this.setState({'edit': true});
            let farmer = props.farmer;
            this.setState({
                user_id: farmer.id,
                idade: Idade(farmer.userProfile.birth),
            });
            if(farmer.familyStructure && farmer.familyStructure != null){
                this.setState({
                    number_children: farmer.familyStructure.number_children,
                    marital_status: farmer.familyStructure.marital_status,
                    value_agricultural_income: farmer.familyStructure.value_agricultural_income,
                    external_income: farmer.familyStructure.external_income,
                    retirement_income: farmer.familyStructure.retirement_income,
                    social_program_income: farmer.familyStructure.social_program_income,
                    social_program_description: farmer.familyStructure.social_program_description,
                    work_off_property: farmer.familyStructure.work_off_property,
                    work_off_property_description: farmer.familyStructure.work_off_property_description,
                    other_external_incomes: farmer.familyStructure.other_external_incomes,
                    other_external_incomes_description: farmer.familyStructure.other_external_incomes_description,
                    external_income_amount: farmer.familyStructure.external_income_amount,
                    percentage_income_from_agricultural_activity: farmer.familyStructure.percentage_income_from_agricultural_activity,
                    family_members_working_production: farmer.familyStructure.family_members_working_production,
                    family_members_dependent_property: farmer.familyStructure.family_members_dependent_property,
                    family_members: farmer.familyStructure.family_members,
                    property_has_family_labor: farmer.familyStructure.property_has_family_labor,
                    property_has_clt_labor: farmer.familyStructure.property_has_clt_labor,
                    property_has_daily_labor: farmer.familyStructure.property_has_daily_labor,
                    property_has_partnership_labor: farmer.familyStructure.property_has_partnership_labor,
                });
            }
        }
    }
    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate({mutation: EDIT_FAMILY, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
               

            } else {
                console.log("DATA: ", data);
                this.setState({error: []})
               alert("Salvo com sucesso !");
               

            }
        })
    }

      
    render() {
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={6}>
                        <TextField id="idade" type="number" label="Idade" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" value={this.state.idade}/>
                        <TextField type="number" id="quantidade-filhos" label="Quantidade de Filhos" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({number_children: e.target.value})} value={this.state.number_children}/>
                        <TextField
                        id="select-estado-civil"
                        select
                        label="Estado Civil*"
                        value={this.state.marital_status}
                        InputLabelProps={{shrink: true,}}
                        onChange={(e) => this.setState({marital_status: e.target.value})}
                        variant="outlined"
                        margin="dense"
                        >
                        {nucleos.map(option => (

                            <MenuItem name={option.value} key={option.value} value={option.value} >
                            {option.label}
                            </MenuItem>
                        ))}
                        </TextField>

                        <TextField id="valor-renda-agricola" type="number" label="Valor Renda Agrícola" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense"  onChange={(e) => this.setState({value_agricultural_income: e.target.value})} value={this.state.value_agricultural_income}/>
                        <TextField id="programa-social4" type="number" label="Valor Renda Externa" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({external_income_amount: e.target.value})} value={this.state.external_income_amount}/>
                        <h4>Renda Externa:</h4>
                            
                        <FormControlLabel
                            
                            control={
                                <Checkbox color="primary" checked={this.state.external_income}  onChange={(e) => this.setState({external_income: e.target.checked})}/>
                            }
                            label="Não possui"
                        />
                        <FormControlLabel
                            
                            control={
                                <Checkbox color="primary" checked={this.state.retirement_income}  onChange={(e) => this.setState({retirement_income: e.target.checked})}/>
                            }
                            label="Aposentadoria"
                        />
                        <FormControlLabel
                            
                            control={
                                <Checkbox color="primary" checked={this.state.social_program_income}  onChange={(e) => this.setState({social_program_income: e.target.checked})}/>
                            }
                            label="Programa Social"
                        />
                        <TextField id="programa-social1" label="Qual?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({social_program_description: e.target.value})} value={this.state.social_program_description}/>
                        <FormControlLabel
                            
                            control={
                                <Checkbox color="primary" checked={this.state.work_off_property}  onChange={(e) => this.setState({work_off_property: e.target.checked})}/>
                            }
                            label="Trabalho fora da propriedade"
                        />
                        <TextField id="programa-social2" label="Qual?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({work_off_property_description: e.target.value})} value={this.state.work_off_property_description} />
                        <FormControlLabel
                            
                            control={
                                <Checkbox color="primary" checked={this.state.other_external_incomes}  onChange={(e) => this.setState({other_external_incomes: e.target.checked})}/>
                            }
                            label="Outros"
                        />
                        <TextField id="programa-social3" label="Qual?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({other_external_incomes_description: e.target.value})} value={this.state.other_external_incomes_description}/>
                    </Grid>
                    
                    <Grid className={this.props.classes.root} item xl={6}>
                        <TextField id="programa-social5" type="number" label="Percentual da Renda que vem da atividade Agricola" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({percentage_income_from_agricultural_activity: e.target.value})} value={this.state.percentage_income_from_agricultural_activity}/>
                        <TextField id="programa-social6" type="number" label="Quantidade de pessoas da família que trabalha na produção" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({family_members_working_production: e.target.value})} value={this.state.family_members_working_production}/>
                        <TextField id="programa-social7" type="number" label="Quantas pessoas da família dependem da propriedade" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({family_members_dependent_property: e.target.value})} value={this.state.family_members_dependent_property}/>
                        <TextField id="programa-social7" type="number" label="Membros da Familia (Nome e CPF de todos os membros da familia)" InputLabelProps={{shrink: true,}} placeholder="Exemplo: Nome 1 CPF: 000.000.000-01, Nome 2 CPF: 000.000.000-02" variant="outlined" margin="dense" multiline rows={4} onChange={(e) => this.setState({family_members: e.target.value})} value={this.state.family_members}/>
                        <h4>Tipo mão de obra que trabalha na propiedade:</h4>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={this.state.property_has_family_labor}  onChange={(e) => this.setState({property_has_family_labor: e.target.checked})}
                                color="primary"
                                
                            />
                            }
                            label="Família"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={this.state.property_has_clt_labor}  onChange={(e) => this.setState({property_has_clt_labor: e.target.checked})}
                                color="primary"
                            />
                            }
                            label="CLT"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={this.state.property_has_daily_labor}  onChange={(e) => this.setState({property_has_daily_labor: e.target.checked})}
                                color="primary"
                            />
                            }
                            label="Diária"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={this.state.property_has_partnership_labor}  onChange={(e) => this.setState({property_has_partnership_labor: e.target.checked})}
                                color="primary"
                            />
                            }
                            label="Parceria - Meeiro"
                        />
                        <Grid container spacing={3}>
                            {/* <Grid className={this.props.classes.root} item xl={6}>
                                <Fab variant="extended" color="primary">
                                    <Print />
                                    Imprimir
                                </Fab>
                            </Grid> */}
                            <Grid className={this.props.classes.root} item xl={6}>
                                <Fab variant="extended" color="primary" onClick={() => this.submitIt()}>
                                    <Save />
                                    Salvar
                                </Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}export default withApollo((withStyles(styles))(EstruturaFamiliar));

