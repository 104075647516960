import gql from 'graphql-tag';

export const FILL_PURCHASE_ORDER = gql`

mutation  fulfillPurchaseOrder(
  $purchase_order_id: Int!, 
  $fulfillments: [FulfillmentProductInput], 
){
    
  fulfillPurchaseOrder(
    purchase_order_id: $purchase_order_id
    fulfillments: $fulfillments
  ) {
    id
  }
}`;

export const CRIA_RODADA = gql`

mutation  createCommercialRound(
    $description: String,
    $offer_starts_on: String,
    $offer_ends_on: String,
    $order_starts_on: String,
    $order_ends_on: String,
    $status_code: String,
    $sellers: [SellerInput],
    $buyers: [BuyerInput],
    $freights: [Int]
){
    
  createCommercialRound(
    description: $description
    offer_starts_on: $offer_starts_on
    offer_ends_on: $offer_ends_on
    order_starts_on: $order_starts_on
    order_ends_on: $order_ends_on
    status_code: $status_code
    sellers: $sellers
    buyers: $buyers
    freights: $freights
  ) {
    id
    
  }
}`;

export const EDITA_RODADA = gql`

mutation  editCommercialRound(
    $id: Int!,
    $description: String,
    $offer_starts_on: String,
    $offer_ends_on: String,
    $order_starts_on: String,
    $order_ends_on: String,
    $status_code: String,
    $sellers: [SellerInput],
    $buyers: [BuyerInput],
    $freights: [Int]
){
    
  editCommercialRound(
    id: $id
    description: $description
    offer_starts_on: $offer_starts_on
    offer_ends_on: $offer_ends_on
    order_starts_on: $order_starts_on
    order_ends_on: $order_ends_on
    status_code: $status_code
    sellers: $sellers
    buyers: $buyers
    freights: $freights
  ) {
    id
    
  }
}`;

export const CONFIRMA_PEDIDOS = gql`

mutation generateSalesOrders(
    $commercial_round_id: Int!,
){
  generateSalesOrders(
    commercial_round_id: $commercial_round_id
  ) {
    id
  }
}`;


export const ALTERA_ESTADO_PEDIDO_CLIENTE = gql`

mutation editPurchaseOrder(
    $id: Int,
    $status_code: String,
){
  editPurchaseOrder(id: $id , status_code: $status_code ){
    id
  }
  
}`;

export const DELETE_COMMERCIAL_ROUND = gql`

mutation deleteCommercialRound(
  $id: Int!,
){
  deleteCommercialRound(id: $id)
}`;


