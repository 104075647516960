import gql from 'graphql-tag';

export const STATION_QUERY = gql`

query StationQuery( $station_id: Int, $name: String, $state: String, $city: String, $status: String ){
  StationQuery ( station_id: $station_id, name: $name, state: $state, city: $city, status: $status ){
    id
    is_substation
    name
    cities_involved
    states_involved
    coordinator
    comercial_phone
    cell_phone
    email
    country
    state
    city
    district
    address
    address_number
    address_complement
    zip_code
    legal_document
    state_registration
    municipal_registration
    status
    }
}
`;