import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {  USER_QUERY  } from '../../services/graphql/querys/user';
import { withApollo, graphql, Query } from 'react-apollo';

// COMPONENTS
import Lista from '../../Component/Usuarios/List';
import Adicionar from '../../Component/Usuarios/Adicionar';
import Editar from '../../Component/Usuarios/Editar';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});
class Usuarios extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: null,
        }
    }
    componentWillMount(){
        
        if(this.props.match.params.action == 'edit' && this.props.match.params.id){
            let body = {
                id: this.props.match.params.id
            }
            this.props.client.query({query: USER_QUERY, variables: body, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    let user = data.UserQuery[0];
                    console.log("User: ",user)
                    this.setState({user: user});
                }
            })
        }
    }

    render() {
        if(this.props.match.params.action == 'new'){
            return (
                <div className="page-wrapper"  style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <Adicionar/>
                </div>
            );
        } else if(this.props.match.params.action == 'edit'){
            return (
                <div className="page-wrapper"  style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <Editar user={this.state.user}/>
                </div>
            );
        } else {
            return(
                <div className="page-wrapper"  style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <Lista/>
                </div>
            );
        }
    }
}
export default withApollo(withStyles(styles)(Usuarios));