import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {  PRODUCT_QUERY  } from '../../services/graphql/querys/product';
import { withApollo, graphql, Query } from 'react-apollo';
import {
    TextField,
    Grid,
    FormControlLabel,
    Button,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    DialogTitle,
    Dialog,
    List,
    ListItem
} from '@material-ui/core';
import { Search, Add, Edit, Delete } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    },
    buttonAction: {
        marginLeft: '10px',
        marginRight: '10px',
    },
    image: {
        width: '3rem',
        height: '3rem'
    }
});



class Lista extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            products: null,
            byName: null,
        }
    }
    fazBusca = () => {
        let body = {
            unified_product:  this.state.byName,
        }
        console.log("FazBusca", this.props);
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Enviar Parametros: ", paramsEnviar);
            this.props.client.query({query: PRODUCT_QUERY, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("DATA: ", data)
                    this.setState({'products': data.ProductQuery});                    
                }
            })
    }
    componentDidMount(){
        this.fazBusca();
    }
    render(){
        const { onClose, selectedValue, open } = this.props;

        const handleClose = () => {
            this.setState({open: false});
        };
        const handleClickOpen = () => {
            this.setState({open: true});
        };
        return(
            <div>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={9}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={12}>
                                <TextField className={this.props.classes.textField} id="byName" value={this.state.byName} onChange={(e) => this.setState({byName: e.target.value})} label="Busca" variant="outlined" margin="dense" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <Grid container spacing={2}>
                            <Grid className={this.props.classes.root} align="center" item xl={5}>
                                <Button variant="contained" color="primary" onClick={() => this.fazBusca()} disableElevation className={this.props.classes.button}>
                                    <Search /> Buscar
                                </Button>
                            </Grid>
                            <Grid className={this.props.classes.root} align="center" item xl={5}>
                                <Button variant="contained" color="primary" disableElevation className={this.props.classes.button} component="a" href="/produtos/new">
                                    <Add /> Adicionar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <TableContainer component={Paper}>
                            <Table className={this.props.classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell>Imagem</TableCell>
                                        <TableCell align="center">Unidade</TableCell>
                                        <TableCell align="center">Tipo</TableCell>
                                        <TableCell align="center">Editar</TableCell>
                                        {/* <TableCell align="center">Excluir</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {!!this.state.products && this.state.products.map(row => (
                                    <TableRow key={row.product_id}>
                                        <TableCell component="th" scope="row">{row.unified_product}</TableCell>
                                        <TableCell>
                                            <img src={row.picture ? row.urlPicture : "https://crestana.com.br/img/imagens_produto/20190726_200300_1____01%20PRODUTO-SEM-IMAGEM-1000X1000.JPG"} alt={row.unified_product} title={row.unified_product} className={this.props.classes.image} />
                                        </TableCell>
                                        <TableCell align="center">KG</TableCell>
                                        <TableCell align="center">{row.productCategory.product_category}</TableCell>
                                        <TableCell align="center">
                                        <Fab color="primary" size="small" aria-label="editar" component="a" href={"/produtos/edit/" + row.product_id}>
                                            <Edit />
                                        </Fab>
                                        </TableCell>
                                        {/* <TableCell align="center">
                                        <Fab color="primary" size="small" aria-label="search" onClick={handleClickOpen}>
                                            <Delete />
                                        </Fab>
                                        </TableCell> */}
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
                    <DialogTitle id="simple-dialog-title" align="center">
                        Você realmente deseja<br/>
                        <strong>EXCLUIR</strong> o Produto X?
                    </DialogTitle>
                    <List>
                        <ListItem>
                            <Button variant="contained" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]} onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant="contained" color="primary" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]}>
                                Continuar
                            </Button>
                        </ListItem>
                    </List>
                </Dialog>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Lista));