import React, { useState, Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    TextField,
    Typography,
    Box,
    MenuItem,
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Collapse,
    IconButton,
} from '@material-ui/core';
import { 
    Add, 
    Save,
    KeyboardArrowDown,
    KeyboardArrowUp 
} from '@material-ui/icons';
import { withApollo, Query } from 'react-apollo';
import CustomErrors from '../../CustomErrors/CustomErrors';

import { CREATE_CUSTOMER_DEMAND } from '../../../services/graphql/mutations/customer';
import { CUSTOMER_DEMAND } from '../../../services/graphql/querys/customer';

const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    title: {
        fontSize: '0.875rem',
        paddingTop: '12px',
        paddingLeft: '0',
        lineHeight: '1.75'
    }
});

let tipos_demanda = [{tipo: 'in_natura',nome: 'Demanda de Produtos IN NATURA'},
                     {tipo: 'processed', nome: 'Demanda de Produtos Processados'},
                     {tipo: 'missing', nome: 'Demanda de Produtos que faltam'},
                     {tipo: 'offer', nome: 'Oferta de Produtos'}];

class Row extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false
        }
    }
    render(){
        console.log("PROPS: ", this.props.months)
        return (
            <>
                <TableRow>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => this.setState({open: !this.state.open})}>
                            {this.state.open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    </TableCell>
                    <TableCell>{tipos_demanda.map(tdeman => tdeman.tipo == this.props.option.demand_type &&  tdeman.nome)}</TableCell>
                    <TableCell align="right">{this.props.option.product.unified_product}</TableCell>
                    <TableCell align="right">{this.props.option.product.productCategory.product_category}</TableCell>
                    <TableCell align="right">{this.props.option.measure_unit_id}</TableCell>
                    <TableCell align="right">{this.props.option.weekly_amount}</TableCell>
                    <TableCell align="right">{this.props.option.purchase_months}</TableCell>
                    <TableCell align="right">{this.props.option.average_purchase_amount}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse style={{ width: "100%" }} in={this.state.open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                            History
                        </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Jan.</TableCell>
                                    <TableCell>Fev.</TableCell>
                                    <TableCell>Mar.</TableCell>
                                    <TableCell>Abr.</TableCell>
                                    <TableCell>Mai.</TableCell>
                                    <TableCell>Jun.</TableCell>
                                    <TableCell>Jul.</TableCell>
                                    <TableCell>Ago.</TableCell>
                                    <TableCell>Set.</TableCell>
                                    <TableCell>Out.</TableCell>
                                    <TableCell>Nov.</TableCell>
                                    <TableCell>Dez.</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{this.props.option.january_amount} kg</TableCell>
                                    <TableCell>{this.props.option.february_amount} kg</TableCell>
                                    <TableCell>{this.props.option.march_amount} kg</TableCell>
                                    <TableCell>{this.props.option.april_amount} kg</TableCell>
                                    <TableCell>{this.props.option.may_amount} kg</TableCell>
                                    <TableCell>{this.props.option.june_amount} kg</TableCell>
                                    <TableCell>{this.props.option.july_amount} kg</TableCell>
                                    <TableCell>{this.props.option.august_amount} kg</TableCell>
                                    <TableCell>{this.props.option.september_amount} kg</TableCell>
                                    <TableCell>{this.props.option.october_amount} kg</TableCell>
                                    <TableCell>{this.props.option.november_amount} kg</TableCell>
                                    <TableCell>{this.props.option.december_amount} kg</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </Box>
                    </Collapse>
                    </TableCell>
                </TableRow>
            </>
        )
    }
}

class Demanda extends Component {
    constructor(props){
        super(props);
        this.state = {
            user_id: '',
            demand_type: '',
            product_id: '',
            measure_unit_id: 'KG',
            weekly_amount: '',
            purchase_months: '',
            average_purchase_amount: '',
            january_amount: '',
            february_amount: '',
            march_amount: '',
            april_amount: '',
            may_amount: '',
            june_amount: '',
            july_amount: '',
            august_amount: '',
            september_amount: '',
            october_amount: '',
            november_amount: '',
            december_amount: '',
            error: [],
        }
    }
    componentWillMount(){
        if(this.props.customer){
            let customer = this.props.customer;
            this.setState({
                user_id: customer.id,
            });
        }
    }
    submitIt = () => {
        console.log("Estado: ",this.state);

        let customerDemand = {
            demand_type: this.state.demand_type,
            product_id: this.state.product_id,
            measure_unit_id: this.state.measure_unit_id,
            weekly_amount: this.state.weekly_amount,
            purchase_months: this.state.purchase_months,
            average_purchase_amount: this.state.average_purchase_amount,
            january_amount: this.state.january_amount,
            february_amount: this.state.february_amount,
            march_amount: this.state.march_amount,
            april_amount: this.state.april_amount,
            may_amount: this.state.may_amount,
            june_amount: this.state.june_amount,
            july_amount: this.state.july_amount,
            august_amount: this.state.august_amount,
            september_amount: this.state.september_amount,
            october_amount: this.state.october_amount,
            november_amount: this.state.november_amount,
            december_amount: this.state.december_amount
        };

        let paramsEnviar = [];
        paramsEnviar['user_id'] = this.state.user_id;
        paramsEnviar['customerDemand'] = customerDemand;

        console.log("PARAMS ENVIAR: ", paramsEnviar)
        this.props.client.mutate(
            {
                mutation: CREATE_CUSTOMER_DEMAND, 
                variables: paramsEnviar,
                errorPolicy: 'all',
                refetchQueries:[{
                    query: CUSTOMER_DEMAND,
                    variables: 
                    { 
                      id: this.state.user_id,
                    }
                }]
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
    
            } else {
                console.log("DATA: ", data);
                this.setState({error: []});
                this.setState({
                    demand_type: '',
                    product_id: '',
                    measure_unit_id: 'KG',
                    weekly_amount: '',
                    purchase_months: '',
                    average_purchase_amount: '',
                    january_amount: '',
                    february_amount: '',
                    march_amount: '',
                    april_amount: '',
                    may_amount: '',
                    june_amount: '',
                    july_amount: '',
                    august_amount: '',
                    september_amount: '',
                    october_amount: '',
                    november_amount: '',
                    december_amount: ''
                });
                alert("Salvo com sucesso !");
            }
        })

    }
    renderResult(result){
        return (
            result.map((option, index) => {
                let open = false;
                return (<Row key={`table-list-demanda-${index}`} option={option} />)
            })
        )
    }


    render(){
        console.log("state: ",this.state)
        return(
            <div>
                <CustomErrors error={this.state.error}/>
                <sub className={this.props.classes.root}>Demanda de produtos in natura, processados e produtos em geral setem falta de oferta.</sub>
                <sub></sub>

                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField
                                id="select-demand-type"
                                select
                                label="Tipo Demanda"
                                value={this.state.demand_type}
                                InputLabelProps={{shrink: true,}}
                                onChange={(e) => this.setState({demand_type: e.target.value})}
                                variant="outlined"
                                margin="dense"
                                className={this.props.classes.textField}
                            
                            >
                                {
                                    tipos_demanda.map(option => (
                                    <MenuItem key={option.tipo} value={option.tipo}>
                                        {option.nome}
                                    </MenuItem>                           
                                    ))
                                
                                }
                            </TextField>

                    </Grid>

                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField
                            id="select-produto"
                            select
                            label="Produto"
                            value={this.state.product_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({product_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            className={this.props.classes.textField}
                        
                        >
                            {
                            !!this.props.products && this.props.products.map(option => (
                                <MenuItem key={option.product_id} value={option.product_id}>
                                    {option.unified_product}
                                </MenuItem>                           
                                ))
                            
                            }
                        </TextField>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        {/* <TextField  name='Unidade' label="Unidade" variant="outlined" margin="dense" onChange={this.inputChange} value={this.state.measure_unit_id} /> */}
                        <TextField
                            id="select-Unidade"
                            select
                            label="Unidade"
                            value="KG"
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({measure_unit_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            className={this.props.classes.textField}
                        
                        >
        
                            <MenuItem key="KG" value="KG" selected>
                                KG
                            </MenuItem>                           

                        </TextField>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField  name='Semanal' label="Quantidade Semanal" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({weekly_amount: e.target.value})} value={this.state.weekly_amount} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField  name='Mes' label="Mês Compra" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense"  onChange={(e) => this.setState({purchase_months: e.target.value})}  value={this.state.purchase_months} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField  name='Media' label="Valor Médio da Compra" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense"  onChange={(e) => this.setState({average_purchase_amount: e.target.value})}  value={this.state.average_purchase_amount} />
                    </Grid>
                    
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  name='Janeiro' label="Quantidade Janeiro" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({january_amount: e.target.value})} value={this.state.january_amount} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  name='Fevereiro' label="Quantidade Fevereiro" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({february_amount: e.target.value})}  value={this.state.february_amount} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  name='Marco' label="Quantidade Março" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({march_amount: e.target.value})}  value={this.state.march_amount} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  name='Abril' label="Quantidade Abril" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({april_amount: e.target.value})}  value={this.state.april_amount} />
                    </Grid>
                    
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  name='Maio' label="Quantidade Maio" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({may_amount: e.target.value})} value={this.state.may_amount} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  name='Junho' label="Quantidade Junho" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({june_amount: e.target.value})}  value={this.state.june_amount} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  name='Julho' label="Quantidade Julho" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({july_amount: e.target.value})}  value={this.state.july_amount} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  name='Agosto' label="Quantidade Agosto" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({august_amount: e.target.value})}  value={this.state.august_amount} />
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  name='Setembro' label="Quantidade Setembro" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({september_amount: e.target.value})} value={this.state.september_amount} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  name='Outubro' label="Quantidade Outubro" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({october_amount: e.target.value})}  value={this.state.october_amount} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  name='Novembro' label="Quantidade Novembro" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({november_amount: e.target.value})}  value={this.state.november_amount} />
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField  name='Dezembro' label="Quantidade Dezembro" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({december_amount: e.target.value})}  value={this.state.december_amount} />
                    </Grid>
                </Grid>
                

                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={2}>
                        <Button variant="contained" color="primary" onClick={() => this.submitIt()} disableElevation>
                            <Save /> Salvar
                        </Button>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <TableContainer component={Paper}>
                            <Table className={this.props.classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Tipo Demanda</TableCell>
                                        <TableCell align="right">Produto</TableCell>
                                        <TableCell align="right">Categoria Produto</TableCell>
                                        <TableCell align="right">Unidade</TableCell>
                                        <TableCell align="right">Quantidade Semanal</TableCell>
                                        <TableCell align="right">Mês Compra</TableCell>
                                        <TableCell align="right">Valor Médio da Compra</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                <Query query={CUSTOMER_DEMAND} variables={{id: this.state.user_id }}>
                                    {({ loading, error, data }) => {
                                        if (loading) {
                                            return("Buscando ..." );
                                        }else if(error){
                                            console.log("Error",error);
                                        }
                                        console.log("RETORNO: ",data);
                                        return this.renderResult(data.CustomerQuery[0].customerDemands);
                                    }}
                                </Query>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Demanda));