import React, { Component } from 'react';

class Logout extends Component {
    componentWillMount(){
        this.props.loginIn();
        localStorage.removeItem('UserAccount');
        localStorage.removeItem('user_token');
        window.location.reload();
    }
    render() {
        return false
    }
}
export default Logout;