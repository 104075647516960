import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withApollo, graphql, Query } from 'react-apollo';
import { COMMERCIALIZATION_TYPES } from '../../services/graphql/querys/valoresAdicionais';
import { CREATE_COMMERC_TYPE_PROFIT, EDIT_COMMERC_TYPE_PROFIT } from '../../services/graphql/mutations/valoresAdicionais';
import CustomErrors from '../CustomErrors/CustomErrors';

import {
    TextField,
    Grid,
    MenuItem,
    Fab,
} from '@material-ui/core';
import { Save, Edit } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    }
});

class Lucro extends Component {
    constructor(props){
        super(props);
        this.state = {   
            error: [],
        }
    }

    componentDidMount(){
       
        let body = {
            commercialization_type_id:  this.props.id && this.props.id > 0  ? this.props.id : null,
        }
        this.props.id && this.props.id > 0 && this.setState({commercialization_type_id: this.props.id})
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
    
            this.props.client.query({query: COMMERCIALIZATION_TYPES, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("DATA: ", data)
                    this.setState({'commercializationTypes': data.CommercializationsTypeQuery});                    
                }
            })
    }

    submitIt(){
        this.setState({error: []})
        let paramsEnviar = Object.entries(this.state).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        console.log("Enviar Parametros: ", paramsEnviar);
        this.props.client.mutate({mutation: this.props.id > 0 ? EDIT_COMMERC_TYPE_PROFIT : CREATE_COMMERC_TYPE_PROFIT, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({error: [{message: "Tipo de cliente ja cadastrado."}]})
                return false;
                // if(errors[0].message == 'validation'){
                //     let erroValid = errors[0].extensions.validation;
                //     let errosMostrar = [];
                //     let i = 0;
                //     for (var [key, value] of Object.entries(erroValid)) {
                //         console.log("ERRO... " + key + ' ',value);
                //         errosMostrar[i] = {message: value};
                //         i++;
                //     }
                //     this.setState({...this.state, error: errosMostrar})
              
            } else {
                console.log("DATA: ", data);
                this.setState({...this.state, error: []});
                alert("Salvo com sucesso !");
                window.location.href = "/valores-adicionais";
            }
        })    
    }

    render() {

        return (
            <div>
                <Grid container spacing={3}>
                    <CustomErrors error={this.state.error}/>
                    <Grid className={this.props.classes.root} item xl={4} xs={4}>
                      {this.state.commercializationTypes && this.state.commercializationTypes.length > 1 ?
                        <TextField 
                            select
                            id='tipo-cliente' 
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="Tipo de Cliente" 
                            value={this.state.commercialization_type_id}
                            onChange={(e) => this.setState({commercialization_type_id: e.target.value})} 
                            variant="outlined" 
                            margin="dense">

                            {this.state.commercializationTypes && this.state.commercializationTypes.map(option => (
                                <MenuItem key={`core-${option.commercialization_type_id}`} value={option.commercialization_type_id}>
                                    {option.description}
                                </MenuItem>
                            ))}
                        </TextField>
                      :
                        <TextField 
                            id='tipo-cliente2' 
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="Tipo de Cliente" 
                            value={!!this.state.commercializationTypes ? this.state.commercializationTypes[0].description : ""}
                            variant="outlined" 
                            margin="dense"/>
                      }
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={4}>
                        <TextField 
                            id='valor' 
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="Porcentagem (%)" 
                            onChange={(e) => this.setState({profit_margin: e.target.value})} 
                            value={this.state.profit_margin}
                            variant="outlined" 
                            margin="dense"/>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={4}>
                        <Fab variant="extended" className={this.props.classes.textField} color="primary" onClick={() => this.submitIt()}>
                            <Save />
                            Salvar
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withApollo(withStyles(styles)(Lucro));