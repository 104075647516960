import gql from 'graphql-tag';

export const EDIT_PLANTS_SPECIE_QUERY = gql`

mutation  editPlantSpecie (
  $specie_id: Int, 
  $specie: String, 
  $description: String
){
    
  editPlantSpecie(
    specie_id: $specie_id
    specie: $specie
    description: $description
  ) {
    specie_id
    specie
    description
  }
}`;