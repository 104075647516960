import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {  STATION_QUERY  } from '../../services/graphql/querys/station';
import { withApollo, graphql, Query } from 'react-apollo';
import CustomErrors from '../CustomErrors/CustomErrors';
import { LISTA_RODADAS } from '../../services/graphql/querys/rodada';
import { CONFIRMA_PEDIDOS } from '../../services/graphql/mutations/rodada';

import {
    TextField,
    Grid,
    FormControlLabel,
    Button,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    DialogTitle,
    Dialog,
    List,
    ListItem
} from '@material-ui/core';
import { Search, Add, Edit, Delete, Visibility } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    },
    buttonAction: {
        marginLeft: '10px',
        marginRight: '10px',
    }
});


class ListaPedidosFarmer extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            id: false,
            rodada: false,
        }
    }
    fazBusca = () => {
        let body = {
            id:  this.props.id,
        }
        console.log("FazBusca", this.props);
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Enviar Parametros: ", paramsEnviar);
            this.props.client.query({query: LISTA_RODADAS, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("DATA: ", data)
                    let rodada = data.CommercialRoundsQuery.data[0];
                    this.setState({rodada: rodada}); 
                }
            })
    }
    componentDidMount(){
        this.fazBusca();
    }
    valorPedido = (index_purchase) => {
        console.log("Este pedido: ", this.state.rodada.sales_orders[index_purchase])
        let orders = this.state.rodada.sales_orders[index_purchase];
        let valorTotal = 0;
        for(let i=0; i<orders.items.length; i++){
            valorTotal += orders.items[i].quantity*orders.items[i].unit_price;
        }

        return valorTotal.toFixed(2);
    }
    valorTotal = () => {
        let orders = this.state.rodada.sales_orders;
        let total = 0;
        for(let h=0; h<orders.length; h++){
            for(let i=0; i<orders[h].items.length; i++){
                total += orders[h].items[i].quantity*orders[h].items[i].unit_price;
            }
        }
        return total.toFixed(2);
    }
    render(){
        const { onClose, selectedValue, open } = this.props;

        const handleClose = () => {
            this.setState({open: false});
        };
        const handleClickOpen = () => {
            this.setState({open: true});
        };
        console.log(this.state)
        return(
            <div>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={9} xs={9}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={12}>
                                <TextField  className={this.props.classes.textField} id="byName" label="Pedido" variant="outlined" margin="dense" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={3}>
                        <Grid container spacing={2}>
                            <Grid className={this.props.classes.root} align="center" item xl={5} >
                                <Button variant="contained" color="primary" disableElevation className={this.props.classes.button}>
                                    <Search /> Buscar
                                </Button>
                            </Grid>
                            {/* <Grid className={this.props.classes.root} align="center" item xl={5}>
                                <Button variant="contained" color="primary" disableElevation className={this.props.classes.button} component="a" href="/rodada/new">
                                    <Add /> Adicionar
                                </Button>
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <TableContainer component={Paper}>
                            <Table className={this.props.classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Pedido</TableCell>
                                        <TableCell>Produtor</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Conferir Pedido</TableCell>
                                        <TableCell align="right">Valor</TableCell>
                                        {/* <TableCell align="center">Excluir</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                <>
                                    {
                                    !!this.state.rodada &&  
                                    !!this.state.rodada.sales_orders && 
                                    this.state.rodada.sales_orders.map((row,index) => (
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row"> {row.id}</TableCell>
                                            <TableCell component="th" scope="row"> {row.seller.__typename == 'Farmer' ? row.seller.user.profile.name : row.seller.name}</TableCell>
                                            <TableCell component="th" scope="row"> {row.status.description}</TableCell>
                                            <TableCell >
                                            {row.status.code != 'new' && row.status.code != "received" && row.status.code != "rejected_by_supplier" &&
                                                <Fab color="primary" size="small" aria-label="editar" component="a" href={"/rodada/conferir/"+this.props.id+"/" + row.id} >
                                                    <Edit />
                                                </Fab>
                                            }
                                            </TableCell>
                                            {/* <TableCell align="center">
                                            <Fab color="primary" size="small" aria-label="search" onClick={handleClickOpen}>
                                                <Delete />
                                            </Fab>
                                            </TableCell> */}
                                            <TableCell align="right">
                                            
                                            R$ {this.valorPedido(index)}
                                        </TableCell>
                                        </TableRow>
                                        
                                    ))}
                                    {!!this.state.rodada &&  !!this.state.rodada.sales_orders &&
                                        <>
                                            <TableCell component="th" scope="row">Total:</TableCell>
                                            <TableCell component="th" scope="row"></TableCell>
                                            <TableCell component="th" scope="row"></TableCell>
                                            <TableCell component="th" scope="row"></TableCell>
                                            <TableCell  align="right" component="th" scope="row">R$ {this.valorTotal()}</TableCell>
                                        </> 
                                    }
                                    </>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
                    <DialogTitle id="simple-dialog-title" align="center">
                        Você realmente deseja<br/>
                        <strong>EXCLUIR</strong> o Grupo X?
                    </DialogTitle>
                    <List>
                        <ListItem>
                            <Button variant="contained" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]} onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant="contained" color="primary" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]}>
                                Continuar
                            </Button>
                        </ListItem>
                    </List>
                </Dialog>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(ListaPedidosFarmer));