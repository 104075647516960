import gql from 'graphql-tag';

export const COMMERCIALIZATION_TYPES = gql`

query CommercializationsTypeQuery( $commercialization_type_id: Int, $station_id: Int ){
  CommercializationsTypeQuery ( commercialization_type_id: $commercialization_type_id ){
    commercialization_type_id
    description
  }
  StationQuery(station_id: $station_id){
    id
    commercialization_type_id
    is_substation
    name
    stationCommercializationTypeProfit {
      station_id
      commercialization_type_id
      profit_margin
      commercializationType {
        commercialization_type_id
        description
      }
    }
  }
}`;

export const FRETE = gql`

query FreightQuery( $freight_id: Int, $station_id: Int, $name: String, $phone: String, $legal_document: String ){
  FreightQuery(freight_id: $freight_id, station_id: $station_id, name: $name, phone: $phone, legal_document: $legal_document){
    freight_id
    station_id
    name
    description
    email
    phone
    legal_document
    cost
  }
}`;




