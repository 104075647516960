import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { EDIT_VEGETABLE_PRODUCTION, CREATE_PEST_DISEASE_CONTROL, DELETE_PEST_CONTROL } from '../../../services/graphql/mutations/management';
import { PEST_CONTROL } from '../../../services/graphql/querys/manejo';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';
import MaskedInput from 'react-text-mask';
import gql from 'graphql-tag';

import {
    TextField,
    Grid,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';
import { Add, Print, Save, Delete } from '@material-ui/icons';


const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    input: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },  
    radioClass: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },    
    divisor: {
        width: "100%",
        height: "3px",
        backgroundColor: "#000",
        marginTop: "3px",
        marginBottom: "10px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});



class CadProducaoAnimal extends Component { 
    constructor(props) {
        super(props);
        if(!!this.props.manejo.manejo.managementVegetableProduction && this.props.manejo.manejo.managementVegetableProduction != null){
            let manejo = this.props.manejo.manejo;
            this.state = {
                management_id: manejo.managementVegetableProduction.management_id,
                all_crops_produced_organically: manejo.managementVegetableProduction.all_crops_produced_organically,
                seeds_origin: manejo.managementVegetableProduction.seeds_origin,
                fertilization_treatment: manejo.managementVegetableProduction.fertilization_treatment,
                soil_management_practices: manejo.managementVegetableProduction.soil_management_practices,
                initiatives_improve_fertility: manejo.managementVegetableProduction.initiatives_improve_fertility,
                main_spontaneous_weeds: manejo.managementVegetableProduction.main_spontaneous_weeds,
                does_mowing: manejo.managementVegetableProduction.does_mowing,
                does_manual_weeding: manejo.managementVegetableProduction.does_manual_weeding,
                does_grazing: manejo.managementVegetableProduction.does_grazing,
                does_green_adubation: manejo.managementVegetableProduction.does_green_adubation,
                does_shading: manejo.managementVegetableProduction.does_shading,
                does_other_controls: manejo.managementVegetableProduction.does_other_controls,
                other_controls_description: manejo.managementVegetableProduction.other_controls_description,
            }
        }else{
            this.state = {
                management_id: this.props.manejo.manejo.management_id,
                all_crops_produced_organically: null,
                seeds_origin: null,
                fertilization_treatment: null,
                soil_management_practices: null,
                initiatives_improve_fertility: null,
                main_spontaneous_weeds: null,
                does_mowing: null,
                does_manual_weeding: null,
                does_grazing: null,
                does_green_adubation: null,
                does_shading: null,
                does_other_controls: null,
                other_controls_description: null,
            };
        }
    } 
  
    submitIt(){
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);
        this.props.client.mutate({mutation: EDIT_VEGETABLE_PRODUCTION, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
                
                

            } else {
                console.log("DATA: ", data);
                alert("Salvo com sucesso !");
            }
        })
    }

    addPestControl(){
        console.log("Faz Mutation", this.state)

        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate(
            {
                mutation: CREATE_PEST_DISEASE_CONTROL, 
                variables: paramsEnviar,
                errorPolicy: 'all',
                refetchQueries:[{
                    query: PEST_CONTROL,
                    variables: 
                    { 
                        management_id: this.state.management_id,
                    }
                }]
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            }else{
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");                
            }
        })
    }

    renderPestControl(pestsControl){
            return  pestsControl.map((pest) =>
                        <TableRow>
                            <TableCell>{pest.pest_disease_name}</TableCell>
                            <TableCell>{pest.used_product_or_management}</TableCell>
                            <TableCell>{pest.used_where}</TableCell>
                            <TableCell>{pest.used_when}</TableCell>
                            <TableCell>{pest.origin}</TableCell>
                            <TableCell>{pest.composition}</TableCell>

                            <TableCell>
                                <Fab variant="extended" color="primary" onClick={() => this.removePestControl(pest.pest_disease_id)}>
                                    <Delete />
                                    Remover
                                </Fab>
                            </TableCell>
                        </TableRow>
                    )
    }
    removePestControl(id){
        console.log("Remove Esse: ",id)
        this.props.client.mutate({
            mutation: DELETE_PEST_CONTROL, 
            variables: {pest_disease_id: id}, 
            errorPolicy: 'all',
            refetchQueries:[{
                query: PEST_CONTROL,
                variables: 
                { 
                    management_id: this.state.management_id,
                }
            }]
        })
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({open: false, remover: null});
                alert("Erro ao remover!"); 
            } else {
                console.log("DATA: ", data);
                alert("Removido!");
            }
        })
    }

    render() {
        // console.log("state", this.state)
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <div style={{paddingTop: "10px"}}>
                    <h4>CADASTRO DA PRODUÇÃO VEGETAL {this.props.manejo.manejo.description}</h4>
                    <hr style={css.hr}/>
                    <sub><strong>Descreva a sua unidade de produção em relação aos itens apresentados - Produção Vegetal</strong></sub>
                    <div style={css.contentColuns}>
                        <div style={css.col4}>
                            <sub>Todas as culturas são produzidas de forma orgânica ?</sub>

                            <TextField 
                                id="prodVeg" 
                                rows="3"
                                multiline={true}
                                InputLabelProps={{shrink: true,}} 
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({all_crops_produced_organically: e.target.value})} 
                                value={this.state.all_crops_produced_organically}
                                style={css.input}
                            />
                        </div>
                    </div>
                    <div style={css.contentColuns}>

                        <div style={css.col4}>
                            <sub>Qual a origem das sementes e mudas utilizadas na unidade de produção? (Se as sementes são de origem orgânica e qual a porcentagem das sementes que são produzidas na unidade de produção).</sub>
                            <TextField 
                                id="origOrg" 
                                rows="3"
                                multiline={true}
                                InputLabelProps={{shrink: true,}} 
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({seeds_origin: e.target.value})} 
                                value={this.state.seeds_origin}
                                style={css.input}
                            />
                        </div>
                    </div>
                    <div style={css.contentColuns}>

                        <div style={css.col4}>
                            <sub>No caso de uso como adubo de: cama de aviário e estercos de outras criações convencionais e resíduos não ecológicos, que tratamento é dado a estes antes de serem utilizados?</sub>

                            <TextField 
                                id="adubo" 
                                rows="3"
                                multiline={true}
                                InputLabelProps={{shrink: true,}} 
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({fertilization_treatment: e.target.value})} 
                                value={this.state.fertilization_treatment}
                                style={css.input}
                            />
                        </div>
                    </div>
                    <div style={css.contentColuns}>

                        <div style={css.col4}>
                            <sub>Quais as práticas de manejo de solo são utilizadas? ( cobertura verde, rotação de culturas, revolvimento do solo, plantio em nível ou direito, etc.)</sub>

                            <TextField 
                                id="manejoSolo" 
                                rows="3"
                                multiline={true}
                                InputLabelProps={{shrink: true,}} 
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({soil_management_practices: e.target.value})} 
                                value={this.state.soil_management_practices}
                                style={css.input}
                            />
                        </div>
                    </div>
                    <div style={css.contentColuns}>

                        <div style={css.col4}>
                            <sub>Descreva: O que você faz para melhorar a fertilidade do sistema ?</sub>

                            <TextField 
                                id="fertilidade" 
                                rows="3"
                                multiline={true}
                                InputLabelProps={{shrink: true,}} 
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({initiatives_improve_fertility: e.target.value})} 
                                value={this.state.initiatives_improve_fertility}
                                style={css.input}
                            />
                        </div>
                    </div>
                    <div style={css.contentColuns}>

                        <div style={css.col4}>
                            <sub>Quais as principais ervas daninhas/plantas espontâneas que ocorrem nas áreas de cultivo?</sub>

                            <TextField 
                                id="daninha" 
                                rows="2"
                                multiline={true}
                                InputLabelProps={{shrink: true,}} 
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({main_spontaneous_weeds: e.target.value})} 
                                value={this.state.main_spontaneous_weeds}
                                style={css.input}
                            />
                        </div>
                    </div>
                    <div style={css.contentColuns}>
                        <div style={css.col4}>
                        <sub>Como você faz o manejo e controle dessas plantas espontâneas?</sub>
                        </div>
                        <div style={css.col4}>
                           
                            
                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.does_mowing}
                                        onChange={(e) => this.setState({does_mowing: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Roçada"/>

                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.does_manual_weeding}
                                        onChange={(e) => this.setState({does_manual_weeding: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Capina Manual"/>

                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.does_grazing}
                                        onChange={(e) => this.setState({does_grazing: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Pastoreio"/>

                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.does_green_adubation}
                                        onChange={(e) => this.setState({does_green_adubation: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Adubação verde"/>

                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.does_shading}
                                        onChange={(e) => this.setState({does_shading: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Sombreamento"/>

                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.does_other_controls}
                                        onChange={(e) => this.setState({does_other_controls: e.target.checked})}
                                        color="primary"
                                    />
                                }
                                label="Outros"/>
                           
                            
                                <TextField 
                                    id="outros_desc" 
                                    label="Quais?" 
                                    InputLabelProps={{shrink: true,}} 
                                    variant="outlined" 
                                    margin="dense" 
                                    onChange={(e) => this.setState({other_controls_description: e.target.value})} 
                                    value={this.state.other_controls_description}
                                    style={css.input}
                                />
                        
                        </div>
                    </div>
                </div>                
                <div style={css.contentColuns}>
                    <Fab variant="extended" color="primary" onClick={() => this.submitIt()}>
                        <Save />
                        Criar
                    </Fab>
                </div>

                <hr style={css.hr} />


                <div style={css.contentColuns}>
                    <div style={{display: "flex", width: "100%", marginBottom: "15px"}}>
                        <sub><strong>Como controla insetos não benéficos, doenças nas plantas ?</strong></sub>
                    </div>
                    <div style={css.contentColuns}>
                        
                        <TextField 
                            label="Pragas ou Doenças" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({pest_disease_name: e.target.value})} 
                            value={this.state.pest_disease_name}
                            style={css.input}
                        />      
                        <TextField 
                            label="Produto ou Manejo" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({used_product_or_management: e.target.value})} 
                            value={this.state.used_product_or_management}
                            style={css.input}
                        />         
                        <TextField 
                            label="Onde?" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({used_where: e.target.value})} 
                            value={this.state.used_where}
                            style={css.input}
                        />         
                        <TextField 
                            label="Quando?" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({used_when: e.target.value})} 
                            value={this.state.used_when}
                            style={css.input}
                        />   
                        <TextField 
                            label="Procedência (int/ext)" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({origin: e.target.value})} 
                            value={this.state.origin}
                            style={css.input}
                        />       
                        <TextField 
                            label="Composição" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            onChange={(e) => this.setState({composition: e.target.value})} 
                            value={this.state.composition}
                            style={css.input}
                        />                                     
                        <Fab variant="extended" color="primary" onClick={() => this.addPestControl()}>
                            <Save />
                            Inserir
                        </Fab>
                    </div>
                {/* </div> */}
                    <TableContainer component={Paper}>
                        <Table className={this.props.classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Pragas ou Doenças</TableCell>
                                    <TableCell>Produto ou Manejo</TableCell>
                                    <TableCell>Onde?</TableCell>
                                    <TableCell>Quando?</TableCell>
                                    <TableCell>Procedência (int/ext)</TableCell>
                                    <TableCell>Composição</TableCell>
                                    <TableCell>Remover</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <Query query={PEST_CONTROL} variables={{management_id: this.state.management_id }}>
                                    {({ loading, error, data }) => {
                                            if (loading) {
                                                return null;
                                            }else if(error){
                                                console.log("Error",error);
                                            }
                                            console.log("RETORNO: ",data.ManagementQuery.managementVegetableProduction);
                                            if(data.ManagementQuery.managementVegetableProduction && data.ManagementQuery.managementVegetableProduction.managementPestDiseaseControl.length > 0){
                                                return this.renderPestControl(data.ManagementQuery.managementVegetableProduction.managementPestDiseaseControl);
                                            }else{
                                                return "";
                                            }
                                    }}
                                </Query>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        );
    }
}
export default withApollo((withStyles(styles))(CadProducaoAnimal));

const css = {
    contentColuns: {
        display:"flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    col1: {
        maxWidth: "25%",
        minWidth: "25%",
        flex: 1
    },
    col2: {
        maxWidth: "50%",
        minWidth: "50%",
        flex: 1
    },
    col3: {
        maxWidth: "75%",
        minWidth: "75%",
        flex: 1
    },
    col4: {
        maxWidth: "100%",
        minWidth: "100%",
        flex: 1
    },
    input: {
       flex: 1,
       display: "flex",
       marginRight: 15
    },
    hr: {
        marginTop: "20px",
        marginBottom: "20px"
    }
}