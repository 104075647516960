import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withApollo, graphql, Query } from 'react-apollo';
import { FRETE } from '../../services/graphql/querys/valoresAdicionais';
import {
    TextField,
    Grid,
    MenuItem,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { Add, Edit } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    }
});
const Celular = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={['5','5',' ','\(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        showMask
      />
    );
}
class Frete extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: null,
            description: null,
            email: null,
            phone: null,
            legal_document: null,
            cost: null,
        }
    }
    handleChangeMask = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    fazBusca = () => {
        let userAcc = JSON.parse(localStorage.getItem('UserAccount'));
        let body = this.state;
    
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
    
            this.props.client.query({query: FRETE, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("DATA: ", data)
                    this.setState({'fretes': data.FreightQuery});     
                }
            })
    }
    
    componentDidMount(){
        this.fazBusca();
    }
    render() {
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <TextField 
                            id='empresa' 
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="Empresa" 
                            variant="outlined" 
                            value={this.state.name}
                            onChange={(e) => this.setState({name: e.target.value})} 
                            margin="dense"/>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <TextField 
                            id='cpf-cnpj' 
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="CPF/CNPJ" 
                            variant="outlined" 
                            value={this.state.legal_document}
                            onChange={(e) => this.setState({legal_document: e.target.value})} 
                            margin="dense"/>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <TextField 
                            id='telefone' 
                            InputLabelProps={{shrink: true,}}
                            InputProps={{
                                inputComponent: Celular,
                                value: this.state.phone ? this.state.phone : '',
                                onChange: this.handleChangeMask('phone'),
                            }}
                            className={this.props.classes.textField} 
                            label="Telefone" 
                            variant="outlined" 
                            margin="dense"/>
                    </Grid>
                    {/* <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <TextField 
                            id='valor' 
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="Valor" 
                            variant="outlined" 
                            margin="dense"/>
                    </Grid> */}
                    <Grid className={this.props.classes.root} item xl={3} xs={4}>
                        <Fab variant="extended" className={this.props.classes.textField} color="primary" onClick={() => this.fazBusca()}>
                            <Add />
                            Buscar
                        </Fab>
                    </Grid>
                </Grid>

                <TableContainer component={Paper}>
                    <Table className={this.props.classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Empresa</TableCell>
                                <TableCell>CPF/CNPJ</TableCell>
                                <TableCell>Telefone</TableCell>
                                <TableCell>valor</TableCell>
                                <TableCell align="center">Editar</TableCell>
                                {/* <TableCell align="center">Excluir</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!this.state.fretes && this.state.fretes.map(frete => (
                                <TableRow>
                                    <TableCell component="th" scope="row">{frete.name}</TableCell>
                                    <TableCell>{frete.legal_document}</TableCell>
                                    <TableCell>{frete.phone}</TableCell>
                                    <TableCell>R${frete.cost}</TableCell>
                                    <TableCell align="center">
                                        <Fab color="primary" size="small" aria-label="editar" component="a" href={"/valores-adicionais/edit-frete/" + frete.freight_id}>
                                            <Edit />
                                        </Fab>
                                    </TableCell>
                                </TableRow>
                            ))}


                        </TableBody>
                    </Table>
                        <Grid className={this.props.classes.root} item xl={6} xs={8}>
                            <Fab variant="extended" className={this.props.classes.textField} color="primary" component="a" href={"/valores-adicionais/edit-frete/0"}>
                                <Add />
                                Adicionar
                            </Fab>
                        </Grid>
                </TableContainer>
                
            </div>
        )
    }
}
export default withApollo(withStyles(styles)(Frete));