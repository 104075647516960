import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { setContext } from 'apollo-link-context';
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";

import introspectionQueryResultData from '../fragmentTypes.json';

const baseUri = window._env_.API_URL;

const httpLink = createUploadLink({
  uri: `${baseUri}graphql`
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
  });

const getToken = async () => {
  let tokenConsult =  localStorage.length ? await localStorage.getItem('user_token') : null;
  return tokenConsult;
}

const authLink = setContext(async (req, { headers }) => {
  const token = await getToken();
  console.log("TOKEN: ", token)
  return {
      ...headers,
      headers: {
      authorization: token ? `Bearer ${token}` : null,
      },
  };
}); 

const link = authLink.concat(httpLink);
const cache = new InMemoryCache({fragmentMatcher});

const client = new ApolloClient({
  link, cache
});

export default client;