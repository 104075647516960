import React from 'react';
import { withApollo, Query } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';

import { EDIT_COSTUMER_COMMERCIALIZATION } from '../../../services/graphql/mutations/customer';

import {
    TextField,
    Grid,
    FormControlLabel,
    Checkbox,
    Fab
} from '@material-ui/core';
import { Print, Save } from '@material-ui/icons';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%',
    },
    title: {
        paddingTop: '3rem',
    }
}));
function ComercializacaoGeral(props) {
    const classes = useStyles();
    console.log("Props",props)
    const [state, setState] = React.useState({
        // TIPO
        user_id: !!props.customer ? props.customer.id : null,
        fair_format: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.fair_format : null,
        organic_station_format: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.organic_station_format : null,
        basket_format: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.basket_format : null,
        supermarket_format: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.supermarket_format : null,
        solidarity_store_format: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.solidarity_store_format : null,
        store_format: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.store_format : null,
        distributor_consumer: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.distributor_consumer : null,
        small_market_network_consumer: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.small_market_network_consumer : null,
        online_platform_consumer: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.online_platform_consumer : null,
        restaurant_consumer: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.restaurant_consumer : null,
        other_initiative_types_description: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.other_initiative_types_description : null,
        associations_cooperatives_origin: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.associations_cooperatives_origin : null,
        individual_producer_origin: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.individual_producer_origin : null,
        distributor_origin: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.distributor_origin : null,
        solidarity_store_origin: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.solidarity_store_origin : null,
        farmers_network_origin: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.farmers_network_origin : null,
        other_origin_description: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.other_origin_description : null,
        agroecological_family_farming_interest: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.agroecological_family_farming_interest : null,
        organic_family_farming_interest: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.organic_family_farming_interest : null,
        organic_company_interest: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.organic_company_interest : null,
        reason_interest_description: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.reason_interest_description : null,
        participatory_certification: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.participatory_certification : null,
        auditing_certification: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.auditing_certification : null,
        ocs_certification: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.ocs_certification : null,
        phone_watsapp_receive_offers: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.phone_watsapp_receive_offers : null,
        site_receive_offers: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.site_receive_offers : null,
        app_receive_offers: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.app_receive_offers : null,
        email_receive_offers: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.email_receive_offers : null,
        other_receive_offers_description: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.other_receive_offers_description : null,
        phone_watsapp_best_channel: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.phone_watsapp_best_channel : null,
        site_best_channel: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.site_best_channel : null,
        app_best_channel: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.app_best_channel : null,
        email_best_channel: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.email_best_channel : null,
        other_best_channel_description: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.other_best_channel_description : null,
        commercialize_in_natura_products: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.commercialize_in_natura_products : null,
        commercialize_minimally_processed_products: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.commercialize_minimally_processed_products : null,
        commercialize_processed_products: !!props.customer && !!props.customer.customerCommercialization ? props.customer.customerCommercialization.commercialize_processed_products : null,
        error: [],
    });
    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });
    };
    const handleChangeText = name => event => {
        setState({ ...state, [name]: event.target.value });
    };
    const submitIt = () => {
        console.log(state)
        let paramsEnviar = Object.entries(state).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        console.log("Enviar Parametros: ", paramsEnviar);
        props.client.mutate({mutation: EDIT_COSTUMER_COMMERCIALIZATION, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    setState({...state, error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    setState({...state, error: errosMostrar})
                }
            } else {
                console.log("DATA: ", data);
                setState({...state, error: []});
                alert("Salvo com sucesso !");
            }
        })
    }
    return(
        <div>
            <CustomErrors error={state.error}/>
            <h2>Tipo Iniciativa</h2>
            <h4>Formato Comercialização</h4>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.fair_format}
                                onChange={handleChange('fair_format')}
                                value="fair_format"
                                color="primary"
                            />
                        }
                        label="Feira"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.organic_station_format}
                                onChange={handleChange('organic_station_format')}
                                value="organic_station_format"
                                color="primary"
                            />
                        }
                        label="Estação Orgánico"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.basket_format}
                                onChange={handleChange('basket_format')}
                                value="basket_format"
                                color="primary"
                            />
                        }
                        label="Cesta"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.supermarket_format}
                                onChange={handleChange('supermarket_format')}
                                value="supermarket_format"
                                color="primary"
                            />
                        }
                        label="Supermercado"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.solidarity_store_format}
                                onChange={handleChange('solidarity_store_format')}
                                value="solidarity_store_format"
                                color="primary"
                            />
                        }
                        label="Loja Solidária"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.store_format}
                                onChange={handleChange('store_format')}
                                value="store_format"
                                color="primary"
                            />
                        }
                        label="Loja"
                    />
                </Grid>
            </Grid>
            <h4>Consumidor Final</h4>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.distributor_consumer}
                                onChange={handleChange('distributor_consumer')}
                                value="distributor_consumer"
                                color="primary"
                            />
                        }
                        label="Distribuidora"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.small_market_network_consumer}
                                onChange={handleChange('small_market_network_consumer')}
                                value="small_market_network_consumer"
                                color="primary"
                            />
                        }
                        label="Pequenas Redes Mercado"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.online_platform_consumer}
                                onChange={handleChange('online_platform_consumer')}
                                value="online_platform_consumer"
                                color="primary"
                            />
                        }
                        label="Plataforma Online"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.restaurant_consumer}
                                onChange={handleChange('restaurant_consumer')}
                                value="restaurant_consumer"
                                color="primary"
                            />
                        }
                        label="Restaurantes"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.other_initiative_types_description}
                                color="primary"
                            />
                        }
                        label="Outros"
                    />
                </Grid>
                <Grid className={classes.root} item xl={12}>
                    <TextField id="type-consumidor-quais" className={classes.textField} value={state.other_initiative_types_description} onChange={handleChangeText('other_initiative_types_description')} label="Quais? " InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                </Grid>
            </Grid>
            <h2 className={classes.title}>Origem Produtos Org</h2>
            <h4>Origem Produtos Comercializados</h4>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleChange('associations_cooperatives_origin')}
                                checked={state.associations_cooperatives_origin}
                                color="primary"
                            />
                        }
                        label="Associações e Cooperativas"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.individual_producer_origin}
                                onChange={handleChange('individual_producer_origin')}
                                value="individual_producer_origin"
                                color="primary"
                            />
                        }
                        label="Produtor Individual"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.distributor_origin}
                                onChange={handleChange('distributor_origin')}
                                value="distributor_origin"
                                color="primary"
                            />
                        }
                        label="Distribuidora"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.solidarity_store_origin}
                                onChange={handleChange('solidarity_store_origin')}
                                value="solidarity_store_origin"
                                color="primary"
                            />
                        }
                        label="Lojas Solidárias"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.farmers_network_origin}
                                onChange={handleChange('farmers_network_origin')}
                                value="farmers_network_origin"
                                color="primary"
                            />
                        }
                        label="Rede Agricultores"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.other_origin_description}
                                onChange={handleChange('other_origin_description')}
                                value="other_origin_description"
                                color="primary"
                            />
                        }
                        label="Outros"
                    />
                </Grid>
                <Grid className={classes.root} item xl={12}>
                    <TextField id="origem-produto-qual" className={classes.textField} value={state.other_origin_description} onChange={handleChangeText('other_origin_description')} label="Qual? " InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                </Grid>
            </Grid>
            <h4>Se interessa em comercializar produtos de origem</h4>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.agroecological_family_farming_interest}
                                onChange={handleChange('agroecological_family_farming_interest')}
                                value="agroecological_family_farming_interest"
                                color="primary"
                            />
                        }
                        label="Agricultura Familiar agroecológica"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.organic_family_farming_interest}
                                onChange={handleChange('organic_family_farming_interest')}
                                value="organic_family_farming_interest"
                                color="primary"
                            />
                        }
                        label="Agricultura Familiar orgánica"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.organic_company_interest}
                                onChange={handleChange('organic_company_interest')}
                                value="organic_company_interest"
                                color="primary"
                            />
                        }
                        label="Empresa Orgânica"
                    />
                </Grid>
                <Grid className={classes.root} item xl={6}>
                    <TextField id="origem-comercializar-por-que" className={classes.textField}  value={state.reason_interest_description} onChange={handleChangeText('reason_interest_description')} label="Por que? " InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                </Grid>
            </Grid>
            <h2 className={classes.title}>Certificação Prod</h2>
            <h4>Certificação dos Produtos</h4>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.participatory_certification}
                                onChange={handleChange('participatory_certification')}
                                color="primary"
                            />
                        }
                        label="Participativa - SPG"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.auditing_certification}
                                onChange={handleChange('auditing_certification')}
                                value="auditing_certification"
                                color="primary"
                            />
                        }
                        label="Auditoria"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.ocs_certification}
                                onChange={handleChange('ocs_certification')}
                                value="ocs_certification"
                                color="primary"
                            />
                        }
                        label="OCS"
                    />
                </Grid>
            </Grid>
            <h2 className={classes.title}>Acesso e Ofertas</h2>
            <h4>Meio ao qual recebe ofertas produtos</h4>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.phone_watsapp_receive_offers}
                                onChange={handleChange('phone_watsapp_receive_offers')}
                                value="phone_watsapp_receive_offers"
                                color="primary"
                            />
                        }
                        label="Tel / Whatsapp direto produtor"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.site_receive_offers}
                                onChange={handleChange('site_receive_offers')}
                                value="site_receive_offers"
                                color="primary"
                            />
                        }
                        label="Site"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.app_receive_offers}
                                onChange={handleChange('app_receive_offers')}
                                value="app_receive_offers"
                                color="primary"
                            />
                        }
                        label="APP"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.email_receive_offers}
                                onChange={handleChange('email_receive_offers')}
                                value="email_receive_offers"
                                color="primary"
                            />
                        }
                        label="Lista por email"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.other_receive_offers_description}
                                color="primary"
                            />
                        }
                        label="Outros"
                    />
                </Grid>
                <Grid className={classes.root} item xl={12}>
                    <TextField id="acesso-meio-quais" className={classes.textField} value={state.other_receive_offers_description} onChange={handleChangeText('other_receive_offers_description')} label="Quais? " InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                </Grid>
            </Grid>
            <h4>Canal que considera melhor e mais fácil</h4>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.phone_watsapp_best_channel}
                                onChange={handleChange('phone_watsapp_best_channel')} 
                                color="primary"
                            />
                        }
                        label="Tel / Whatsapp direto produtor"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.site_best_channel}
                                onChange={handleChange('site_best_channel')}
                                value="site_best_channel"
                                color="primary"
                            />
                        }
                        label="Site"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.app_best_channel}
                                onChange={handleChange('app_best_channel')}
                                value="app_best_channel"
                                color="primary"
                            />
                        }
                        label="APP"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.email_best_channel}
                                onChange={handleChange('email_best_channel')}
                                value="email_best_channel"
                                color="primary"
                            />
                        }
                        label="Lista por email"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.other_best_channel_description}
                                color="primary"
                            />
                        }
                        label="Outros"
                    />
                </Grid>
                <Grid className={classes.root} item xl={12}>
                    <TextField id="acesso-canal-quais" className={classes.textField} value={state.other_best_channel_description} onChange={handleChangeText('other_best_channel_description')} label="Quais? " InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                </Grid>
            </Grid>
            <h2 className={classes.title}>Tipos</h2>
            <h4>Tipos de produtos orgânicos Comercializa</h4>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.commercialize_in_natura_products}
                                onChange={handleChange('commercialize_in_natura_products')}
                                value="commercialize_in_natura_products"
                                color="primary"
                            />
                        }
                        label="In natura"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.commercialize_minimally_processed_products}
                                onChange={handleChange('commercialize_minimally_processed_products')}
                                value="commercialize_minimally_processed_products"
                                color="primary"
                            />
                        }
                        label="Minimamente commercialize_processed_products"
                    />
                </Grid>
                <Grid className={classes.root} item xl={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.commercialize_processed_products}
                                onChange={handleChange('commercialize_processed_products')}
                                value="commercialize_processed_products"
                                color="primary"
                            />
                        }
                        label="commercialize_processed_products"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={3}>
                    <Fab variant="extended" color="primary" className={classes.textField} onClick={() => submitIt()}>
                        <Save />
                        Salvar
                    </Fab>
                </Grid>
            </Grid>
        </div>
    );
}export default withApollo(ComercializacaoGeral)