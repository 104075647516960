import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// COMPONENTS
import Propriedade from './DadosProducao/Propriedade';
import EstruturaFamiliar from './DadosProducao/EstruturaFamiliar';
import ProducaoA from './DadosProducao/ProducaoA';
import ProducaoB from './DadosProducao/ProducaoB';
import Tabela from './DadosProducao/Tabela';
import Logistica from './DadosProducao/Logistica';
import Comercializacao from './DadosProducao/Comercializacao';
import Cacau from './DadosProducao/Cacau';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function DadosProducao(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className="page-wrapper" style={{bottom: 0, top: '140px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
            <AppBar position="static" color="default">
                <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                >
                    <Tab  label={<span style={{color: "red"}}>PRODUTO (OBRIGATÓRIO)</span>} {...a11yProps(0)} />
                    <Tab label="Estrutura Familiar" {...a11yProps(1)} />
                    <Tab label="Propriedade" {...a11yProps(2)} />
                    <Tab label="Produção" {...a11yProps(3)} />
                    <Tab label="Logística" {...a11yProps(4)} />
                    <Tab label="Comercialização" {...a11yProps(5)} />
                    <Tab label="Cacau" {...a11yProps(6)} />         
                </Tabs>
            </AppBar>

            <div style={{ display: value === 0? 'block': 'none'}}>
                <Tabela farmer={props.farmer} productUnit={props.productUnit} products={props.products}/>
            </div>
            <div style={{ display: value === 1? 'block': 'none'}}>
                <EstruturaFamiliar farmer={props.farmer}/>
            </div>

            <div style={{ display: value === 2? 'block': 'none'}}>
                <Propriedade farmer={props.farmer}/>
            </div>
            <div style={{ display: value === 3? 'block': 'none'}}>
                <ProducaoA farmer={props.farmer}/>
            </div>
            <div style={{ display: value === 4? 'block': 'none'}}>
                <Logistica farmer={props.farmer}/>
            </div>
            <div style={{ display: value === 5? 'block': 'none'}}>
                <Comercializacao farmer={props.farmer}/>
            </div>
            <div style={{ display: value === 6? 'block': 'none'}}>
                <Cacau farmer={props.farmer}/>
            </div>
        </div>
    );
}