import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {  PROD_UNIT_QUERY  } from '../../services/graphql/querys/productUnit';
import { withApollo, graphql, Query } from 'react-apollo';

// COMPONENTS
import Lista from '../../Component/UnidadesProdutiovas/List';
import Formulario from '../../Component/UnidadesProdutiovas/Formulario';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});
class UnidadesProdutiovas extends Component {
    constructor(props){
        super(props);
        this.state = {
            productUnit: null,
        }
    }
    componentWillMount(){
        
        if(this.props.match.params.action == 'edit' && this.props.match.params.id){
            let body = {
                productive_unit_id: this.props.match.params.id
            }
            this.props.client.query({query: PROD_UNIT_QUERY, variables: body, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    let productUnit = data.ProductiveUnitQuery[0];
                    console.log("Prod Unit: ",productUnit)
                    this.setState({productUnit: productUnit});
                }
            })
        }
    }
    render() {
        if(this.props.match.params.action == 'new' || this.props.match.params.action == 'edit'){
            return (
                <div className="page-wrapper"  style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <Formulario productUnit={this.state.productUnit} />
                </div>
            );
        } else {
            return(
                <div className="page-wrapper"  style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <Lista/>
                </div>
            );
        }
    }
}
export default withApollo(withStyles(styles)(UnidadesProdutiovas));