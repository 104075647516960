import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import {EDIT_OUTSIDE_PRODUCTION, DELETE_OUTSIDE_PRODUCTION,DELETE_PRODUCTION_SUMMARY,CREATE_PRODUCTION_SUMMARY} from '../../../services/graphql/mutations/management';

import {OUTSIDE_PRODUCTION,VARIAVEIS_QUERY,PRODUCTION_SUMMARY} from '../../../services/graphql/querys/manejo';

import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';
import MaskedInput from 'react-text-mask';
import gql from 'graphql-tag';

import {
    TextField,
    Grid,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormGroup,
    MenuItem,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';
import { Add, Print, Save, Delete } from '@material-ui/icons';


const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    input: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },  
    radioClass: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },    
    divisor: {
        width: "100%",
        height: "3px",
        backgroundColor: "#000",
        marginTop: "3px",
        marginBottom: "10px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});



class CadGleba extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            management_id: this.props.manejo.manejo.management_id,
            production_inputs: null,
            measure_units: null,
            products: null,
            periodicidades: null,
        };
    } 

    componentDidMount(){
        this.props.client.query({query: VARIAVEIS_QUERY, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("Data Ingred: ", data)
                //let production_inputs = data.ProductionInputQuery;
                let measure_units = data.MeasureUnitsQuery;
                let products = data.ProductQuery;
                let periodicidades = data.PeriodicityQuery;
                
                this.setState({
                    //production_inputs: production_inputs, 
                    measure_units: measure_units, 
                    products: products,
                    periodicidades: periodicidades,
                })
            }
        })
    }

    addOutsideProduction(){
        console.log("Faz Mutation", this.state)

        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros OUTSIDE: ", paramsEnviar);

        this.props.client.mutate(
            {
                mutation: EDIT_OUTSIDE_PRODUCTION, 
                variables: paramsEnviar,
                errorPolicy: 'all',
                refetchQueries:[{
                    query: OUTSIDE_PRODUCTION,
                    variables: 
                    { 
                        management_id: this.state.management_id,
                    }
                }]
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            }else{
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");                
            }
        })
    }
   
    removeOutsideProduction(id){
        console.log("Remove Esse: ",id)
        this.props.client.mutate({
            mutation: DELETE_OUTSIDE_PRODUCTION, 
            variables: {management_id: this.state.management_id, production_input_id: id}, 
            errorPolicy: 'all',
            refetchQueries:[{
                query: OUTSIDE_PRODUCTION,
                variables: 
                { 
                    management_id: this.state.management_id,
                }
            }]
        })
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({open: false, remover: null});
                alert("Erro ao remover produto!"); 
            } else {
                console.log("DATA: ", data);
                alert("Produto removido!");
            }
        })
    }
    
    renderOutsideProduction(outsideProduct){
        return  outsideProduct.map((out) =>
                    <TableRow>
                        <TableCell>{out.local_name}</TableCell>
                        <TableCell>{out.measure_unit_description}</TableCell>
                        <TableCell>{out.annual_amount}</TableCell>
                        <TableCell>
                            <Fab variant="extended" color="primary" onClick={() => this.removeOutsideProduction(out.product_id)}>
                                <Delete />
                                Remover
                            </Fab>
                        </TableCell>
                    </TableRow>
                )
    }

    render() {
        // console.log("state", this.state)
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <div style={{paddingTop: "10px"}}>
                    <h4>CADASTRO GLEBA {this.props.manejo.manejo.description}</h4>
                </div>
            
                <hr style={css.hr}/>


                <div style={css.contentColuns}>
                    <div style={css.contentColuns}>
                        <div style={{display: "flex", width: "100%", marginBottom: "15px"}}>
                            <sub><strong>RESUMO DO PLANO DE PRODUÇÃO, ROTAÇÃO DE CULTURAS, MANEJO E CONVERSÃO DA UNIDADE DE PRODUÇÃO PARA PRODUÇÃO ECOLÓGICA </strong></sub>
                        </div>
                    
                        <TextField
                            id="selectProduto2"
                            select
                            label="Produto"
                            value={this.state.product_id}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => this.setState({product_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            style={css.input}
                        
                        >
                            {
                            !!this.state.products && this.state.products.map(option => (
                                <MenuItem key={`select-prodinput2-${option.product_id}`} value={option.product_id}>
                                    {option.local_name}
                                </MenuItem>                           
                                ))
                            
                            }
                        </TextField>
                        
                        <TextField
                            id="selectMeasure"
                            select
                            label="Unidade"
                            value={this.state.measure_unit_id}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => this.setState({measure_unit_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            style={css.input}
                        
                        >
                            {
                            !!this.state.measure_units && this.state.measure_units.map(option => (
                                <MenuItem key={`select-measure-${option.measure_unit_id}`} value={option.measure_unit_id}>
                                    {option.measure_unit_description}
                                </MenuItem>                           
                                ))
                            
                            }
                        </TextField>
                        

                        <TextField 
                            label="Qtde Anual" 
                            InputLabelProps={{shrink: true,}} 
                            variant="outlined" 
                            margin="dense" 
                            type="number"
                            onChange={(e) => this.setState({annual_amount: e.target.value.replace(/,/i, '.')})} 
                            value={this.state.annual_amount}
                            style={css.input}
                        />             
                        <Fab variant="extended" color="primary" onClick={() => this.addOutsideProduction()}>
                            <Save />
                            Inserir
                        </Fab>
                    </div>
                    <TableContainer component={Paper}>
                        <Table className={this.props.classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Produto</TableCell>
                                    <TableCell>Unidade</TableCell>
                                    <TableCell>Qtde Anual</TableCell>
                                    <TableCell>Remover</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <Query query={OUTSIDE_PRODUCTION} variables={{management_id: this.state.management_id }}>
                                    {({ loading, error, data }) => {
                                            if (loading) {
                                                return null;
                                            }else if(error){
                                                console.log("Error",error);
                                            }
                                            console.log("RETORNO: ",data.ManagementQuery.managementProductiveUnit);
                                            if(data.ManagementQuery.managementProductiveUnit && data.ManagementQuery.managementProductiveUnit.managementOutsideProductionInput.length > 0){
                                                return this.renderOutsideProduction(data.ManagementQuery.managementProductiveUnit.managementOutsideProductionInput);
                                            }else{
                                                return "";
                                            }
                                    }}
                                </Query>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <hr style={css.hr}/>
            </div>
        );
    }
}
export default withApollo((withStyles(styles))(CadGleba));

const css = {
    contentColuns: {
        display:"flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    col1: {
        maxWidth: "25%",
        minWidth: "25%",
        flex: 1
    },
    col2: {
        maxWidth: "50%",
        minWidth: "50%",
        flex: 1
    },
    col3: {
        maxWidth: "75%",
        minWidth: "75%",
        flex: 1
    },
    col4: {
        maxWidth: "100%",
        minWidth: "100%",
        flex: 1
    },
    input: {
       flex: 1,
       display: "flex",
       marginRight: 15
    },
    hr: {
        marginTop: "20px",
        marginBottom: "20px"
    }
}