import gql from 'graphql-tag';

export const LISTA_RODADAS = gql`

query CommercialRoundsQuery(
  $id: Int, 
  $status_code: String, 
  $started: Boolean, 
  $ended: Boolean
){
  CommercialRoundsQuery(
    id: $id
    status_code: $status_code
    started: $started
    ended: $ended
  ) {
    data{
      id
      description
      status {
        code
        description
        posible_statuses{
          code
          description
        }
      }
      offer_starts_on
      offer_ends_on
      order_starts_on
      order_ends_on
      open_to_offer
      open_to_order
      offer_by_product_origin{
        product{
          product_id
          local_name
        }
        origins{
          quantity
          seller{
              ... on Farmer {
              farmer_user_id: id
              contact
              identity_document
              productive_unit_id
              group_id
              core_id
              dap
              user {
                id
                email
                profile{
                  name
                }
              }
    
            }
            ... on Station {
              station_id: id
              name
              is_substation
              state
              city
            }
          }
        }
        stock_quantity
      }
      sellers {
        ... on Farmer {
          farmer_user_id: id
          contact
          identity_document
          dap
          productive_unit_id
          group_id
          core_id
          user {
            id
            email
            profile{
              name
            }
          }
        }
        ... on Station {
          station_id: id
          name
          is_substation
          state
          city
        }
      }
      buyers {
        __typename
        ... on Customer {
          customer_user_id: id
          responsible
          comercial_phone
          commercialization_type_id
          user {
            id
            email
            profile{
              name
            }
          }
        }
        ... on Station {
          station_id: id
          name
          is_substation
          state
          city
        }
      }
      freights{
        freight_id
        station_id
        name
        description
      }
      offered_products{
        data{
          available_quantity
          reserved_quantity
          product{
            product_id
            product_category_id
            local_name
            picture
            urlPicture
          }
        }
      }
      purchase_orders{
        id
        purchaser{
          __typename
          ... on Customer {
              user {
                id
                profile{
                  name
                }
              }         
            }
          ... on Station {
              name
          }
        }
        status {
          code
          type
          description
          posible_statuses{
            code
            description
          }
        }
        items{
          id
          quantity
          unit_price
          product{
            product_id
            product_category_id
            local_name
            picture
            urlPicture            
          }
        }
      }
      sales_orders{
        id
        seller {
          ... on Farmer {
            user {
              id
              profile{
                name
              }
            }
          }
          ... on Station {
            name
          }
        }
        status{
          code
          description
          posible_statuses{
            code
            description
          }
        }
        items{
          id
          quantity
          unit_price
          product{
            product_id
            local_name
          }
          status{
            code
            description
            posible_statuses{
              code
              description
            }
          }
        }
      }
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
}
`;



export const DADOS_STATION = gql`

query StationQuery( $station_id: Int ){
  StationQuery ( station_id: $station_id ){
    id
    is_substation
    name
    status
    stationCustomers{
      station_id
      id
      commercialization_type_id
      userProfile{
        name
      }
    }
    stationFarmers{
      id
      station_id
      userProfile{
        name
      }
    }
    stationFreights{
      freight_id
      name
    }
    stationGroups{
      group_id
      name
      groupFarmers{
        id
        userProfile{
          name
        }
      }
    }
  }
  CommercializationsTypeQuery{
    commercialization_type_id
    description
  }
}`;


