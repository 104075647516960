import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withApollo, graphql, Query } from 'react-apollo';
import { FRETE } from '../../services/graphql/querys/valoresAdicionais';
import { CRIA_FRETE, EDITA_FRETE } from '../../services/graphql/mutations/valoresAdicionais';
import CustomErrors from '../CustomErrors/CustomErrors';

import {
    TextField,
    Grid,
    MenuItem,
    Fab,
} from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { Save, Edit } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    }
});
const Celular = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={['5','5',' ','\(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        showMask
      />
    );
}
class Frete extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: null,
            description: null,
            email: null,
            phone: null,
            legal_document: null,
            cost: null,
            error: [],
        }
    }
    handleChangeMask = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };
    componentDidMount(){
       
        if(!!this.props.id && this.props.id > 0){
            let body = {
                freight_id:  this.props.id,
            }
            this.setState({freight_id: this.props.id});
            let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            if(!paramsEnviar.name){
                this.setState({error: [{message: "Você deve preencher o nome da empresa."}]})
                return false;
            }

            this.props.client.query({query: FRETE, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("DATA: ", data)
                    this.setState({'name': data.FreightQuery[0].name,
                        'description': data.FreightQuery[0].description,
                        'email': data.FreightQuery[0].email,
                        'phone': data.FreightQuery[0].phone,
                        'legal_document': data.FreightQuery[0].legal_document,
                        'cost': data.FreightQuery[0].cost});                    
                }
            })
        }
    }

    submitIt(){

        let paramsEnviar = Object.entries(this.state).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        console.log("Enviar Parametros: ", paramsEnviar);
        this.props.client.mutate({mutation: this.props.id > 0 ? EDITA_FRETE : CRIA_FRETE, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({...this.state, error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({...this.state, error: errosMostrar})
                }
            } else {
                console.log("DATA: ", data);
                this.setState({...this.state, error: []});
                alert("Salvo com sucesso !");
                window.location.href = "/valores-adicionais";
            }
        })    
    }

    render() {
        return (
            <div>
             <CustomErrors error={this.state.error}/>
                 <sub>
                    <span>*</span> Informações Obrigatórias
                </sub>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <TextField 
                            id='empresa' 
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="Empresa*" 
                            variant="outlined" 
                            value={this.state.name}
                            onChange={(e) => this.setState({name: e.target.value})} 
                            margin="dense"/>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <TextField 
                            id='desc' 
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="Descrição" 
                            variant="outlined" 
                            value={this.state.description}
                            onChange={(e) => this.setState({description: e.target.value})} 
                            margin="dense"/>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <TextField 
                            id='cpf-cnpj' 
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="CPF/CNPJ" 
                            variant="outlined" 
                            value={this.state.legal_document}
                            onChange={(e) => this.setState({legal_document: e.target.value})} 
                            margin="dense"/>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <TextField 
                            id='telefone' 
                            InputLabelProps={{shrink: true,}}
                            InputProps={{
                                inputComponent: Celular,
                                value: this.state.phone ? this.state.phone : '',
                                onChange: this.handleChangeMask('phone'),
                            }}
                            className={this.props.classes.textField} 
                            label="Telefone" 
                            variant="outlined" 
                            margin="dense"/>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={6}>
                        <TextField 
                            id='valor' 
                            InputLabelProps={{shrink: true,}}
                            className={this.props.classes.textField} 
                            label="Valor" 
                            variant="outlined" 
                            value={this.state.cost}
                            onChange={(e) => this.setState({cost: e.target.value.replace(/,/i, '.')})} 
                            margin="dense"/>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3} xs={4}>
                        <Fab variant="extended" className={this.props.classes.textField} color="primary" onClick={() => this.submitIt()}>
                            <Save />
                            Salvar
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withApollo(withStyles(styles)(Frete));