import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {  CORE_QUERY  } from '../../services/graphql/querys/core';
import {  DELETE_CORE  } from '../../services/graphql/mutations/core';


import { withApollo, graphql, Query } from 'react-apollo';
import {
    TextField,
    Grid,
    FormControlLabel,
    Button,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    DialogTitle,
    Dialog,
    List,
    ListItem
} from '@material-ui/core';
import { Search, Add, Edit, Delete } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    },
    buttonAction: {
        marginLeft: '10px',
        marginRight: '10px',
    }
});

class Lista extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            cores: null,
            byName: null,
            byState: null,
            byCity: null,
            remover: null,
        }
    }

    fazBusca = () => {
        let body = {
            // station_id:  document.getElementById('byId').value,
            // status:  document.getElementById('byStatus').value,
            name:  this.state.byName,
            city:  this.state.byCity,
            state:  this.state.byState,
        }
        console.log("FazBusca", this.props);
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Enviar Parametros: ", paramsEnviar);
            this.props.client.query({query: CORE_QUERY, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                    if(errors[0].message == 'validation'){
                        let erroValid = errors[0].extensions.validation;
                        for (var [key, value] of Object.entries(erroValid)) {
                            console.log("ERRO... " + key + ' ',value);
                        }
                    }else{
                        alert("Por favor, preencha todos os campos do formulário")
                    }
                } else {
                    console.log("DATA: ", data)
                    this.setState({'cores': data.CoreQuery});                    
                }
            })
    }
    componentDidMount(){
        this.fazBusca();
    }
    removeNucleo(){
        console.log("Remover grupo: ", this.state.remover)
        this.props.client.mutate({mutation: DELETE_CORE, variables: {core_id: this.state.remover}, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({open: false, remover: null});
                alert("Erro ao remover o núcleo!"); 
            } else {
                console.log("DATA: ", data);
                this.setState({open: false, remover: null});
                alert("Núcleo removido!");
                window.location.href = "/nucleos";
            }
        })
    }
    render(){
        const { onClose, selectedValue, open } = this.props;

        const handleClose = () => {
            this.setState({open: false, remover: null});
        };
        const handleClickOpen = (core_id) => {
            console.log("core_id: ", core_id)
            this.setState({open: true, remover: core_id});
        };

        return(
            <div>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={9}>
                                <Grid container spacing={3}>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <TextField  className={this.props.classes.textField} id="byName" value={this.state.byName} onChange={(e) => this.setState({byName: e.target.value})} label="Nome" variant="outlined" margin="dense" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <TextField  className={this.props.classes.textField} id="byCity" value={this.state.byCity} onChange={(e) => this.setState({byCity: e.target.value})} label="Município" variant="outlined" margin="dense" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={4}>
                                        <TextField  className={this.props.classes.textField} id="byState" value={this.state.byState} onChange={(e) => this.setState({byState: e.target.value})} label="Estado" variant="outlined" margin="dense" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={3}>
                                <Grid container spacing={2}>
                                    <Grid className={this.props.classes.root} align="center" item xl={5}>
                                        <Button variant="contained" color="primary" disableElevation onClick={() => this.fazBusca()} className={this.props.classes.button}>
                                            <Search /> Buscar
                                        </Button>
                                    </Grid>
                                    <Grid className={this.props.classes.root} align="center" item xl={5}>
                                        <Button variant="contained" color="primary" disableElevation className={this.props.classes.button} component="a" href="/nucleos/new">
                                            <Add /> Adicionar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <TableContainer component={Paper}>
                            <Table className={this.props.classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell align="center">Município</TableCell>
                                        <TableCell align="center">Estado</TableCell>
                                        <TableCell align="center">Editar</TableCell>
                                        <TableCell align="center">Excluir</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {!!this.state.cores && this.state.cores.map(row => (
                                    <TableRow key={row.core_id}>
                                        <TableCell component="th" scope="row">{row.name}</TableCell>
                                        <TableCell align="center">{row.city}</TableCell>
                                        <TableCell align="center">{row.state}</TableCell>
                                        <TableCell align="center">
                                        <Fab color="primary" size="small" aria-label="editar" component="a" href={"/nucleos/edit/" + row.core_id} >
                                            <Edit />
                                        </Fab>
                                        </TableCell>
                                        <TableCell align="center">
                                        <Fab color="primary" size="small" aria-label="search" onClick={() => handleClickOpen(row.core_id)}>
                                            <Delete />
                                        </Fab>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
                    <DialogTitle id="simple-dialog-title" align="center">
                        Você realmente deseja<br/>
                        <strong>EXCLUIR</strong> o Núcleo?
                    </DialogTitle>
                    <List>
                        <ListItem>
                            <Button variant="contained" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]} onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant="contained" color="primary" disableElevation className={[this.props.classes.button,this.props.classes.buttonAction]} onClick={() => this.removeNucleo()}>
                                Continuar
                            </Button>
                        </ListItem>
                    </List>
                </Dialog>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Lista));