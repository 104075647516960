import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {  PRODUCT_PRICE, PRODUCTS_QUERY  } from '../../services/graphql/querys/product';
import { withApollo, graphql, Query } from 'react-apollo';
import { ADD_PRODUCT_PRICE } from '../../services/graphql/mutations/product';
import CustomErrors from '../CustomErrors/CustomErrors';

import {
    TextField,
    Grid,
    MenuItem,
    Fab,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
} from '@material-ui/core';
import { Add ,Edit } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    }
});

class Formulario extends Component {
    constructor(props){
        super(props);
        this.state = {
            products: null,
            product_id: null,
            price: null,
            error: [],
            all_products: null,
            naoInseridos: null,
            measure_unit_id: "KG",

        }
    }
    submitIt(){
        this.setState({error: []});
        let paramsEnviar = Object.entries(this.state).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        console.log("Enviar Parametros: ", paramsEnviar);

        if(!paramsEnviar.product_id){
            this.setState({error: [{message: "Você deve escolher o produto que quer inserir"}]})
            return false;
        }else if(!paramsEnviar.price){
            this.setState({error: [{message: "Você deve preencher o preco do produto."}]})
            return false;
        }

        this.props.client.mutate({mutation: ADD_PRODUCT_PRICE, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({...this.state, error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({...this.state, error: errosMostrar})
                }
            } else {
                console.log("DATA: ", data);
                this.setState({...this.state, error: []});
                alert("Salvo com sucesso !");
                window.location.href = "/valor-produto";
            }
        })    
    }

    fazBusca = () => {
        let paramsEnviar = Object.entries(this.state).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Enviar Parametros: ", paramsEnviar);
            this.props.client.query({query: PRODUCT_PRICE, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("DATA: ", data)
                    this.setState({'products': data.StationProductPriceQuery});                    
                }
            })

            this.props.client.query({query: PRODUCTS_QUERY, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("DATA 2: ", data)
                    this.setState({'all_products': data.ProductQuery});                    
                }
            })
    }
    componentDidMount(){
        this.fazBusca();
    }
    comparer(otherArray){
        return function(current){
          return otherArray.filter(function(other){
            return other.product_id == current.product_id
          }).length == 0;
        }
    }
    render(){
        if(!!this.state.all_products && !this.state.naoInseridos){
            let allProducts = this.state.all_products;
            let jaInseridos = this.state.products

              
              var onlyInA = allProducts.filter(this.comparer(jaInseridos));
              var onlyInB = jaInseridos.filter(this.comparer(allProducts));
              
              let naoInseridos = onlyInA.concat(onlyInB);
            this.setState({naoInseridos: naoInseridos})
            console.log("Nao inserido: ", naoInseridos)
        }
        return(
            <div className={this.props.classes.content}>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={2}>
                                <center>
                                    <h1>Preço do Produto</h1>
                                </center>
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={8}>
                                <sub>(Selecione o produto e insira o valor do produto.)</sub>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={4}>
                        <TextField 
                        select
                        id='produto' 
                        value={this.state.product_id}
                        onChange={(e) => this.setState({product_id: e.target.value})} 
                        className={this.props.classes.textField} 
                        label="Produto" 
                        variant="outlined" 
                        margin="dense">
                            {!!this.state.naoInseridos && this.state.naoInseridos.length > 0 ? 
                                this.state.naoInseridos.map(option => (
                                    <MenuItem key={`core-${option.product_id}`} value={option.product_id}>
                                        {option.comercial_name}
                                    </MenuItem>
                                ))
                            :
                                <MenuItem key={`semprod`} value="0">
                                   Todos produtos ja foram cadastrados
                                </MenuItem>
                            }
                        </TextField>
                    </Grid>
                    {!!this.state.naoInseridos && this.state.naoInseridos.length > 0 &&
                        <>
                            <Grid className={this.props.classes.root} item xl={4} xs={4}>
                                <TextField id='valor'  type="number" className={this.props.classes.textField} label="Valor " variant="outlined" margin="dense"  value={this.state.price}  onChange={(e) => this.setState({price: e.target.value})} />
                            </Grid>
                            
                            <Grid className={this.props.classes.root} item xl={4} xs={4}>
                                <Fab variant="extended" className={this.props.classes.textField} color="primary" onClick={() => this.submitIt()}>
                                    <Add />
                                    Adicionar
                                </Fab>
                            </Grid>
                        </>
                    }
                </Grid>
                <TableContainer component={Paper}>
                    <Table className={this.props.classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Produto</TableCell>
                                <TableCell>Valor</TableCell>
                                <TableCell align="center">Editar</TableCell>
                                {/* <TableCell align="center">Excluir</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {!!this.state.products && this.state.products.map(product => (
                            <TableRow>
                                <TableCell component="th" scope="row">{product.product.comercial_name}</TableCell>
                                <TableCell>R$ {product.price}</TableCell>
                                <TableCell align="center">
                                    <Fab color="primary" size="small" aria-label="editar" component="a" href={"/valor-produto/edit/" + product.product_id}>
                                        <Edit />
                                    </Fab>
                                </TableCell>
                            </TableRow>
                        ))
                        }
                          
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Formulario));