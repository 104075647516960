import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import CustomErrors from '../CustomErrors/CustomErrors';
import { withApollo, Query } from 'react-apollo';
import { COMBOS_DEMANDA_ESTIMADA, RELATORIO_OPAC } from "../../services/graphql/querys/relatorio";
import {
    makeStyles,
    Table,
    TableBody,
    TableFooter,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    Button,
    TextField,
    MenuItem
} from '@material-ui/core/';
import Save from '@material-ui/icons/Save';
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});

class Opac extends Component {
    constructor(props){
        super(props);
        this.state ={}
    }
    componentWillMount(){
        this.props.client.query({query: COMBOS_DEMANDA_ESTIMADA, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else { 
                this.setState({
                    estacoes: data.StationQuery,
                    grupos: data.GroupQuery,
                    nucleos: data.CoreQuery
                }) 
            }
        })
        .catch(erro => console.log(erro))
    }
    render(){
        let UserAccount = localStorage.getItem('UserAccount') ? JSON.parse(localStorage.getItem('UserAccount')) : undefined;
        console.log("UserAccount", UserAccount)
        return (
            <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                <h1 style={{marginBottom: "1rem"}}>Relatorio OPAC</h1>
                <Grid container spacing={3}>
                     <Grid className={this.props.classes.root} item xl={12}>
                        
                    </Grid>
                </Grid> 
                <Grid container spacing={3} style={{marginBottom: "1rem"}}>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField
                            select
                            label="Núcleo: "
                            className={this.props.classes.textField} 
                            value={this.state.core_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({core_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                            <MenuItem key={`option-opac-nucleo-null`} value="">Todos Núcleos</MenuItem>
                            {
                                this.state.nucleos &&
                                this.state.nucleos.map(
                                    (nucleo, index) => {
                                        console.log(nucleo);
                                        return <MenuItem key={`option-opac-nucleo-${index}`} value={nucleo.core_id}>{nucleo.name}</MenuItem>
                                    }
                                )
                            }
                        </TextField>
                    </Grid>
                    {
                        UserAccount && 
                        UserAccount.profile &&
                        UserAccount.profile.user_type === 'admin_central' &&
                        <Grid className={this.props.classes.root} item xl={4}>
                            <TextField
                                select
                                label="Estação: "
                                className={this.props.classes.textField} 
                                value={this.state.station_id}
                                InputLabelProps={{shrink: true,}}
                                onChange={(e) => this.setState({station_id: e.target.value})}
                                variant="outlined"
                                margin="dense"
                            >
                                <MenuItem key={`option-opac-estacoes-null`} value="">Todas Estações</MenuItem>
                                {
                                    this.state.estacoes &&
                                    this.state.estacoes.map(
                                        (estacao, index) => {
                                            console.log(estacao);
                                            return <MenuItem key={`option-opac-estacoes-${index}`} value={estacao.id}>{estacao.name}</MenuItem>
                                        }
                                    )
                                }
                            </TextField>
                        </Grid>
                    }
                    {
                        UserAccount && 
                        UserAccount.profile &&
                        UserAccount.profile.user_type === 'admin_central' &&
                        !this.state.station_id &&
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField
                            select
                            label="Estação Preferencial: "
                            className={this.props.classes.textField} 
                            value={this.state.get_register_from_station}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({get_register_from_station: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                            <MenuItem key={`option-opac-estacoes-preferencial-null`} value="">Todas Estações</MenuItem>
                            {
                                this.state.estacoes &&
                                this.state.estacoes.map(
                                    (estacao, index) => {
                                        console.log(estacao);
                                        return <MenuItem key={`option-opac-estacoes-preferencial-${index}`} value={estacao.id}>{estacao.name}</MenuItem>
                                    }
                                )
                            }
                        </TextField>
                    </Grid>
                    }
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField
                            select
                            label="Grupo: "
                            className={this.props.classes.textField} 
                            value={this.state.group_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({group_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                            <MenuItem key={`option-opac-grupo-null`} value="">Todos Grupos</MenuItem>
                            {
                                this.state.grupos &&
                                this.state.grupos.map(
                                    (grupo, index) => {
                                        console.log(grupo);
                                        return <MenuItem key={`option-opac-grupo-${index}`} value={grupo.group_id}>{grupo.name}</MenuItem>
                                    }
                                )
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <CustomErrors error={this.state.error}/>
                <Query query={RELATORIO_OPAC} variables={
                    {
                        station_id: this.state.station_id,
                        group_id: this.state.group_id,
                        core_id: this.state.core_id,
                        get_register_from_station: this.state.get_register_from_station
                    }
                }>
                    {
                        ({ loading, error, data }) => {
                            if (loading) {
                                return("Buscando ..." );
                            } else if(error) {
                                console.log("Error",error);
                            } else {
                                console.log("DATA: ",data);
                                return (
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="a dense table">
                                            {/* <TableHead>
                                                <TableRow>
                                                    <TableCell component="th">Grupo</TableCell>
                                                    <TableCell component="th" align="left">Nucleo</TableCell>
                                                    <TableCell component="th" align="right">Agricultor</TableCell>
                                                </TableRow>
                                            </TableHead> */}
                                            {/* <TableBody>
                                            {
                                                data.ReportOpacQuery.dataReport && data.ReportOpacQuery.dataReport.length > 0 &&
                                                data.ReportOpacQuery.dataReport.map((row, index) => 
                                                <TableRow key={`opac-${index}`}>
                                                    <TableCell scope="row">{row.group}</TableCell>
                                                    <TableCell align="left">{row.core}</TableCell>
                                                    <TableCell align="right">{row.farmer_name}</TableCell>
                                                </TableRow>
                                                )
                                            }
                                            </TableBody> */}
                                            <TableFooter>
                                            {
                                                data.ReportOpacQuery.dataReport && data.ReportOpacQuery.dataReport.length > 0 ?
                                                <TableRow>
                                                    <TableCell align="right" colSpan={3}>
                                                        <Button variant="contained" color="primary" component="a" href={data.ReportOpacQuery.csv.replace("http:","https:")}>
                                                            <Save/> Gerar e Salvar CSV
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                                :
                                                <TableRow>
                                                    <TableCell align="center" colSpan={3}>
                                                        <h3>Sem relatorio para os filtros selecionados</h3>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                )
                            }
                        }
                    }
                </Query>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Opac));