import gql from 'graphql-tag';

export const PRODUCT_QUERY = gql`
query productsQuery($product_id: Int, $product_category_id: Int, $local_name: String, $comercial_name: String, $unified_product: String){
  ProductQuery(
    product_id: $product_id
    product_category_id: $product_category_id
    local_name: $local_name
    comercial_name: $comercial_name
    unified_product: $unified_product
  ) {
    product_id
    product_category_id
    local_name
    comercial_name
    unified_product
    picture
    urlPicture
    productCategory {
      product_category_id
      product_category
    }
  }
 }`;

 export const PRODUCT_CATEGORY_QUERY = gql`
 query ProductCategoryQuery{
  ProductCategoryQuery{
    product_category_id
    product_category
  }
  }`;



export const PRODUCTS_QUERY = gql`

query productsQuery{
  ProductiveUnitQuery{
    productive_unit_id
    group_id
    name
    cities_involved
    states_involved
    coordinator
    comercial_phone
    cell_phone
    email
    country
    state
    city
    district
    address
    address_number
    address_complement
    zip_code
    legal_document
    state_registration
    municipal_registration
    status
  }
  ProductQuery{
    product_id
    product_category_id
    local_name
    comercial_name
    unified_product
    picture
    urlPicture
  }
  ListFarmersQuery {
    user_id
    name
    state
    city
    legal_document
  }
  CoreQuery{
    core_id
    name
    cities_involved
    states_involved
    coordinator
    comercial_phone
    cell_phone
    email
    country
    state
    city
    district
    address
    address_number
    address_complement
    zip_code
    legal_document
    state_registration
    municipal_registration
    status
    }

    GroupQuery{
      group_id
      name
      cities_involved
      states_involved
      coordinator
      comercial_phone
      cell_phone
      email
      country
      state
      city
      district
      address
      address_number
      address_complement
      zip_code
      legal_document
      state_registration
      municipal_registration
      status
    }
}
`;

export const FARMER_PRODUCTS = gql`

query farmerProducts($user_id: String!){
  FarmerQuery(id: $user_id){
    farmerProducts{
        farmer_product_id
        user_id
        product_id
        measure_unit_id
        amount_units_for_kilo
        week_production_quantity
        sale_quantity_for_unit
        week_sales_value
        week_production_capacity
        produces_in_january
        produces_in_february
        produces_in_march
        produces_in_april
        produces_in_may
        produces_in_june
        produces_in_july
        produces_in_august
        produces_in_september
        produces_in_october
        produces_in_november
        produces_in_december
      
    }
    
  }
}`;



export const PRODUCT_PRICE = gql`

query StationProductPriceQuery($product_id: Int, $measure_unit_id: String, $price: Float){
  StationProductPriceQuery(product_id: $product_id, measure_unit_id: $measure_unit_id, price: $price){
      station_id
      product_id
      measure_unit_id
      price
      product{
        product_id
        local_name
        comercial_name
        unified_product
        picture
        urlPicture
        productCategory{
          product_category_id
          product_category
        }
      }
  }
}`;

export const PRODUCT_STOCK = gql`

query StationStockQuery($product_id: Int){
  StationStockQuery(product_id: $product_id){
    data{
      quantity
      Product{
        product_id
        local_name
        comercial_name
        unified_product
        picture
        urlPicture
        productCategory{
          product_category_id
          product_category
        }
      }
    }
  }
}`;






