import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { EDIT_FARMER_COMMERCIALIZATION } from '../../../services/graphql/mutations/farmer';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';

import {
    TextField,
    Grid,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Fab,
} from '@material-ui/core';
import { Add, Print, Save } from '@material-ui/icons';


const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    radioClass: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },    
    divisor: {
        width: "100%",
        height: "3px",
        backgroundColor: "#000",
        marginTop: "3px",
        marginBottom: "10px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});

class Comercializacao extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            edit: null,
            user_id: null,
            organic_commercialization_percentage: 0,
            conventional_commercialization_percentage: 0,
            baskets_in_circuit: null,
            fair_in_circuit: null,
            station_in_circuit: null,
            long_circuit_in_circuit: null,
            baskets_out_circuit: null,
            institutional_sales_out_circuit: null,
            public_market_out_circuit: null,
            company_out_circuit: null,
            conventional_fairs_out_circuit: null,
            others_out_circuit: null,
            description_others_out_circuit: null,
            has_packaging: null,
            tray_type_packing: null,
            net_type_packing: null,
            little_bag_type_packing: null,
            bunch_type_packing: null,
            others_type_packing: null,
            description_others_type_packing: null,
            has_packaging_label: null,
            error: [],
        };
    } 
        
    componentWillReceiveProps(props){
        if(props.farmer){
            this.setState({'edit': true});
            let farmer = props.farmer;
            this.setState({
                user_id: farmer.id,

            });
            if(farmer.farmerCommercialization && farmer.farmerCommercialization != null){
                this.setState({
                    organic_commercialization_percentage: farmer.farmerCommercialization.organic_commercialization_percentage,
                    conventional_commercialization_percentage: farmer.farmerCommercialization.conventional_commercialization_percentage,
                    baskets_in_circuit: farmer.farmerCommercialization.baskets_in_circuit,
                    fair_in_circuit: farmer.farmerCommercialization.fair_in_circuit,
                    station_in_circuit: farmer.farmerCommercialization.station_in_circuit,
                    long_circuit_in_circuit: farmer.farmerCommercialization.long_circuit_in_circuit,
                    baskets_out_circuit: farmer.farmerCommercialization.baskets_out_circuit,
                    institutional_sales_out_circuit: farmer.farmerCommercialization.institutional_sales_out_circuit,
                    public_market_out_circuit: farmer.farmerCommercialization.public_market_out_circuit,
                    company_out_circuit: farmer.farmerCommercialization.company_out_circuit,
                    conventional_fairs_out_circuit: farmer.farmerCommercialization.conventional_fairs_out_circuit,
                    others_out_circuit: farmer.farmerCommercialization.others_out_circuit,
                    description_others_out_circuit: farmer.farmerCommercialization.description_others_out_circuit,
                    has_packaging: JSON.parse(farmer.farmerCommercialization.has_packaging) ? 'S' : 'N',
                    tray_type_packing: farmer.farmerCommercialization.tray_type_packing,
                    net_type_packing: farmer.farmerCommercialization.net_type_packing,
                    little_bag_type_packing: farmer.farmerCommercialization.little_bag_type_packing,
                    bunch_type_packing: farmer.farmerCommercialization.bunch_type_packing,
                    others_type_packing: farmer.farmerCommercialization.others_type_packing,
                    description_others_type_packing: farmer.farmerCommercialization.description_others_type_packing,
                    has_packaging_label: JSON.parse(farmer.farmerCommercialization.has_packaging_label) ? 'S' : 'N',
                });
            }
        }
    }
    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        if(!!paramsEnviar.has_packaging){
            if(paramsEnviar.has_packaging == 'S'){
                paramsEnviar.has_packaging = true;
            }else{
                paramsEnviar.has_packaging = false;
            }
        } 
        if(!!paramsEnviar.has_packaging_label){
            if(paramsEnviar.has_packaging_label == 'S'){
                paramsEnviar.has_packaging_label = true;
            }else{
                paramsEnviar.has_packaging_label = false;
            }
        } 
        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate({mutation: EDIT_FARMER_COMMERCIALIZATION, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
                
                

            } else {
                console.log("DATA: ", data);
                this.setState({error: []})
                alert("Salvo com sucesso !");

            }
        })
    }
    
    tot_comer(){
        return Number(this.state.conventional_commercialization_percentage) +  Number(this.state.organic_commercialization_percentage);
    }
    render() {
        return (
            <div>
                <CustomErrors error={this.state.error}/>

                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12}>
                            <h4>Quanto vc comercializa organico?</h4>
 
                            <Grid container spacing={3}>
                                <Grid className={this.props.classes.root} item xl={6}>
                                    <TextField id="organico" onChange={(e) => this.setState({organic_commercialization_percentage: e.target.value})} value={this.state.organic_commercialization_percentage} label="Organico %" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" /> 
                                </Grid>
                                     </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                            <h4>Quanto convencional? </h4>
 
                            <Grid container spacing={3}>
                                <Grid className={this.props.classes.root} item xl={6}>
                                    <TextField id="convencional" onChange={(e) => this.setState({conventional_commercialization_percentage: e.target.value})} value={this.state.conventional_commercialization_percentage} label="Convencional %" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" /> 
                                </Grid>
                            </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                            <h4>Vamos conferir?</h4>
 
                            <Grid container spacing={3}>
                                <Grid className={this.props.classes.root} item xl={6}>
                                    <TextField disabled id="tot_prod" value={this.tot_comer() + "%"} label="Conferir" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" />
                                </Grid>
        
                            </Grid>
                    </Grid>
                    
                </Grid>

                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <h3>Local de venda:</h3>
                        <h4>Circuito Agroecológico </h4>
                        <FormGroup name="local_venda">
                            <Grid container spacing={3}>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel control={<Checkbox checked={this.state.baskets_in_circuit} onChange={(e) => this.setState({baskets_in_circuit: e.target.checked})} />} label="Cestas" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel control={<Checkbox checked={this.state.fair_in_circuit} onChange={(e) => this.setState({fair_in_circuit: e.target.checked})} />} label="Feiras" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel control={<Checkbox checked={this.state.station_in_circuit} onChange={(e) => this.setState({station_in_circuit: e.target.checked})} />} label="Estação" />
                                </Grid>
                                <Grid className={this.props.classes.root} item xl={3}>
                                    <FormControlLabel control={<Checkbox checked={this.state.long_circuit_in_circuit} onChange={(e) => this.setState({long_circuit_in_circuit: e.target.checked})} />} label="Circuito Longo" />
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <h4>Vendas externas ao circuito: </h4>
                        <FormGroup name="local_venda" style={{marginLeft: "10px", display: "flex", flexDirection: "row" ,flexWrap: "wrap"}} >
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={3}>
                                <FormControlLabel control={<Checkbox checked={this.state.institutional_sales_out_circuit} onChange={(e) => this.setState({institutional_sales_out_circuit: e.target.checked})} />} label="Vendas Institucionais" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={3}>
                                <FormControlLabel control={<Checkbox checked={this.state.public_market_out_circuit} onChange={(e) => this.setState({public_market_out_circuit: e.target.checked})} />} label="Mercado Público" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={3}>
                                <FormControlLabel control={<Checkbox checked={this.state.company_out_circuit} onChange={(e) => this.setState({company_out_circuit: e.target.checked})} />} label="Empresa" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={3}>
                                <FormControlLabel control={<Checkbox checked={this.state.baskets_out_circuit} onChange={(e) => this.setState({baskets_out_circuit: e.target.checked})}/>} label="Cesta" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={3}>
                                <FormControlLabel control={<Checkbox checked={this.state.conventional_fairs_out_circuit} onChange={(e) => this.setState({conventional_fairs_out_circuit: e.target.checked})} />} label="Feiras Convencionais" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={3}>
                                <FormControlLabel control={<Checkbox checked={this.state.others_out_circuit} onChange={(e) => this.setState({others_out_circuit: e.target.checked})} />} label="Outras" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={6}>
                                <TextField id="local_venda_outros" label="Qual?" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({description_others_out_circuit: e.target.value})} value={this.state.description_others_out_circuit}  variant="outlined" margin="dense" /> 
                            </Grid>
                        </Grid>
                        </FormGroup>  
                    </Grid>
                </Grid>

                <Grid className={this.props.classes.root} item xl={12}>
                    <h4>Possui Embalagens:</h4>
                    <Grid container spacing={3}>
                        <Grid className={this.props.classes.root} item xl={6}>
                            <RadioGroup aria-label="tem_embalagens" name="tem_embalagens" style={{marginLeft: "10px"}} value={this.state.has_packaging} onChange={(e) => this.setState({has_packaging: e.target.value})} row>
                                <Grid container spacing={3}>
                                    <Grid className={this.props.classes.root} item xl={6}>
                                        <FormControlLabel value="S" control={<Radio />} label="Sim" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={6}>
                                        <FormControlLabel value="N" control={<Radio />} label="Não" />
                                    </Grid>
                                </Grid>
                            </RadioGroup> 
                        </Grid>
                        <Grid className={this.props.classes.root} item xl={6}>
                            <FormGroup name="embalagem" style={{marginLeft: "10px", display: "flex", flexDirection: "row" ,flexWrap: "wrap"}} >
                                <Grid container spacing={3}>
                                    <Grid className={this.props.classes.root} item xl={6}>
                                        <FormControlLabel control={<Checkbox checked={this.state.tray_type_packing} onChange={(e) => this.setState({tray_type_packing: e.target.checked})} />} label="Bandeja" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={6}>
                                        <FormControlLabel control={<Checkbox checked={this.state.little_bag_type_packing} onChange={(e) => this.setState({little_bag_type_packing: e.target.checked})} />} label="Saquinho" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={6}>
                                        <FormControlLabel control={<Checkbox checked={this.state.net_type_packing} onChange={(e) => this.setState({net_type_packing: e.target.checked})} />} label="Rede" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={6}>
                                        <FormControlLabel control={<Checkbox checked={this.state.bunch_type_packing} onChange={(e) => this.setState({bunch_type_packing: e.target.checked})} />} label="Moió/Molho" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={6}>
                                        <FormControlLabel control={<Checkbox checked={this.state.others_type_packing} onChange={(e) => this.setState({others_type_packing: e.target.checked})} />} label="Outras" />
                                    </Grid>
                                    <Grid className={this.props.classes.root} item xl={6}>
                                        <TextField id="embalagem_outros" label="Qual?" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({description_others_type_packing: e.target.value})} value={this.state.description_others_type_packing} variant="outlined" margin="dense" /> 
                                    </Grid>
                                </Grid>
                            </FormGroup>   
                        </Grid>    
                    </Grid>
                </Grid>

                <Grid className={this.props.classes.root} item xl={12}>
                    <h4>Possui Rótulos:</h4>
                    <RadioGroup aria-label="tem_rotulos" name="tem_embalagens" value={this.state.has_packaging_label} onChange={(e) => this.setState({has_packaging_label: e.target.value})} row>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={6}>
                                <FormControlLabel value="S" control={<Radio />} label="Sim" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={6}>
                                <FormControlLabel value="N" control={<Radio />} label="Não" />
                            </Grid>
                        </Grid>
                    </RadioGroup>      
                </Grid>            

                <Grid container spacing={3}>
                            {/* <Grid className={this.props.classes.root} item xl={6}>
                                <Fab variant="extended" color="primary">
                                    <Print />
                                    Imprimir
                                </Fab>
                            </Grid> */}
                            <Grid className={this.props.classes.root} item xl={6}>
                                <Fab variant="extended" color="primary" onClick={() => this.submitIt()}>
                                    <Save />
                                    Salvar
                                </Fab>
                            </Grid>
                        </Grid>
            </div>
            
        );
    }
}export default withApollo((withStyles(styles))(Comercializacao));
