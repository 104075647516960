import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { CREATE_MANAGEMENT } from '../../../services/graphql/mutations/management';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';
import MaskedInput from 'react-text-mask';

import {
    TextField,
    Grid,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    MenuItem
} from '@material-ui/core';
import { Add, Print, Save } from '@material-ui/icons';
import gql from 'graphql-tag';

const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    input: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },  
    radioClass: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },    
    divisor: {
        width: "100%",
        height: "3px",
        backgroundColor: "#000",
        marginTop: "3px",
        marginBottom: "10px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});

const Celular = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={['5','5',' ','\(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        showMask
      />
    );
}
const CelFixo = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={['5','5',' ','\(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        showMask
      />
    );
  }

class CadPorArea extends Component { 
    constructor(props) {
        super(props);
        if(!!this.props.manejo.manejo.managementActivityByArea && this.props.manejo.manejo.managementActivityByArea != null){
            let manejo = this.props.manejo.manejo;
            this.state = {
                managementActivityByArea: manejo.managementActivityByArea
            }
        }else{
            let userProfile = !!this.props.farmer && !!this.props.farmer.userProfile ? this.props.farmer.userProfile : null;
            this.state = {
                management_id: this.props.manejo.manejo.management_id,
                area_type_id: !!userProfile && !!userProfile.name ? userProfile.name : "",
                birth: !!userProfile && !!userProfile.birth ? userProfile.birth : null,
                identity_document: !!this.props.farmer.identity_document ? this.props.farmer.identity_document : "",
                cpf_document: !!userProfile && !!userProfile.legal_document ? userProfile.legal_document : "",
                rural_block_registration: !!this.props.farmer.rural_block_registration ? this.props.farmer.rural_block_registration : "",
                spouse_name: null,
                spouse_birth: null,
                children: !!this.props.farmer.familyStructure && !!this.props.farmer.familyStructure.number_children ? this.props.farmer.familyStructure.number_children : null,
                family_members: null,
                address: !!userProfile && !!userProfile.address ? userProfile.address : "",
                line_or_community: null,
                city: !!userProfile && !!userProfile.city ? userProfile.city : "",
                zip_code: !!userProfile && !!userProfile.zip_code ? userProfile.zip_code : "",
                state: !!userProfile && !!userProfile.state ? userProfile.state : "",
                phone: !!this.props.farmer.user && !!this.props.farmer.user.msisdn ? this.props.farmer.user.msisdn : null,
                fax: !!this.props.farmer.user && !!this.props.farmer.user.phone ? this.props.farmer.user.phone : null,
                productive_unit_description: null,
                error: [],
            };
        }
    } 
    componentDidMount(){
        this.props.client.query({
            query: gql`query{
                AreaTypeQuery{
                    area_type_id
                    area_description
                }
                ManagementTypeQuery{
                    management_type_id
                    management_type
                }
            }`
        , errorPolicy: 'all'})
        .then(
            ({data, loading, error}) => {
                if(data){
                    console.log(data)
                    let list = []
                    data.AreaTypeQuery.map((area, index) => {
                        console.log("area:", area)
                        list.push({
                            management_id:this.props.manejo.manejo.management_id,
                            area_description: this.state.managementActivityByArea[index].area_description ? this.state.managementActivityByArea[index].area_description : area.area_description, 
                            area_type_id: this.state.managementActivityByArea[index].area_type_id ? this.state.managementActivityByArea[index].area_type_id : area.area_description, 
                            occupied_area: this.state.managementActivityByArea[index].occupied_area ? this.state.managementActivityByArea[index].occupied_area : null,
                            management_type_id: this.state.managementActivityByArea[index].management_type_id ? this.state.managementActivityByArea[index].management_type_id : null,
                            installments_number: this.state.managementActivityByArea[index].installments_number ? this.state.managementActivityByArea[index].installments_number : null,
                        })
                    })
                    this.setState({
                        managementActivityByArea: list,
                        ManagementTypeQuery: data.ManagementTypeQuery
                    })
                    console.log(this.states)
                }
            }
        )
    }
        
    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        //let managementQuery
        console.log("Enviar Parametros: ", paramsEnviar);
        // paramsEnviar.managementActivityByArea.map(management => {
        //     `editManagementActivityByArea(
        //         management_id: ${management.management_id}, 
        //         area_type_id: ${management.area_type_id}, 
        //         management_type_id: ${management.management_type_id}, 
        //         occupied_area: ${management.occupied_area}, 
        //         installments_number: ${management.installments_number}
        //     ){
        //         management_id
        //         area_type_id
        //         area_description
        //         occupied_area
        //         management_type_id
        //         installments_number
        //       }
        //     `
        // }
        let types = "";
        let request = ""; 
        let variables = [];

        for(let i = 0; i < paramsEnviar.managementActivityByArea.length; i++) {
            let {management_id, area_type_id, management_type_id, occupied_area, installments_number} = paramsEnviar.managementActivityByArea[i]
            
            if(management_id !== null) variables[`management_id_${i}`] = management_id;
            if(area_type_id !== null) variables[`area_type_id_${i}`] = area_type_id;
            if(management_type_id !== null) variables[`management_type_id_${i}`] = management_type_id;
            if(occupied_area !== null) variables[`occupied_area_${i}`] = occupied_area;
            if(installments_number !== null) variables[`installments_number_${i}`] = parseInt(installments_number);

            types = types + `$management_id_${i}: Int!, `
            types = types + `$area_type_id_${i}: Int!, `;
            types = types + `$management_type_id_${i}: String!, `;
            types = types + `$occupied_area_${i}: Float, `;
            types = types + `$installments_number_${i}: Int, `;

            request = request + `product${i}: editManagementActivityByArea(
                management_id: $management_id_${i}, 
                area_type_id: $area_type_id_${i}, 
                management_type_id: $management_type_id_${i}, 
                occupied_area: $occupied_area_${i}, 
                installments_number: $installments_number_${i}
            ){
                management_id
                area_type_id
                management_type_id
                occupied_area
                installments_number
            }`;
        }

        console.log("variables: ", this.state.managementActivityByArea);
        console.log("Request: ", request);
        console.log("Types: ", types);
        this.props.client.mutate({mutation: gql`
            mutation editManagementActivityByArea(${types}){ ${request} }`, 
            variables: variables, 
            errorPolicy: 'all'
        })
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
                
                

            } else {
                console.log("DATA: ", data);
                //this.props.setManagement(true);
                alert("Salvo com sucesso !");

            }
        })
        .catch(
            error => {
                console.log(error)
            }
        )
        

        // this.props.client.mutate({mutation: EDIT_MANAGEMENT_ACTIVIT, variables: paramsEnviar, errorPolicy: 'all'})
        // .then(({data, errors}) => {
        //     if(errors){
        //         console.log("ERROR: ", errors)
        //         if(errors[0].message == 'validation'){
        //             let erroValid = errors[0].extensions.validation;
        //             let errosMostrar = [];
        //             let i = 0;
        //             for (var [key, value] of Object.entries(erroValid)) {
        //                 console.log("ERRO... " + key + ' ',value);
        //                 errosMostrar[i] = {message: value};
        //                 i++;
        //             }
        //             this.setState({error: errosMostrar})
        //         }else{
        //             let errosMostrar = [];
        //             for(var [key, value] of Object.entries(errors)){
        //                 errosMostrar[key] = {message: value.message};
        //             }
        //             this.setState({error: errosMostrar})
        //         }
                
                

        //     } else {
        //         console.log("DATA: ", data);
        //        this.props.setManagement(true);
        //         alert("Salvo com sucesso !");

        //     }
        // })
        // .catch(
        //     error => {
        //         console.log(error)
        //     }
        // )
    }

    handleChangeMask = name => event => {
        this.setState({
        [name]: event.target.value,
        });
    };

    render() {
        // console.log("state", this.state)
        return (
            <>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3} style={{paddingTop: "10px"}}>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <h4>Gerenciamento por Área {this.props.manejo.manejo.description}</h4>
                        
                        <TableContainer component={Paper}>
                            <Table className={this.props.classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Descrição da Área</TableCell>
                                        <TableCell>Área ocupada (ha)</TableCell>
                                        <TableCell>Tipo de Manejo*</TableCell>
                                        <TableCell align="right">Parcela Nº</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.managementActivityByArea &&
                                        this.state.managementActivityByArea.map(
                                        (management, index) => 
                                            <TableRow key={`row-area-list-${index}`}>
                                                <TableCell>{management.area_description}</TableCell>
                                                <TableCell>
                                                    <TextField 
                                                        id="occupied_area" 
                                                        label="Área Ocupada" 
                                                        InputLabelProps={{shrink: true,}} 
                                                        variant="outlined" 
                                                        margin="dense" 
                                                        onChange={
                                                            (e) => {
                                                                let managementActivityByArea = this.state.managementActivityByArea;
                                                                managementActivityByArea[index].occupied_area = e.target.value;
                                                                this.setState({managementActivityByArea: managementActivityByArea})
                                                            }
                                                        }
                                                        value={this.state.managementActivityByArea[index].occupied_area}/>
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        this.state.ManagementTypeQuery &&
                                                        <TextField
                                                            select
                                                            className={this.props.classes.input}
                                                            label="Tipo de manejo"
                                                            value={this.state.managementActivityByArea[index].management_type_id}
                                                            InputLabelProps={{shrink: true,}}
                                                            onChange={
                                                                (e) => {
                                                                    let managementActivityByArea = this.state.managementActivityByArea;
                                                                    managementActivityByArea[index].management_type_id = e.target.value;
                                                                    this.setState({managementActivityByArea: managementActivityByArea})
                                                                }
                                                            }
                                                            variant="outlined"
                                                            margin="dense"
                                                        >
                                                            {
                                                                this.state.ManagementTypeQuery.map(
                                                                    (type, ind) => 
                                                                        <MenuItem key={`option-${index}-${ind}`} value={type.management_type_id}>
                                                                            {type.management_type}
                                                                        </MenuItem>
                                                                )
                                                            }
                                                        </TextField>
                                                    }
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField 
                                                        id="installments_number" 
                                                        label="Parcela Nº" 
                                                        InputLabelProps={{shrink: true,}} 
                                                        variant="outlined" 
                                                        margin="dense" 
                                                        onChange={
                                                            (e) => {
                                                                let managementActivityByArea = this.state.managementActivityByArea;
                                                                managementActivityByArea[index].installments_number = e.target.value;
                                                                this.setState({managementActivityByArea: managementActivityByArea})
                                                            }
                                                        }
                                                        value={this.state.managementActivityByArea[index].installments_number}/>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                </Grid>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={6}>
                        <Fab variant="extended" color="primary" onClick={() => this.submitIt()}>
                            <Save />
                            Criar
                        </Fab>
                    </Grid>
                </Grid>
            </>
        );
    }
}export default withApollo((withStyles(styles))(CadPorArea));
