import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CHANGE_PASSWORD } from '../../services/graphql/mutations/user';
import { withApollo, graphql, Query } from 'react-apollo';
import CustomErrors from '../CustomErrors/CustomErrors';

import {
    TextField,
    Grid,
    MenuItem,
    Fab,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    textField: {
        width: '100%'
    },
    title: {
        marginTop: '2rem',
        marginBottom: '1rem'
    }
});

class Editar extends Component {
    constructor(props){
        super(props);
        this.state = {
            status: '', 
            current_password: '',
            new_password: '',
            new_password_confirm: '',
            login: '',
            name: '',
            email: '',
            error: [],
        }
    }
    componentWillReceiveProps(props){
        console.log("Recebe props: ",props)
        if(!!props.user){
            let user = props.user;
            console.log("USER: ",props.user)
            this.setState({
                user_id: user.id,
                name: user.profile.name,
                login: user.login,
                email: user.email,
            })
        }
    }

    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        console.log("Enviar Parametros: ", paramsEnviar);

        if(this.state.new_password != this.state.new_password_confirm){
            console.log("INCORRECT PASSWORD");
            alert("Por favor confirme seu novo password");
        }else{
            let paransAtualiza = {
                user_id: paramsEnviar.user_id,
                name: paramsEnviar.name,
                login: paramsEnviar.login,
                email: paramsEnviar.email,
                password: this.state.new_password
            }
            this.props.client.mutate({mutation: CHANGE_PASSWORD, variables: paransAtualiza, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                    if(errors[0].message == 'validation'){
                        let erroValid = errors[0].extensions.validation;
                        let errosMostrar = [];
                        let i = 0;
                        for (var [key, value] of Object.entries(erroValid)) {
                            console.log("ERRO... " + key + ' ',value);
                            errosMostrar[i] = {message: value};
                            i++;
                        }
                        this.setState({error: errosMostrar})
                    }else{
                        let errosMostrar = [];
                        for(var [key, value] of Object.entries(errors)){
                            errosMostrar[key] = {message: value.message};
                        }
                        this.setState({error: errosMostrar})
                    }
                } else {
                    console.log("DATA: ", data);
                    this.setState({error: []});
                    alert("Salvo com sucesso !");
                    window.location.href = "/usuarios";

                }
            })
        }
    }

    render(){
        return(
            <div className={this.props.classes.content}>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={6} xs={12}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xs={12}>
                                <TextField disabled  className={this.props.classes.textField} label="Nome Login" variant="outlined" margin="dense" value={this.state.login}/>
                            </Grid>
                            <Grid className={this.props.classes.root} item xs={12}>
                                <TextField className={this.props.classes.textField} 
                                label="Nome Usuário" 
                                InputLabelProps={{shrink: true,}}
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({name: e.target.value})}
                                value={this.state.name} />
                            </Grid>
                            <Grid className={this.props.classes.root} item xs={12}>
                                <TextField className={this.props.classes.textField} 
                                label="Email Address" 
                                InputLabelProps={{shrink: true,}}
                                variant="outlined" 
                                margin="dense" 
                                onChange={(e) => this.setState({email: e.target.value})}
                                value={this.state.email}/>
                            </Grid>
                            {/* <Grid className={this.props.classes.root} item xs={12}>
                                <TextField  className={this.props.classes.textField} 
                                label="Senha Atual" 
                                InputLabelProps={{shrink: true,}}
                                type="password"
                                onChange={(e) => this.setState({current_password: e.target.value})} 
                                value={this.state.current_password}
                                variant="outlined" margin="dense" />
                            </Grid> */}
                            <Grid className={this.props.classes.root} item xs={12}>
                                <TextField  className={this.props.classes.textField} 
                                label="Nova Senha" 
                                InputLabelProps={{shrink: true,}}
                                type="password"
                                onChange={(e) => this.setState({new_password: e.target.value})} 
                                value={this.state.new_password}
                                variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xs={12}>
                                <TextField  className={this.props.classes.textField} 
                                label="Confirmar Nova Senha" 
                                InputLabelProps={{shrink: true,}}
                                type="password"
                                onChange={(e) => this.setState({new_password_confirm: e.target.value})} 
                                value={this.state.new_password_confirm}
                                variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xs={4}>
                                <Fab variant="extended" className={this.props.classes.textField} color="primary" onClick={() => this.submitIt()}>
                                    <Save />
                                    Salvar
                                </Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Editar));