import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withApollo, Query } from 'react-apollo';


// COMPONENTS
import Lista from '../../Component/Rodada/ListRodada';
import ListaPedidos from '../../Component/Rodada/ListaPedidos';
import ListaPedidosFarmer from '../../Component/Rodada/ListaPedidosFarmer';
import Formulario from '../../Component/Rodada/PedidoRodada';
import NovaRodada from '../../Component/Rodada/NovaRodada';
import ConferirPedido from '../../Component/Rodada/ConferirPedido';
import VerConferido from '../../Component/Rodada/VerConferido';


const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    contentBox: {
        backgroundColor:"#fff",
        borderRadius: "4px",
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    }
});
class Rodada extends Component {
    constructor(props){
        super(props);
        this.state ={
            bottom: false,
            expanded: false
        }
    }
    render(){
        console.log("PROPS: ",this.props)

        console.log("ACHEI O PROPS: ",this.props)
        if(this.props.match.params.action == 'editar' ){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <Formulario id_rodada={this.props.match.params.id} id_pedido={this.props.match.params.id_pedido}/>
                </div>
            );
        } else if(this.props.match.params.action == 'pedidoFarmer' ){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <ListaPedidosFarmer id={this.props.match.params.id }/>
                </div>
            );
        } else if(this.props.match.params.action == 'visualizar' ){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <ListaPedidos id={this.props.match.params.id }/>
                </div>
            );
        } else if(this.props.match.params.action == 'verConferido' ){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <VerConferido id={this.props.match.params.id_pedido }/>
                </div>
            );
        } else if(this.props.match.params.action == 'new' ){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <NovaRodada id={this.props.match.params.id }/>
                </div>
            );    
        } else if(this.props.match.params.action == 'conferir' ){
            return (
                <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <ConferirPedido id_rodada={this.props.match.params.id} id_pedido={this.props.match.params.id_pedido}/>
                </div>
            );                      
        } else {
            return(
                <div className="page-wrapper"  style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                    <Lista/>
                </div>
            );
        }
    }
}
export default withApollo(withStyles(styles)(Rodada));