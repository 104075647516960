import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withApollo, graphql, Query } from 'react-apollo';
import { LISTA_TIPOS_CONSUMIDOR } from '../../services/graphql/querys/customer';
import { EDIT_COMMERCIALIZATION_TYPE } from '../../services/graphql/mutations/customer';
import {
    TextField,
    Grid,
    MenuItem,
    Fab,
} from '@material-ui/core';
import { Save ,Edit } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    }
});

class EditarTiposConsumidor extends Component {
    constructor(props){
        super(props);
        this.state = {
            commercialization_type_id: this.props.id,
            description: "",
        }
    }
    componentDidMount(){
       
        if(!!this.props.id && this.props.id > 0){
            let body = {
                commercialization_type_id:  this.props.id,
            }
            this.setState({commercialization_type_id: this.props.id});
            let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Params Env: ",paramsEnviar)
            this.props.client.query({query: LISTA_TIPOS_CONSUMIDOR, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("RRRRR: ", errors)
                } else {
                    console.log("DATAAAAAA: ", data)
                    this.setState({'types': data.CommercializationsTypeQuery});                    

                    this.setState({ 'commercialization_type_id': data.CommercializationsTypeQuery[0].commercialization_type_id, 'description': data.CommercializationsTypeQuery[0].description });   

                }
            })
        }
    }

    submitIt(){
        if(!!this.state.description){
            let paramsEnviar = Object.entries(this.state).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Enviar Parametros: ", paramsEnviar);
            this.props.client.mutate({mutation: EDIT_COMMERCIALIZATION_TYPE, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                    if(errors[0].message == 'validation'){
                        let erroValid = errors[0].extensions.validation;
                        let errosMostrar = [];
                        let i = 0;
                        for (var [key, value] of Object.entries(erroValid)) {
                            console.log("ERRO... " + key + ' ',value);
                            errosMostrar[i] = {message: value};
                            i++;
                        }
                        this.setState({...this.state, error: errosMostrar})
                    }else{
                        let errosMostrar = [];
                        for(var [key, value] of Object.entries(errors)){
                            errosMostrar[key] = {message: value.message};
                        }
                        this.setState({...this.state, error: errosMostrar})
                    }
                } else {
                    console.log("DATA: ", data);
                    this.setState({...this.state, error: []});
                    alert("Salvo com sucesso !");
                    window.location.href = "/tipos-consumidor";
                }
            })    
        }else{
            alert("Por favor preencher o campo corretamente");
        }
    }

    render(){
        console.log("props: ", this.props)
        return(
            <div className={this.props.classes.content}>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={2}>
                                <center>
                                    <h1>Tipo Consumidor</h1>
                                </center>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className={this.props.classes.root} item xl={4} xs={4}>
                        <TextField id='description' className={this.props.classes.textField} label="Tipo Consumidor" variant="outlined" margin="dense"  value={this.state.description}  onChange={(e) => this.setState({description: e.target.value})} />
                    </Grid>
                    
                    <Grid className={this.props.classes.root} item xl={4} xs={4}>
                        <Fab variant="extended" className={this.props.classes.textField} color="primary" onClick={() => this.submitIt()}>
                            <Save />
                            Salvar
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(EditarTiposConsumidor));