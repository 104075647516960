import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CREATE_USER } from '../../services/graphql/mutations/user';
import { STATION_QUERY } from '../../services/graphql/querys/station';
import CustomErrors from '../CustomErrors/CustomErrors';

import { withApollo, graphql, Query } from 'react-apollo';

import {
    TextField,
    Grid,
    MenuItem,
    Fab,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    textField: {
        width: '100%'
    },
    title: {
        marginTop: '2rem',
        marginBottom: '1rem'
    }
});

class Adicionar extends Component {
    constructor(props){
        super(props);
        this.state = {
            status: '', 

            name: '', 
            email: '', 
            login: '', 
            password: '', 
            user_type: '', 
            station_id: '', 
            stations: [],
            admin_central: false,
            station: false,
            admin_station: false,
            legal_document: '',
            error: [],
            
        }
    }
    
    handleChange = event => {
        this.setState({status:event.target.value});
    };
    handleChangeStation = event => {
        this.setState({station_id: event.target.value});
    };
    handleChangeCheck = name => event => {
        console.log(name)
        if(name == "admin_central"){
            this.setState({ 
                station: false,
                admin_station: false,
                pertenceEstacao:''
            });
        } else {
            this.setState({ admin_central: false });
        }
        this.setState({ [name]: event.target.checked });
      }

      componentDidMount(){
        this.props.client.query({query: STATION_QUERY, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                    let { validation } = errors[0];
                    console.log("Valid: ",validation)
                    // if(validation.codename){
                    //     sendMessageToUser('O codinome já foi usado');
                    // }
           
                } else {
                    console.log("DATA: ", data)
                    this.setState({'stations': data.StationQuery});                    
                }
            })
    }

    submitIt = () => {
        console.log("State: ", this.state)
        let userType = this.state.admin_central ? 'admin_central' : this.state.admin_station ? 'admin_station' : 'station';
    
        let body = {
            name: this.state.name, 
            email: this.state.email, 
            login: this.state.login, 
            password: this.state.password, 
            user_type: userType, 
            station_id: this.state.station_id, 
            legal_document: this.state.legal_document,
        }

        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate({mutation: CREATE_USER, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
 
            } else {
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");
                window.location.href = "/usuarios";
            }
        })
    }
    render(){
        let UserAccount = localStorage.getItem('UserAccount') ? JSON.parse(localStorage.getItem('UserAccount')) : undefined;
        return(
            <div className={this.props.classes.content}>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xs={6}>
                        <TextField  className={this.props.classes.textField} label="Nome Usuário" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({name: e.target.value})} value={this.state.name} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xs={6}>
                        <TextField  className={this.props.classes.textField} label="Login" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({login: e.target.value})} value={this.state.login} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xs={6}>
                        <TextField  className={this.props.classes.textField} label="Email Address" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({email: e.target.value})} value={this.state.email} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xs={6}>
                        <TextField  className={this.props.classes.textField} label="Senha (letras e numeros)" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({password: e.target.value})} value={this.state.password} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item xs={6}>
                        <TextField  className={this.props.classes.textField} label="CPF/CNPJ" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({legal_document: e.target.value})} value={this.state.legal_document} variant="outlined" margin="dense" />
                    </Grid>
                </Grid>
                <h2 className={this.props.classes.title}>Funções</h2>
                <Grid container spacing={3}>
                    {UserAccount && UserAccount.profile.user_type == 'admin_central' &&
                    <Grid className={this.props.classes.root} item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox color="primary" checked={this.state.admin_central}  onChange={this.handleChangeCheck('admin_central')}/>
                            }
                            label="Administrador Central"
                        />
                    </Grid>
                    }
                    <Grid className={this.props.classes.root} item xs={6}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox color="primary" checked={this.state.station}  onChange={this.handleChangeCheck('station')}/>
                                    }
                                    label="Estação/Estoquista"
                                />
                            </Grid>
                        </Grid>
                        {this.state.station&&
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox color="primary" checked={this.state.admin_station}  onChange={this.handleChangeCheck('admin_station')}/>
                                    }
                                    label="Estação Administrador"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xs={12}>
                                <TextField
                                    id="select-nucleo"
                                    select
                                    label="Vinculado a Estação"
                                    value={this.state.station_id}
                                    InputLabelProps={{shrink: true,}}
                                    onChange={this.handleChangeStation}
                                    variant="outlined"
                                    margin="dense"
                                    className={this.props.classes.textField}
                                >
                                    {this.state.stations.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        }
                    </Grid>
                    <Grid className={this.props.classes.root} item xs={6}>
                        <Fab variant="extended" className={this.props.classes.textField} onClick={() => this.submitIt()} color="primary">
                            <Save />
                            Salvar
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Adicionar));