import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withApollo, graphql, Query } from 'react-apollo';
import { PRODUCTS_QUERY, PRODUCT_STOCK } from '../../services/graphql/querys/product';
import { ADD_PRODUCT_STOCK, EDIT_PRODUCT_STOCK } from '../../services/graphql/mutations/product';
import {
    TextField,
    Grid,
    MenuItem,
    Fab,
} from '@material-ui/core';
import { Save ,Edit } from '@material-ui/icons';
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        marginRight: '20px'
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '8px',
        lineHeight: '2'
    }
});

class EditarProdutoEstoque extends Component {
    constructor(props){
        super(props);
        this.state = {
            product_id: null,
            quantity: "",
            products: null,
        }
    }
    componentDidMount(){
       
        if(!!this.props.id && this.props.id > 0){
            let body = {
                product_id:  this.props.id,
            }
            this.setState({product_id: this.props.id});
            let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Params Env: ",paramsEnviar)
            this.props.client.query({query: PRODUCT_STOCK, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("RRRRR: ", errors)
                } else {
                    console.log("DATAAAAAA: ", data)
                    this.setState({ 'product_id': data.StationStockQuery.data[0].Product.product_id, 'quantity': data.StationStockQuery.data[0].quantity, 'name': data.StationStockQuery.data[0].Product.comercial_name });   

                }
            })
        }else{
            this.props.client.query({query: PRODUCTS_QUERY, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("DATA: ", data)
                    this.setState({ 'products': data.ProductQuery });   
                }
            })
        }
    }

    submitIt(){
        if(!!this.state.product_id && !!this.state.quantity){
            let paramsEnviar = Object.entries(this.state).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Enviar Parametros: ", paramsEnviar);
            this.props.client.mutate({mutation: this.props.id > 0 ? EDIT_PRODUCT_STOCK : ADD_PRODUCT_STOCK, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                    if(errors[0].message == 'validation'){
                        let erroValid = errors[0].extensions.validation;
                        let errosMostrar = [];
                        let i = 0;
                        for (var [key, value] of Object.entries(erroValid)) {
                            console.log("ERRO... " + key + ' ',value);
                            errosMostrar[i] = {message: value};
                            i++;
                        }
                        this.setState({...this.state, error: errosMostrar})
                    }else{
                        let errosMostrar = [];
                        for(var [key, value] of Object.entries(errors)){
                            errosMostrar[key] = {message: value.message};
                        }
                        this.setState({...this.state, error: errosMostrar})
                    }
                } else {
                    console.log("DATA: ", data);
                    this.setState({...this.state, error: []});
                    alert("Salvo com sucesso !");
                    window.location.href = "/produto-estoque";
                }
            })    
        }else{
            alert("Por favor preencher os campos corretamente");
        }
    }

    render(){
        console.log("props: ", this.props)
        return(
            <div className={this.props.classes.content}>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={2}>
                                <center>
                                    <h1>Estoque do Produto</h1>
                                </center>
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={8}>
                                <sub>(Selecione o produto e insira o estoque do produto.)</sub>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={4}>
                        {!!this.props.id && this.props.id > 0 ?
                            <center>{this.state.name}</center>
                        :
                            <TextField 
                            select
                            id='produto' 
                            className={this.props.classes.textField} 
                            label="Produto" 
                            variant="outlined" 
                            value={this.state.product_id}
                            onChange={(e) => this.setState({product_id: e.target.value})} 
                            margin="dense">
                                {!!this.state.products && this.state.products.map(option => (
                                    <MenuItem key={`core-${option.product_id}`} value={option.product_id}>
                                    {option.comercial_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        }
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4} xs={4}>
                        <TextField id='estoque' className={this.props.classes.textField} label="KG" variant="outlined" margin="dense"  value={this.state.quantity}  onChange={(e) => this.setState({quantity: e.target.value.replace(/,/i, '.')})} />
                    </Grid>
                    
                    <Grid className={this.props.classes.root} item xl={4} xs={4}>
                        <Fab variant="extended" className={this.props.classes.textField} color="primary" onClick={() => this.submitIt()}>
                            <Save />
                            Salvar
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(EditarProdutoEstoque));