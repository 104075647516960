import gql from 'graphql-tag';

export const CREATE_GROUP = gql`
mutation createGroup(
    $name: String!, 
    $cities_involved: String, 
    $states_involved: String, 
    $coordinator: String, 
    $comercial_phone: String, 
    $cell_phone: String, 
    $email: String, 
    $country: String, 
    $state: String, 
    $city: String, 
    $district: String, 
    $address: String, 
    $address_number: Int, 
    $address_complement: String, 
    $zip_code: String, 
    $legal_document: String, 
    $state_registration: String, 
    $municipal_registration: String, 
    $status: String
){
    createGroup(
        name: $name, 
        cities_involved: $cities_involved, 
        states_involved: $states_involved, 
        coordinator: $coordinator, 
        comercial_phone: $comercial_phone, 
        cell_phone: $cell_phone, 
        email: $email, 
        country: $country, 
        state: $state, 
        city: $city, 
        district: $district, 
        address: $address, 
        address_number: $address_number, 
        address_complement: $address_complement, 
        zip_code: $zip_code, 
        legal_document: $legal_document, 
        state_registration: $state_registration, 
        municipal_registration: $municipal_registration, 
        status: $status
    ){
        group_id
        name
        cities_involved
        states_involved
        coordinator
        comercial_phone
        cell_phone
        email
        country
        state
        city
        district
        address
        address_number
        address_complement
        zip_code
        legal_document
        state_registration
        municipal_registration
        status
  }
}
`;

export const EDIT_GROUP = gql`
mutation editGroup(
    $group_id: Int!, 
    $name: String!, 
    $cities_involved: String, 
    $states_involved: String, 
    $coordinator: String, 
    $comercial_phone: String, 
    $cell_phone: String, 
    $email: String, 
    $country: String, 
    $state: String, 
    $city: String, 
    $district: String, 
    $address: String, 
    $address_number: Int, 
    $address_complement: String, 
    $zip_code: String, 
    $legal_document: String, 
    $state_registration: String, 
    $municipal_registration: String, 
    $status: String
){
    editGroup(
        group_id: $group_id, 
        name: $name, 
        cities_involved: $cities_involved, 
        states_involved: $states_involved, 
        coordinator: $coordinator, 
        comercial_phone: $comercial_phone, 
        cell_phone: $cell_phone, 
        email: $email, 
        country: $country, 
        state: $state, 
        city: $city, 
        district: $district, 
        address: $address, 
        address_number: $address_number, 
        address_complement: $address_complement, 
        zip_code: $zip_code, 
        legal_document: $legal_document, 
        state_registration: $state_registration, 
        municipal_registration: $municipal_registration, 
        status: $status
    ){
      group_id
      name
      cities_involved
      states_involved
      coordinator
      comercial_phone
      cell_phone
      email
      country
      state
      city
      district
      address
      address_number
      address_complement
      zip_code
      legal_document
      state_registration
      municipal_registration
      status
    }
  }
`;
export const DELETE_GROUP = gql`

mutation  deleteGroup(
  $group_id: Int!,
){
    deleteGroup(group_id: $group_id)
}`;


