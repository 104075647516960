import React, { Component } from 'react';
import { withApollo, graphql, Query } from 'react-apollo';
import { EDIT_FARMER_PRODUCTS, DELETE_FARMER_PRODUCT } from '../../../services/graphql/mutations/farmer';
import { FARMER_PRODUCTS } from '../../../services/graphql/querys/product';
import CustomErrors from '../../CustomErrors/CustomErrors';

import { withStyles } from '@material-ui/core/styles';
import { 
    TextField,
    FormControlLabel,
    Checkbox,
    MenuItem,
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper,
    Grid,
    Fab
 } from '@material-ui/core/';

import { Print, Save, Delete } from '@material-ui/icons';

const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        width: '100%'
    },
    ContentCheckBox: {
        marginLeft: '-11px'
    }
});


class Tabela extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            edit: null,
            user_id: null,
            farmer_product_id: null,
            product_id: null,
            measure_unit_id: "KG",
            amount_units_for_kilo: null,
            week_production_quantity: null,
            sale_quantity_for_unit: null,
            week_sales_value: null,
            week_production_capacity: null,
            produces_in_january: null,
            produces_in_february: null,
            produces_in_march: null,
            produces_in_april: null,
            produces_in_may: null,
            produces_in_june: null,
            produces_in_july: null,
            produces_in_august: null,
            produces_in_september: null,
            produces_in_october: null,
            produces_in_november: null,
            produces_in_december: null,
            todos_meses: null,
            error: [],
        };
    } 
    
    marcaTodosMeses(checked){
        console.log("Checked ? ", checked)
        this.setState({
            produces_in_january: checked,
            produces_in_february: checked,
            produces_in_march: checked,
            produces_in_april: checked,
            produces_in_may: checked,
            produces_in_june: checked,
            produces_in_july: checked,
            produces_in_august: checked,
            produces_in_september: checked,
            produces_in_october: checked,
            produces_in_november: checked,
            produces_in_december: checked,
            todos_meses: checked,
        })
    }

    componentWillReceiveProps(props){

        if(props.farmer){
            let farmer = props.farmer;
            this.setState({
                user_id: farmer.id,
                'edit': true,


            });
            if(farmer.farmerProduct && farmer.farmerProduct != null){
                this.setState({
                    farmer_product_id: farmer.farmerProduct.farmer_product_id,
                    product_id: farmer.farmerProduct.product_id,
                    measure_unit_id: farmer.farmerProduct.measure_unit_id,
                    amount_units_for_kilo: farmer.farmerProduct.amount_units_for_kilo,
                    week_production_quantity: farmer.farmerProduct.week_production_quantity,
                    sale_quantity_for_unit: farmer.farmerProduct.sale_quantity_for_unit,
                    week_sales_value: farmer.farmerProduct.week_sales_value,
                    week_production_capacity: farmer.farmerProduct.week_production_capacity,
                    produces_in_january: farmer.farmerProduct.produces_in_january,
                    produces_in_february: farmer.farmerProduct.produces_in_february,
                    produces_in_march: farmer.farmerProduct.produces_in_march,
                    produces_in_april: farmer.farmerProduct.produces_in_april,
                    produces_in_may: farmer.farmerProduct.produces_in_may,
                    produces_in_june: farmer.farmerProduct.produces_in_june,
                    produces_in_july: farmer.farmerProduct.produces_in_july,
                    produces_in_august: farmer.farmerProduct.produces_in_august,
                    produces_in_september: farmer.farmerProduct.produces_in_september,
                    produces_in_october: farmer.farmerProduct.produces_in_october,
                    produces_in_november: farmer.farmerProduct.produces_in_november,
                    produces_in_december: farmer.farmerProduct.produces_in_december,
                });
            }
        }
    }
    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate(
            {
                mutation: EDIT_FARMER_PRODUCTS, 
                variables: paramsEnviar,
                errorPolicy: 'all',
                refetchQueries:[{
                    query: FARMER_PRODUCTS,
                    variables: 
                    { 
                      user_id: this.state.user_id,
                    }
                }]
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
            }else{
                console.log("DATA: ", data);
                this.setState({error: []});
                alert("Salvo com sucesso !");                
            }
        })
    }
    removeProd(id){
        console.log("Remove produto ", id)
        this.props.client.mutate({
            mutation: DELETE_FARMER_PRODUCT, 
            variables: {farmer_product_id: id.farmer_product_id}, 
            errorPolicy: 'all',
            refetchQueries:[{
                query: FARMER_PRODUCTS,
                variables: 
                { 
                  user_id: this.state.user_id,
                }
            }]
        })
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({open: false, remover: null});
                alert("Erro ao remover produto!"); 
            } else {
                console.log("DATA: ", data);
                this.setState({open: false, remover: null});
                alert("Produto removido!");
            }
        })
    }
    renderResult(result){
        return (
            result.map(option => (
                <TableRow key={`table-row-mes-${option.product_id}`}>
                    <TableCell>{option.product_id}</TableCell>
                    <TableCell align="right">
                    {this.props.products.map(product => (
                        product.product_id == option.product_id ?
                        product.unified_product
                        :
                        ""
                    ))}
                    </TableCell>
                    {/* <TableCell align="right">
                    {this.props.productUnit.map(productUnit => (
                        productUnit.productive_unit_id == option.measure_unit_id ?
                        productUnit.name
                        :
                        ""
                    ))}
                    </TableCell> */}
                    <TableCell align="right">{option.week_production_quantity}</TableCell>
                    <TableCell align="right">{option.amount_units_for_kilo}</TableCell>

                    
                    <TableCell align="right">
                        <Table className={this.props.classes.table} size="small" aria-label="a dense table">
                            <TableBody>
                                <TableRow>
                                    <TableCell padding={'none'}>{option.produces_in_january ? "X" : "O"}</TableCell>
                                    <TableCell padding={'none'}>{option.produces_in_february ? "X" : "O"}</TableCell>
                                    <TableCell padding={'none'}>{option.produces_in_march ? "X" : "O"}</TableCell>
                                    <TableCell padding={'none'}>{option.produces_in_april ? "X" : "O"}</TableCell>
                                    <TableCell padding={'none'}>{option.produces_in_may ? "X" : "O"}</TableCell>
                                    <TableCell padding={'none'}>{option.produces_in_june ? "X" : "O"}</TableCell>
                                    <TableCell padding={'none'}>{option.produces_in_july ? "X" : "O"}</TableCell>
                                    <TableCell padding={'none'}>{option.produces_in_august ? "X" : "O"}</TableCell>
                                    <TableCell padding={'none'}>{option.produces_in_september ? "X" : "O"}</TableCell>
                                    <TableCell padding={'none'}>{option.produces_in_october ? "X" : "O"}</TableCell>
                                    <TableCell padding={'none'}>{option.produces_in_november ? "X" : "O"}</TableCell>
                                    <TableCell padding={'none'}>{option.produces_in_december ? "X" : "O"}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableCell>
                    <TableCell align="right">{option.sale_quantity_for_unit}</TableCell>
                    <TableCell align="right">{option.week_sales_value}</TableCell>
                    <TableCell align="right">{option.week_production_capacity}</TableCell>
                    <TableCell align="right">
                        <Fab variant="extended" color="primary" onClick={() => this.removeProd(option)}>
                            <Delete />
                        </Fab>
                    </TableCell>
                </TableRow>
            ))
        )
    }

    render() {
        console.log("Meu state: ", this.state)
        return (
            <div style={{paddingTop:"2rem"}}>
             <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>

                    <Grid className={this.props.classes.root} item sm={12}  md={4}>
                    <TextField
                        id="select-produto"
                        select
                        label="Produto"
                        value={this.state.product_id}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => this.setState({product_id: e.target.value})}
                        variant="outlined"
                        margin="dense"
                        className={this.props.classes.textField}
                    
                    >
                        {
                           !!this.props.products && this.props.products.map(option => (
                            <MenuItem key={`select-produto-${option.product_id}`} value={option.product_id}>
                                {option.unified_product}
                            </MenuItem>                           
                            ))
                        
                        }
                    </TextField>
                    </Grid>
                    {/* <Grid className={this.props.classes.root} item  sm={12}  md={4}>
                        <TextField
                            id="select-unidade-preodutor"
                            select
                            label="Unidade Produtor"
                            value={this.state.measure_unit_id}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => this.setState({measure_unit_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                            className={this.props.classes.textField}
                        >
                            {
                                !!this.props.productUnit && this.props.productUnit.map(option => (
                                <MenuItem key={`select-unidade-preodutor-${option.productive_unit_id}`} value={option.productive_unit_id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid> */}
                    <Grid className={this.props.classes.root} item sm={12}  md={4}>
                        <TextField className={this.props.classes.textField} label="Quantas Unidades fazem 1kg?" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({amount_units_for_kilo: e.target.value.replace(/[^\d]+/g,'') })} value={this.state.amount_units_for_kilo} variant="outlined" margin="dense" />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item  sm={12}  md={6}>
                        <TextField  className={this.props.classes.textField} label="Quantidade de produção por semana (KG)" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({week_production_quantity: e.target.value.replace(/[^\d]+/g,'') })} value={this.state.week_production_quantity} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item  sm={12}  md={6}>
                        <span>Meses da produção 

                        </span>
                        <FormControlLabel
                            key="c_todos"
                            control={
                            <Checkbox
                                checked={this.state.todos_meses}
                                onChange={(e) => this.marcaTodosMeses(e.target.checked)}
                                color="primary"
                            />
                            }
                            className={this.props.classes.ContentCheckBox}
                            name="selectAll"
                            labelPlacement="bottom"
                            label="Todos"
                        />
                        <Grid container spacing={0}>
                            <Grid className={this.props.classes.root} item xl={1}>
                                <FormControlLabel
                                key="c_j"
                                    control={
                                    <Checkbox
                                        checked={this.state.produces_in_january}
                                        onChange={(e) => this.setState({produces_in_january: e.target.checked})}
                                        color="primary"
                                    />
                                    }
                                    className={this.props.classes.ContentCheckBox}
                                    labelPlacement="bottom"
                                    label="J"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={1}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.produces_in_february}
                                        onChange={(e) => this.setState({produces_in_february: e.target.checked})}
                                        color="primary"
                                    />
                                    }
                                    className={this.props.classes.ContentCheckBox}
                                    labelPlacement="bottom"
                                    label="F"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={1}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.produces_in_march}
                                        onChange={(e) => this.setState({produces_in_march: e.target.checked})}
                                        color="primary"
                                    />
                                    }
                                    className={this.props.classes.ContentCheckBox}
                                    labelPlacement="bottom"
                                    label="M"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={1}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.produces_in_april}
                                        onChange={(e) => this.setState({produces_in_april: e.target.checked})}
                                        color="primary"
                                    />
                                    }
                                    className={this.props.classes.ContentCheckBox}
                                    labelPlacement="bottom"
                                    label="A"
                                />
                            </Grid>

                            <Grid className={this.props.classes.root} item xl={1}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.produces_in_may}
                                        onChange={(e) => this.setState({produces_in_may: e.target.checked})}
                                        color="primary"
                                    />
                                    }
                                    className={this.props.classes.ContentCheckBox}
                                    labelPlacement="bottom"
                                    label="M"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={1}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.produces_in_june}
                                        onChange={(e) => this.setState({produces_in_june: e.target.checked})}
                                        color="primary"
                                    />
                                    }
                                    className={this.props.classes.ContentCheckBox}
                                    labelPlacement="bottom"
                                    label="J"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={1}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.produces_in_july}
                                        onChange={(e) => this.setState({produces_in_july: e.target.checked})}
                                        color="primary"
                                    />
                                    }
                                    className={this.props.classes.ContentCheckBox}
                                    labelPlacement="bottom"
                                    label="J"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={1}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.produces_in_august}
                                        onChange={(e) => this.setState({produces_in_august: e.target.checked})}
                                        color="primary"
                                    />
                                    }
                                    className={this.props.classes.ContentCheckBox}
                                    labelPlacement="bottom"
                                    label="A"
                                />
                            </Grid>

                            <Grid className={this.props.classes.root} item xl={1}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.produces_in_september}
                                        onChange={(e) => this.setState({produces_in_september: e.target.checked})}
                                        color="primary"
                                    />
                                    }
                                    className={this.props.classes.ContentCheckBox}
                                    labelPlacement="bottom"
                                    label="S"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={1}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.produces_in_october}
                                        onChange={(e) => this.setState({produces_in_october: e.target.checked})}
                                        color="primary"
                                    />
                                    }
                                    className={this.props.classes.ContentCheckBox}
                                    labelPlacement="bottom"
                                    label="O"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={1}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.produces_in_november}
                                        onChange={(e) => this.setState({produces_in_november: e.target.checked})}
                                        color="primary"
                                    />
                                    }
                                    className={this.props.classes.ContentCheckBox}
                                    labelPlacement="bottom"
                                    label="N"
                                />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={1}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.produces_in_december}
                                        onChange={(e) => this.setState({produces_in_december: e.target.checked})}
                                        color="primary"
                                    />
                                    }
                                    className={this.props.classes.ContentCheckBox}
                                    labelPlacement="bottom"
                                    label="D"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={this.props.classes.root} item sm={12}  md={6}>
                        <TextField 
                        className={this.props.classes.textField} label="Disponibilidade semanal (KG)" InputLabelProps={{shrink: true}} onChange={(e) => this.setState({sale_quantity_for_unit: e.target.value.replace(/[^\d]+/g,'') })} value={this.state.sale_quantity_for_unit} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item  sm={12}  md={6}>
                        <TextField  className={this.props.classes.textField} label="Valor do produto (R$)" InputLabelProps={{shrink: true}} onChange={(e) => this.setState({week_sales_value: e.target.value.replace(/[^\d]+/g,'') })} value={this.state.week_sales_value} variant="outlined" margin="dense" />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item sm={12}  md={6}>
                        <TextField className={this.props.classes.textField} label="Capacidade de produção semanal (Quanto consegue produzir)" InputLabelProps={{shrink: true}} onChange={(e) => this.setState({week_production_capacity: e.target.value.replace(/[^\d]+/g,'') })} value={this.state.week_production_capacity} variant="outlined" margin="dense" />
                    </Grid>
                    <Grid className={this.props.classes.root} item sm={12}  md={6}>
                        <Fab variant="extended" color="primary" className={this.props.classes.textField} onClick={() => this.submitIt()}>
                            <Save />
                            Inserir
                        </Fab>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <TableContainer component={Paper}>
                            <Table className={this.props.classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nº</TableCell>
                                        <TableCell align="right">Produto</TableCell>
                                        <TableCell align="right">Quantidade produção por semana(kg)</TableCell>
                                        <TableCell align="right">Quantas Unidades fazem 1kg?</TableCell>
                                        <TableCell align="center">
                                            Meses da Produção
                                            <br/>
                                            <Table size="small" aria-label="a dense table">
                                                <TableBody>
                                                    <TableCell padding={'none'}>J</TableCell>
                                                    <TableCell padding={'none'}>F</TableCell>
                                                    <TableCell padding={'none'}>M</TableCell>
                                                    <TableCell padding={'none'}>A</TableCell>
                                                    <TableCell padding={'none'}>M</TableCell>
                                                    <TableCell padding={'none'}>J</TableCell>
                                                    <TableCell padding={'none'}>J</TableCell>
                                                    <TableCell padding={'none'}>A</TableCell>
                                                    <TableCell padding={'none'}>S</TableCell>
                                                    <TableCell padding={'none'}>O</TableCell>
                                                    <TableCell padding={'none'}>N</TableCell>
                                                    <TableCell padding={'none'}>D</TableCell>
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                        <TableCell align="right">Disponibilidade semanal (KG)</TableCell>
                                        <TableCell align="right">Valor do produto (R$)</TableCell>
                                        <TableCell align="right">Capacidade de produção por semana(Quanto consegue produzir)</TableCell>
                                        <TableCell align="right">Apagar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {!!this.state.user_id && 
                                    <Query query={FARMER_PRODUCTS} variables={{user_id: this.state.user_id }}>
                                        {({ loading, error, data }) => {
                                            if (loading) {
                                                return("Buscando ..." );
                                            }else if(error){
                                                console.log("Error",error);
                                            }
                                            console.log("RETORNO: ",data);
                                            
                                            return this.renderResult(data.FarmerQuery[0].farmerProducts);
                                        }}
                                    </Query>
                                }
                                    
                                   



                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                {/* <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={6}>
                        <Fab variant="extended" color="primary" className={this.props.classes.textField}>
                            <Print />
                            Imprimir
                        </Fab>
                    </Grid>
                </Grid> */}
            </div>
        )
    }
}export default withApollo(withStyles(styles)(Tabela));
