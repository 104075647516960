import React from 'react';
import { withApollo, Query } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import { EDIT_CUSTOMER_LOGISTIC } from '../../../services/graphql/mutations/customer';
import CustomErrors from '../../CustomErrors/CustomErrors';

import {
    TextField,
    MenuItem,
    Grid,
    FormControlLabel,
    Checkbox,
    Fab
} from '@material-ui/core';

import { Print, Save } from '@material-ui/icons';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%',
    },
    title: {
        paddingTop: '3rem',
    }
}));

function Logistica(props) {
    const classes = useStyles();
    let horas = [];
    let minutos = [];
    for(let i=0; i <= 23; i++){
        horas.push({
            value: i,
            label: i,
        })
    }
    for(let i=0; i <= 59; i++){
        minutos.push({
            value: i,
            label: i,
        })
    }
    const [state, setState] = React.useState({
        user_id: !!props.customer ? props.customer.id : null,
        needs_local_logistics: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.needs_local_logistics : null,
        can_fetch_product: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.can_fetch_product : null,
        own_vehicle: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.own_vehicle : null,
        outsourced_freight: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.outsourced_freight : null,
        receives_only_on_place: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.receives_only_on_place : null,
        uses_solidarity_freight: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.uses_solidarity_freight : null,
        monday_morning_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.monday_morning_until : null,
        monday_afternoon_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.monday_afternoon_until : null,
        monday_night_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.monday_night_until : null,
        tuesday_morning_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.tuesday_morning_until : null,
        tuesday_afternoon_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.tuesday_afternoon_until : null,
        tuesday_night_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.tuesday_night_until : null,
        wednesday_morning_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.wednesday_morning_until : null,
        wednesday_afternoon_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.wednesday_afternoon_until : null,
        wednesday_night_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.wednesday_night_until : null,
        thursday_morning_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.thursday_morning_until : null,
        thursday_afternoon_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.thursday_afternoon_until : null,
        thursday_night_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.thursday_night_until : null,
        friday_morning_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.friday_morning_until : null,
        friday_afternoon_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.friday_afternoon_until : null,
        friday_night_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.friday_night_until : null,
        saturday_morning_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.saturday_morning_until : null,
        saturday_afternoon_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.saturday_afternoon_until : null,
        saturday_night_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.saturday_night_until : null,
        sunday_morning_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.sunday_morning_until : null,
        sunday_afternoon_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.sunday_afternoon_until : null,
        sunday_night_until: !!props.customer && !!props.customer.customerLogistic ? props.customer.customerLogistic.sunday_night_until : null,
        error: [],
    });
    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });
    };
    const handleChangeTime = name => event => {
        setState({ ...state, [name]: event.target.value });
    };

    const submitIt = () => {
        let paramsEnviar = Object.entries(state).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        props.client.mutate({mutation: EDIT_CUSTOMER_LOGISTIC, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    setState({...state, error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    setState({...state, error: errosMostrar})
                }
            } else {
                console.log("DATA: ", data);
                setState({...state, error: []});
                alert("Salvo com sucesso !");
            }
        })
    }

    return(
        <div>
            <CustomErrors error={state.error}/>

            <h4>Logística de recebimento de carga</h4>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.needs_local_logistics}
                                onChange={handleChange('needs_local_logistics')}
                                color="primary"
                            />
                        }
                        label="Precisa ser local"
                    />
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.can_fetch_product}
                                onChange={handleChange('can_fetch_product')}
                                color="primary"                          
                            />
                        }
                        label="Pode buscar produto"
                    />
                </Grid>
                <Grid className={classes.root} item xl={3}></Grid>
                <Grid className={classes.root} item xl={3}></Grid>
            </Grid>
            <h4>Qual tipo de frete utilizado</h4>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.own_vehicle}
                                onChange={handleChange('own_vehicle')}
                                color="primary"
                            />
                        }
                        label="Veículo próprio para coletar"
                    />
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.outsourced_freight}
                                onChange={handleChange('outsourced_freight')}
                                color="primary"
                            />
                        }
                        label="Terceiriza frete"
                    />
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.receives_only_on_place}
                                onChange={handleChange('receives_only_on_place')}
                                color="primary"
                            />
                        }
                        label="Só recebe no local"
                    />
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.uses_solidarity_freight}
                                onChange={handleChange('uses_solidarity_freight')}
                                color="primary"
                            />
                        }
                        label="Usa frete solidário - compartilha com outros parceiros"
                    />
                </Grid>
            </Grid>
            <h4>Dias/Horário recebimento produtos orgânicos</h4>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={3}><strong><i>Dia / Hora</i></strong></Grid>
                <Grid className={classes.root} item xl={3}>
                    <strong>Manhã até as</strong>
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <strong>Tarde até as</strong>
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <strong>Noite até as</strong>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={3}>
                    <strong>Segunda-feira</strong>
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.monday_morning_until}
                                onChange={handleChangeTime('monday_morning_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.monday_afternoon_until}
                                onChange={handleChangeTime('monday_afternoon_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.monday_night_until}
                                onChange={handleChangeTime('monday_night_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={3}>
                    <strong>Terça-feira</strong>
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.tuesday_morning_until}
                                onChange={handleChangeTime('tuesday_morning_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.tuesday_afternoon_until}
                                onChange={handleChangeTime('tuesday_afternoon_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.tuesday_night_until}
                                onChange={handleChangeTime('tuesday_night_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <strong>Quarta-feira</strong>
                </Grid>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.wednesday_morning_until}
                                onChange={handleChangeTime('wednesday_morning_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.wednesday_afternoon_until}
                                onChange={handleChangeTime('wednesday_afternoon_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.wednesday_night_until}
                                onChange={handleChangeTime('wednesday_night_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <strong>Quinta-feira</strong>
                </Grid>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.thursday_morning_until}
                                onChange={handleChangeTime('thursday_morning_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.root} item xl={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.thursday_afternoon_until}
                                onChange={handleChangeTime('thursday_afternoon_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.thursday_night_until}
                                onChange={handleChangeTime('thursday_night_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <strong>Sexta-feira</strong>
                </Grid>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.friday_morning_until}
                                onChange={handleChangeTime('friday_morning_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.friday_afternoon_until}
                                onChange={handleChangeTime('friday_afternoon_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.friday_night_until}
                                onChange={handleChangeTime('friday_night_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <strong>Sábado</strong>
                </Grid>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.saturday_morning_until}
                                onChange={handleChangeTime('saturday_morning_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.saturday_afternoon_until}
                                onChange={handleChangeTime('saturday_afternoon_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.saturday_night_until}
                                onChange={handleChangeTime('saturday_night_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <strong>Domingo</strong>
                </Grid>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.sunday_morning_until}
                                onChange={handleChangeTime('sunday_morning_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.sunday_afternoon_until}
                                onChange={handleChangeTime('sunday_afternoon_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.root} item xl={3} xs={3}>
                    <Grid container spacing={3}>
                        <Grid className={classes.root} item xl={12}>
                            <TextField
                                type="time"
                                value={state.sunday_night_until}
                                onChange={handleChangeTime('sunday_night_until')}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.root} item xl={6}>
                    <Fab variant="extended" className={classes.textField} color="primary" onClick={() => submitIt()}>
                        <Save />
                        Salvar
                    </Fab>
                </Grid>
        </div>
    );
}export default withApollo(Logistica)