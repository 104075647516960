import React, { Component } from 'react';
import { withApollo, Query } from 'react-apollo';
import { EDIT_FARMER_PROPERTY } from '../../../services/graphql/mutations/farmer';
import { withStyles } from '@material-ui/core/styles';
import CustomErrors from '../../CustomErrors/CustomErrors';

import {
    TextField,
    Grid,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Fab,
} from '@material-ui/core';
import { Add, Print, Save } from '@material-ui/icons';


const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    radioClass: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",   
        padding: "3",     
    },    
    divisor: {
        width: "100%",
        height: "3px",
        backgroundColor: "#000",
        marginTop: "3px",
        marginBottom: "10px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
});

class Propriedade extends Component { 

    constructor(props) {
        super(props);
        this.state = {
            edit: null,
            id: null,
            user_id: null,
            name: null,
            country: 'BRASIL',
            state: null,
            city: null,
            address: null,
            address_number: null,
            address_complement: null,
            zip_code: null,
            reference_point: null,
            latitude: null,
            longitude: null,
            district: null,
            land_owner: null,
            legal_right: null,
            other_legal_right: null,
            property_size: null,
            productive_area_size: null,
            production_area_size: null,
            agroindustrial_area: null,
            organic_area: null,
            farmers_quantity_by_unit: null,
            resides_in_property: null,
            local_residence: null,
            certification: null,
            has_house: null,
            has_deposit: null,
            has_corral: null,
            has_hennery: null,
            has_cocoa_barge: null,
            has_bathroom: null,
            has_dry_toilet: null,
            has_electricity: null,
            has_vegetable_garden: null,
            has_dike: null,
            has_seedling_nursery: null,
            has_cold_chamber: null,
            has_paileteria: null,
            has_caixaria: null,
            has_weigher: null,
            has_beneficiation_kitchen: null,
            others_improvements: null,
            has_tractor: null,
            has_tobata: null,
            has_dehydrator: null,
            has_transport: null,
            has_irrigation: null,
            others_equipments: null,
            has_drip_irrigation: null,
            has_sprinkler_irrigation: null,
            others_irrigation_type: null,
            uses_own_water_mine: null,
            uses_cistern: null,
            uses_weir: null,
            uses_mine_off_property: null,
            uses_river_stream: null,
            uses_underground_water: null,
            uses_artesian: null,
            uses_circular: null,
            others_water_sources: null,
            cubic_meters_used: null,
            error: [],
        };
    } 
    
    
    componentWillReceiveProps(props){
        if(props.farmer){
            this.setState({'edit': true});
            let farmer = props.farmer;
            this.setState({
                user_id: farmer.id,
            });
            if(farmer.farmerProperty && farmer.farmerProperty != null){
                this.setState({
                    id: farmer.farmerProperty.id,
                    user_id: farmer.farmerProperty.user_id,
                    name: farmer.farmerProperty.name,
                    country: farmer.farmerProperty.country,
                    state: farmer.farmerProperty.state,
                    city: farmer.farmerProperty.city,
                    address: farmer.farmerProperty.address,
                    address_number: farmer.farmerProperty.address_number,
                    address_complement: farmer.farmerProperty.address_complement,
                    zip_code: farmer.farmerProperty.zip_code,
                    reference_point: farmer.farmerProperty.reference_point,
                    district: farmer.farmerProperty.district,
                    latitude: farmer.farmerProperty.latitude,
                    longitude: farmer.farmerProperty.longitude,
                    land_owner: farmer.farmerProperty.land_owner ? 'S' : 'N',
                    legal_right: farmer.farmerProperty.legal_right,
                    other_legal_right: farmer.farmerProperty.other_legal_right,
                    property_size: farmer.farmerProperty.property_size,
                    productive_area_size: farmer.farmerProperty.productive_area_size,
                    production_area_size: farmer.farmerProperty.production_area_size,
                    agroindustrial_area: farmer.farmerProperty.agroindustrial_area,
                    organic_area: farmer.farmerProperty.organic_area,
                    farmers_quantity_by_unit: farmer.farmerProperty.farmers_quantity_by_unit,
                    resides_in_property: farmer.farmerProperty.resides_in_property ? 'S' : 'N',
                    local_residence: farmer.farmerProperty.local_residence,
                    certification: farmer.farmerProperty.certification,
                    has_house: farmer.farmerProperty.has_house,
                    has_deposit: farmer.farmerProperty.has_deposit,
                    has_corral: farmer.farmerProperty.has_corral,
                    has_hennery: farmer.farmerProperty.has_hennery,
                    has_cocoa_barge: farmer.farmerProperty.has_cocoa_barge,
                    has_bathroom: farmer.farmerProperty.has_bathroom,
                    has_dry_toilet: farmer.farmerProperty.has_dry_toilet,
                    has_electricity: farmer.farmerProperty.has_electricity,
                    has_vegetable_garden: farmer.farmerProperty.has_vegetable_garden,
                    has_dike: farmer.farmerProperty.has_dike,
                    has_seedling_nursery: farmer.farmerProperty.has_seedling_nursery,
                    has_cold_chamber: farmer.farmerProperty.has_cold_chamber,
                    has_paileteria: farmer.farmerProperty.has_paileteria,
                    has_caixaria: farmer.farmerProperty.has_caixaria,
                    has_weigher: farmer.farmerProperty.has_weigher,
                    has_beneficiation_kitchen: farmer.farmerProperty.has_beneficiation_kitchen,
                    others_improvements: farmer.farmerProperty.others_improvements,
                    has_tractor: farmer.farmerProperty.has_tractor,
                    has_tobata: farmer.farmerProperty.has_tobata,
                    has_dehydrator: farmer.farmerProperty.has_dehydrator,
                    has_transport: farmer.farmerProperty.has_transport,
                    has_irrigation: farmer.farmerProperty.has_irrigation,
                    others_equipments: farmer.farmerProperty.others_equipments,
                    has_drip_irrigation: farmer.farmerProperty.has_drip_irrigation,
                    has_sprinkler_irrigation: farmer.farmerProperty.has_sprinkler_irrigation,
                    others_irrigation_type: farmer.farmerProperty.others_irrigation_type,
                    uses_own_water_mine: farmer.farmerProperty.uses_own_water_mine,
                    uses_cistern: farmer.farmerProperty.uses_cistern,
                    uses_weir: farmer.farmerProperty.uses_weir,
                    uses_mine_off_property: farmer.farmerProperty.uses_mine_off_property,
                    uses_river_stream: farmer.farmerProperty.uses_river_stream,
                    uses_underground_water: farmer.farmerProperty.uses_underground_water,
                    uses_artesian: farmer.farmerProperty.uses_artesian,
                    uses_circular: farmer.farmerProperty.uses_circular,
                    others_water_sources: farmer.farmerProperty.others_water_sources,
                    cubic_meters_used: farmer.farmerProperty.cubic_meters_used,
                });
            }
        }
    }
    submitIt = () => {
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        
        if(!!paramsEnviar.land_owner){
            if(paramsEnviar.land_owner == 'S'){
                paramsEnviar.land_owner = true;
            }else{
                paramsEnviar.land_owner = false;
            }
        } 
        if(!!paramsEnviar.resides_in_property){
            if(paramsEnviar.resides_in_property == 'S'){
                paramsEnviar.resides_in_property = true;
            }else{
                paramsEnviar.resides_in_property = false;
            }
        } 

        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate({mutation: EDIT_FARMER_PROPERTY, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                if(errors[0].message == 'validation'){
                    let erroValid = errors[0].extensions.validation;
                    let errosMostrar = [];
                    let i = 0;
                    for (var [key, value] of Object.entries(erroValid)) {
                        console.log("ERRO... " + key + ' ',value);
                        errosMostrar[i] = {message: value};
                        i++;
                    }
                    this.setState({error: errosMostrar})
                }else{
                    let errosMostrar = [];
                    for(var [key, value] of Object.entries(errors)){
                        errosMostrar[key] = {message: value.message};
                    }
                    this.setState({error: errosMostrar})
                }
               

            } else {
                console.log("DATA: ", data);
                this.setState({error: []})
               alert("Salvo com sucesso !");

            }
        })
    }

      
    render() {
        return (
            <div>
                <CustomErrors error={this.state.error}/>
                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField id="nome_propriedade" label="Nome da Propriedade" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({name: e.target.value})} value={this.state.name}/>
                        <TextField id="endereco_propriedade" label="Endereço" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({address: e.target.value})} value={this.state.address}/>
                        <TextField id="bairro_propriedade" label="Bairro" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({district: e.target.value})} value={this.state.district}/>                    
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField id="latitude_propriedade" label="Latitude" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({latitude: e.target.value})} value={this.state.latitude}/>
                        <TextField id="numero_propriedade" label="N°" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({address_number: e.target.value})} value={this.state.address_number}/>
                        <TextField id="cidade_propriedade" label="Cidade" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({city: e.target.value})} value={this.state.city}/>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={4}>
                        <TextField id="longitude_propriedade" label="Longitude" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({longitude: e.target.value})} value={this.state.longitude}/>
                        <TextField id="complemento_propriedade" label="Complemento" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({address_complement: e.target.value})} value={this.state.address_complement}/>
                        <TextField id="estado_propriedade" label="Estado" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({state: e.target.value})} value={this.state.state}/>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid className={this.props.classes.root} item xl={12}>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={6}>
                                <FormLabel component="e_proprietario" row><b>Proprietário da terra: </b></FormLabel>
                                <RadioGroup aria-label="e_proprietario" name="e_proprietario" style={{marginLeft: "10px"}} value={this.state.land_owner} onChange={(e) => this.setState({land_owner: e.target.value})} row>
                                    <FormControlLabel value="S" control={<Radio />} label="Sim" />
                                    <FormControlLabel value="N" control={<Radio />} label="Não" />
                                </RadioGroup>                    
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={6}>
                                <FormLabel component="tipo_propriedade"><b>A propriedade é:  </b></FormLabel> 
                                <RadioGroup aria-label="tipo_propriedade" name="tipo_propriedade" style={{marginLeft: "10px"}} value={this.state.legal_right} onChange={(e) => this.setState({legal_right: e.target.value})} row>
                                    <FormControlLabel value="tenure" control={<Radio />} label="Posse" />
                                    <FormControlLabel value="deed" control={<Radio />} label="Escritura" />
                                    <FormControlLabel value="other" control={<Radio />} label="Outros" />
                                </RadioGroup>      
                                <TextField id="tipo_propriedade_outro" label="Qual?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({other_legal_right: e.target.value})} value={this.state.other_legal_right}/>
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="hectares_propriedade" label="Tamanho Propriedade (em hectares)" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({property_size: e.target.value})} value={this.state.property_size} variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="hectares_producao"  label="Tamanho Área Produtiva (em hectares)" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({productive_area_size: e.target.value})} value={this.state.productive_area_size} variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="hectares_produtiva"  label="Tamanho Área em produção (em hectares)" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({production_area_size: e.target.value})} value={this.state.production_area_size} variant="outlined" margin="dense" />
                            </Grid>

                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="hectares_agroindustrial"  label="Tamanho Área Total Agroindustria (em hectares)" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({agroindustrial_area: e.target.value})} value={this.state.agroindustrial_area} variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="hectares_organica"  label="Tamanho Área Organica (em hectares)" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({organic_area: e.target.value})} value={this.state.organic_area} variant="outlined" margin="dense" />
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={4}>
                                <TextField id="hectares_quantidade_agricultores"  label="Quantidade agricultores por unidade" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({farmers_quantity_by_unit: e.target.value})} value={this.state.farmers_quantity_by_unit} variant="outlined" margin="dense" />
                            </Grid>

                            <Grid className={this.props.classes.root} item xl={12}>
                                <FormLabel component="e_residente" row><b>Reside na propriedade? </b></FormLabel>
                                <RadioGroup aria-label="e_residente" name="e_residente" style={{marginLeft: "10px"}} value={this.state.resides_in_property} onChange={(e) => this.setState({resides_in_property: e.target.value})}  row>
                                        <FormControlLabel value="S" control={<Radio />} label="Sim" />
                                        <FormControlLabel value="N" control={<Radio />} label="Não" />
                                </RadioGroup>     
                                <TextField id="e_residente_local" label="Aonde?" InputLabelProps={{shrink: true,}} variant="outlined" margin="dense" onChange={(e) => this.setState({local_residence: e.target.value})} value={this.state.local_residence} />                  
                            </Grid>
                            <Grid className={this.props.classes.root} item xl={12}>
                                <FormLabel component="certificacao" row><b>Certificação:  </b></FormLabel>
                                <RadioGroup aria-label="certificacao" name="certificacao" style={{marginLeft: "10px"}}  value={this.state.certification} onChange={(e) => this.setState({certification: e.target.value})} row>
                                        <FormControlLabel value="audited" control={<Radio />} label="Auditada" />
                                        <FormControlLabel value="participatory" control={<Radio />} label="Participativa" />
                                        <FormControlLabel value="ocs" control={<Radio />} label="OCS" />
                                </RadioGroup>                   
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={12}>
                                <FormLabel component="BemFeitorias" xl={4} row><b>Bem feitorias existentes na propriedade:  </b></FormLabel>
                                <FormGroup>
                                    <Grid container spacing={3}>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_house} onChange={(e) => this.setState({has_house: e.target.checked})}/>} label="Casa" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_deposit} onChange={(e) => this.setState({has_deposit: e.target.checked})} />} label="Depósito" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_hennery} onChange={(e) => this.setState({has_hennery: e.target.checked})}  />} label="Galinheiro" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_cocoa_barge} onChange={(e) => this.setState({has_cocoa_barge: e.target.checked})}  />} label="Bragança Cacau" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_bathroom} onChange={(e) => this.setState({has_bathroom: e.target.checked})}  />} label="Banheiro" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_dry_toilet} onChange={(e) => this.setState({has_dry_toilet: e.target.checked})}  />} label="Banheiro Seco" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_electricity} onChange={(e) => this.setState({has_electricity: e.target.checked})}  />} label="Energia elétrica" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_vegetable_garden} onChange={(e) => this.setState({has_vegetable_garden: e.target.checked})}  />} label="Horta" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_dike} onChange={(e) => this.setState({has_dike: e.target.checked})}  />} label="Represa" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_seedling_nursery} onChange={(e) => this.setState({has_seedling_nursery: e.target.checked})}  />} label="Viveiro de Mudas" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_cold_chamber} onChange={(e) => this.setState({has_cold_chamber: e.target.checked})}  />} label="Câmara Fria" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_paileteria} onChange={(e) => this.setState({has_paileteria: e.target.checked})}  />} label="Paileteria" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_caixaria} onChange={(e) => this.setState({has_caixaria: e.target.checked})}  />} label="Caixaria" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_weigher} onChange={(e) => this.setState({has_weigher: e.target.checked})}  />} label="Balanca" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_beneficiation_kitchen} onChange={(e) => this.setState({has_beneficiation_kitchen: e.target.checked})}  />} label="Cozinha Beneficiamento" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.others_improvements} />} label="Outros" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={4}>
                                            <TextField id="bemFeitorias_adicional" label="Qual?" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({others_improvements: e.target.value})} value={this.state.others_improvements} variant="outlined" margin="dense" /> 
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={12}>
                                <FormLabel component="equipamentos" xl={4} row><b>Equipamentos Existentes:  </b></FormLabel>
                                <FormGroup>
                                    <Grid container spacing={3}>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_tractor} onChange={(e) => this.setState({has_tractor: e.target.checked})} />} label="Trator" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_irrigation} onChange={(e) => this.setState({has_irrigation: e.target.checked})} />} label="Irrigacao" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_tobata} onChange={(e) => this.setState({has_tobata: e.target.checked})} />} label="Tobata" />
                                            </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_dehydrator} onChange={(e) => this.setState({has_dehydrator: e.target.checked})} />} label="Desidratador" />
                                            </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_transport} onChange={(e) => this.setState({has_transport: e.target.checked})} />} label="Transporte" />
                                            </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.others_equipments} />} label="Outros" />
                                            </Grid>
                                        <Grid className={this.props.classes.root} item xl={6}>
                                            <TextField id="equips_adicional" label="Qual?" InputLabelProps={{shrink: true,}}  onChange={(e) => this.setState({others_equipments: e.target.value})} value={this.state.others_equipments} variant="outlined" margin="dense" />    
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={12}>
                                <FormLabel component="tipo_irrigacao" row><b>Tipo Irrigação:  </b></FormLabel>
                                <FormGroup>
                                    <Grid container spacing={3}>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_drip_irrigation} onChange={(e) => this.setState({has_drip_irrigation: e.target.checked})}/>} label="Gotejamento" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.has_sprinkler_irrigation} onChange={(e) => this.setState({has_sprinkler_irrigation: e.target.checked})} />} label="Micro Aspersão" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.others_irrigation_type}  />} label="Outros" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <TextField id="tipo_irrigacao_outro" label="Qual?" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({others_irrigation_type: e.target.value})} value={this.state.others_irrigation_type}  variant="outlined" margin="dense" />  
                                        </Grid>
                                    </Grid>
                                </FormGroup>  
                                            
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid className={this.props.classes.root} item xl={12}>
                                <FormLabel component="fonte_agua" row><b>Fonte de água utilizada:  </b></FormLabel>
                                <FormGroup>
                                    <Grid container spacing={3}>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.uses_own_water_mine} onChange={(e) => this.setState({uses_own_water_mine: e.target.checked})} />} label="Mina própria/nascente/olho d'agua" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.uses_mine_off_property} onChange={(e) => this.setState({uses_mine_off_property: e.target.checked})} />} label="Mina fora da propriedade" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.uses_cistern} onChange={(e) => this.setState({uses_cistern: e.target.checked})} />} label="Cisterna" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.uses_weir} onChange={(e) => this.setState({uses_weir: e.target.checked})} />} label="Açude" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.uses_river_stream} onChange={(e) => this.setState({uses_river_stream: e.target.checked})} />} label="Rio ou Riacho" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.uses_underground_water} onChange={(e) => this.setState({uses_underground_water: e.target.checked})} />} label="Água Subterrânea" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.uses_artesian} onChange={(e) => this.setState({uses_artesian: e.target.checked})} />} label="Artesiano" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={3}>
                                            <FormControlLabel control={<Checkbox checked={this.state.uses_circular} onChange={(e) => this.setState({uses_circular: e.target.checked})} />} label="Circular" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={4}>
                                            <FormControlLabel control={<Checkbox checked={this.state.others_water_sources}  />} label="Outros" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={4}>
                                            <TextField id="fonte_agua_outros" label="Qual?" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({others_water_sources: e.target.value})} value={this.state.others_water_sources} variant="outlined" margin="dense" />
                                        </Grid>
                                        <Grid className={this.props.classes.root} item xl={4}>
                                            <TextField id="fonte_agua_outros" label="Metros cúbicos utilizados por dia" InputLabelProps={{shrink: true,}} onChange={(e) => this.setState({cubic_meters_used: e.target.value})} value={this.state.cubic_meters_used} variant="outlined" margin="dense" />
                                        </Grid>
                                    </Grid>
                                </FormGroup>            
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            {/* <Grid className={this.props.classes.root} item xl={6}>
                                <Fab variant="extended" color="primary">
                                    <Print />
                                    Imprimir
                                </Fab>
                            </Grid> */}
                            <Grid className={this.props.classes.root} item xl={6}>
                                <Fab variant="extended" color="primary" onClick={() => this.submitIt()}>
                                    <Save />
                                    Salvar
                                </Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            
        );
    }
}export default withApollo((withStyles(styles))(Propriedade));
