import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withApollo, Query } from 'react-apollo';
import CustomErrors from '../CustomErrors/CustomErrors';

import { RELATORIO_FARMER_STATION, COMBOS_DEMANDA_ESTIMADA } from "../../services/graphql/querys/relatorio";
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    Button,
    TextField,
    Select,
    MenuItem,
    TableFooter
} from '@material-ui/core/';
import {Save, Print} from '@material-ui/icons';
//import { makeStyles, useTheme } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});
// const useStyles = makeStyles((themes) => ({
//     formControl: {
//       margin: theme.spacing(1),
//       minWidth: 120,
//       maxWidth: 300,
//     },
//     chips: {
//       display: 'flex',
//       flexWrap: 'wrap',
//     },
//     chip: {
//       margin: 2,
//     },
//     noLabel: {
//       marginTop: theme.spacing(3),
//     },
//   }));
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };


let UserAccount = localStorage.getItem('UserAccount') ? JSON.parse(localStorage.getItem('UserAccount')) : undefined;
console.log("UserAccount", UserAccount)
class Agricultores extends Component {
    constructor(props){
        super(props);
        this.state ={}
    }
    componentWillMount(){
        this.props.client.query({query: COMBOS_DEMANDA_ESTIMADA, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else { 
                this.setState({
                    estacoes: data.StationQuery,
                    grupos: data.GroupQuery,
                    nucleos: data.CoreQuery
                }) 
            }
        })
        .catch(erro => console.log(erro))
    }
    render(){
        return (
            <div className="page-wrapper" style={{bottom: 0, top: '70px', right: 0, left: 0, position: 'fixed', overflowY: 'auto'}}>
                <h1 style={{marginBottom: "1rem"}}>Relatorio de Agricultores</h1>
                <Grid container spacing={3} style={{marginBottom: "1rem"}}>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField
                            label="Agricultor: "
                            className={this.props.classes.textField} 
                            value={this.state.name}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({name: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                        </TextField>
                    </Grid>
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField
                            select
                            label="Núcleo: "
                            className={this.props.classes.textField} 
                            value={this.state.core_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({core_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                            <MenuItem key={`option-opac-nucleo-null`} value="">Todos Núcleos</MenuItem>
                            {
                                this.state.nucleos &&
                                this.state.nucleos.map(
                                    (nucleo, index) => {
                                        console.log(nucleo);
                                        return <MenuItem key={`option-opac-nucleo-${index}`} value={nucleo.core_id}>{nucleo.name}</MenuItem>
                                    }
                                )
                            }
                        </TextField>
                    </Grid>
                    {
                        UserAccount && UserAccount.profile.user_type === 'admin_central' &&
                        <Grid className={this.props.classes.root} item xl={3}>
                            <TextField
                                select
                                label="Estação: "
                                className={this.props.classes.textField} 
                                value={this.state.station_id}
                                InputLabelProps={{shrink: true,}}
                                onChange={(e) => this.setState({station_id: e.target.value})}
                                variant="outlined"
                                margin="dense"
                            >
                                <MenuItem key={`option-demanda-real-estacoes-null`} value="">Todas Estações</MenuItem>
                                {
                                    this.state.estacoes &&
                                    this.state.estacoes.map(
                                        (estacao, index) => {
                                            console.log(estacao);
                                            return <MenuItem key={`option-demanda-real-estacoes-${index}`} value={estacao.id}>{estacao.name}</MenuItem>
                                        }
                                    )
                                }
                            </TextField>
                        </Grid>
                    }
                    <Grid className={this.props.classes.root} item xl={3}>
                        <TextField
                            select
                            label="Grupo: "
                            className={this.props.classes.textField} 
                            value={this.state.group_id}
                            InputLabelProps={{shrink: true,}}
                            onChange={(e) => this.setState({group_id: e.target.value})}
                            variant="outlined"
                            margin="dense"
                        >
                            <MenuItem key={`option-agricultor-grupo-null`} value="">Todos Grupos</MenuItem>
                            {
                                this.state.grupos &&
                                this.state.grupos.map(
                                    (grupo, index) => {
                                        console.log(grupo);
                                        return <MenuItem key={`option-agricultor-grupo-${index}`} value={grupo.group_id}>{grupo.name}</MenuItem>
                                    }
                                )
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <CustomErrors error={this.state.error}/>
                <Query query={RELATORIO_FARMER_STATION} variables={
                    {
                        station_id: this.state.station_id,
                        group_id: this.state.group_id,
                        core_id: this.state.core_id,
                        name: this.state.name,
                    }
                }>
                    {
                        ({ loading, error, data }) => {
                            if (loading) {
                                return("Buscando ..." );
                            } else if(error) {
                                console.log("Error",error);
                            } else {
                                console.log("DATA: ",data);
                                return <TableContainer component={Paper}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="th">Estação</TableCell>
                                                <TableCell align="left">Agricultor</TableCell>
                                                <TableCell align="left">Endereço</TableCell>
                                                <TableCell align="left">Cidade</TableCell>
                                                <TableCell align="left">Estado</TableCell>
                                                <TableCell align="right">CPF/CNPJ</TableCell>
                                                <TableCell align="right">Telefone</TableCell>
                                                <TableCell align="right">Celular</TableCell>
                                                <TableCell align="right">Grupo</TableCell>
                                                <TableCell align="right">Núcleo</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {/* <TableHead>
                                            <TableRow>
                                                <TableCell component="th">Estação</TableCell>
                                                <TableCell align="left">Agricultor</TableCell>
                                                <TableCell align="right">Grupo</TableCell>
                                            </TableRow>
                                        </TableHead> */}
                                        <TableBody>
                                            {
                                                data.ReportFarmerStationQuery.dataFarmerStation.map((row, index) => 
                                                    <TableRow key={`farm-station-${index}`}>
                                                        <TableCell component="th" scope="row">{row.station}</TableCell>
                                                        <TableCell align="left">{row.farmer}</TableCell>
                                                        <TableCell align="left">{row.full_address}, CEP: {row.zip_code}</TableCell>
                                                        <TableCell align="left">{row.city}</TableCell>
                                                        <TableCell align="left">{row.state}</TableCell>
                                                        <TableCell align="right">{row.legal_document}</TableCell>
                                                        <TableCell align="right">{row.phone}</TableCell>
                                                        <TableCell align="right">{row.msisdn}</TableCell>
                                                        <TableCell align="right">{row.group}</TableCell>
                                                        <TableCell align="right">{row.core}</TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        </TableBody>
                                        <TableFooter>
                                            {
                                            data.ReportFarmerStationQuery.dataFarmerStation && data.ReportFarmerStationQuery.dataFarmerStation.length > 0 &&
                                                <TableRow>
                                                    <TableCell align="right" colSpan={10}>
                                                        <Button variant="contained" color="primary" component="a" href={data.ReportFarmerStationQuery.csv.replace("http:","https:")}>
                                                            <Save style={{marginRight:"10px"}}/> Salvar CSV
                                                        </Button>
                                                        <Button variant="contained" color="primary" style={{marginLeft:"10px"}} component="a" onClick={() => window.print()}>
                                                            <Print style={{marginRight:"10px"}}/> Imprimir
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            }
                        }
                    }
                </Query>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Agricultores));